import React from 'react';
import { AcvtSupportedLanguages } from '../../../types/acvt';
import { Text } from '../../../utils/text';
import { AcvtTopTabs } from '../top-tabs';
import style from './style.module.scss';

export const AcvtCanvas = (props: {
    children?: React.ReactNode;
    changeLang: (lang: AcvtSupportedLanguages) => void;
}) => {
    return (
        <>
            <div className={style.canvas}>
                <AcvtTopTabs
                    tabType="card"
                    tabChangeLanguage={props.changeLang}
                    tabsArray={[
                        {
                            name: Text.ACVT.canvas.tabLangEn,
                            key: 'en',
                            children: (
                                <div className={style.iphone}>
                                    {props.children}
                                </div>
                            ),
                        },
                        {
                            name: Text.ACVT.canvas.tabLangFr,
                            key: 'fr',
                            children: (
                                <div className={style.iphone}>
                                    {props.children}
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    );
};
