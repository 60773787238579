import React from 'react';
import style from './style.module.scss';
import { Collapse } from 'antd';
import { ReactComponent as PlusIcon } from '../../icons/Plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/Minus.svg';

const { Panel } = Collapse;

type ExpansionPanelProps = {
    title: string;
    children: React.ReactNode;
    defaultExpanded?: boolean;
};

export const ExpansionPanel = ({
    children,
    title,
    defaultExpanded = false,
}: ExpansionPanelProps) => {
    return (
        <>
            <Collapse
                expandIcon={({ isActive }) =>
                    isActive ? <MinusIcon /> : <PlusIcon />
                }
                className={style.expansion_panel}
                defaultActiveKey={defaultExpanded ? 1 : undefined}
            >
                <Panel header={title} key={1}>
                    {children}
                </Panel>
            </Collapse>
        </>
    );
};
