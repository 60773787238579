import React from 'react';
import style from './style.module.scss';

export const AcvtPlaceholderContent = () => {
    return (
        <>
            <div
                className={style.layout}
                style={{
                    background: 'radial-gradient(circle, lightgrey, white)',
                }}
            >
                <div className={style.header}></div>
                <div className={style.cards_layout}>
                    <div className={`${style.card} ${style.first}`}></div>
                    <div className={`${style.card} ${style.sec}`}></div>
                    <div className={`${style.card} ${style.third}`}></div>
                    <div className={`${style.card} ${style.fourth}`}></div>
                </div>
            </div>
        </>
    );
};
