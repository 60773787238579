import React from 'react';
import style from './style.module.scss';

interface Props<T> {
    elems: T[];
    predicate: (t: T) => boolean;
}

export function ScrollMarker<T>(props: Props<T>) {
    const { elems, predicate } = props;

    const indexes: number[] = [];
    elems.forEach((d, i) => {
        if (predicate(d)) {
            indexes.push(i);
        }
    });

    return (
        <div className={style.scroll_helper}>
            {indexes.map((index) => (
                <div
                    key={index}
                    className={style.scroll_marker}
                    style={{
                        height: `max(${100 / elems.length}%, 2px)`,
                        top: `${(index * 100) / elems.length}%`,
                    }}
                ></div>
            ))}
        </div>
    );
}
