import React from 'react';
import { Button } from 'antd';
import { ScrollMarker, SearchInput } from '../../components';
import {
    diffStores,
    Difference,
    DifferenceMap,
    getAddedStores,
    storeFilter,
    WEEKDAYS,
} from '../../helpers/store-helper';
import { useAuthStore, useStoresStore } from '../../contexts/mobx';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import { Store } from '../../stores/stores';
import { useNavigation } from '../../utils/use-navigation';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useSimpleMap } from '../../helpers/use-simple-map';
import { LeftOutlined } from '@ant-design/icons';

export const StoresDiffPage = observer(() => {
    const store = useStoresStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }
    const [filter, setFilter] = React.useState('');
    const [added, setAdded] = React.useState<Store[]>([]);
    const [
        deselectionMap,
        setDeselection,
        setMultipleDeselection,
    ] = useSimpleMap<boolean>();
    const [differences, setDifferences] = React.useState<DifferenceMap>(
        new DifferenceMap()
    );

    React.useEffect(() => {
        if (store.parsedStore === null) {
            navigation.replaceToStores();
            return;
        }
        const map = diffStores(store.stores!, store.parsedStore);
        const added = getAddedStores(store.stores!, store.parsedStore);
        setAdded(added);
        setDifferences(map);
    }, []);

    const diff = Array.from(differences.values());
    const filteredDiff = diff.filter((diff) =>
        storeFilter(filter)(diff.initial)
    );

    // This is a bit of hack. I initially forgot about the added stores. However it is
    // pretty easy to reuse the Difference display to show added stores.
    const addedDiff = added.map((a) => new Difference(a));
    const filteredAddedDiff = addedDiff.filter((diff) =>
        storeFilter(filter)(diff.initial)
    );

    const uploadChanges = async () => {
        const payload = {
            additions: addedDiff
                .filter((d) => !deselectionMap[d.initial.nref])
                .map((d) => d.initial),
            modifications: diff
                .filter((d) => !deselectionMap[d.initial.nref])
                .map((d) => d.getModifiedStore()),
        };
        const actionWorked = await store.uploadChanges(payload);
        if (actionWorked) {
            // wait for DB to update
            setTimeout(() => {
                navigation.replaceToStores();
            }, 500);
        }
    };

    const allFilteredDiff = [...filteredAddedDiff, ...filteredDiff];

    return (
        <>
            <ScrollMarker
                elems={allFilteredDiff}
                predicate={(d) => deselectionMap[d.initial.nref]}
            />
            <div className={style.container}>
                <div className={style.top_content}>
                    <div className={style.page_header}>
                        <LeftOutlined
                            className={style.back_button}
                            onClick={() => window.history.back()}
                        />
                        Stores
                    </div>
                    <div className={style.actions}>
                        <div className={style.input_row}>
                            <SearchInput
                                searchValue={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <Button
                                type="primary"
                                loading={store.isUpdatingStores}
                                disabled={
                                    !!filter ||
                                    allFilteredDiff.length === 0 ||
                                    store.isUpdatingStores
                                }
                                onClick={uploadChanges}
                            >
                                Upload changes
                            </Button>
                        </div>
                        <div className={style.select_buttons}>
                            <button
                                className={style.select_button}
                                onClick={() =>
                                    setMultipleDeselection(
                                        allFilteredDiff.map(
                                            (d) => d.initial.nref
                                        ),
                                        true
                                    )
                                }
                            >
                                Deselect All
                            </button>
                            <button
                                className={style.select_button}
                                onClick={() =>
                                    setMultipleDeselection(
                                        allFilteredDiff.map(
                                            (d) => d.initial.nref
                                        ),
                                        false
                                    )
                                }
                            >
                                Select All
                            </button>
                        </div>
                    </div>
                </div>
                <div className={style.section}>
                    <h1 className={style.section_header}>
                        Additions ({filteredAddedDiff.length})
                    </h1>
                    {filteredAddedDiff.map((difference, i) => (
                        <div
                            key={difference.initial.nref}
                            className={
                                deselectionMap[difference.initial.nref]
                                    ? style.deselected
                                    : ''
                            }
                        >
                            <h2 className={style.nref_header}>
                                {' '}
                                <b>Nref: </b>
                                {difference.initial.nref}
                                <SelectionIcons
                                    selected={
                                        !deselectionMap[difference.initial.nref]
                                    }
                                    onChange={(selected) =>
                                        setDeselection(
                                            difference.initial.nref,
                                            !selected
                                        )
                                    }
                                />
                            </h2>
                            <DifferenceDisplay
                                difference={difference}
                                showDifference={false}
                            />
                        </div>
                    ))}
                </div>
                <div>
                    <h1 className={style.section_header}>
                        Differences ({filteredDiff.length})
                    </h1>
                    {filteredDiff.map((difference, i) => (
                        <div
                            key={difference.initial.nref}
                            className={
                                deselectionMap[difference.initial.nref]
                                    ? style.deselected
                                    : ''
                            }
                        >
                            <h2 className={style.nref_header}>
                                {' '}
                                <b>Nref: </b>
                                {difference.initial.nref}
                                <SelectionIcons
                                    selected={
                                        !deselectionMap[difference.initial.nref]
                                    }
                                    onChange={(selected) =>
                                        setDeselection(
                                            difference.initial.nref,
                                            !selected
                                        )
                                    }
                                />
                            </h2>
                            <div className={style.dual_box_holder}>
                                <DifferenceDisplay
                                    difference={difference}
                                    showDifference={false}
                                />
                                <DifferenceDisplay
                                    difference={difference}
                                    showDifference={true}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
});

const DifferenceDisplay = React.memo(
    (props: { difference: Difference; showDifference: boolean }) => {
        const { showDifference, difference } = props;

        const initial = difference.initial;
        const hasStreetChanged = difference.street !== undefined;
        const hasTelChanged = difference.phoneNumber !== undefined;
        const hasCityChanged = difference.city !== undefined;
        const hasPostChanged = difference.post !== undefined;
        const hasStatusChanged = difference.status !== undefined;
        const hasLngChanged = difference.lng !== undefined;
        const hasLatChanged = difference.lat !== undefined;

        const modifiedServices = initial.services
            .concat(difference.addedServices ?? [])
            .filter(
                (service) => !difference.removedServices?.includes(service)
            );

        const displayedServices = showDifference
            ? modifiedServices
            : initial.services;

        const getServiceClass = (service: string) => {
            if (showDifference && difference.addedServices?.includes(service)) {
                return style.added;
            }

            if (
                !showDifference &&
                difference.removedServices?.includes(service)
            ) {
                return style.removed;
            }
        };

        return (
            <div className={style.store_box}>
                <h3>{showDifference ? 'Modified' : 'Initial'}</h3>
                <p>
                    <b>Title: </b>
                    {initial.addr.street}
                </p>
                <p>
                    <b>Tel: </b>
                    <span className={hasTelChanged ? style.changed : ''}>
                        {showDifference && hasTelChanged
                            ? difference.phoneNumber
                            : initial.tel}
                    </span>
                </p>
                <p>
                    <b>Street: </b>
                    <span className={hasStreetChanged ? style.changed : ''}>
                        {showDifference && hasStreetChanged
                            ? difference.street
                            : initial.addr.street}
                    </span>
                </p>
                <p>
                    <b>City: </b>
                    <span className={hasCityChanged ? style.changed : ''}>
                        {showDifference && hasCityChanged
                            ? difference.city
                            : initial.addr.city}
                    </span>
                </p>
                <p>
                    <b>Zip code: </b>
                    <span className={hasPostChanged ? style.changed : ''}>
                        {showDifference && hasPostChanged
                            ? difference.post
                            : initial.addr.post}
                    </span>
                </p>
                <p>
                    <b>Lat: </b>
                    <span className={hasLatChanged ? style.changed : ''}>
                        {showDifference && hasLatChanged
                            ? difference.lat
                            : initial.lat}
                    </span>
                </p>
                <p>
                    <b>Lng: </b>
                    <span className={hasLngChanged ? style.changed : ''}>
                        {showDifference && hasLngChanged
                            ? difference.lng
                            : initial.lng}
                    </span>
                </p>
                <p>
                    <b>Status: </b>
                    <span className={hasStatusChanged ? style.changed : ''}>
                        {showDifference && hasStatusChanged
                            ? difference.status
                            : initial.status}
                    </span>
                </p>
                <div>
                    <b>Opening hours: </b>

                    <ul>
                        {WEEKDAYS.map((weekday, i) => (
                            <li key={weekday}>
                                <b className={style.weekday_label}>
                                    {weekday}:{' '}
                                </b>
                                <span
                                    className={
                                        difference.hours[i] !== undefined
                                            ? style.changed
                                            : ''
                                    }
                                >
                                    {showDifference &&
                                    difference.hours[i] !== undefined
                                        ? difference.hours[i]
                                        : initial.hours[i]}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <b>Services: </b>
                    <ul>
                        {displayedServices.map((service) => (
                            <li key={service}>
                                <span className={getServiceClass(service)}>
                                    {service}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
);

DifferenceDisplay.displayName = 'DifferenceDisplay';

const SelectionIcons = (props: {
    onChange: (selected: boolean) => void;
    selected: boolean;
}) => {
    return (
        <>
            {props.selected ? (
                <MinusCircleOutlined
                    className={style.remove_icon}
                    onClick={() => props.onChange(false)}
                />
            ) : (
                <PlusCircleOutlined
                    className={style.add_icon}
                    onClick={() => props.onChange(true)}
                />
            )}
        </>
    );
};
