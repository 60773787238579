import React from 'react';
import style from './style.module.scss';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

interface ActiveValues {
    status: string;
}

interface ActiveColumn {
    values: ActiveValues;
    title: string;
    onChange: (values: ActiveValues) => void;
}

export const IsActiveSwitch = (props: ActiveColumn) => {
    return (
        <div className={`${style.fluid_input_col} ${style.vertical_col}`}>
            <div className={style.input_row}>
                <span className="left_label">{props.title}</span>
                <Switch
                    checked={props.values.status === 'active'}
                    onChange={(e) => {
                        const status = e ? 'active' : 'inactive';
                        props.onChange({
                            ...props.values,
                            status,
                        });
                    }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                />
            </div>
        </div>
    );
};
