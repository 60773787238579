import React from 'react';
import style from './style.module.scss';
import { Input } from 'antd';
import { ErrorAwareLabel, RichText } from '../..';
import { TranslationMap, SupportedLanguages } from '../../../types';

interface GameFormErrors {
    fr: boolean;
    en: boolean;
}
interface GameFormValues {
    title: TranslationMap;
    subTitle: TranslationMap;
    description: TranslationMap;
    buttonTitle: TranslationMap;
}
interface GameFormProps {
    values: GameFormValues;
    errors: GameFormErrors;
    onChange: (values: GameFormValues) => void;
}

export const GameForm = (props: GameFormProps) => {
    return (
        <>
            <div className={style.input_row}>
                {/* Title */}
                {SupportedLanguages.map((lang) => (
                    <div key={lang} className={style.fixed_width_input_col}>
                        <ErrorAwareLabel
                            text={`Title ${lang}`}
                            hasError={props.errors[lang]}
                        />
                        <Input
                            name={`title.${lang}`}
                            onChange={(e) =>
                                props.onChange({
                                    ...props.values,
                                    title: {
                                        ...props.values.title,
                                        [lang]: e.target.value,
                                    },
                                })
                            }
                            value={props.values.title[lang] || ''}
                        />
                    </div>
                ))}
            </div>
            {/* SubTitle */}
            <div className={style.input_row}>
                {SupportedLanguages.map((lang) => (
                    <div key={lang} className={style.fixed_width_input_col}>
                        <ErrorAwareLabel
                            text={`Sub title ${lang}`}
                            hasError={props.errors[lang]}
                        />
                        <Input
                            name={`subTitle.${lang}`}
                            onChange={(e) =>
                                props.onChange({
                                    ...props.values,
                                    subTitle: {
                                        ...props.values.subTitle,
                                        [lang]: e.target.value,
                                    },
                                })
                            }
                            value={props.values.subTitle[lang] || ''}
                        />
                    </div>
                ))}
            </div>
            {/* Button Title */}
            <div className={style.input_row}>
                {SupportedLanguages.map((lang) => (
                    <div key={lang} className={style.fixed_width_input_col}>
                        <ErrorAwareLabel
                            text={`Button Title ${lang}`}
                            hasError={props.errors[lang]}
                        />
                        <Input
                            name={`buttonTitle.${lang}`}
                            onChange={(e) =>
                                props.onChange({
                                    ...props.values,
                                    buttonTitle: {
                                        ...props.values.buttonTitle,
                                        [lang]: e.target.value,
                                    },
                                })
                            }
                            value={props.values.buttonTitle[lang] || ''}
                        />
                    </div>
                ))}
            </div>
            {/* Description */}
            <div className={style.input_row}>
                {SupportedLanguages.map((lang) => (
                    <div key={lang} className={style.fixed_width_input_col}>
                        <ErrorAwareLabel
                            text={`Description ${lang}`}
                            hasError={props.errors[lang]}
                        />
                        <RichText
                            text={props.values.description[lang] ?? ''}
                            onChange={(text) =>
                                props.onChange({
                                    ...props.values,
                                    description: {
                                        ...props.values.description,
                                        [lang]: text,
                                    },
                                })
                            }
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
