import React, { useState, useMemo, useCallback } from 'react';
import { CmsUserHistory } from '../../types';
import style from './style.module.scss';
import moment from 'moment';
import { Modal, Table } from 'antd';
import { Text } from '../../utils/text';

interface CmsUserHistoryProps {
    cmsUserHistory: CmsUserHistory[];
}

const formatDate = (date: string) => moment(date).format('LLLL');

export const CmsUserHistoryHeader = ({
    cmsUserHistory,
}: CmsUserHistoryProps) => {
    const [isModalVisible, setModalVisible] = useState(false);

    const lastUpdated = useMemo(() => {
        return cmsUserHistory.length
            ? cmsUserHistory.reduce((a, b) =>
                  a.createdAt > b.createdAt ? a : b
              )
            : null;
    }, [cmsUserHistory]);

    const createdBy = useMemo(() => {
        return (
            cmsUserHistory.find((creator) => creator.action === 'create') ||
            null
        );
    }, [cmsUserHistory]);

    const renderDates = useCallback(
        (_: unknown, record: CmsUserHistory) => (
            <div>{formatDate(record.createdAt)}</div>
        ),
        []
    );

    const columns = useMemo(
        () => [
            {
                title: Text.cmsUserhistoryComponent.columns.email,
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: Text.cmsUserhistoryComponent.columns.action,
                dataIndex: 'action',
                key: 'action',
            },
            {
                title: Text.cmsUserhistoryComponent.columns.date,
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: renderDates,
            },
        ],
        [renderDates]
    );

    const handleModalOpen = useCallback(() => setModalVisible(true), []);
    const handleModalClose = useCallback(() => setModalVisible(false), []);

    return (
        <div className={style.cms_user_history_container}>
            <div
                className={style.version_info}
                onClick={handleModalOpen}
                style={{ cursor: 'pointer' }}
            >
                {createdBy && (
                    <div>
                        <span>
                            Created by {createdBy.email} on{' '}
                            {formatDate(createdBy.createdAt)}.
                        </span>
                    </div>
                )}
                {lastUpdated && (
                    <div>
                        <span>
                            Last modified by {lastUpdated.email} on{' '}
                            {formatDate(lastUpdated.createdAt)}.
                        </span>
                    </div>
                )}
            </div>
            <Modal
                visible={isModalVisible}
                title={
                    <div className={style.modal_title}>
                        {Text.cmsUserhistoryComponent.title}
                    </div>
                }
                footer={null}
                onCancel={handleModalClose}
                className={style.custom_modal}
            >
                <div className={style.modal_content}>
                    <Table
                        dataSource={cmsUserHistory}
                        columns={columns}
                        pagination={false}
                        className={style.custom_table}
                    />
                </div>
            </Modal>
        </div>
    );
};
