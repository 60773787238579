import React from 'react';
import style from './style.module.scss';

type NavbarProps = {
    children?: React.ReactNode;
    textColor?: string;
};

export const AcvtGenHeader = ({ children, textColor }: NavbarProps) => {
    return (
        <>
            <div className={style.banner}>
                <h1 className={style.header} style={{ color: textColor }}>
                    {children}
                </h1>
            </div>
        </>
    );
};
