export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: string;
    updatedAt: string;
}
export interface MinimalAppConfig {
    miniOSVersion: string;
    minAndroidBuildVersion: number;
}

export interface CarwashCustomerServiceStatus {
    status: string;
}

export interface FeatureFlags {
    id: number;
    name: string;
    description: string | null;
    iosMinimalVersion: string | null;
    androidMinimalVersion: number | null;
    createdAt: string;
    active: boolean;
}

export type SupportedLanguage = 'en' | 'fr';

export type TranslationMap = {
    [key in SupportedLanguage]: string;
};

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export type NullableTranslationMap = Nullable<TranslationMap>;

export const SupportedLanguages: SupportedLanguage[] = ['en', 'fr'];

export type PaginatedList<Elem> = {
    page: number;
    perPage: number;
    total: number;
    results: Elem[];
};

export type TableList<Elem> = {
    limit: number;
    results: Elem[];
};
export type CsvContent = {
    [key: string]: string | number | boolean | null | undefined;
}[];
export interface Checkboxes {
    id: number | null;
    isMandatory: boolean | null;
    translations: TranslationMap;
}

export interface CmsUserHistory {
    email: string | null;
    action: string;
    createdAt: string;
}

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export enum OperationStatus {
    SUCCESS = 'success',
    ERROR = 'error',
}
