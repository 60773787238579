import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Table, Spin, DatePicker, Button, Modal } from 'antd';
import moment, { Moment } from 'moment';
import { useClientStore } from '../../../contexts/mobx';
import { Client, Rewards } from '../../../stores/client';

type UserRewardsTabProps = {
    clientObject?: Client;
};

export const UserRewardsTab = observer(
    ({ clientObject }: UserRewardsTabProps) => {
        const clientStore = useClientStore();
        const [isLoading, setLoading] = useState(false);
        const [editingKey, setEditingKey] = useState<string | null>(null);
        const [newExpireAt, setNewExpireAt] = useState<{
            [key: string]: Moment | null;
        }>({});

        useEffect(() => {
            fetchRewards();
        }, [clientObject?.id, clientStore]);

        const fetchRewards = async () => {
            if (clientObject?.id) {
                setLoading(true);
                try {
                    await clientStore.fetchClientRewards(clientObject.id);
                } catch (error) {
                    console.error('Failed to fetch grants:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        const handleEdit = (record: Rewards) => {
            setEditingKey(record.id.toString());
            setNewExpireAt({ [record.id]: moment(record.expireAt) });
        };

        const handleSave = async (id: string) => {
            const newDate = newExpireAt[id];
            if (!newDate) {
                Modal.error({
                    title: 'Invalid Date',
                    content:
                        'Please select a valid date and time before saving.',
                });
                return;
            }

            setLoading(true);
            const success =
                clientObject &&
                (await clientStore.updateClientGrant(
                    clientObject.id,
                    Number(id),
                    newDate.toISOString()
                ));

            if (success) {
                await fetchRewards();
            }

            setLoading(false);
            setEditingKey(null);
        };

        const handleCancel = () => {
            setEditingKey(null);
        };

        const columns = [
            {
                title: 'Promotion id',
                dataIndex: 'promotionId',
                key: 'promotionId',
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Image',
                dataIndex: 'image',
                key: 'image',
                render: (image?: string) =>
                    image ? (
                        <img
                            src={image}
                            alt="Reward"
                            style={{ width: 50, height: 50 }}
                        />
                    ) : (
                        'No Image Available'
                    ),
            },
            {
                title: 'Expiration Date',
                dataIndex: 'expireAt',
                key: 'expireAt',
                render: (_: any, record: Rewards) => {
                    return editingKey === record.id.toString() ? (
                        <DatePicker
                            format="YYYY-MM-DDTHH:mm:ssZ"
                            showTime={{ format: 'HH:mm' }}
                            value={
                                newExpireAt[record.id] ??
                                moment(record.expireAt)
                            }
                            onChange={(moment) =>
                                setNewExpireAt({
                                    ...newExpireAt,
                                    [record.id]: moment,
                                })
                            }
                        />
                    ) : record.expireAt ? (
                        moment(record.expireAt).format('YYYY-MM-DD HH:mm')
                    ) : (
                        'N/A'
                    );
                },
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render: (_: any, record: Rewards) => {
                    return editingKey === record.id.toString() ? (
                        <div>
                            <Button
                                onClick={() => handleSave(record.id.toString())}
                                type="link"
                            >
                                Save
                            </Button>
                            <Button onClick={handleCancel} type="link">
                                Cancel
                            </Button>
                        </div>
                    ) : (
                        <Button onClick={() => handleEdit(record)} type="link">
                            Edit
                        </Button>
                    );
                },
            },
        ];

        return (
            <div>
                {isLoading ? (
                    <Spin />
                ) : (
                    <Table
                        columns={columns}
                        dataSource={clientStore.clientRewardsList ?? undefined}
                        rowKey="id"
                        pagination={false}
                    />
                )}
            </div>
        );
    }
);

export default UserRewardsTab;
