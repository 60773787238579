import React from 'react';
import styles from './style.module.scss';
import { Alert } from 'antd';

type InfoBannerProps = {
    message: string;
    description: string;
    type?: 'success' | 'info' | 'warning' | 'error';
    showIcon?: boolean;
};

export const InfoBanner = ({
    message,
    description,
    type = 'info',
    showIcon = true,
}: InfoBannerProps) => {
    return (
        <div className={styles.info_banner}>
            <Alert
                message={
                    <span className={styles.info_banner_message}>
                        {message}
                    </span>
                }
                description={
                    description && (
                        <span className={styles.info_banner_description}>
                            {description}
                        </span>
                    )
                }
                type={type}
                showIcon={showIcon}
            />
        </div>
    );
};
