import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { Input, Spin, Button, Table } from 'antd';
import { useFormik } from 'formik';
import {
    useAuthStore,
    useCategoryStore,
    usePromotionStore,
} from '../../contexts/mobx';
import { Promotion } from '../../stores/promotion';
import { getPromotionStatus } from '../../utils/promotion-status';
import { useNavigation } from '../../utils/use-navigation';
import { ModalManager } from '../../components';
import { ColumnProps } from 'antd/es/table';
import {
    CategoryUpsertPayload,
    YupCategoryUpsertPayload,
} from '../../stores/category';
import { getPromotionsTypeDisplay } from '../../utils/promotion-types';
import { RollbackOutlined } from '@ant-design/icons';
import { getItemProp } from '../../utils';
import { Tag } from 'antd';
import Title from 'antd/lib/skeleton/Title';

// Validation for the expected params
const YupParams = yup.object({
    action: yup.string().oneOf(['create', 'edit']).required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(v === undefined && action !== 'create');
        }),
});

const renderTitle = (onEdit: (record: Promotion) => void) => {
    const TitleComponent = (_: unknown, record: Promotion) => {
        const enTitle = getItemProp(record.items, 'en', 'title');
        return <div className={style.title}>{enTitle}</div>;
    };
    TitleComponent.displayName = 'TitleComponent';
    return TitleComponent;
};

const renderThumbnail = (_: unknown, record: Promotion) => {
    const enImgSrc = getItemProp(record.items, 'en', 'thumbnail');
    return <img key="en" src={enImgSrc ?? ''} alt="" className={'thumbnail'} />;
};

const renderDates = (_: unknown, record: Promotion) => {
    return (
        <div className={style.dates_holder}>
            <div className="text_center inline_block">
                <div className="light_label">
                    Start
                    <div className="vr"></div>
                </div>
                <div>{record.startDate.format('ll')}</div>
                <div>{record.startDate.format('LT')}</div>
            </div>
            {record.activatableDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        Activatable
                        <div className="vr"></div>
                    </div>
                    <div>{record.activatableDate.format('ll')}</div>
                    <div>{record.activatableDate.format('LT')}</div>
                </div>
            )}
            {record.inactivatableDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        Inactivatable
                        <div className="vr"></div>
                    </div>
                    <div>{record.inactivatableDate.format('ll')}</div>
                    <div>{record.inactivatableDate.format('LT')}</div>
                </div>
            )}
            {record.endDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        End
                        <div className="vr"></div>
                    </div>
                    <div>{record.endDate.format('ll')}</div>
                    <div className="lil_label">
                        {record.endDate.format('LT')}
                    </div>
                </div>
            )}
        </div>
    );
};

const renderIsActive = (_: unknown, record: Promotion) => {
    const promotionDateStatus = getPromotionStatus(record);
    return (
        <div>
            {record.isActive ? (
                <Tag color="#87d068">Active</Tag>
            ) : (
                <Tag color="#ee1313">Inactive</Tag>
            )}
            {promotionDateStatus !== null && (
                <Tag className={style.date_status} color="#f0f0f0">
                    {promotionDateStatus}
                </Tag>
            )}
        </div>
    );
};

const renderType = (_: unknown, record: Promotion) => {
    return getPromotionsTypeDisplay(record.type);
};

type Params = yup.InferType<typeof YupParams>;

export const CategoryPage = observer(() => {
    const navigation = useNavigation();
    const params = useParams<Params>();
    const categoryStore = useCategoryStore();
    const promotionStore = usePromotionStore();
    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const formik = useFormik<CategoryUpsertPayload>({
        initialValues: {
            items: [
                {
                    language: 'en',
                    title: '',
                },
                {
                    language: 'fr',
                    title: '',
                },
            ],
            canBeDeleted: false,
        },
        validationSchema: YupCategoryUpsertPayload,
        validateOnMount: true,
        onSubmit: () => console.log('Submit!'),
    });

    if (!YupParams.isValidSync(params)) {
        navigation.replaceToCategoryCreate();
    }

    React.useEffect(() => {
        if (params.action === 'edit') {
            setIsFetching(true);
            const categoryId = Number(params.id!);
            categoryStore
                .fetchCategory(categoryId)
                .then((cat) => formik.setValues(cat))
                .catch(() => navigation.replaceToCategoryCreate())
                .finally(() => setIsFetching(false));
            promotionStore.fetchForCategory(categoryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createCategory = async () => {
        setIsUpserting(true);
        try {
            await categoryStore.createCategory(formik.values);
            navigation.replaceToCategories();
        } catch {
            //empty
        } finally {
            setIsUpserting(false);
        }
    };

    const updateCategory = async () => {
        setIsUpserting(true);
        try {
            await categoryStore.updateCategory(
                formik.values,
                Number(params.id!)
            );
        } catch {
            //empty
        } finally {
            setIsUpserting(false);
        }
    };

    const onEdit = (record: Promotion) => {
        promotionStore.setCachedPromotion(record);
        navigation.goToPromotionEdit(record.id);
    };

    const onDeleteConfirm = async () => {
        setIsDeleting(true);
        const response = await categoryStore.deleteCategory(Number(params.id!));
        setIsDeleting(false);

        if (!response.err) {
            navigation.replaceToCategories();
        }
    };

    const columns: ColumnProps<Promotion>[] = [
        {
            key: 'is_active',
            render: renderIsActive,
        },
        {
            key: 'image',
            render: renderThumbnail,
        },
        {
            title: 'Title',
            key: 'title',
            render: renderTitle(onEdit),
        },
        {
            title: 'Type',
            key: 'type',
            render: renderType,
        },
        {
            title: 'Dates',
            key: 'dates',
            render: renderDates,
        },
    ];

    return (
        <div>
            <div className={'buttons_holder'}>
                <Button
                    type="default"
                    icon={<RollbackOutlined />}
                    shape="round"
                    onClick={navigation.goToCategories}
                >
                    Back
                </Button>
                {params.action === 'create' && (
                    <Button
                        type="primary"
                        loading={isUpserting}
                        onClick={createCategory}
                        disabled={!formik.isValid}
                    >
                        Create
                    </Button>
                )}
                {params.action === 'edit' && (
                    <Button
                        type="primary"
                        onClick={updateCategory}
                        loading={isUpserting}
                        disabled={!formik.isValid}
                    >
                        Save
                    </Button>
                )}
                {params.action === 'edit' && formik.values.canBeDeleted && (
                    <Button
                        type="primary"
                        danger={true}
                        onClick={ModalManager.showDeleteModal({
                            onConfirm: onDeleteConfirm,
                        })}
                        loading={isDeleting}
                        disabled={!formik.isValid}
                    >
                        Delete
                    </Button>
                )}
            </div>
            <br />
            <h1>Category</h1>
            {isFetching ? (
                <Spin />
            ) : (
                <div>
                    <form className="small_form">
                        {formik.values.items.map((item, i) => (
                            <div key={item.language}>
                                <span>Title {item.language}</span>
                                <Input
                                    name={`items[${i}].title`}
                                    value={item.title}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        ))}
                    </form>
                    <br />
                    <h2>Promotions of this category</h2>
                    <Table<Promotion>
                        className={style.table}
                        scroll={{ x: 900 }}
                        rowKey="id"
                        dataSource={promotionStore.promotionsForCategory}
                        columns={columns}
                        loading={promotionStore.isFetchingForCategory}
                        pagination={false}
                        onRow={(r) => ({
                            onClick: () => navigation.goToPromotionEdit(r.id),
                        })}
                    />
                </div>
            )}
        </div>
    );
});
