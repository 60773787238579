import React from 'react';
import { Spin, Table } from 'antd';
import Papa from 'papaparse';
import {
    ButtonFileInput,
    NotificationManager,
    PageHeader,
    SearchInput,
    Pagination,
} from '../../components';
import { observer } from 'mobx-react';
import { ColumnProps } from 'antd/es/table';
import { Store } from '../../stores/stores';
import { useAuthStore, useStoresStore } from '../../contexts/mobx';
import style from './styles.module.scss';
import { useQueryParams } from '../../utils/use-query-params';
import { StoreTag } from '../../components/store-tag';
import {
    parseStoreFile,
    storeFilter,
    storesToJson,
} from '../../helpers/store-helper';
import { useNavigation } from '../../utils/use-navigation';
import { getFileAsString, getFileEncoding } from '../../utils/csv';
import { ReactComponent as ElbowUpIcon } from '../../icons/ArrowElbowRightUp.svg';
import { ReactComponent as TrashIcon } from '../../icons/Trash.svg';
import { ReactComponent as XIcon } from '../../icons/SmallX.svg';
import { StoreEditing } from '../../components/store-editing';
import { StoreEditingBatch } from '../../components/store-editing-batch';
import { StoreBatchConfirm } from '../../components/store-editing-batch-confirm';
import { PopupSuccess } from '../../components/popup-success';
import { saveAs } from 'file-saver';
import { PopupConfirmDelete } from '../../components/popup-confirm-delete';

type SearchParams = {
    perPage?: number;
    page?: number;
    q?: string;
};

export const StoresPage = observer(() => {
    const store = useStoresStore();
    const navigation = useNavigation();
    const [selectedStore, setSelectedStore] = React.useState<Store | null>(
        null
    );
    const [selectedStores, setSelectedStores] = React.useState<Store[]>([]);
    const [storeEditing, setStoreEditing] = React.useState(false);
    const [batchEditing, setBatchEditing] = React.useState(false);
    const [batchModifications, setBatchModifications] = React.useState<Store[]>(
        []
    );
    const [isBatchConfirm, setIsBatchConfirm] = React.useState(false);
    const [params, setParams, setParamsAndGoToFirstPage] = useQueryParams<
        SearchParams
    >();
    const [searchValue, setSearchValue] = React.useState<string>();
    const [checkedList, setCheckedList] = React.useState<string[]>([]);
    const [checkAll, setCheckAll] = React.useState(false);
    const [isBatchPopupVisible, setIsBatchPopupVisible] = React.useState(false);
    const [isDeletePopupVisible, setIsDeletePopupVisible] = React.useState(
        false
    );
    const [
        isConfirmDeletePopupVisisble,
        setIsConfirmDeletePopupVisible,
    ] = React.useState(false);
    const [numberOfDeletedStores, setNumberOfDeletedStores] = React.useState(0);
    const [isLoadingCsv, setIsLoadingCsv] = React.useState(false);

    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const closeStoreEditing = () => {
        setStoreEditing((current) => !current);
    };

    const showStoreEditing = (store: Store) => {
        setSelectedStore(store);
        !storeEditing && setStoreEditing((current) => !current);
    };

    const closeBatchEditing = React.useCallback(() => {
        closeBatchConfirm();
        setBatchEditing(false);
    }, []);

    const showBatchEditing = () => {
        setBatchEditing(true);
    };

    const closeBatchConfirm = () => {
        setBatchModifications([]);
        setIsBatchConfirm(false);
    };

    const saveBatchModifications = (modifications: Store[]) => {
        setBatchModifications(modifications);
        setIsBatchConfirm(true);
    };

    const handleClear = () => {
        closeBatchEditing();
        setCheckedList([]);
        setCheckAll(false);
    };

    const waitAndFetchStores = () => {
        setTimeout(() => {
            store.fetchStores();
        }, 500);
    };

    const handlePopupClose = () => {
        handleClear();
        setIsBatchPopupVisible(false);
        setIsDeletePopupVisible(false);
        waitAndFetchStores();
    };

    const handleBatchEditConfirm = (worked: boolean) => {
        setIsBatchPopupVisible(worked);
    };

    const handleSaveEdit = () => {
        waitAndFetchStores();
    };

    const handleDeleteConfirm = () => {
        setIsConfirmDeletePopupVisible(true);
    };

    const handleDelete = async () => {
        setIsConfirmDeletePopupVisible(false);
        const numberOfDeletedStores = await store.deleteStores({
            nrefs: checkedList,
        });
        setIsDeletePopupVisible(true);
        setNumberOfDeletedStores(numberOfDeletedStores);
    };

    const onCheckAllChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked;
            if (store.stores) {
                setCheckedList(
                    checked ? store.stores.map((store) => store.nref) : []
                );
                setSelectedStores(checked ? store.stores : []);
            }
            setCheckAll(checked);
            if (!checked) {
                closeBatchEditing();
            }
        },
        [closeBatchEditing, store.stores]
    );

    const onChangeCheckbox = React.useCallback(
        (nref: string, checked: boolean) => {
            const newCheckedList = checked
                ? [...checkedList, nref]
                : checkedList.filter((nrefValue) => nrefValue !== nref);

            setCheckedList(newCheckedList);
            const newSelectedStores =
                store.stores?.filter((store) =>
                    newCheckedList.includes(store.nref)
                ) ?? [];
            setSelectedStores(newSelectedStores);
            setCheckAll(
                store.stores
                    ? newCheckedList.length === store.stores?.length
                    : false
            );
            if (newCheckedList.length === 0) {
                closeBatchEditing();
            }
        },
        [checkedList, closeBatchEditing, store.stores]
    );

    const columns = React.useMemo<ColumnProps<Store>[]>(
        () => [
            {
                title: (
                    <label className={style.checkbox}>
                        <input
                            type="checkbox"
                            onChange={onCheckAllChange}
                            checked={checkAll}
                        />
                    </label>
                ),
                render: (_: unknown, record: Store) => (
                    <label className={style.checkbox}>
                        <input
                            type="checkbox"
                            checked={checkedList.includes(record.nref)}
                            onChange={(e) =>
                                onChangeCheckbox(record.nref, e.target.checked)
                            }
                            onClick={(e) => e.stopPropagation()}
                        />
                    </label>
                ),
            },
            {
                title: 'Nref',
                dataIndex: 'nref',
                key: 'nref',
            },
            {
                title: 'Address',
                dataIndex: 'address',
                render: (_: unknown, record: Store) =>
                    `${record.addr.street}, ${record.addr.city}, ${record.addr.post}`,
            },
            {
                title: 'Tel',
                dataIndex: 'tel',
                key: 'nref',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (_: unknown, record: Store) => (
                    <StoreTag status={record.status} />
                ),
            },
        ],
        [checkAll, checkedList, onChangeCheckbox, onCheckAllChange]
    );

    React.useEffect(() => {
        store.fetchStores();
    }, []);

    const onFileSelected = async (file: File) => {
        setIsLoadingCsv(true);
        const encoding = await getFileEncoding(file);
        const text = await getFileAsString(file, encoding);

        Papa.parse<string[]>(text, {
            complete: (results) => {
                store.setParsedStore(parseStoreFile(results.data));
                setIsLoadingCsv(false);
                navigation.goToStoresDiff();
            },
            error: () => {
                NotificationManager.showError('Error parsing the file');
                setIsLoadingCsv(false);
            },
        });
    };

    const exportStoresToCSV = (storesToExport: Store[]) => {
        const data = storesToJson(storesToExport);
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'ct_stores.csv');
    };

    const exportAllStoresToCSV = () => {
        if (store.stores && store.stores.length > 0) {
            exportStoresToCSV(store.stores);
        } else {
            NotificationManager.showError('No stores available to export');
        }
    };

    const exportSelectedStoresToCSV = () => {
        if (selectedStores.length > 0) {
            exportStoresToCSV(selectedStores);
        } else {
            NotificationManager.showError('No stores selected to export');
        }
    };

    const page = params.page ?? 1 - 1;
    const perPage = params.perPage ?? 10;

    const stringifyQ = params.q !== undefined ? String(params.q) : undefined;
    const filteredStores = store.stores?.filter(storeFilter(stringifyQ));
    const start = page * perPage;

    const sortedStores = React.useMemo(() => {
        if (!filteredStores) {
            return [];
        }
        const selectedStores = filteredStores.filter((store) =>
            checkedList.includes(store.nref)
        );
        const unselectedStores = filteredStores.filter(
            (store) => !checkedList.includes(store.nref)
        );
        return [...selectedStores, ...unselectedStores];
    }, [filteredStores, checkedList]);

    const paginatedStores = sortedStores?.slice(start, start + perPage);

    const handleSearchClick = () => setParamsAndGoToFirstPage('q', searchValue);
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        e.target.value !== ''
            ? setSearchValue(e.target.value)
            : (setParamsAndGoToFirstPage('q', e.target.value),
              setSearchValue(e.target.value));
    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.key === 'Enter' && setParamsAndGoToFirstPage('q', searchValue);

    return (
        <div className={style.stores_content}>
            <PageHeader title="Stores"></PageHeader>
            <div className={style.table_header}>
                <div className={style.top_fonctionalities}>
                    <div className={style.query_box}>
                        <SearchInput
                            onClickIcon={handleSearchClick}
                            onChange={handleOnChange}
                            onKeyDown={handleOnKeyDown}
                        />
                    </div>
                    <div className={style.csv_buttons}>
                        <button
                            className={`${style.transparent_button} ${style.not_allowed}`}
                        >
                            <ElbowUpIcon className={style.icon} />
                            Revert Last Edit
                        </button>
                        <button
                            className={style.export_all}
                            onClick={() => exportAllStoresToCSV()}
                        >
                            Export all stores
                        </button>
                        {isLoadingCsv ? (
                            <div className={style.loading_csv}>
                                Loading CSV <Spin />
                            </div>
                        ) : (
                            <ButtonFileInput
                                text="Import CSV"
                                onFileSelected={onFileSelected}
                                disabled={store.isFetchingStores}
                            ></ButtonFileInput>
                        )}
                    </div>
                </div>
                {checkedList.length > 0 && (
                    <div className={style.edit_buttons_container}>
                        <button
                            className={style.edit_store_button}
                            onClick={() => showBatchEditing()}
                        >
                            {checkedList.length > 1
                                ? `Edit ${checkedList.length} stores`
                                : 'Edit Store'}
                        </button>
                        <div className={style.store_actions_holder}>
                            <button
                                className={`${style.transparent_button}`}
                                onClick={() => exportSelectedStoresToCSV()}
                            >
                                <ElbowUpIcon className={style.icon} />
                                Export
                            </button>
                            <button
                                className={`${style.transparent_button} ${style.delete_button}`}
                                onClick={() => handleDeleteConfirm()}
                            >
                                <TrashIcon className={style.icon} />
                                Delete
                            </button>
                            <button
                                className={style.transparent_button}
                                onClick={() => handleClear()}
                            >
                                <XIcon className={style.icon} />
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <Table<Store>
                rowKey="id"
                columns={columns}
                scroll={{ x: 900 }}
                dataSource={paginatedStores ?? []}
                loading={store.isFetchingStores}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => {
                        showStoreEditing(record);
                    },
                    className: checkedList.includes(record.nref)
                        ? style.selected_row
                        : '',
                })}
            />
            <div className={style.pagination_container}>
                <Pagination
                    className={style.pagination}
                    params={params}
                    meta={{
                        total: filteredStores?.length ?? 0,
                        perPage: 10,
                    }}
                    onChange={(pageNumber) => setParams({ page: pageNumber })}
                    onPageSizeChange={(size) => setParams({ perPage: size })}
                />
            </div>
            <div
                className={
                    storeEditing
                        ? `${style.store_editing} ${style.active}`
                        : `${style.store_editing}`
                }
            >
                {selectedStore && (
                    <StoreEditing
                        onCloseClick={closeStoreEditing}
                        storeObject={selectedStore}
                        store={store}
                        onSave={handleSaveEdit}
                    />
                )}
            </div>
            <div
                className={
                    batchEditing
                        ? `${style.store_editing} ${style.active}`
                        : `${style.store_editing}`
                }
            >
                {batchModifications.length > 0 && isBatchConfirm ? (
                    <StoreBatchConfirm
                        onCloseClick={closeBatchEditing}
                        modifications={batchModifications}
                        store={store}
                        goBack={closeBatchConfirm}
                        onConfirm={handleBatchEditConfirm}
                    />
                ) : (
                    <StoreEditingBatch
                        onCloseClick={closeBatchEditing}
                        storeObjects={selectedStores}
                        saveBatchModifications={saveBatchModifications}
                    />
                )}
            </div>
            {isBatchPopupVisible && (
                <PopupSuccess
                    message={`Your modifications to the ${batchModifications.length} selected stores were successfully saved!`}
                    onClose={handlePopupClose}
                />
            )}
            {isConfirmDeletePopupVisisble && (
                <PopupConfirmDelete
                    message={
                        checkedList.length > 1
                            ? `Are you sure you want to delete ${checkedList.length} stores?`
                            : `Are you sure you want to delete the store?`
                    }
                    onClose={() => setIsConfirmDeletePopupVisible(false)}
                    onOk={handleDelete}
                />
            )}
            {isDeletePopupVisible && (
                <PopupSuccess
                    message={
                        numberOfDeletedStores > 1
                            ? `${numberOfDeletedStores} stores were successfully deleted!`
                            : `${numberOfDeletedStores} store was successfully deleted!`
                    }
                    onClose={handlePopupClose}
                />
            )}
        </div>
    );
});
