import React from 'react';
import { Tag } from 'antd';
import { PromotionTypes } from './const';

export const getPromotionsTypeDisplay = (type: PromotionTypes) => {
    if (type === PromotionTypes[0]) {
        return <Tag color="volcano">{getPromotionsTypeName(type)}</Tag>;
    }
    if (type === PromotionTypes[1]) {
        return <Tag color="gold">{getPromotionsTypeName(type)}</Tag>;
    }
};

export const getPromotionsTypeName = (type: PromotionTypes) => {
    if (type === 'in_store') {
        return 'In store';
    }

    if (type === 'coupon') {
        return 'Coupon';
    }
};
