import React from 'react';
import { observer } from 'mobx-react';
import {
    Table,
    Tag,
    Modal,
    Statistic,
    Select,
    Empty,
    Card,
    Button,
} from 'antd';
import { Gauge, GaugeConfig } from '@ant-design/plots';
import { DownloadOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/charts';
import { ColumnProps } from 'antd/es/table';
import { useFeedbackNPSStore, useFeedbackStore } from '../../contexts/mobx';
import { Feedback, SearchParams } from '../../stores/feedbacks';
import {
    AnalyticsCompareOptions,
    ANALYTICS_DEFAULT_COMPARE_OPTION,
    FeedbackTypeColors,
    FeedbackTypes,
} from '../../utils/const';
import { useQueryParams } from '../../utils/use-query-params';
import {
    AnalyticsCompareDates,
    AnalyticsDatesPicker,
    NotificationManager,
    Pagination,
} from '../../components';
import style from './style.module.scss';
import moment from 'moment';
import { downloadCSVFile } from '../../utils/csv';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
    GAUGE_CONFIG,
    LINE_CONFIG,
    NPS,
} from '../../helpers/analytics-helpers';
import { Text } from '../../utils/text';

const renderUserName = (_: unknown, record: Feedback) => {
    return (
        <div>
            {record.user.firstName} {record.user.lastName}
        </div>
    );
};

const renderScore = (_: unknown, record: Feedback) => {
    return (
        <div>
            <Tag color={FeedbackTypeColors[record.score as FeedbackTypes]}>
                {record.score}
            </Tag>
        </div>
    );
};

const renderDescription = (_: unknown, record: Feedback) => {
    return <div>{record.description}</div>;
};

const renderFeatureScored = (_: unknown, record: Feedback) => {
    return <div>{record.featureScored}</div>;
};

const renderSelectedStore = (_: unknown, record: Feedback) => {
    return <div>{record.selectedStore}</div>;
};

const renderCreatedDate = (_: unknown, record: Feedback) => {
    return (
        <div>
            <div>{record.createdAt?.format('ll')}</div>
        </div>
    );
};

export const FeedbacksPage = observer(() => {
    const feedbackStore = useFeedbackStore();
    const npsStore = useFeedbackNPSStore();
    const [
        params,
        setParams,
        _,
        setManyParamsAndGoToFirstPage,
    ] = useQueryParams<SearchParams>();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [
        selectedFeedback,
        setSelectedFeedback,
    ] = React.useState<Feedback | null>(null);

    const [dateRange, setDateRange] = React.useState<number>(28);
    const [isComparing, setCompare] = React.useState<boolean>(false);
    const [searchPeriod, setSearchPeriod] = React.useState<
        AnalyticsCompareOptions
    >(ANALYTICS_DEFAULT_COMPARE_OPTION);

    const currentNpsPerDayArray: NPS[] = [];
    const compareNpsPerDayArray: NPS[] = [];

    npsStore.npsList?.currentPeriod?.npsDay.map((r) => {
        const nps: NPS = {
            value: Number(r.npsOfTheDay),
            date: r.date?.format('ll'),
        };
        currentNpsPerDayArray.push(nps);
    });

    const data = currentNpsPerDayArray;

    npsStore.npsList?.comparePeriod?.npsDay?.map((r) => {
        const nps: NPS = {
            value: Number(r.npsOfTheDay),
            date: r.date?.format('ll'),
        };
        compareNpsPerDayArray.push(nps);
    });

    const compareData = compareNpsPerDayArray;

    const npsPeriodScore = Number(
        npsStore.npsList?.currentPeriod?.npsPeriod.npsOfThePeriod
    );
    const compareNpsPeriodScore = Number(
        npsStore.npsList?.comparePeriod?.npsPeriod?.npsOfThePeriod
    );

    React.useEffect(() => {
        feedbackStore.fetchList(params);
    }, [feedbackStore, params]);

    React.useEffect(() => {
        npsStore.fetchNPS(params);
    }, [npsStore, params]);

    const columns: ColumnProps<Feedback>[] = [
        {
            title: Text.feedbacksPage.table.userName,
            key: 'userName',
            render: renderUserName,
        },
        {
            title: Text.feedbacksPage.table.score,
            key: 'score',
            render: renderScore,
        },
        {
            title: Text.feedbacksPage.table.description,
            key: 'description',
            render: renderDescription,
        },
        {
            title: Text.feedbacksPage.table.featuredScore,
            key: 'featureScored',
            render: renderFeatureScored,
        },
        {
            title: Text.feedbacksPage.table.selectedStore,
            key: 'selectedStore',
            render: renderSelectedStore,
        },
        {
            title: Text.feedbacksPage.table.createdDate,
            key: 'createdDate',
            render: renderCreatedDate,
        },
    ];

    const downloadCsv = async () => {
        try {
            const { startDate, endDate, featureScored } = params;
            const featured = await feedbackStore.fetchFeedbackForCSV({
                startDate,
                endDate,
                featureScored,
            });
            const csvData = featured!.map((feedback) => ({
                'Full Name': `${feedback.user.firstName} ${feedback.user.lastName}`,
                Score: feedback.score,
                Description: feedback.description,
                'Feature Scored': feedback.featureScored,
                'Selected Store': feedback.selectedStore
                    ? feedback.selectedStore
                    : 'Unknown',
                'Created Date': feedback.createdAt?.format('YYYY-MM-DD HH:MM'),
            }));
            return downloadCSVFile(csvData, 'feedbacks');
        } catch (error) {
            NotificationManager.showError('Error during exportation of file');
        }
    };

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '2px 3px 15px 1px rgba(208, 216, 243, 0.4)',
        marginRight: '20px',
        marginBottom: '10px',
        width: 1000,
    };

    const onChange = async (e: RadioChangeEvent) => {
        setDateRange(e.target.value);

        setManyParamsAndGoToFirstPage({
            startDate: moment().subtract(e.target.value, 'days').toISOString(),
            endDate: moment().toISOString(),
        });

        if (isComparing) {
            setManyParamsAndGoToFirstPage({
                startDate: moment()
                    .subtract(e.target.value, 'days')
                    .toISOString(),
                endDate: moment().toISOString(),
                compareStartDate:
                    searchPeriod === 'preceding'
                        ? moment()
                              .subtract(2 * e.target.value, 'days')
                              .toISOString()
                        : moment(moment().subtract(e.target.value, 'days'))
                              .subtract(1, 'year')
                              .toISOString(),
                compareEndDate:
                    searchPeriod === 'preceding'
                        ? moment()
                              .subtract(e.target.value, 'days')
                              .toISOString()
                        : moment().subtract(1, 'year').toISOString(),
            });
        }
    };

    const compare = async (e: boolean) => {
        if (e) {
            setManyParamsAndGoToFirstPage({
                compareStartDate: moment()
                    .subtract(2 * dateRange, 'days')
                    .toISOString(),
                compareEndDate: moment()
                    .subtract(dateRange, 'days')
                    .toISOString(),
            });
        } else {
            setManyParamsAndGoToFirstPage({
                compareStartDate: undefined,
                compareEndDate: undefined,
            });
            setSearchPeriod('preceding');
        }
        setCompare(e);
    };

    const changeSearchPeriod = async (
        selectedPeriod: AnalyticsCompareOptions
    ) => {
        setSearchPeriod(selectedPeriod);

        if (isComparing) {
            setManyParamsAndGoToFirstPage({
                compareStartDate:
                    selectedPeriod === 'preceding'
                        ? moment(params.startDate)
                              .subtract(2 * dateRange, 'days')
                              .toISOString()
                        : moment(params.startDate)
                              .subtract(1, 'year')
                              .toISOString(),
                compareEndDate:
                    selectedPeriod === 'preceding'
                        ? moment(params.startDate)
                              .subtract(dateRange, 'days')
                              .toISOString()
                        : moment(params.endDate)
                              .subtract(1, 'year')
                              .toISOString(),
            });
        }
    };

    return (
        <div>
            <h1>Feedbacks</h1>
            <Card style={cardStyle}>
                <AnalyticsDatesPicker
                    onChange={onChange}
                    compare={compare}
                    updateParams={(dates) => {
                        setManyParamsAndGoToFirstPage({
                            startDate: dates?.[0]?.toISOString(),
                            endDate: dates?.[1]?.toISOString(),
                        });
                    }}
                    startDate={params.startDate}
                    endDate={params.endDate}
                />
            </Card>
            <Card style={cardStyle}>
                <Select
                    className={style.select_filter}
                    placeholder="Feature Scored"
                    value={params.featureScored}
                    onChange={(e) => setParams({ featureScored: e })}
                    allowClear
                >
                    <Select.Option value="carwash">Car Wash</Select.Option>
                    <Select.Option value="ctdelivery">
                        CT Delivery
                    </Select.Option>
                    <Select.Option value="ctconnect">CT Connecté</Select.Option>
                    <Select.Option value="general">General</Select.Option>
                </Select>

                {data?.length ? (
                    <div>
                        <Gauge
                            className={style.stats}
                            {...((GAUGE_CONFIG(
                                npsPeriodScore
                            ) as unknown) as GaugeConfig)}
                        />

                        <div className={style.export_button}>
                            <Button
                                style={{ background: '#1D6F42' }}
                                icon={<DownloadOutlined />}
                                onClick={downloadCsv}
                                type="primary"
                            >
                                Export to CSV
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Statistic
                        title="NPS for this period"
                        value="No Feedback"
                    />
                )}
            </Card>

            <Card className={style.card_container} style={cardStyle}>
                {data?.length ? (
                    <div>
                        <Line {...LINE_CONFIG(data)} className={style.line} />
                    </div>
                ) : (
                    <Empty
                        className={style.empty_chart}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
            </Card>
            {isComparing && (
                <Card
                    className={style.card_container}
                    style={cardStyle}
                    title={
                        <>
                            <AnalyticsCompareDates
                                compareStartDate={params.compareStartDate}
                                compareEndDate={params.compareEndDate}
                                endDate={params.endDate}
                                format={'YYYY-MM-DD'}
                                updateParams={(dates) => {
                                    setManyParamsAndGoToFirstPage({
                                        compareStartDate: dates?.[0]?.toISOString(),
                                        compareEndDate: dates?.[1]?.toISOString(),
                                    });
                                }}
                                changeSearchPeriod={(e) => {
                                    changeSearchPeriod(e);
                                }}
                                searchPeriod={searchPeriod}
                            />
                            {isComparing && (
                                <>
                                    {compareData.length ? (
                                        <Gauge
                                            className={style.stats}
                                            {...((GAUGE_CONFIG(
                                                compareNpsPeriodScore
                                            ) as unknown) as GaugeConfig)}
                                        />
                                    ) : (
                                        <Statistic
                                            title="Comparaison NPS for this period"
                                            value="No NPS"
                                            className={style.stats}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    }
                >
                    {data?.length ? (
                        <div>
                            {compareData.length ? (
                                <Line
                                    {...LINE_CONFIG(compareData)}
                                    className={style.line}
                                />
                            ) : (
                                <Empty
                                    className={style.empty_chart}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={<span>No NPS to compare</span>}
                                />
                            )}
                        </div>
                    ) : (
                        <Empty
                            className={style.empty_chart}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                </Card>
            )}
            <Card style={cardStyle}>
                <div className={style.pagination_container}>
                    <Pagination
                        className={style.pagination}
                        params={params}
                        meta={feedbackStore.list}
                        onChange={(pageNumber) =>
                            setParams({ page: pageNumber })
                        }
                        onPageSizeChange={(size) =>
                            setParams({ perPage: size })
                        }
                    />
                </div>
                <Table<Feedback>
                    className={`${style.table} clickable_table`}
                    rowKey="id"
                    columns={columns}
                    dataSource={feedbackStore.list?.results}
                    loading={feedbackStore.fetchingCount > 0}
                    onRow={(record) => ({
                        onClick: () => {
                            setSelectedFeedback(record);
                            setIsModalVisible(true);
                        },
                    })}
                    pagination={false}
                />
                <div className={style.pagination_container}>
                    <Pagination
                        className={style.pagination}
                        params={params}
                        meta={feedbackStore.list}
                        onChange={(pageNumber) =>
                            setParams({ page: pageNumber })
                        }
                        onPageSizeChange={(size) =>
                            setParams({ perPage: size })
                        }
                    />
                </div>
            </Card>
            <Modal
                title="Feedback"
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <p>
                    <b>{Text.feedbacksPage.modal.user}</b>
                    <span>{selectedFeedback?.userId}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.username}</b>
                    <span>
                        {selectedFeedback?.user?.firstName}{' '}
                        {selectedFeedback?.user?.lastName}
                    </span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.email}</b>
                    <span>{selectedFeedback?.user?.email}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.score}</b>
                    <span>{selectedFeedback?.score}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.category}</b>
                    <span>{selectedFeedback?.category}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.featureScored}</b>
                    <span>{selectedFeedback?.featureScored}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.selectedStore}</b>
                    <span>{selectedFeedback?.selectedStore}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.description}</b>
                    <span>{selectedFeedback?.description}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.lang}</b>
                    <span>{selectedFeedback?.user?.language}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.birthday}</b>
                    <span>
                        {selectedFeedback?.user?.birthday?.format('ll')}
                    </span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.gender}</b>
                    <span>{selectedFeedback?.user?.gender}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.phoneNumber}</b>
                    <span>{selectedFeedback?.user?.phoneNumber}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.postalCode}</b>
                    <span>{selectedFeedback?.user?.postalCode}</span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.newsletter_receiver}</b>
                    <span>
                        {selectedFeedback?.user?.newsletter_receiver
                            ? 'Yes'
                            : 'No'}
                    </span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.updateAt}</b>
                    <span>
                        {selectedFeedback?.updateAt?.format('ll') ?? 'Never'}
                    </span>
                </p>
                <p>
                    <b>{Text.feedbacksPage.modal.createdAt}</b>
                    <span>{selectedFeedback?.createdAt?.format('ll')}</span>
                </p>
            </Modal>
        </div>
    );
});
