import React, { useState } from 'react';
import { AcvtSupportedLanguages } from '../../../types/acvt';
import { AcvtGenHeader } from '../generic-header';
import style from './style.module.scss';
import { ReactComponent as CTLogo } from '../../../icons/ct_logo_rounded.svg';
import { ReactComponent as RefreshArrow } from '../../../icons/refresh_arrow.svg';
import moment from 'moment';
import { useAuthStore } from '../../../contexts/mobx';
import { AcvtDeliveryCard } from '../cards/delivery-card';
import CtDelivery from '../../../icons/v4_ct_deliveries_items.png';
import { AcvtPlaceholderContent } from '../placeholder-content';
import { Text } from '../../../utils/text';

type AcvtHomeProps = {
    language?: AcvtSupportedLanguages;
    featuredCarousel?: React.ReactNode;
    promos?: React.ReactNode;
    games?: React.ReactNode;
    onEditMode?: boolean;
};

export const AcvtHomePage = ({
    language,
    featuredCarousel,
    promos,
    games,
    onEditMode = false,
}: AcvtHomeProps) => {
    const authStore = useAuthStore();
    const { claims } = authStore;

    const [isIconHovering, setIsIconHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsIconHovering(true);
    };

    const handleMouseLeave = () => {
        setIsIconHovering(false);
    };

    const firstName = claims?.name?.substring(0, claims?.name.indexOf(' '));

    return (
        <>
            <div className={style.home_content}>
                <AcvtGenHeader textColor="white">
                    <CTLogo fill="red" className={style.CT_logo} />
                    <div className={style.text}>
                        {language === 'fr'
                            ? moment().isBefore(moment({ hour: 12, minute: 0 }))
                                ? `${Text.ACVT.homePage.greatings.morning.fr} ${firstName}!`
                                : `${Text.ACVT.homePage.greatings.generic.fr} ${firstName}!`
                            : moment().isBefore(moment({ hour: 12, minute: 0 }))
                            ? `${Text.ACVT.homePage.greatings.morning.en} ${firstName}!`
                            : `${Text.ACVT.homePage.greatings.morning.en} ${firstName}!`}
                    </div>
                    <RefreshArrow
                        fill={isIconHovering ? 'black' : 'white'}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={style.refresh_arrow}
                    />
                </AcvtGenHeader>
                <div className={style.scroll_content}>
                    {featuredCarousel}
                    {promos}
                    {games}
                    {onEditMode && <AcvtPlaceholderContent />}
                    <div className={style.delivery_container}>
                        {language === 'fr' ? (
                            <>
                                <div className={style.title}>
                                    {`${Text.ACVT.homePage.header.delivery.fr}`}
                                </div>
                                <AcvtDeliveryCard
                                    image={<img alt="" src={CtDelivery} />}
                                    title={Text.ACVT.homePage.header.title.fr}
                                    description={
                                        Text.ACVT.homePage.header.description.fr
                                    }
                                    link={Text.ACVT.homePage.header.link.fr}
                                />
                            </>
                        ) : (
                            <>
                                <div className={style.title}>
                                    {`${Text.ACVT.homePage.header.delivery.en}`}
                                </div>
                                <AcvtDeliveryCard
                                    image={<img alt="" src={CtDelivery} />}
                                    title={Text.ACVT.homePage.header.title.en}
                                    description={
                                        Text.ACVT.homePage.header.description.en
                                    }
                                    link={Text.ACVT.homePage.header.link.en}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
