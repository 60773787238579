import { Area } from '@ant-design/charts';
import { Col, Row, Spin, Statistic } from 'antd';
import React from 'react';
import {
    CHART_CONFIG,
    GoogleAnalyticsData,
    LastDaysData,
} from '../../helpers/analytics-helpers';
import style from './style.module.scss';

interface Props {
    chartData: GoogleAnalyticsData[];
    loading: boolean;
    sideStats: LastDaysData;
}

export const AnalyticsCharts = (props: Props) => {
    return (
        <div className={style.container}>
            <Spin size="large" tip="One moment..." spinning={props.loading}>
                <Row>
                    <Col span={21}>
                        <Area {...CHART_CONFIG(props.chartData)} />
                    </Col>
                    <Col span={3}>
                        <div>
                            <Spin size="small" spinning={props.loading}>
                                <Statistic
                                    title={'30 Days'}
                                    value={props.sideStats.value_3}
                                    className={style.stats}
                                    valueStyle={{ color: '#1366a6' }}
                                ></Statistic>
                            </Spin>

                            <Spin size="small" spinning={props.loading}>
                                <Statistic
                                    title={'7 Days'}
                                    value={props.sideStats.value_2}
                                    className={style.stats}
                                    valueStyle={{ color: '#ed3426' }}
                                ></Statistic>
                            </Spin>

                            <Spin size="small" spinning={props.loading}>
                                <Statistic
                                    title={'1 Day'}
                                    value={props.sideStats.value_1}
                                    className={style.stats}
                                    valueStyle={{ color: '#f6931e' }}
                                ></Statistic>
                            </Spin>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};
