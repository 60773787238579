import moment from 'moment';
import { Promotion } from '../stores/promotion';
import { PromotionStatuses } from './const';

export const getPromotionStatus = (record: Promotion) => {
    const now = moment();

    /**
     * Check if the coupon is recurring
     * (if start and end period are not null), if it's the case,
     * activatableDate and inactivatableDate must not be null.
     * For now, the CMS's user will only be able to select
     * hour period and not the minutes.
     * **/
    if (
        record.startPeriod != null &&
        record.endPeriod != null &&
        record.activatableDate != null &&
        record.inactivatableDate != null &&
        now >= record.activatableDate &&
        now <= record.inactivatableDate
    ) {
        /**
         * Check if it's a overnight period.
         * Eg. startPeriod at 17h and endPeriod at 6h (next morning)
         * Show upcomming or Outside Active Hours Label
         * **/

        if (record.startPeriod > record.endPeriod) {
            if (
                now.hour() >= record.endPeriod &&
                now.hour() < record.startPeriod
            ) {
                return record.items[0].outsideActivatableHours;
            } else {
                return PromotionStatuses[1];
            }
        } else {
            if (
                now.hour() < record.endPeriod &&
                now.hour() >= record.startPeriod
            ) {
                return PromotionStatuses[1];
            } else {
                return record.items[0].outsideActivatableHours;
            }
        }
    } else {
        if (
            now >= record.startDate &&
            record.activatableDate != null &&
            now < record.activatableDate
        ) {
            return PromotionStatuses[0];
        } else if (
            now >= record.startDate &&
            record.inactivatableDate != null &&
            now >= record.inactivatableDate
        ) {
            return PromotionStatuses[2];
        } else if (now >= record.endDate) {
            return PromotionStatuses[3];
        }
    }

    return null;
};
