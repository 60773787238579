import React from 'react';
import produce from 'immer';

type SimpleMap<T> = {
    [key: string]: T;
};

type SetSimpleMapFn<T> = (key: string, value: T) => void;
type RemoveSimpleMapFn<T> = (key: string) => void;
type SetMultiple<T> = (keys: string[], value: T) => void;
type Clear = () => void;

export const useSimpleMap = function <T>(): [
    SimpleMap<T>,
    SetSimpleMapFn<T>,
    SetMultiple<T>
] {
    const [map, setMap] = React.useState<SimpleMap<T>>(Object.create(null));

    return [
        map,
        (nref, deselected) =>
            setMap(produce((draft) => void (draft[nref] = deselected))),
        (nrefs, value) =>
            setMap(
                produce((draft) => {
                    nrefs.forEach((k) => (draft[k] = value));
                })
            ),
    ];
};
