import React from 'react';
import { Card, Statistic } from 'antd';
import style from './style.module.scss';

interface StatsSectionProps {
    totalGrants: number | null | undefined;
    totalActivations: number | null | undefined;
    discountValue: number | null | undefined;
    isPrivate: boolean | null | undefined;
}

const StatCard = ({
    title,
    value,
    prefix,
}: {
    title: string;
    value: number;
    prefix?: string;
}) => (
    <Card className={style.stat_card}>
        <Statistic
            className={style.stats}
            title={title}
            value={value}
            prefix={prefix}
        />
    </Card>
);

export const StatsSection = ({
    totalGrants = 0,
    totalActivations = 0,
    discountValue = 0,
    isPrivate = false,
}: StatsSectionProps) => {
    const safeTotalActivations = totalActivations ?? 0;
    const safeDiscountValue = discountValue ?? 0;
    const totalCost = safeTotalActivations * safeDiscountValue;

    return (
        <Card>
            <div className={style.stats_row}>
                {isPrivate && <StatCard title="Won" value={totalGrants ?? 0} />}
                <StatCard title="Activated" value={safeTotalActivations} />
                <StatCard title="Cost" value={totalCost} prefix="$" />
            </div>
        </Card>
    );
};
