import React from 'react';
import style from './style.module.scss';

interface Props {
    src?: string | null;
    loading?: 'lazy' | 'eager';
    alt?: string;
    size?: number;
    className?: string;
    showOverlay?: boolean;
    grayscale?: boolean;
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
    children?: React.ReactNode;
}

export const ImageHolder = (props: Props) => {
    const size = props.size ?? 140;
    const onClick = props.onClick ?? (() => void 0);
    return (
        <div
            className={`${style.image_holder} ${props.className ?? ''}`}
            style={{ width: size, height: size }}
        >
            <img
                className={`${style.image} ${
                    props.grayscale ? style.expired : ''
                }`}
                src={props.src ?? undefined}
                loading={props.loading}
                alt={props.alt ?? ''}
                onClick={onClick}
            />
            {props.showOverlay && props.children}
        </div>
    );
};
