import React, { useEffect, useCallback } from 'react';
import { Select, Input, InputNumber, Card, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import style from './style.module.scss';
import { PromotionForm } from '../..';
import { PromotionTypes } from '../../../../utils/const';
import { getPromotionsTypeName } from '../../../../utils/promotion-types';
import { WeatherActivationSection } from '../WeatherActivationSection';

interface Category {
    id: number;
    items: {
        title: string;
        language: string;
    }[];
}

interface Offer {
    id: number;
    items: {
        title?: string;
        language: string;
    }[];
}

interface ConfigSectionProps {
    values: PromotionForm;
    setFieldValue: (field: string, value: unknown) => void;
    setCategory: (categoryId: number) => void;
    categoryStore: {
        isFetchingActiveCategories: boolean;
        activeCategories?: { results: Category[] } | null;
    };
    offerStore: {
        offers: { results: Offer[] } | null;
        isFetchingOffers: boolean;
        getOffersById: (id: number) => Offer | undefined;
        fetchOffers: () => Promise<void>;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ConfigSection = ({
    values,
    setFieldValue,
    handleChange,
    categoryStore,
    offerStore,
    setCategory,
}: ConfigSectionProps) => {
    useEffect(() => {
        offerStore.fetchOffers();
    }, [offerStore]);

    const isWeatherPromotionActive =
        values.temperatureActivationFloor !== null ||
        values.temperatureActivationCeiling !== null;

    const handleWeatherToggleChange = useCallback(
        (checked: boolean) => {
            if (!checked) {
                setFieldValue('temperatureActivationFloor', null);
                setFieldValue('temperatureActivationCeiling', null);
            } else {
                if (values.temperatureActivationFloor === null) {
                    setFieldValue('temperatureActivationFloor', -50);
                }
                if (values.temperatureActivationCeiling === null) {
                    setFieldValue('temperatureActivationCeiling', 50);
                }
            }
        },
        [
            setFieldValue,
            values.temperatureActivationFloor,
            values.temperatureActivationCeiling,
        ]
    );

    return (
        <Card className={style.card_container}>
            <div className={style.switch_row}>
                <div className={style.single_toggle}>
                    <label className={style.bold_label}>Active</label>
                    <Switch
                        checked={values.isActive}
                        onChange={(checked) =>
                            setFieldValue('isActive', checked)
                        }
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </div>
                <div className={style.single_toggle}>
                    <label className={style.bold_label}>Homepage</label>
                    <Switch
                        checked={values.isVisibleOnHomePage}
                        onChange={(checked) =>
                            setFieldValue('isVisibleOnHomePage', checked)
                        }
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </div>
                <div className={style.single_toggle}>
                    <label className={style.bold_label}>18+</label>
                    <Switch
                        checked={values.isForMajor}
                        onChange={(checked) =>
                            setFieldValue('isForMajor', checked)
                        }
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </div>
                <div className={style.single_toggle}>
                    <label className={style.bold_label}>Carwash Only</label>
                    <Switch
                        checked={values.isForCarwash}
                        onChange={(checked) =>
                            setFieldValue('isForCarwash', checked)
                        }
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </div>
                <div className={style.single_toggle}>
                    <label className={style.bold_label}>AB Testing</label>
                    <Switch
                        checked={values.inTestingCampaign}
                        onChange={(checked) =>
                            setFieldValue('inTestingCampaign', checked)
                        }
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </div>
                {values.type === 'coupon' && values.isForCTConnect && (
                    <div className={style.single_toggle}>
                        <label className={style.bold_label}>CT Connect</label>
                        <Switch
                            checked={values.isForCTConnect ?? false}
                            onChange={(checked) =>
                                setFieldValue('isForCTConnect', checked)
                            }
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </div>
                )}
                <div className={style.single_toggle}>
                    <label className={style.bold_label}>Weather</label>
                    <Switch
                        checked={isWeatherPromotionActive}
                        onChange={handleWeatherToggleChange}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </div>
            </div>
            {isWeatherPromotionActive && (
                <div className={style.slider_container}>
                    <WeatherActivationSection
                        temperatureActivationFloor={
                            values.temperatureActivationFloor
                        }
                        temperatureActivationCeiling={
                            values.temperatureActivationCeiling
                        }
                        setFieldValue={setFieldValue}
                    />
                </div>
            )}
            <div className={style.input_row}>
                <div className={style.single_input}>
                    <label className={style.bold_label}>Offer Type</label>
                    <Select
                        value={values.type ?? ''}
                        onChange={(value) =>
                            setFieldValue('type', value as PromotionTypes)
                        }
                        className={style.custom_select}
                        style={{ width: '100%' }}
                    >
                        {PromotionTypes.map((type) => (
                            <Select.Option value={type} key={type}>
                                {getPromotionsTypeName(type)}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={style.single_input}>
                    <label className={style.bold_label}>Category</label>
                    <Select
                        loading={categoryStore.isFetchingActiveCategories}
                        onChange={setCategory}
                        value={values.category?.id}
                        className={style.custom_select}
                        style={{ width: '100%' }}
                    >
                        {categoryStore.activeCategories?.results.map(
                            (category) => (
                                <Select.Option
                                    value={category.id}
                                    key={category.id}
                                >
                                    {category.items[0].title}
                                </Select.Option>
                            )
                        )}
                    </Select>
                </div>
                <div className={style.single_input}>
                    <label className={style.bold_label}>
                        Promotional Offer
                    </label>
                    <Select
                        style={{ width: '100%' }}
                        disabled={
                            !offerStore.offers || offerStore.isFetchingOffers
                        }
                        loading={offerStore.isFetchingOffers}
                        onChange={(id) => {
                            const selectedOffer = offerStore.getOffersById(
                                Number(id)
                            );
                            if (selectedOffer) {
                                setFieldValue(
                                    'promotionalOffer',
                                    selectedOffer
                                );
                                setFieldValue('promotionalOfferId', id);
                            } else {
                                setFieldValue('promotionalOffer', null);
                                setFieldValue('promotionalOfferId', null);
                            }
                        }}
                        allowClear
                        value={values.promotionalOffer?.id}
                        className={style.custom_select}
                    >
                        {offerStore.offers?.results.map((offer) => (
                            <Select.Option value={offer.id} key={offer.id}>
                                {offer.items[0].title}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={style.single_input}>
                    <label className={style.bold_label}>Deep Link</label>
                    <Input
                        value={values.deepLink ?? ''}
                        name="deepLink"
                        onChange={handleChange}
                        className={style.custom_input}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className={style.single_input}>
                    <label className={style.bold_label}>Discount Value</label>
                    <InputNumber
                        value={values.discountValue ?? 0}
                        name="discountValue"
                        onChange={(value) =>
                            setFieldValue('discountValue', value)
                        }
                        className={style.custom_input}
                        style={{ width: '100%' }}
                    />
                </div>
                {values.type === 'coupon' && (
                    <>
                        <div className={style.single_input}>
                            <label className={style.bold_label}>Quantity</label>
                            <InputNumber
                                min={0}
                                value={values.quantity ?? 0}
                                name="quantity"
                                onChange={(value) =>
                                    setFieldValue('quantity', value)
                                }
                                className={style.custom_input}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={style.single_input}>
                            <label className={style.bold_label}>
                                Activation Code
                            </label>
                            <Input
                                value={values.activationCode ?? ''}
                                name="activationCode"
                                onChange={handleChange}
                                className={style.custom_input}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};
