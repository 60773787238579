import React from 'react';
import { AcvtHomeGame, AcvtSupportedLanguages } from '../../../../types/acvt';
import style from './style.module.scss';

type SmallGameCard = {
    gameObject: AcvtHomeGame;
    language: AcvtSupportedLanguages;
    opened: React.Dispatch<
        React.SetStateAction<{
            homePromoCard: boolean;
            homeGameCard: boolean;
            promoCard: boolean;
            gameCard: boolean;
        }>
    >;
    setSingleGame: (promo: AcvtHomeGame) => void;
};

export const AcvtSmallGameCard = ({
    gameObject,
    language,
    opened,
    setSingleGame,
}: SmallGameCard) => {
    const setGame = () => {
        setSingleGame(gameObject);
    };

    return (
        <>
            <div className={style.container}>
                <div
                    className={style.card}
                    onClick={() => {
                        opened((prevState) => {
                            return { ...prevState, homeGameCard: true };
                        });
                        setGame();
                    }}
                >
                    <div className={style.image}>
                        <img
                            alt=""
                            src={
                                gameObject.image[
                                    language as AcvtSupportedLanguages
                                ]!
                            }
                        />
                    </div>
                    <div className={style.title}>
                        {gameObject.title[language as AcvtSupportedLanguages]}
                    </div>
                </div>
            </div>
        </>
    );
};
