import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import {
    Card,
    DatePicker,
    Statistic,
    List,
    Avatar,
    Spin,
    Divider,
    Table,
} from 'antd';
import { useAuthStore, useNhlGamesStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { useNavigation } from '../../utils/use-navigation';
import { SearchParams, KpiSearchParams } from '../../stores/nhl-game';
import moment from 'moment';
import Meta from 'antd/lib/card/Meta';
import { NotificationManager, PageHeader } from '../../components';
import { downloadCSVFile } from '../../utils/csv';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Text } from '../../utils/text';
import { ColumnProps } from 'antd/lib/table';
import { NhlGameStoresPinsKpi } from '../../types/nhl-game';
import { oktaAuth } from '../../App';

const { RangePicker } = DatePicker;

export const NhlGamesDashboardPage = observer(() => {
    const store = useNhlGamesStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const [queryParams, updateQueryParams] = useQueryParams<SearchParams>();
    const [
        queryKpiParams,
        _,
        __,
        setManyParamsAndGoToFirstPage,
    ] = useQueryParams<KpiSearchParams>();

    React.useEffect(() => {
        store.getKpiNhlGame(queryKpiParams);
        store.getNhlGameStoresPinsKpi(queryKpiParams);
    }, [store, queryParams]);

    const cardStyle = {
        marginRight: '20px',
        width: '278px',
        height: '160px',
    };

    const downloadCsv = async () => {
        const { startDate, endDate } = queryKpiParams;

        if (!startDate || !endDate) {
            NotificationManager.showError(
                Text.notificationManager.nhlGame.errorDateExportDashboard
            );
        } else {
            try {
                const newParticipant = await store.getKpiNhlGameForExport({
                    startDate,
                    endDate,
                });
                const csvData = newParticipant!.map((data) => ({
                    'User ID': data.userId,
                    'First Name': data.firstName,
                    'Last Name': data.lastName,
                    Email: data.email,
                    'Phone Number': data.phoneNumber,
                    Language: data.language,
                    'Created Date': data.createdAt,
                }));
                return downloadCSVFile(
                    csvData,
                    Text.nhlGame.dashboardContainer.csvTitle
                );
            } catch (error) {
                NotificationManager.showError(
                    Text.notificationManager.general.errorExportFile
                );
            }
        }
    };

    const downloadStoreCsv = async () => {
        const token = oktaAuth.getAccessToken() as string;
        const url = new URL(
            `${process.env.REACT_APP_API_URL}/api-cms/nhl-games/stores/kpi/download`
        );
        const searchCriterias: Record<string, string> = {};
        if (queryKpiParams.startDate)
            searchCriterias.startDate = queryKpiParams.startDate;
        if (queryKpiParams.endDate)
            searchCriterias.endDate = queryKpiParams.endDate;
        url.search = new URLSearchParams(searchCriterias).toString();
        const response = await fetch(url.toString(), {
            headers: { jwt: token },
        });
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.href = blobUrl;
        anchor.download = 'stores_pins.csv';
        anchor.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(anchor);
    };

    const columns = React.useMemo<ColumnProps<NhlGameStoresPinsKpi>[]>(
        () => [
            {
                title: Text.nhlGame.dashboardContainer.stores.table.nref,
                dataIndex: 'nref',
                key: 'nref',
            },
            {
                title: Text.nhlGame.dashboardContainer.stores.table.adress,
                dataIndex: 'address',
                render: (_: unknown, record: NhlGameStoresPinsKpi) =>
                    `${record.street}, ${record.city}, ${record.postalCode}`,
            },
            {
                title:
                    Text.nhlGame.dashboardContainer.stores.table.pinsValidated,
                dataIndex: 'validatedTbPinCount',
                key: 'validatedTbPinCount',
            },
        ],
        []
    );

    return (
        <div className={style.page_content}>
            <PageHeader title={Text.nhlGame.dashboardContainer.pageTitle} />
            <div className={style.date_picker_header}>
                <RangePicker
                    className={style.filters}
                    allowEmpty={[true, true]}
                    placeholder={[
                        Text.nhlGame.dashboardContainer.rangerPicker.placeholder
                            .start,
                        Text.nhlGame.dashboardContainer.rangerPicker.placeholder
                            .end,
                    ]}
                    defaultValue={[
                        queryKpiParams.startDate
                            ? moment(queryKpiParams.startDate)
                            : null,
                        queryKpiParams.endDate
                            ? moment(queryKpiParams.endDate)
                            : null,
                    ]}
                    onCalendarChange={(dates) => {
                        setManyParamsAndGoToFirstPage({
                            startDate: dates?.[0]?.format(),
                            endDate: dates?.[1]?.format(),
                        });
                    }}
                    format="YYYY-MM-DD"
                />
                <span
                    className={style.download_csv}
                    onClick={() => {
                        downloadCsv();
                    }}
                >
                    {Text.nhlGame.dashboardContainer.downloadCsv}{' '}
                    <ArrowDownOutlined />
                </span>
            </div>

            <div className={style.layout_section}>
                <div className={style.input_row}>
                    <h1 className={style.section_title}>
                        {Text.nhlGame.dashboardContainer.sectionTitle.users}
                    </h1>
                </div>
                <div className={style.input_row}>
                    {store.isFetchingKpi ? (
                        <Spin />
                    ) : (
                        <div className={style.input_row}>
                            <div className={`${style.fluid_input_col}`}>
                                <Card style={cardStyle}>
                                    <Statistic
                                        className={style.stats}
                                        title={
                                            Text.nhlGame.dashboardContainer
                                                .stats.newUser
                                        }
                                        value={store.kpi?.newClients}
                                    ></Statistic>
                                </Card>
                            </div>
                            <div className={`${style.fluid_input_col}`}>
                                <Card style={cardStyle}>
                                    <Statistic
                                        className={style.stats}
                                        title={
                                            Text.nhlGame.dashboardContainer
                                                .stats.newPlayers
                                        }
                                        value={store.kpi?.newParticipations}
                                    ></Statistic>
                                </Card>
                            </div>
                            <div className={`${style.fluid_input_col}`}>
                                <Card style={cardStyle}>
                                    <Statistic
                                        className={style.stats}
                                        title={
                                            Text.nhlGame.dashboardContainer
                                                .stats.visits
                                        }
                                        value={
                                            store.kpi?.firebaseMetrics
                                                .eventCount
                                        }
                                    ></Statistic>

                                    <Divider type="horizontal" />

                                    <Meta
                                        description={`${Text.nhlGame.dashboardContainer.stats.distinctUsers}
                                                ${store.kpi?.firebaseMetrics.totalUsers}`}
                                    />
                                </Card>
                            </div>
                        </div>
                    )}
                </div>

                <div className={style.section}>
                    <div className={style.input_row}>
                        <h1 className={style.section_title}>
                            {
                                Text.nhlGame.dashboardContainer.sectionTitle
                                    .coupons
                            }
                        </h1>
                    </div>
                    <div className={style.input_row}>
                        {store.isFetchingKpi ? (
                            <Spin />
                        ) : (
                            <div className={style.input_row}>
                                <div className={`${style.fluid_input_col}`}>
                                    <Card style={cardStyle}>
                                        <Statistic
                                            className={style.stats}
                                            title={
                                                Text.nhlGame.dashboardContainer
                                                    .stats.couponsWon
                                            }
                                            value={
                                                store.kpi?.kpiPromotions.grants
                                            }
                                        ></Statistic>
                                    </Card>
                                </div>
                                <div className={`${style.fluid_input_col}`}>
                                    <Card style={cardStyle}>
                                        <Statistic
                                            className={style.stats}
                                            title={
                                                Text.nhlGame.dashboardContainer
                                                    .stats.couponsRedeemed
                                            }
                                            value={
                                                store.kpi?.kpiPromotions
                                                    .activations
                                            }
                                        ></Statistic>
                                    </Card>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={style.section}>
                    <div className={style.input_row}>
                        <h1 className={style.section_title}>
                            {Text.nhlGame.dashboardContainer.sectionTitle.teams}
                        </h1>
                    </div>
                    <div className={style.input_row}>
                        {store.isFetchingKpi ? (
                            <Spin />
                        ) : (
                            <>
                                <Card
                                    title={
                                        <span>
                                            {store.kpi?.teamsSelected}/32 are
                                            selected
                                        </span>
                                    }
                                >
                                    <List
                                        itemLayout="horizontal"
                                        className={style.list}
                                        dataSource={
                                            store.kpi?.mostSelectedTeams
                                        }
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar
                                                            src={item.logo}
                                                        />
                                                    }
                                                    title={
                                                        <h2>
                                                            {item.city}{' '}
                                                            {item.name}
                                                        </h2>
                                                    }
                                                    description={`${Text.nhlGame.dashboardContainer.teamSelected} ${item.count}`}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </>
                        )}
                    </div>
                </div>
                <div className={style.section}>
                    <div className={style.input_row}>
                        <h1 className={style.section_title}>
                            {
                                Text.nhlGame.dashboardContainer.sectionTitle
                                    .stores
                            }
                        </h1>
                        <span
                            className={style.download_csv}
                            onClick={() => {
                                downloadStoreCsv();
                            }}
                        >
                            {Text.nhlGame.dashboardContainer.downloadCsv}{' '}
                            <ArrowDownOutlined />
                        </span>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={store.storesPinsKpi || []}
                    />
                </div>
            </div>
        </div>
    );
});
