import React, { useState } from 'react';
import { AcvtGenHeader } from '../generic-header';
import style from './style.module.scss';
import { ReactComponent as ArrowIcon } from '../../../icons/left-arrow.svg';
import { AcvtSupportedLanguages } from '../../../types/acvt';
import { Text } from '../../../utils/text';

export const AcvtGamesPage = (props: {
    language?: AcvtSupportedLanguages;
    cards: React.ReactNode;
}) => {
    const [isIconHovering, setIsIconHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsIconHovering(true);
    };

    const handleMouseLeave = () => {
        setIsIconHovering(false);
    };
    return (
        <>
            <div className={style.games_content}>
                <AcvtGenHeader textColor="#FFFFFF">
                    <ArrowIcon
                        fill={isIconHovering ? 'black' : 'white'}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={style.back_arrow}
                    />
                    {props.language === 'fr'
                        ? Text.ACVT.gamesPage.header['fr']
                        : Text.ACVT.gamesPage.header['en']}
                </AcvtGenHeader>
                <div className={style.scroll_content}>{props.cards}</div>
            </div>
        </>
    );
};
