import React from 'react';
import { AcvtSupportedLanguages } from '../../../types/acvt';
import style from './style.module.scss';

export const AcvtPlaceholderPage = (props: {
    language: AcvtSupportedLanguages;
    children: React.ReactNode;
}) => {
    return (
        <>
            <div className={style.placeholder_content}>
                {props.children}
                {props.language === 'fr'
                    ? 'Pas encore de contenu...'
                    : 'No Content yet...'}
            </div>
        </>
    );
};
