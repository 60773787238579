import { action, makeObservable, observable } from 'mobx';
import * as yup from 'yup';
import { RootStore } from './root';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { NotificationManager } from '../components';
import { HttpMethod } from '../types';

export const yupRecharge = yup.object({
    id: yup.number().required(),
    active: yup.boolean().required(),
    place: yup.string().required(),
    type: yup.string().required(),
    image: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
});

export type RechargeListResponse = {
    rechargeImages: Recharge[];
};

export interface UpsertRechargeImage {
    active: boolean;
    place: string;
    type: string;
    image: {
        fr: string;
        en: string;
    };
}

export interface RechargeForm {
    image: {
        fr: string | null;
        en: string | null;
    };
    active: boolean;
    place: string;
    type: string;
}

export type Recharge = yup.InferType<typeof yupRecharge>;

export class RechargeStore extends WithNetworkConcurrency {
    @observable rechargeList: Recharge[] = [];
    @observable isFetchingList = false;
    @observable isFetchingRechargeImages = false;
    @observable isUpdatedRechargeImages = false;
    @observable isCreatingRechargeImages = false;
    @observable isDeletingRechargeImages = false;
    @observable isFetchingRechargeImage = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchRechargeImageList(this: RechargeStore) {
        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall<
            RechargeListResponse
        >({
            method: HttpMethod.GET,
            url: 'recharge/images',
        });

        if (response.data) {
            this.rechargeList = response.data.rechargeImages;
        }

        this.isFetchingList = false;
    }
    @action
    async updateRechargeImage(
        this: RechargeStore,
        id: number,
        data: UpsertRechargeImage
    ) {
        this.isUpdatedRechargeImages = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            data,
            url: `/recharge/images/${id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Recharge image modified.');
        }

        this.isUpdatedRechargeImages = false;
    }

    @action
    async createRechargeImage(this: RechargeStore, data: UpsertRechargeImage) {
        this.isCreatingRechargeImages = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            data,
            url: '/recharge/images',
        });

        if (!response.err) {
            NotificationManager.showSuccess('Recharge image created');
        }
        this.isCreatingRechargeImages = false;
    }

    @action
    async deleteRechargeImage(this: RechargeStore, id: number) {
        this.isDeletingRechargeImages = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/recharge/images/${id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Recharge image deleted.');
        }

        this.isDeletingRechargeImages = false;

        return response;
    }

    @action
    async fetchRechargeImage(
        this: RechargeStore,
        id: string
    ): Promise<Recharge | null> {
        this.isFetchingRechargeImage = true;
        try {
            const response = await this.rootStore.makeNetworkCall<Recharge>({
                method: HttpMethod.GET,
                url: `/recharge/images/${id}`,
            });

            if (!response.err && response.data) {
                return response.data;
            } else {
                NotificationManager.showError(
                    'No recharge image found. Look for something else.'
                );
                return null;
            }
        } finally {
            this.isFetchingRechargeImage = false;
        }
    }
}
