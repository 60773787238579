import Papa from 'papaparse';
import { CsvContent } from '../types';
import jsCharDet from 'jschardet';

export const downloadCSVFile = (
    ArrayOfDataToExport: CsvContent,
    type: string
) => {
    const csvData = Papa.unparse(ArrayOfDataToExport ?? []);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${type}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

/**
 * New helper method to extract the file encoding
 *
 * @param file
 * @returns
 */
export const getFileEncoding = (file: File): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = (e: ProgressEvent) => {
            const fileInfo = jsCharDet.detect(reader.result as string);
            resolve(fileInfo.encoding);
        };
        reader.onerror = (e: ProgressEvent) => {
            reject(reader.error);
            reader.abort();
        };
        reader.readAsBinaryString(file);
    });
};

/**
 * New helper method to text to read a csv file
 *
 * @param file
 * @returns
 */
export const getFileAsString = (
    file: File,
    encoding: string
): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = (e: ProgressEvent) => {
            resolve(reader.result as string);
        };
        reader.onerror = (e: ProgressEvent) => {
            reject(reader.error);
            reader.abort();
        };
        reader.readAsText(file, encoding);
    });
};
