import React from 'react';
import style from './style.module.scss';
import { ReactComponent as PromotionsIcon } from '../../../../icons/Icon_Promotions_1X.svg';
import { AcvtRedSticker } from '../../red-sticker';
import { AcvtPromotion, AcvtSupportedLanguages } from '../../../../types/acvt';
import moment, { Moment } from 'moment';

type PromoCard = {
    promoObject: AcvtPromotion;
    language: AcvtSupportedLanguages;
    opened: React.Dispatch<
        React.SetStateAction<{
            homePromoCard: boolean;
            homeGameCard: boolean;
            promoCard: boolean;
            gameCard: boolean;
        }>
    >;
    setSinglePromo: (promo: AcvtPromotion) => void;
};

export const AcvtPromoCard = ({
    promoObject,
    language,
    opened,
    setSinglePromo,
}: PromoCard) => {
    const setPromo = () => {
        setSinglePromo(promoObject);
    };

    const getStartDateFR = (date: Moment | string | undefined) => {
        const localLocale = moment(date);
        localLocale.locale('fr-ca');
        return localLocale.format('Do MMMM YYYY');
    };

    const getEndDateFR = (date: Moment | string | undefined) => {
        const localLocale = moment(date);
        localLocale.locale('fr-ca');
        return localLocale.format('Do MMMM YYYY');
    };

    return (
        <>
            <div className={style.container}>
                <div
                    className={style.card}
                    onClick={() => {
                        opened((prevState) => {
                            return { ...prevState, promoCard: true };
                        });
                        setPromo();
                    }}
                >
                    <div className={style.image}>
                        <img alt="" src={promoObject?.thumbnail ?? ''} />
                    </div>
                    <div className={style.text_grid}>
                        <div id={style.category}>
                            {promoObject.category.title}
                        </div>
                        <div id={style.title}>{promoObject.title}</div>
                        <div id={style.dates}>
                            {promoObject.activatableDate != null &&
                            promoObject.inactivatableDate != null
                                ? language === 'fr'
                                    ? `Valide entre le ${getStartDateFR(
                                          promoObject.activatableDate
                                      )} et le ${getEndDateFR(
                                          promoObject.inactivatableDate
                                      )}`
                                    : `Valid between ${moment(
                                          promoObject.activatableDate
                                      ).format('MMMM Do YYYY')} and ${moment(
                                          promoObject.inactivatableDate
                                      ).format('MMMM Do YYYY')} `
                                : language === 'fr'
                                ? `Valide entre le ${getStartDateFR(
                                      promoObject.startDate
                                  )} et le ${getEndDateFR(promoObject.endDate)}`
                                : `Valid between ${moment(
                                      promoObject.startDate
                                  ).format('MMMM Do YYYY')} and ${moment(
                                      promoObject.endDate
                                  ).format('MMMM Do YYYY')} `}
                        </div>
                        {promoObject.type === 'coupon' &&
                        promoObject?.promotionalOffer?.title !==
                            (undefined && 'undefined' && '') ? (
                            <div id={style.coupon_icon_box}>
                                <span id={style.coupon_icon}>
                                    <PromotionsIcon />
                                </span>
                                {promoObject.activatableDate == null &&
                                promoObject.inactivatableDate == null
                                    ? language === 'fr'
                                        ? 'Utiliser'
                                        : 'Use'
                                    : moment().isBetween(
                                          promoObject.activatableDate,
                                          promoObject.inactivatableDate
                                      ) &&
                                      (moment().hours() >=
                                          promoObject.startPeriod! ||
                                          promoObject.startPeriod === null) &&
                                      (moment().hours() <=
                                          promoObject.endPeriod! ||
                                          promoObject.endPeriod === null)
                                    ? language === 'fr'
                                        ? 'Utiliser'
                                        : 'Use'
                                    : moment().isBetween(
                                          promoObject.activatableDate,
                                          promoObject.inactivatableDate
                                      )
                                    ? language === 'fr'
                                        ? 'À venir'
                                        : 'Upcoming'
                                    : moment().isBefore(
                                          promoObject.activatableDate
                                      )
                                    ? language === 'fr'
                                        ? 'À venir'
                                        : 'Upcoming'
                                    : language === 'fr'
                                    ? 'Expiré'
                                    : 'Expired'}
                            </div>
                        ) : (
                            <div style={{ height: 35 }}></div>
                        )}
                    </div>
                </div>
                <div className={style.red_sticker}>
                    {promoObject.type === 'coupon' &&
                        promoObject?.promotionalOffer?.title !==
                            (undefined && 'undefined' && '') && (
                            <AcvtRedSticker
                                offer={promoObject?.promotionalOffer?.title}
                            />
                        )}
                </div>
            </div>
        </>
    );
};
