import React, { useState } from 'react';
import { AcvtGenHeader } from '../generic-header';
import style from './style.module.scss';
import { ReactComponent as ArrowIcon } from '../../../icons/left-arrow.svg';
import { AcvtRedSticker } from '../red-sticker';
import { ReactComponent as PromotionsIcon } from '../../../icons/Icon_Promotions_1X.svg';
import parser from 'html-react-parser';
import { AcvtPromotion, AcvtSupportedLanguages } from '../../../types/acvt';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';
moment.locale('en');

type PromoInfo = {
    language: AcvtSupportedLanguages;
    promoObject?: AcvtPromotion;
    opened: React.Dispatch<
        React.SetStateAction<{
            homePromoCard: boolean;
            homeGameCard: boolean;
            promoCard: boolean;
            gameCard: boolean;
        }>
    >;
    onEditMode?: boolean;
};

export const AcvtPromotionPage = ({
    language,
    promoObject,
    opened,
    onEditMode = false,
}: PromoInfo) => {
    const [isIconHovering, setIsIconHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsIconHovering(true);
    };

    const handleMouseLeave = () => {
        setIsIconHovering(false);
    };

    const getStartDateFR = (date: Moment | string | undefined) => {
        const localLocale = moment(date);
        localLocale.locale('fr-ca');
        return localLocale.format('Do MMMM YYYY');
    };

    const getEndDateFR = (date: Moment | string | undefined) => {
        const localLocale = moment(date);
        localLocale.locale('fr-ca');
        return localLocale.format('Do MMMM YYYY');
    };

    const convertToAMPM = (hour: number) => {
        if (hour <= 12) {
            return `${hour}AM`;
        } else {
            return `${hour - 12}PM`;
        }
    };

    return (
        <>
            <div className={style.page_content}>
                <AcvtGenHeader>
                    <ArrowIcon
                        fill={isIconHovering ? 'black' : 'grey'}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={style.back_arrow}
                        onClick={() =>
                            opened((prevState) => {
                                return {
                                    ...prevState,
                                    promoCard: false,
                                    homePromoCard: false,
                                };
                            })
                        }
                    />
                    Promotion
                </AcvtGenHeader>
                <div
                    className={style.promotion_content}
                    style={
                        onEditMode
                            ? {}
                            : promoObject?.activatableDate == null &&
                              promoObject?.inactivatableDate == null
                            ? {}
                            : moment().isBetween(
                                  promoObject?.activatableDate,
                                  promoObject?.inactivatableDate
                              ) &&
                              (((moment().hours() >= promoObject.startPeriod! ||
                                  promoObject?.startPeriod == null) &&
                                  (moment().hours() <= promoObject.endPeriod! ||
                                      promoObject?.endPeriod == null)) ||
                                  (promoObject.endPeriod! <
                                      promoObject.startPeriod! &&
                                      (moment().hours() >=
                                          promoObject.endPeriod! ||
                                          promoObject?.endPeriod == null) &&
                                      (moment().hours() >=
                                          promoObject.startPeriod! ||
                                          promoObject?.startPeriod == null)))
                            ? {}
                            : { opacity: 0.5 }
                    }
                >
                    <div
                        className={style.promo_banner}
                        style={{
                            backgroundImage: `url("${promoObject?.background}")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            filter: onEditMode
                                ? ''
                                : moment().isBetween(
                                      promoObject?.activatableDate,
                                      promoObject?.inactivatableDate
                                  ) ||
                                  moment().isBefore(
                                      promoObject?.activatableDate
                                  ) ||
                                  (promoObject?.activatableDate == null &&
                                      promoObject?.inactivatableDate == null)
                                ? ''
                                : 'grayscale(1)',
                        }}
                    >
                        <div className={style.red_sticker}>
                            {promoObject?.type === 'coupon' &&
                                promoObject?.promotionalOffer?.title !==
                                    (undefined || 'undefined') && (
                                    <AcvtRedSticker
                                        offer={
                                            promoObject?.promotionalOffer?.title
                                        }
                                    />
                                )}
                        </div>
                        <img alt="" src={promoObject?.thumbnail ?? ''} />
                    </div>
                    <div className={style.info}>
                        <div className={style.text_grid}>
                            <div id={style.category}>
                                {promoObject?.category.title}
                            </div>
                            <div id={style.title}>{promoObject?.title}</div>
                            <div id={style.dates}>
                                {promoObject?.activatableDate != null &&
                                promoObject?.inactivatableDate != null
                                    ? language === 'fr'
                                        ? `Valide entre le ${getStartDateFR(
                                              promoObject?.activatableDate
                                          )} et le ${getEndDateFR(
                                              promoObject?.inactivatableDate
                                          )}`
                                        : `Valid between ${moment(
                                              promoObject?.activatableDate
                                          ).format(
                                              'MMMM Do YYYY'
                                          )} and ${moment(
                                              promoObject?.inactivatableDate
                                          ).format('MMMM Do YYYY')} `
                                    : language === 'fr'
                                    ? `Valide entre le ${getStartDateFR(
                                          promoObject?.startDate
                                      )} et le ${getEndDateFR(
                                          promoObject?.endDate
                                      )}`
                                    : `Valid between ${moment(
                                          promoObject?.startDate
                                      ).format('MMMM Do YYYY')} and ${moment(
                                          promoObject?.endDate
                                      ).format('MMMM Do YYYY')} `}
                            </div>
                            {promoObject?.type === 'coupon' &&
                                promoObject?.promotionalOffer?.title !==
                                    (undefined && 'undefined' && '') && (
                                    <div
                                        className={
                                            style.coupon_button_container
                                        }
                                    >
                                        {moment().isBetween(
                                            promoObject?.activatableDate,
                                            promoObject?.inactivatableDate
                                        ) ||
                                        moment().isBefore(
                                            promoObject?.activatableDate
                                        ) ||
                                        (promoObject?.activatableDate == null &&
                                            promoObject?.inactivatableDate ==
                                                null) ? (
                                            <button id={style.coupon_button}>
                                                <span id={style.coupon_icon}>
                                                    <PromotionsIcon />
                                                </span>
                                                {promoObject?.activatableDate ==
                                                    null &&
                                                promoObject?.inactivatableDate ==
                                                    null
                                                    ? language === 'fr'
                                                        ? 'Utiliser'
                                                        : 'Use'
                                                    : moment().isBetween(
                                                          promoObject?.activatableDate,
                                                          promoObject?.inactivatableDate
                                                      ) &&
                                                      (moment().hours() >=
                                                          promoObject.startPeriod! ||
                                                          promoObject?.startPeriod ==
                                                              null) &&
                                                      (moment().hours() <=
                                                          promoObject.endPeriod! ||
                                                          promoObject?.endPeriod ==
                                                              null)
                                                    ? language === 'fr'
                                                        ? 'Utiliser'
                                                        : 'Use'
                                                    : moment().isBetween(
                                                          promoObject?.activatableDate,
                                                          promoObject?.inactivatableDate
                                                      )
                                                    ? language === 'fr'
                                                        ? `${promoObject?.startPeriod}h à ${promoObject?.endPeriod}h`
                                                        : `${convertToAMPM(
                                                              promoObject.startPeriod!
                                                          )} to ${convertToAMPM(
                                                              promoObject.endPeriod!
                                                          )}`
                                                    : moment().isBefore(
                                                          promoObject?.activatableDate
                                                      )
                                                    ? language === 'fr'
                                                        ? 'À venir'
                                                        : 'Upcoming'
                                                    : language === 'fr'
                                                    ? 'Expiré'
                                                    : 'Expired'}
                                            </button>
                                        ) : (
                                            <div
                                                style={{
                                                    color: 'red',
                                                }}
                                            >
                                                Expired
                                            </div>
                                        )}
                                    </div>
                                )}
                            <div id={style.description}>
                                {parser(promoObject?.description ?? '')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
