import React from 'react';
import { observer } from 'mobx-react';
import { Table, Tag, Select, Spin } from 'antd';
import { useAuthStore, useFeaturedStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { SearchParams, Featured } from '../../stores/featured';
import { ColumnProps } from 'antd/es/table';
import { ImageHolder, PageHeader, Pagination } from '../../components';
import { useNavigation } from '../../utils/use-navigation';
import {
    FeatureTypeColors,
    ActiveQueryParam,
    coucheTardRed,
} from '../../utils/const';
import style from './style.module.scss';
import { FormOutlined } from '@ant-design/icons';
import { Text } from '../../utils/text';

const renderIsActive = (_: unknown, record: Featured) => {
    return (
        <div>
            {record.isActive ? (
                <Tag color="#87d068">Active</Tag>
            ) : (
                <Tag color="#ee1313">Inactive</Tag>
            )}
        </div>
    );
};

const renderImage = (_: unknown, record: Featured) => {
    return (
        <ImageHolder
            src={record.items[0].backgroundImage ?? undefined}
            className="thumbnail"
            size={60}
        />
    );
};

const renderType = (_: unknown, record: Featured) => {
    return (
        <>
            <div className={style.input_row}>
                <Tag color={FeatureTypeColors[record.type]}>{record.type}</Tag>
                {record.type === 'external_url' && (
                    <div className="light_label lil_label">
                        {record.items[0].externalUrl}
                    </div>
                )}
                {record.geolocated ? (
                    <Tag color={coucheTardRed} style={{ marginLeft: 10 }}>
                        {Text.featuredPage.tags.geolocated}
                    </Tag>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export const FeaturedPage = observer(() => {
    const featuredStore = useFeaturedStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const columns: ColumnProps<Featured>[] = [
        {
            title: Text.featuredPage.columnTitle.active,
            key: 'is_active',
            render: renderIsActive,
        },
        {
            title: Text.featuredPage.columnTitle.image,
            key: 'image',
            render: renderImage,
        },
        {
            title: Text.featuredPage.columnTitle.type,
            render: renderType,
        },
    ];

    const [queryParams, updateParams, updateAndResetParams] = useQueryParams<
        SearchParams
    >();

    React.useEffect(() => {
        featuredStore.getPaginatedList(queryParams);
    }, [featuredStore, queryParams]);

    return (
        <div className={style.page_content}>
            <PageHeader title={Text.featuredPage.pageTitle}>{}</PageHeader>
            <div className={style.table_header}>
                <div className={style.query_box}>
                    <span
                        className={style.create_button}
                        onClick={() => {
                            navigation.replaceToFeaturedCreation();
                        }}
                    >
                        {Text.featuredPage.createButton} <FormOutlined />
                    </span>
                </div>

                <div className={style.query_box}>
                    <Select<ActiveQueryParam>
                        placeholder="Status"
                        style={{ width: 120 }}
                        value={queryParams.filter}
                        onChange={(a) => updateAndResetParams('filter', a)}
                    >
                        {ActiveQueryParam.map((a) => (
                            <Select.Option value={a} key={a}>
                                {a}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div className={style.pagination_container}>
                    <Pagination
                        className={style.pagination}
                        params={queryParams}
                        meta={featuredStore.paginatedList}
                        onChange={(pageNumber) =>
                            updateParams({ page: pageNumber })
                        }
                        onPageSizeChange={(size) =>
                            updateParams({ perPage: size })
                        }
                    />
                </div>
            </div>

            {featuredStore.isFetchingList ? (
                <Spin />
            ) : (
                <Table<Featured>
                    rowKey="id"
                    dataSource={featuredStore.paginatedList?.results}
                    columns={columns}
                    loading={featuredStore.isFetchingList}
                    pagination={false}
                    bordered
                    onRow={(r) => ({
                        onClick: () => navigation.goToFeaturedEdit(r.id),
                    })}
                />
            )}
        </div>
    );
});
