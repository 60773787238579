import React, { useState } from 'react';
import { AcvtGenHeader } from '../generic-header';
import style from './style.module.scss';
import { ReactComponent as ArrowIcon } from '../../../icons/left-arrow.svg';
import parser from 'html-react-parser';
import { AcvtGame, AcvtSupportedLanguages } from '../../../types/acvt';
import { Text } from '../../../utils/text';

type GameInfo = {
    language: AcvtSupportedLanguages;
    gameObject?: AcvtGame;
    opened: React.Dispatch<
        React.SetStateAction<{
            homePromoCard: boolean;
            homeGameCard: boolean;
            promoCard: boolean;
            gameCard: boolean;
        }>
    >;
};

export const AcvtGamePage = ({ language, gameObject, opened }: GameInfo) => {
    const [checkboxStatus, setCheckboxStatus] = useState(
        Array(gameObject?.checkboxes?.length).fill(false)
    );

    const [isIconHovering, setIsIconHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsIconHovering(true);
    };

    const handleMouseLeave = () => {
        setIsIconHovering(false);
    };

    const buttonHandler = (index: number) => {
        const status = [...checkboxStatus];
        status[index as number] = !status[index as number];
        setCheckboxStatus(status);
    };

    return (
        <>
            <div className={style.page_content}>
                <AcvtGenHeader textColor="#FFFFFF">
                    <ArrowIcon
                        fill={isIconHovering ? 'black' : 'white'}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={style.back_arrow}
                        onClick={() =>
                            opened((prevState) => {
                                return {
                                    ...prevState,
                                    gameCard: false,
                                    homeGameCard: false,
                                };
                            })
                        }
                    />
                    {language === 'fr'
                        ? Text.ACVT.gamesPage.header.fr
                        : Text.ACVT.gamesPage.header.en}
                </AcvtGenHeader>
                <div className={style.game_content}>
                    <div className={style.title}>
                        {gameObject?.title[language as AcvtSupportedLanguages]}
                        <div id={style.subtitle}>
                            {
                                gameObject?.subTitle[
                                    language as AcvtSupportedLanguages
                                ]
                            }
                        </div>
                    </div>
                    <div className={style.game_banner}>
                        <img
                            alt=""
                            src={
                                gameObject?.image[
                                    language as AcvtSupportedLanguages
                                ] ?? ''
                            }
                        />
                    </div>
                    <div className={style.info}>
                        <div className={style.info_grid}>
                            {gameObject?.type === 'sport' ? (
                                gameObject?.isGameDay ? (
                                    <>
                                        <div
                                            className={
                                                style.play_button_container
                                            }
                                        >
                                            <div id={style.sport_message}>
                                                {
                                                    gameObject
                                                        ?.gameTodayMessage[
                                                        language as AcvtSupportedLanguages
                                                    ]
                                                }
                                            </div>
                                            <button
                                                id={style.play_button}
                                                disabled={
                                                    checkboxStatus.filter(
                                                        (status) =>
                                                            status === true
                                                    ).length ===
                                                    gameObject?.checkboxes
                                                        ?.length
                                                        ? false
                                                        : true
                                                }
                                            >
                                                {gameObject?.buttonTitle[
                                                    language as AcvtSupportedLanguages
                                                ] != null &&
                                                gameObject?.buttonTitle[
                                                    language as AcvtSupportedLanguages
                                                ] !== ''
                                                    ? gameObject?.buttonTitle[
                                                          language as AcvtSupportedLanguages
                                                      ]
                                                    : language === 'fr'
                                                    ? Text.ACVT.gamesPage
                                                          .playbutton.fr
                                                    : Text.ACVT.gamesPage
                                                          .playbutton.en}
                                            </button>
                                        </div>
                                        <div className={style.checkboxes}>
                                            {gameObject?.checkboxes.map(
                                                (checkbox, index) => (
                                                    <label
                                                        key={index}
                                                        className={
                                                            style.square_checkbox
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                checkboxStatus[
                                                                    index as number
                                                                ]
                                                            }
                                                            onChange={() =>
                                                                buttonHandler(
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <span
                                                            className={
                                                                style.checkmark
                                                            }
                                                        ></span>
                                                        {checkbox.text != null
                                                            ? parser(
                                                                  checkbox.text[
                                                                      language as AcvtSupportedLanguages
                                                                  ]
                                                              )
                                                            : parser(
                                                                  checkbox
                                                                      .translations[
                                                                      language as AcvtSupportedLanguages
                                                                  ]
                                                              )}
                                                    </label>
                                                )
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        className={style.play_button_container}
                                    >
                                        <div id={style.sport_message}>
                                            {
                                                gameObject?.noGameTodayMessage[
                                                    language as AcvtSupportedLanguages
                                                ]
                                            }
                                        </div>
                                    </div>
                                )
                            ) : (
                                <>
                                    <div
                                        className={style.play_button_container}
                                    >
                                        <button
                                            id={style.play_button}
                                            disabled={
                                                checkboxStatus.filter(
                                                    (status) => status === true
                                                ).length ===
                                                gameObject?.checkboxes?.length
                                                    ? false
                                                    : true
                                            }
                                        >
                                            {gameObject?.buttonTitle[
                                                language as AcvtSupportedLanguages
                                            ] != null &&
                                            gameObject?.buttonTitle[
                                                language as AcvtSupportedLanguages
                                            ] !== ''
                                                ? gameObject?.buttonTitle[
                                                      language as AcvtSupportedLanguages
                                                  ]
                                                : language === 'fr'
                                                ? Text.ACVT.gamesPage.playbutton
                                                      .fr
                                                : Text.ACVT.gamesPage.playbutton
                                                      .en}
                                        </button>
                                    </div>
                                    <div className={style.checkboxes}>
                                        {gameObject?.checkboxes?.map(
                                            (checkbox, index) => (
                                                <label
                                                    key={index}
                                                    className={
                                                        style.square_checkbox
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            checkboxStatus[
                                                                index as number
                                                            ]
                                                        }
                                                        onChange={() =>
                                                            buttonHandler(index)
                                                        }
                                                    />
                                                    <span
                                                        className={
                                                            style.checkmark
                                                        }
                                                    ></span>
                                                    {checkbox.text != null
                                                        ? parser(
                                                              checkbox.text[
                                                                  language as AcvtSupportedLanguages
                                                              ]
                                                          )
                                                        : parser(
                                                              checkbox
                                                                  .translations[
                                                                  language as AcvtSupportedLanguages
                                                              ]
                                                          )}
                                                </label>
                                            )
                                        )}
                                    </div>
                                </>
                            )}
                            <div id={style.description}>
                                {parser(
                                    gameObject?.description[
                                        language as AcvtSupportedLanguages
                                    ] ?? ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
