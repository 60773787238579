import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface DeleteOptions {
    onConfirm: () => void;
}

export const ModalManager = {
    showDeleteModal: (options: DeleteOptions) => () => {
        Modal.confirm({
            title: 'Warning',
            icon: <ExclamationCircleOutlined />,
            content: 'This action is not reversible',
            cancelText: 'Cancel',
            okText: 'Confirm',
            onOk: options.onConfirm,
            okButtonProps: {
                danger: true,
            },
        });
    },
};
