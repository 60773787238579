import React from 'react';
import { Tag } from 'antd';

export const StoreTag = (props: { status: string }) => {
    switch (props.status) {
        case 'open':
            return <Tag color="cyan">Open</Tag>;
        case 'open-24':
            return <Tag color="#0B8E8E">Open 24</Tag>;
        case 'closed':
            return <Tag color="red">Closed</Tag>;
        case 'temporarily-closed':
            return <Tag color="volcano">Closed Temporarily</Tag>;
        case 'coming-soon':
            return <Tag color="blue">Coming Soon</Tag>;
        default:
            return <Tag color="purple">{props.status}</Tag>;
    }
};
