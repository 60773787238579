import React from 'react';
import style from './style.module.scss';
import { Select } from 'antd';
import { AcvtSupportedLanguages } from '../../../../types/acvt';

const { Option } = Select;

export const AcvtCouponsPage = (props: {
    language?: AcvtSupportedLanguages;
    cards: React.ReactNode;
    offersList?: (string | undefined | null)[];
    categoriesList?: (string | undefined | null)[];
    selectedOffer: string | undefined;
    selectedCategory: string | undefined;
    changeOffers: (selection: string) => void;
    changeCategories: (selection: string) => void;
}) => {
    const changeOffers = () => {
        return props.changeOffers;
    };

    const changeCategories = () => {
        return props.changeCategories;
    };

    return (
        <>
            <div className={style.page_container}>
                <div className={style.selector_container}>
                    <Select
                        placeholder={
                            props.language === 'en' ? 'Offers' : 'Offres'
                        }
                        allowClear
                        style={{ width: 160, margin: 5 }}
                        onChange={changeOffers()}
                        defaultValue={props.selectedOffer}
                    >
                        {props.offersList?.map((offer) => (
                            <Option key={`${offer}`} value={`${offer}`}>
                                {offer}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        placeholder={
                            props.language === 'en'
                                ? 'Categories'
                                : 'Catégories'
                        }
                        allowClear
                        style={{ width: 160, margin: 5 }}
                        onChange={changeCategories()}
                        defaultValue={props.selectedCategory}
                    >
                        {props.categoriesList?.map((category) => (
                            <Option key={`${category}`} value={`${category}`}>
                                {category}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={style.coupons_content}>
                    <h3 className={style.header}>COUPONS</h3>
                    {props.cards}
                </div>
            </div>
        </>
    );
};
