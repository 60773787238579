import React from 'react';
import { AcvtSupportedLanguages } from '../../../types/acvt';
import { Text } from '../../../utils/text';
import style from './style.module.scss';

export const AcvtHomeGames = (props: {
    language: AcvtSupportedLanguages;
    smallCards: React.ReactNode;
    featuredCards?: React.ReactNode;
}) => {
    return (
        <>
            <div className={style.content}>
                <div className={style.title}>
                    {props.language === 'fr'
                        ? Text.ACVT.gamesPage.header['fr']
                        : Text.ACVT.gamesPage.header['en']}
                </div>
                <div className={style.featured_card}>{props.featuredCards}</div>
                <div className={style.side_scroll}>{props.smallCards}</div>
            </div>
        </>
    );
};
