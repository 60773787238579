import React from 'react';
import { observer } from 'mobx-react';
import { useAnalyticsStore, useAuthStore } from '../../contexts/mobx';
import { Card, Spin } from 'antd';
import { ComparableDateRange } from '../../stores/analytics';
import { useQueryParams } from '../../utils/use-query-params';
import moment from 'moment';
import style from './style.module.scss';
import { useNavigation } from '../../utils/use-navigation';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
    mapAnalytics,
    getLastDayData,
    GoogleAnalyticsData,
    LastDaysData,
} from '../../helpers/analytics-helpers';
import {
    AnalyticsCompareOptions,
    ANALYTICS_DEFAULT_DATE_RANGE,
    ANALYTICS_DEFAULT_COMPARE_OPTION,
} from '../../utils/const';
import {
    AnalyticsCharts,
    AnalyticsCompareDates,
    AnalyticsDatesPicker,
    PageHeader,
} from '../../components';
import StatsBox from '../../components/stats-box';
import { ReactComponent as Bacon } from '../../icons/Bacon.svg';
import { ReactComponent as Carwash } from '../../icons/Carwash.svg';
import { ReactComponent as CarwashPurchase } from '../../icons/CarwashPurchase.svg';
import { ReactComponent as Income } from '../../icons/Income.svg';
import { ReactComponent as Money } from '../../icons/Money.svg';
import { ReactComponent as NewUser } from '../../icons/NewUser.svg';
import { ReactComponent as Request } from '../../icons/Request.svg';
import { ReactComponent as UserAction } from '../../icons/UserAction.svg';
import { ReactComponent as Users } from '../../icons/Users.svg';
import { ReactComponent as Visit } from '../../icons/Visit.svg';
import { ReactComponent as PromotionsIcon } from '../../icons/Icon_Promotions_1X.svg';
import { ReactComponent as CreditCard } from '../../icons/CreditCard.svg';

export const DashboardPage = observer(() => {
    const analyticsStore = useAnalyticsStore();
    const authStore = useAuthStore();
    const navigation = useNavigation();
    const [isComparing, setCompare] = React.useState<boolean>(false);
    const [dateRange, setDateRange] = React.useState<number>(
        ANALYTICS_DEFAULT_DATE_RANGE
    );
    const [searchPeriod, setSearchPeriod] = React.useState<
        AnalyticsCompareOptions
    >(ANALYTICS_DEFAULT_COMPARE_OPTION);

    const { hasManagementAccess, isCustomerServiceAgent } = authStore;

    if (!hasManagementAccess && !isCustomerServiceAgent) {
        navigation.goToHome();
    }

    const [
        dateRangeParams,
        _,
        __,
        setManyParamsAndGoToFirstPage,
    ] = useQueryParams<ComparableDateRange>();

    React.useEffect(() => {
        analyticsStore.fetchData(dateRangeParams);
        analyticsStore.fetchAcquisition(dateRangeParams);
        analyticsStore.fetchFeaturesKpis(dateRangeParams);
    }, [dateRangeParams, analyticsStore]);

    const kpis = analyticsStore.featuresKpisList;

    const calculateDifference = (
        current: number | null | undefined,
        previous: number | null | undefined
    ): number => {
        if (typeof current === 'number' && typeof previous === 'number') {
            return ((current - previous) / previous) * 100;
        }
        return 0;
    };

    const currentData: GoogleAnalyticsData[] = analyticsStore.activeUsersList
        ?.activeDayUsers
        ? mapAnalytics(
              dateRangeParams.startDate,
              analyticsStore.activeUsersList?.activeDayUsers,
              'current'
          )
        : [];

    const compareData: GoogleAnalyticsData[] =
        isComparing && analyticsStore.activeUsersList?.activeDayUsers
            ? mapAnalytics(
                  dateRangeParams.compareEndDate,
                  analyticsStore.activeUsersList?.activeDayUsers,
                  'compare'
              )
            : [];

    const currentLastDays: LastDaysData = getLastDayData(
        currentData,
        dateRangeParams.endDate
    );

    const compareLastDays: LastDaysData = getLastDayData(
        compareData,
        dateRangeParams.compareEndDate
    );

    const changeSearchPeriod = async (
        selectedPeriod: AnalyticsCompareOptions
    ) => {
        setSearchPeriod(selectedPeriod);

        if (isComparing) {
            setManyParamsAndGoToFirstPage({
                compareStartDate:
                    selectedPeriod === 'preceding'
                        ? moment(dateRangeParams.startDate)
                              .subtract(2 * dateRange, 'days')
                              .format('YYYY-MM-DD')
                        : moment(dateRangeParams.startDate)
                              .subtract(1, 'year')
                              .format('YYYY-MM-DD'),
                compareEndDate:
                    selectedPeriod === 'preceding'
                        ? moment(dateRangeParams.startDate)
                              .subtract(dateRange, 'days')
                              .format('YYYY-MM-DD')
                        : moment(dateRangeParams.endDate)
                              .subtract(1, 'year')
                              .format('YYYY-MM-DD'),
            });
        }
    };

    const onChange = async (e: RadioChangeEvent) => {
        setDateRange(e.target.value);

        setManyParamsAndGoToFirstPage({
            startDate: moment()
                .subtract(e.target.value, 'days')
                .format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        });

        if (isComparing) {
            setManyParamsAndGoToFirstPage({
                startDate: moment()
                    .subtract(e.target.value, 'days')
                    .format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                compareStartDate:
                    searchPeriod === 'preceding'
                        ? moment()
                              .subtract(2 * e.target.value, 'days')
                              .format('YYYY-MM-DD')
                        : moment(moment().subtract(e.target.value, 'days'))
                              .subtract(1, 'year')
                              .format('YYYY-MM-DD'),
                compareEndDate:
                    searchPeriod === 'preceding'
                        ? moment()
                              .subtract(e.target.value, 'days')
                              .format('YYYY-MM-DD')
                        : moment().subtract(1, 'year').format('YYYY-MM-DD'),
            });
        }
    };

    const compare = async (e: boolean) => {
        if (e) {
            setManyParamsAndGoToFirstPage({
                compareStartDate: moment()
                    .subtract(2 * dateRange, 'days')
                    .format('YYYY-MM-DD'),
                compareEndDate: moment()
                    .subtract(dateRange, 'days')
                    .format('YYYY-MM-DD'),
            });
        } else {
            setManyParamsAndGoToFirstPage({
                compareStartDate: undefined,
                compareEndDate: undefined,
            });
            setSearchPeriod('preceding');
        }
        setCompare(e);
    };

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '2px 3px 15px 1px rgba(208, 216, 243, 0.4)',
        marginRight: '20px',
        marginBottom: '10px',
    };

    return (
        <div className={style.page_content}>
            <PageHeader title="Dashboard"></PageHeader>

            <div className={style.verticalContainer}>
                <div className={style.date_picker_header}>
                    <AnalyticsDatesPicker
                        onChange={onChange}
                        compare={compare}
                        updateParams={(dates) => {
                            setManyParamsAndGoToFirstPage({
                                startDate: dates?.[0]?.format('YYYY-MM-DD'),
                                endDate: dates?.[1]?.format('YYYY-MM-DD'),
                            });
                        }}
                        startDate={dateRangeParams.startDate}
                        endDate={dateRangeParams.endDate}
                    />
                </div>

                <div className={style.container}>
                    <div className={style.section_container}>
                        <div className={style.main_container}>
                            <div className={style.box_container}>
                                <h1 className={style.section_title}>
                                    User Acquisition
                                </h1>
                                {analyticsStore.isFetchingKpis ? (
                                    <Spin />
                                ) : (
                                    <>
                                        <div className={style.row}>
                                            <StatsBox
                                                title="New"
                                                currentValue={
                                                    analyticsStore.userCount
                                                }
                                                icon={NewUser}
                                            />
                                            <StatsBox
                                                title="Active Feature"
                                                currentValue={
                                                    analyticsStore.userWithActionsCount
                                                }
                                                icon={UserAction}
                                                tooltipText={
                                                    'Number of users who have used at least one app feature. Currently, this includes activating a promotion, visiting Ct Connecté, or generating a washcode/beacon for the carwash.'
                                                }
                                            />
                                            <StatsBox
                                                title="Total"
                                                currentValue={
                                                    kpis?.totalAppUser
                                                        .currentPeriod || 0
                                                }
                                                difference={calculateDifference(
                                                    kpis?.totalAppUser
                                                        .currentPeriod,
                                                    kpis?.totalAppUser
                                                        .previousPeriod
                                                )}
                                                icon={Users}
                                                tooltipText={`Number of users as of ${dateRangeParams.endDate}`}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={style.box_container}>
                                <h1 className={style.section_title}>
                                    User Actions
                                </h1>
                                <div>
                                    {analyticsStore.isFetchingKpis ? (
                                        <Spin />
                                    ) : (
                                        <>
                                            <div className={style.row}>
                                                <StatsBox
                                                    title="Promotions Activated"
                                                    currentValue={
                                                        kpis
                                                            ?.totalPromotionActivation
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis
                                                            ?.totalPromotionActivation
                                                            .currentPeriod,
                                                        kpis
                                                            ?.totalPromotionActivation
                                                            .previousPeriod
                                                    )}
                                                    icon={PromotionsIcon}
                                                />
                                                <StatsBox
                                                    title="Carwash Purchased"
                                                    currentValue={
                                                        kpis?.carwashPurchased
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis?.carwashPurchased
                                                            .currentPeriod,
                                                        kpis?.carwashPurchased
                                                            .previousPeriod
                                                    )}
                                                    icon={CarwashPurchase}
                                                    tooltipText="This is only the number of time a purchase was made with carwash. This include bundle, single wash, subscription and group plan"
                                                />
                                                <StatsBox
                                                    title="New Payment Method"
                                                    currentValue={
                                                        kpis?.newPaymentsMethod
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis?.newPaymentsMethod
                                                            .currentPeriod,
                                                        kpis?.newPaymentsMethod
                                                            .previousPeriod
                                                    )}
                                                    icon={CreditCard}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.section_container}>
                        <h1 className={style.section_title}>Active Users</h1>
                        <div>
                            <AnalyticsCharts
                                chartData={currentData}
                                loading={analyticsStore.isFetchingAnalytics}
                                sideStats={currentLastDays}
                            />
                        </div>
                    </div>
                    {isComparing && (
                        <div>
                            <Card
                                style={cardStyle}
                                title={
                                    <>
                                        <AnalyticsCompareDates
                                            compareStartDate={
                                                dateRangeParams.compareStartDate
                                            }
                                            compareEndDate={
                                                dateRangeParams.compareEndDate
                                            }
                                            endDate={dateRangeParams.endDate}
                                            format={'YYYY-MM-DD'}
                                            updateParams={(dates) => {
                                                setManyParamsAndGoToFirstPage({
                                                    compareStartDate: dates?.[0]?.format(
                                                        'YYYY-MM-DD'
                                                    ),
                                                    compareEndDate: dates?.[1]?.format(
                                                        'YYYY-MM-DD'
                                                    ),
                                                });
                                            }}
                                            changeSearchPeriod={(e) => {
                                                changeSearchPeriod(e);
                                            }}
                                            searchPeriod={searchPeriod}
                                        />
                                    </>
                                }
                            >
                                {isComparing && (
                                    <AnalyticsCharts
                                        chartData={compareData}
                                        loading={
                                            analyticsStore.isFetchingAnalytics
                                        }
                                        sideStats={compareLastDays}
                                    />
                                )}
                            </Card>
                        </div>
                    )}
                    <div className={style.section_container}>
                        <div className={style.main_container}>
                            <div className={style.box_container}>
                                <h1 className={style.section_title}>Carwash</h1>
                                <div>
                                    {analyticsStore.isFetchingKpis ? (
                                        <Spin />
                                    ) : (
                                        <>
                                            <div className={style.row}>
                                                <StatsBox
                                                    title="Wash Code Generated"
                                                    currentValue={
                                                        kpis?.washCodeGenerated
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis?.washCodeGenerated
                                                            .currentPeriod,
                                                        kpis?.washCodeGenerated
                                                            .previousPeriod
                                                    )}
                                                    icon={Carwash}
                                                />
                                                <StatsBox
                                                    title="Beacon Generated"
                                                    currentValue={
                                                        kpis?.beaconGenerated
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis?.beaconGenerated
                                                            .currentPeriod,
                                                        kpis?.beaconGenerated
                                                            .previousPeriod
                                                    )}
                                                    icon={Bacon}
                                                />
                                                <StatsBox
                                                    title="Beacon Requested"
                                                    currentValue={
                                                        kpis?.beaconRequested
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis?.beaconRequested
                                                            .currentPeriod,
                                                        kpis?.beaconRequested
                                                            .previousPeriod
                                                    )}
                                                    icon={Request}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={style.box_container}>
                                <h1 className={style.section_title}>
                                    CT Connecté
                                </h1>
                                <div>
                                    {analyticsStore.isFetchingKpis ? (
                                        <Spin />
                                    ) : (
                                        <>
                                            <div className={style.row}>
                                                <StatsBox
                                                    title="Visit Count"
                                                    currentValue={
                                                        kpis?.visitCount
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis?.visitCount
                                                            .currentPeriod,
                                                        kpis?.visitCount
                                                            .previousPeriod
                                                    )}
                                                    icon={Visit}
                                                />
                                                <StatsBox
                                                    title="Total Revenue"
                                                    currentValue={
                                                        kpis
                                                            ?.totalRevenueCtConnecte
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis
                                                            ?.totalRevenueCtConnecte
                                                            .currentPeriod,
                                                        kpis
                                                            ?.totalRevenueCtConnecte
                                                            .previousPeriod
                                                    )}
                                                    icon={Money}
                                                    isCurrency={true}
                                                />
                                                <StatsBox
                                                    title="Avg Revenue/User"
                                                    currentValue={
                                                        kpis
                                                            ?.averageRevenueCtConnectePerUser
                                                            .currentPeriod || 0
                                                    }
                                                    difference={calculateDifference(
                                                        kpis
                                                            ?.averageRevenueCtConnectePerUser
                                                            .currentPeriod,
                                                        kpis
                                                            ?.averageRevenueCtConnectePerUser
                                                            .previousPeriod
                                                    )}
                                                    icon={Income}
                                                    isCurrency={true}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
