import { action, observable, makeObservable } from 'mobx';
import { RootStore } from './root';
import { WithNetworkConcurrency } from './with-network-concurrency';
import {
    NhlGame,
    UpsertNhlGamePayload,
    NhlGameKpi,
    NhlGamePinsKpi,
    NhlGameAddPinsResponse,
    NhlGameAddPinsRequest,
    NhlGameKpiCsv,
    NhlGameLeaderboard,
    NhlGameStoresPinsKpi,
    NhlGameKpiPeriod,
} from '../types/nhl-game';
import { HttpMethod, PaginatedList } from '../types';
import { NotificationManager } from '../components';
import { Text } from '../utils/text';

type PaginatedGameList = PaginatedList<NhlGame>;
type TableKpiList = NhlGameKpi;
type TableKpiListCsv = NhlGameKpiCsv;
type LeaderboardResponse = NhlGameLeaderboard;
type ListNhlGamePinsKpi = NhlGamePinsKpi;
type ListNhlGameStoresPinsKpi = NhlGameStoresPinsKpi;

export type SearchParams = {
    perPage?: number;
    page?: number;
};

export type LeaderboardSearchParams = {
    perPage: number;
    page: number;
    nhlGameId: number;
};

export type KpiSearchParams = {
    startDate?: string;
    endDate?: string;
    perPage?: number;
};

export type KpiCsvSearchParams = {
    startDate: string;
    endDate: string;
};

export class NhlGameStore extends WithNetworkConcurrency {
    rootStore: RootStore;
    @observable list: PaginatedGameList | null = null;
    @observable kpi: TableKpiList | null = null;
    @observable pinsKpi: NhlGamePinsKpi | null = null;
    @observable storesPinsKpi: NhlGameStoresPinsKpi[] | null = null;
    @observable leaderboard: NhlGameLeaderboard | null = null;
    @observable leaderboardList: PaginatedGameList | null = null;
    @observable isFetchingList = false;
    @observable isFetchingValidList = false;
    @observable isUploadingPins = false;
    @observable isFetchingLeaderboard = false;
    @observable isFetchingKpi = false;
    @observable isFetchingKpiCsv = false;
    @observable isFetchingPinsKpi = false;
    @observable isFetchingStoresPinsKpi = false;
    @observable isDeletingGame = false;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async getKpiNhlGame(this: NhlGameStore, params: KpiSearchParams) {
        this.isFetchingKpi = true;
        const response = await this.rootStore.makeNetworkCall<TableKpiList>({
            method: HttpMethod.GET,
            url: '/nhl-games/kpi',
            params,
        });
        this.isFetchingKpi = false;

        if (!response.err) {
            this.kpi = response.data;
        } else {
            NotificationManager.showError(
                'An error occurred while fetching the kpi'
            );
        }
    }

    @action
    async getKpiNhlGameForExport(
        this: NhlGameStore,
        params: KpiCsvSearchParams
    ) {
        this.isFetchingKpiCsv = true;
        const response = await this.rootStore.makeNetworkCall<
            TableKpiListCsv[]
        >({
            method: HttpMethod.GET,
            url: '/nhl-games/kpi/csv',
            params,
        });
        this.isFetchingKpiCsv = false;

        if (!response.err) {
            return response.data;
        } else {
            NotificationManager.showError(
                Text.notificationManager.nhlGame.errorDateFetchingDashboard
            );
        }
    }

    @action
    async getNhlGameLeaderboard(
        this: NhlGameStore,
        params: LeaderboardSearchParams
    ) {
        this.isFetchingLeaderboard = true;
        const response = await this.rootStore.makeNetworkCall<
            LeaderboardResponse
        >({
            method: HttpMethod.GET,
            url: `/nhl-games/leaderboard/${params.nhlGameId}`,
            params,
        });
        this.isFetchingLeaderboard = false;

        if (!response.err) {
            this.leaderboard = response.data;
        } else {
            NotificationManager.showError(
                Text.notificationManager.nhlGame.errorDateFetchingLeaderboard
            );
        }
    }

    @action
    async getAllGames(this: NhlGameStore, params: SearchParams) {
        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall<
            PaginatedGameList
        >({
            method: HttpMethod.GET,
            url: '/nhl-games',
            params,
        });
        this.isFetchingList = false;

        if (!response.err) {
            this.list = response.data;
        } else {
            NotificationManager.showError(
                Text.notificationManager.nhlGame.errorGetValidNhlGames
            );
        }
    }

    @action
    async getValidNhlGames(this: NhlGameStore) {
        this.isFetchingValidList = true;
        const response = await this.rootStore.makeNetworkCall<
            PaginatedGameList
        >({
            method: HttpMethod.GET,
            url: '/nhl-games/valid',
        });
        this.isFetchingValidList = false;

        if (!response.err) {
            this.leaderboardList = response.data;
        } else {
            NotificationManager.showError(
                'An error occurred while fetching the list'
            );
        }
    }

    @action
    async getSingleGame(
        this: NhlGameStore,
        gameId: number,
        limit?: number
    ): Promise<NhlGame> {
        const response = await this.rootStore.makeNetworkCall<NhlGame>({
            method: HttpMethod.GET,
            url: `/nhl-games/${gameId}`,
            params: {
                limit: limit,
            },
        });

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async updateNhlGame(
        this: NhlGameStore,
        gameId: number,
        data: UpsertNhlGamePayload
    ) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            data,
            url: `/nhl-games/${gameId}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Nhl game modified');
        }
    }

    @action
    async createNhlGame(this: NhlGameStore, data: UpsertNhlGamePayload) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            data,
            url: '/nhl-games',
        });

        if (!response.err) {
            NotificationManager.showSuccess('Nhl game modified');
        }
    }

    @action
    async deleteGame(this: NhlGameStore, gameId: number) {
        this.isDeletingGame = true;

        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/nhl-games/${gameId}`,
        });

        this.isDeletingGame = false;

        if (!response.err) {
            NotificationManager.showSuccess(`Nhl game id ${gameId} deleted.`);
        }
    }

    @action
    async addPinsNhlGame(data: NhlGameAddPinsRequest, gameId: number) {
        this.isUploadingPins = true;
        const response = await this.rootStore.makeNetworkCall<
            NhlGameAddPinsResponse
        >({
            method: HttpMethod.POST,
            data,
            url: `/nhl-games/pins/${gameId}`,
        });

        this.isUploadingPins = false;

        if (!response.err) {
            NotificationManager.showSuccess(
                `${Text.nhlGame.store.addPinsSuccess} 
                 ${Text.nhlGame.store.ctPinsEnteredSuccess} ${response.data.ctPinsEntered}. 
                 ${Text.nhlGame.store.pinsValidatedSuccess} ${response.data.pinsValidated}. 
                ${Text.nhlGame.store.invalidPinsSuccess} ${response.data.invalidPins}`
            );

            this.getNhlGamePinsKpi(gameId);
        }
    }

    @action
    async getNhlGamePinsKpi(
        this: NhlGameStore,
        gameId: number,
        period?: NhlGameKpiPeriod
    ) {
        this.isFetchingPinsKpi = true;
        const response = await this.rootStore.makeNetworkCall<
            ListNhlGamePinsKpi
        >({
            method: HttpMethod.GET,
            url: `/nhl-games/pins/kpi/${gameId}`,
            params: period,
        });

        this.isFetchingKpi = false;

        if (!response.err) {
            this.pinsKpi = response.data;
        } else {
            throw response.err;
        }
    }
    @action
    async getNhlGameStoresPinsKpi(this: NhlGameStore, params: KpiSearchParams) {
        this.isFetchingStoresPinsKpi = true;
        const response = await this.rootStore.makeNetworkCall<
            ListNhlGameStoresPinsKpi[]
        >({
            method: HttpMethod.GET,
            url: `/nhl-games/stores/kpi`,
            params,
        });

        this.isFetchingStoresPinsKpi = false;

        if (!response.err) {
            this.storesPinsKpi = response.data;
        } else {
            throw response.err;
        }
    }
}
