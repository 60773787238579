import * as yup from 'yup';
import moment, { Moment } from 'moment';
import { Lang } from '.';

export class MomentSchema extends yup.Schema<Moment> {
    constructor() {
        super({
            type: 'moment',
            check: function (value: any): value is moment.Moment {
                return value?.isValid?.();
            },
        });
        (this as any).transforms.push(function (this: any, value: any) {
            if (this.isType(value)) {
                return value;
            }
            value = moment(value);
            return value.isValid() ? value : moment.invalid();
        });
    }
}

export function validateItem(items: any) {
    try {
        for (const lang of Lang) {
            const item = items.find((i: any) => i.language === lang);
            if (!item) {
                return false;
            }
        }
        return true;
    } catch (e) {
        return false;
    }
}

export const yupCmsHistory = yup.object({
    email: yup.string().required(),
    action: yup.string().required(),
    createdAt: yup.string().required(),
});
