import React from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { Input, Spin, Select, Card, PageHeader, Tooltip, Switch } from 'antd';
import { useFormik } from 'formik';
import { useAuthStore, useNhlGamesStore } from '../../contexts/mobx';
import { usePromotionStore } from '../../contexts/mobx';
import { useNavigation } from '../../utils/use-navigation';
import get from 'lodash/get';
import {
    GameForm,
    Images,
    CheckboxForm,
    DeepLinkLabel,
    NavigationHeader,
    ModalManager,
    NotificationManager,
    ButtonFileInput,
} from '../../components';
import {
    InfoCircleOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import {
    UpsertNhlGamePayload,
    NhlGameForm,
    PinData,
} from '../../types/nhl-game';
import { SupportedLanguages } from '../../types';
import style from './style.module.scss';
import moment from 'moment';
import { yupCmsHistory } from '../../utils/yup-schemas';
import Papa from 'papaparse';
import { getFileAsString, getFileEncoding } from '../../utils/csv';
import { Text } from '../../utils/text';
import { useQueryParams } from '../../utils/use-query-params';
import { NhlGameRewardsForm } from '../../components/nhl-game-rewards';
import { Promotion } from '../../types/promotion';
import { NhlGameKpiPeriod } from '../../types/nhl-game';
import { NhlGameKpi } from '../../components/nhl-game-kpi';
import { DatePicker } from '../../components/date-picker';

// Validation for the expected params
const YupParams = yup.object({
    action: yup.string().oneOf(['create', 'edit']).required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(v === undefined && action !== 'create');
        }),
});

const yupCheckboxes = yup.object({
    id: yup.number().nullable(),
    isMandatory: yup.boolean().nullable(),
    deletedAt: yup.string().nullable(),
    translations: yup.object({
        fr: yup.string(),
        en: yup.string(),
    }),
});

const YupForm = yup.object({
    stage: yup.string().required(),
    status: yup.string().required(),
    year: yup.number().required(),
    linkedGameId: yup.number().notRequired().nullable(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    endGameDate: yup
        .string()
        .test(
            'shouldBeAfterEnDate',
            'EndGameDate should be after EndDate',
            function (v) {
                const EndDate = this.resolve(yup.ref('endDate'));
                return !(v == undefined || v < (EndDate as string));
            }
        ),
    isFeatured: yup.boolean().required(),
    title: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    description: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    image: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    detailImage: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    subTitle: yup.object({
        fr: yup.string().notRequired(),
        en: yup.string().notRequired(),
    }),
    victoryNotificationTitle: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    victoryNotificationBody: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    buttonTitle: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    checkboxes: yup.array(yupCheckboxes),
    marketingDeepLink: yup.string().nullable(),
    cmsUserEmail: yup.string().notRequired().nullable(),
    cmsUserHistory: yup.array(yupCmsHistory).nullable(),
    grandPrizeEndDate: yup.string().nullable(),
    rewards: yup.array(
        yup.object({
            id: yup.number().nullable(),
            active: yup.boolean().required(),
            promotionId: yup.number().required(),
            pucksValue: yup.number().required(),
        })
    ),
});

type Params = yup.InferType<typeof YupParams>;

const today = moment();

export type SearchParams = {
    limit?: number;
};

export const NhlGamePage = observer(() => {
    const params = useParams<Params>();
    const nhlGameStore = useNhlGamesStore();
    const promotionStore = usePromotionStore();
    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);
    const [lastUploadDate, setLastUploadDate] = React.useState<string>();
    const [nhlGamePinsKpiPeriod, setNhlGamePinsKpiPeriod] = React.useState<
        NhlGameKpiPeriod
    >({
        startDate: '',
        endDate: '',
    });

    const [queryParams, updateQueryParams] = useQueryParams<SearchParams>();

    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }
    const formik = useFormik<NhlGameForm>({
        initialValues: {
            stage: 'regular_season',
            status: 'active',
            year: today.year(),
            linkedGameId: null,
            startDate: null,
            grandPrizeEndDate: null,
            endDate: null,
            endGameDate: null,
            isFeatured: true,
            title: {
                fr: '',
                en: '',
            },
            description: {
                fr: '',
                en: '',
            },
            image: {
                fr: '',
                en: '',
            },
            detailImage: {
                fr: '',
                en: '',
            },
            subTitle: {
                fr: '',
                en: '',
            },
            victoryNotificationTitle: {
                fr: '',
                en: '',
            },
            victoryNotificationBody: {
                fr: '',
                en: '',
            },
            buttonTitle: {
                fr: '',
                en: '',
            },
            checkboxes: [],
            marketingDeepLink: null,
            cmsUserEmail: '',
            cmsUserHistory: [],
            rewards: [],
        },
        validateOnMount: true,
        validationSchema: YupForm,
        onSubmit: () => void 0,
    });

    if (!YupParams.isValidSync(params)) {
        navigation.replaceToNhlGame();
    }

    React.useEffect(() => {
        if (params.action === 'edit') {
            setIsFetching(true);
            nhlGameStore
                .getSingleGame(Number(params.id!), queryParams.limit)
                .then((nhlGame) => {
                    formik.setValues({
                        ...nhlGame,
                    });
                    nhlGame.lastPinsUploadDate
                        ? setLastUploadDate(
                              moment(nhlGame.lastPinsUploadDate)
                                  .format('LLLL')
                                  .toString()
                          )
                        : setLastUploadDate(
                              Text.nhlGame.configContainer.lastDateMissing
                          );
                })
                .catch(() => navigation.replaceToNhlGame())
                .finally(() => setIsFetching(false));
            nhlGameStore.getNhlGamePinsKpi(Number(params.id!));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        promotionStore.fetchForSelect();
    }, []);

    const createNhlGame = async () => {
        formik.values.cmsUserEmail = authStore.claims?.email || null;
        setIsUpserting(true);
        try {
            const payload = YupForm.validateSync(formik.values, {
                stripUnknown: true,
            });
            await nhlGameStore.createNhlGame(payload as UpsertNhlGamePayload);
            navigation.replaceToNhlGame();
        } catch {
            //empty
        } finally {
            setIsUpserting(false);
        }
    };

    const updateNhlGame = async () => {
        formik.values.cmsUserEmail = authStore.claims?.email || null;
        setIsUpserting(true);
        try {
            const payload = YupForm.validateSync(formik.values, {
                stripUnknown: true,
            });
            console.log(payload, 'payload');
            await nhlGameStore.updateNhlGame(
                Number(params.id!),
                payload as UpsertNhlGamePayload
            );
        } catch {
            //empty
        } finally {
            setIsUpserting(false);
            navigation.replaceToNhlGame();
        }
    };

    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return hasError && isTouched;
    };

    const privatePromotions = promotionStore.promotionsForSelect.filter(
        (p) => p.isPrivate && p.type === 'coupon'
    );

    const nhlSelect = nhlGameStore.list?.results.filter(
        (nhlGame) => nhlGame.stage === 'regular_season'
    );

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '1px 4px 20px 1px rgba(208, 216, 243, 0.4)',
        marginRight: '20px',
    };

    const transformId = params.id ? Number(params.id) : undefined;

    const onDeleteConfirm = async () => {
        if (transformId) nhlGameStore.deleteGame(transformId);
        else NotificationManager.showError("Can't delete this game");
        navigation.goToNhlGames();
    };

    const parsePinsFile = (pins: string[][]) => {
        const parsedPins: PinData[] = [];
        pins.forEach((pin) => {
            parsedPins.push({ pin: pin[0], storeNref: pin[2] });
        });

        return parsedPins;
    };

    const onFileSelected = async (file: File) => {
        const encoding = await getFileEncoding(file);
        const text = await getFileAsString(file, encoding);

        Papa.parse<string[]>(text, {
            complete: async (results) => {
                const data = {
                    pins: parsePinsFile(results.data),
                };

                await nhlGameStore.addPinsNhlGame(data, Number(params.id));
                setLastUploadDate(moment().format('LLLL').toString());
            },
            error: () => {
                NotificationManager.showError('Error parsing the file');
            },
        });
    };

    const onPinsKpiFetch = async () => {
        if (
            nhlGamePinsKpiPeriod &&
            nhlGamePinsKpiPeriod.startDate !== '' &&
            nhlGamePinsKpiPeriod.endDate !== ''
        ) {
            await nhlGameStore.getNhlGamePinsKpi(
                Number(params.id!),
                nhlGamePinsKpiPeriod
            );
        } else {
            await nhlGameStore.getNhlGamePinsKpi(Number(params.id!));
        }
    };

    return (
        <div>
            <NavigationHeader
                id={transformId}
                type={Text.navigationHeader.game}
                action={params.action}
                onBackClick={navigation.goToNhlGames}
                onSaveClick={updateNhlGame}
                onCreateClick={createNhlGame}
                disabled={!formik.isValid}
                isLoading={isUpserting}
                cmsUserHistory={formik.values.cmsUserHistory}
                onDeleteClick={ModalManager.showDeleteModal({
                    onConfirm: onDeleteConfirm,
                })}
                isDeleting={nhlGameStore.isDeletingGame}
                canBeDeleted={true}
            />

            <br />

            <PageHeader
                title={<span>Nhl-Game</span>}
                subTitle={<span> {formik.values.title.en}</span>}
                className={`${style.site_page_header}`}
            />

            {isFetching ? (
                <Spin />
            ) : (
                <form>
                    <Card
                        className={style.card_container}
                        title="Config"
                        style={cardStyle}
                    >
                        <div className={style.input_row}>
                            <Tooltip
                                className={style.tooltip}
                                title={
                                    "Featured games appear as full width cards on the mobile apps' home page"
                                }
                            >
                                <InfoCircleOutlined />
                            </Tooltip>

                            <span className={style.is_featured}>
                                Is Featured Game
                            </span>
                            <Switch
                                checked={formik.values.isFeatured === true}
                                onChange={(e) => {
                                    formik.setFieldValue('isFeatured', e);
                                }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                            />
                        </div>
                        <div className={style.input_row_responsive}>
                            <div>
                                <span>Status</span>
                                <br />
                                <Select
                                    onChange={(e) =>
                                        formik.setFieldValue('status', e)
                                    }
                                    value={formik.values.status}
                                    className={style.config_field}
                                >
                                    <Select.Option value="active">
                                        Active
                                    </Select.Option>
                                    <Select.Option value="inactive">
                                        Inactive
                                    </Select.Option>
                                </Select>
                            </div>
                            <div>
                                <span>Year</span>
                                <br />
                                <Input
                                    className={style.config_field}
                                    name={`year`}
                                    type="number"
                                    value={formik.values.year ?? today.year()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div>
                                <span>Stage</span>
                                <br />
                                <Select
                                    onChange={(e) =>
                                        formik.setFieldValue('stage', e)
                                    }
                                    value={formik.values.stage}
                                    className={style.config_field}
                                >
                                    <Select.Option value="regular_season">
                                        Regular Season
                                    </Select.Option>
                                    <Select.Option value="playoff">
                                        Playoff
                                    </Select.Option>
                                </Select>
                            </div>
                            {formik.values.stage === 'playoff' && (
                                <div>
                                    <span>Link a Regular Season Game</span>
                                    <br />
                                    <Select
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'linkedGameId',
                                                e
                                            )
                                        }
                                        value={formik.values.linkedGameId || ''}
                                        className={style.config_field}
                                    >
                                        {nhlSelect?.map((e) => (
                                            <Select.Option
                                                key={e.id}
                                                value={e.id}
                                                label={e.id}
                                            >
                                                <div
                                                    className={
                                                        style.custom_option
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.titles_holder
                                                        }
                                                    >
                                                        <span>
                                                            {e.title.en}
                                                        </span>
                                                        <span> #{e.id}</span>
                                                    </div>
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        </div>
                        <div className={style.input_row_responsive}>
                            <div>
                                <span>
                                    {Text.nhlGame.gameFields.startDate.title}
                                </span>
                                <br />
                                <DatePicker
                                    value={formik.values.startDate}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            Text.nhlGame.gameFields.startDate
                                                .key,
                                            e?.toISOString()
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <span>
                                    {Text.nhlGame.gameFields.endDate.title}
                                </span>
                                <br />
                                <DatePicker
                                    value={formik.values.endDate}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            Text.nhlGame.gameFields.endDate.key,
                                            e?.toISOString()
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <span>
                                    {Text.nhlGame.gameFields.endGameDate.title}
                                </span>
                                <br />
                                <DatePicker
                                    disabledDate={(current) => {
                                        if (formik.values.endDate)
                                            return (
                                                moment(
                                                    formik.values.endDate
                                                ).startOf('day') > current
                                            );
                                        else return true;
                                    }}
                                    value={formik.values.endGameDate}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            Text.nhlGame.gameFields.endGameDate
                                                .key,
                                            e?.toISOString()
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <span>
                                    {
                                        Text.nhlGame.gameFields
                                            .grandPrizeEndDate.title
                                    }
                                </span>
                                <br />
                                <DatePicker
                                    value={formik.values.grandPrizeEndDate}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            Text.nhlGame.gameFields
                                                .grandPrizeEndDate.key,
                                            e?.toISOString()
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Card>
                    {params.action === 'edit' && (
                        <Card
                            className={style.card_container}
                            title={Text.nhlGame.sectionHeaders.pins}
                            style={cardStyle}
                            extra={
                                <ButtonFileInput
                                    text={Text.nhlGame.buttonText.importCsv}
                                    onFileSelected={onFileSelected}
                                ></ButtonFileInput>
                            }
                        >
                            {nhlGameStore.isUploadingPins ? (
                                <Spin />
                            ) : (
                                <NhlGameKpi
                                    pinsKpi={nhlGameStore.pinsKpi}
                                    lastUploadDate={lastUploadDate ?? ''}
                                    onPinsKpiFetch={onPinsKpiFetch}
                                    kpiPeriod={nhlGamePinsKpiPeriod}
                                    setNhlGamePinsKpiPeriod={
                                        setNhlGamePinsKpiPeriod
                                    }
                                />
                            )}
                        </Card>
                    )}
                    <Card
                        className={style.card_container}
                        title="Rewards"
                        style={cardStyle}
                    >
                        <div className={style.input_row_rewards}>
                            <div>
                                <NhlGameRewardsForm
                                    privatePromotions={
                                        privatePromotions as Promotion[]
                                    }
                                    loading={promotionStore.isFetchingForSelect}
                                    value={formik.values.rewards}
                                    setValue={(v) =>
                                        formik.setFieldValue('rewards', v)
                                    }
                                />
                            </div>
                        </div>
                    </Card>
                    <Card
                        className={style.card_container}
                        title="Push Notification Texts"
                        style={cardStyle}
                    >
                        <div className={style.input_row}>
                            {SupportedLanguages.map((lang) => (
                                <div key={lang}>
                                    <span>
                                        Victory Notification Title {lang}
                                    </span>
                                    <Input
                                        name={`victoryNotificationTitle[${lang}]`}
                                        value={
                                            formik.values
                                                .victoryNotificationTitle[lang]
                                        }
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className={style.input_row}>
                            {SupportedLanguages.map((lang) => (
                                <div key={lang}>
                                    <span>
                                        Victory Notification Body {lang}
                                    </span>
                                    <Input
                                        name={`victoryNotificationBody[${lang}]`}
                                        value={
                                            formik.values
                                                .victoryNotificationBody[lang]
                                        }
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            ))}
                        </div>
                    </Card>
                    <Card
                        className={style.card_container}
                        title="Images"
                        style={cardStyle}
                    >
                        <Images
                            image={formik.values.image}
                            title="Feature Image"
                            onChange={(image) => {
                                formik.setValues({
                                    ...formik.values,
                                    image,
                                });
                            }}
                            onDelete={(image) => {
                                formik.setValues({
                                    ...formik.values,
                                    image,
                                });
                            }}
                        />
                        {formik.values.isFeatured && (
                            <Images
                                image={formik.values.detailImage}
                                title="Detail View Image"
                                onChange={(detailImage) => {
                                    formik.setValues({
                                        ...formik.values,
                                        detailImage,
                                    });
                                }}
                                onDelete={(detailImage) => {
                                    formik.setValues({
                                        ...formik.values,
                                        detailImage,
                                    });
                                }}
                            />
                        )}
                    </Card>

                    <Card
                        className={style.card_container}
                        title="Details"
                        style={cardStyle}
                    >
                        {/* title,subtitle,description,button */}
                        <GameForm
                            values={formik.values}
                            onChange={(values) => {
                                formik.setValues({
                                    ...formik.values,
                                    ...values,
                                });
                            }}
                            errors={{
                                fr: checkIfHasError('fr'),
                                en: checkIfHasError('en'),
                            }}
                        />
                        {formik.values.marketingDeepLink && (
                            <DeepLinkLabel
                                text={formik.values.marketingDeepLink}
                            />
                        )}
                        <CheckboxForm
                            values={formik.values}
                            onChange={(values) => {
                                formik.setValues({
                                    ...formik.values,
                                    ...values,
                                });
                            }}
                            errors={{
                                fr: checkIfHasError('fr'),
                                en: checkIfHasError('en'),
                            }}
                        />
                    </Card>
                </form>
            )}
        </div>
    );
});
