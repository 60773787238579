import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useCarWashStore } from '../../contexts/mobx';
import { Button, Card, Modal, Table, Input } from 'antd';
import style from './style.module.scss';
import { PageHeader } from '../../components';

export const CarWashPage = observer(() => {
    const carWashStore = useCarWashStore();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nref, setNref] = useState('');
    const [currentCategory, setCurrentCategory] = useState<
        keyof typeof carWashStore.carwashData | null
    >(null);

    React.useEffect(() => {
        carWashStore.fetchCarWashData();
    }, [carWashStore]);

    const addCarWash = async () => {
        if (currentCategory === 'contactlessOnly') {
            await carWashStore.addContactlessCarWash(nref);
        } else if (currentCategory === 'acceptUltimateWash') {
            await carWashStore.addUltimateCarWash(nref);
        } else if (currentCategory === 'softTouchOnly') {
            await carWashStore.addSoftTouchCarWash(nref);
        }
        setIsModalVisible(false);
        setNref('');
    };

    const showModal = (category: keyof typeof carWashStore.carwashData) => {
        setCurrentCategory(category);
        setIsModalVisible(true);
    };

    const deleteCarWash = (
        nref: string,
        category: keyof typeof carWashStore.carwashData
    ) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this car wash?',
            content: `This will permanently delete the car wash entry with NREF: ${nref}.`,
            okText: 'Yes, delete it',
            cancelText: 'No, cancel',
            onOk: async () => {
                if (category === 'contactlessOnly') {
                    await carWashStore.deleteContactlessCarWash(nref);
                } else if (category === 'acceptUltimateWash') {
                    await carWashStore.deleteUltimateCarWash(nref);
                } else if (category === 'softTouchOnly') {
                    await carWashStore.deleteSoftTouchCarWash(nref);
                }
            },
        });
    };

    const columns = (category: keyof typeof carWashStore.carwashData) => [
        {
            title: 'NREF',
            dataIndex: 'nref',
            key: 'nref',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => new Date(text).toLocaleString(),
        },
        {
            title: 'Deleted At',
            dataIndex: 'deletedAt',
            key: 'deletedAt',
            render: (text: string | null) =>
                text ? new Date(text).toLocaleString() : 'N/A',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: { nref: string }) => (
                <Button
                    onClick={() => deleteCarWash(record.nref, category)}
                    type="primary"
                    danger
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <div className={style.page_content}>
            <PageHeader title={'Car Wash'} />
            <Card>
                {Object.entries(carWashStore.carwashData || {}).map(
                    ([category, data]) => (
                        <React.Fragment key={category}>
                            <h2>
                                {category.charAt(0).toUpperCase() +
                                    category
                                        .slice(1)
                                        .replace(/([A-Z])/g, ' $1')}
                                <Button
                                    onClick={() =>
                                        showModal(
                                            category as keyof typeof carWashStore.carwashData
                                        )
                                    }
                                    type="primary"
                                    style={{ marginLeft: '10px' }}
                                >
                                    Add
                                </Button>
                            </h2>
                            <Table
                                dataSource={data}
                                columns={columns(
                                    category as keyof typeof carWashStore.carwashData
                                )}
                                rowKey="nref"
                            />
                        </React.Fragment>
                    )
                )}
            </Card>
            <Modal
                title="Add Car Wash Store"
                visible={isModalVisible}
                onOk={addCarWash}
                onCancel={() => setIsModalVisible(false)}
            >
                <Input
                    placeholder="Enter NREF (including prefix)"
                    value={nref}
                    onChange={(e) => setNref(e.target.value)}
                />
            </Modal>
        </div>
    );
});
