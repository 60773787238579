import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Card, Input, Button } from 'antd';
import { MinimalAppConfig } from '../../../types';
import style from './style.module.scss';

const { Meta } = Card;

interface Props {
    isLoading: boolean;
    isUpdating: boolean;
    minimalAppConfig: MinimalAppConfig | null;
    onSubmit: (conf: MinimalAppConfig) => void;
}

export const MinimalAppCard = (props: Props) => {
    const valuesSnapshot = React.useRef<null | MinimalAppConfig>(null);
    const formik = useFormik<MinimalAppConfig>({
        initialValues: {
            miniOSVersion: '',
            minAndroidBuildVersion: 0,
        },
        onSubmit: props.onSubmit,
    });

    useEffect(() => {
        if (props.minimalAppConfig) {
            valuesSnapshot.current = props.minimalAppConfig;
            formik.setValues(props.minimalAppConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.minimalAppConfig]);

    const hasChanged = () => {
        return (
            formik.values.miniOSVersion !==
                valuesSnapshot.current?.miniOSVersion ||
            formik.values.minAndroidBuildVersion !==
                valuesSnapshot.current.minAndroidBuildVersion
        );
    };

    return (
        <Card bordered={false} loading={props.isLoading} className={style.card}>
            <Meta title="Minimal app configuration" />
            <form className={style.card_content} onSubmit={formik.handleSubmit}>
                <div>
                    Minimal iOS version:{' '}
                    <Input
                        type="text"
                        disabled={props.isUpdating}
                        name="miniOSVersion"
                        value={formik.values.miniOSVersion}
                        onChange={formik.handleChange}
                    />
                </div>
                <div>
                    Minimal android version:{' '}
                    <Input
                        disabled={props.isUpdating}
                        type="number"
                        name="minAndroidBuildVersion"
                        value={formik.values.minAndroidBuildVersion}
                        onChange={formik.handleChange}
                    />
                </div>
                <Button
                    type="primary"
                    disabled={!hasChanged()}
                    loading={props.isUpdating}
                    onClick={() => formik.submitForm()}
                >
                    Save
                </Button>
            </form>
        </Card>
    );
};
