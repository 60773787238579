import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import { HttpMethod, PaginatedList } from '../types';

export interface SmsLog {
    id: number;
    messageId: string;
    userId: number;
    status: string;
    provider: string;
    userFirstName?: string;
    userLastName?: string;
    createdAt: string;
}
export type PaginatedSmsList = PaginatedList<SmsLog>;

export type SmsSearchParams = {
    perPage?: number;
    page?: number;
};

export class SmsHistoryStore {
    @observable isFetching = false;
    @observable list: PaginatedSmsList | null = null;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchList(this: SmsHistoryStore, params: SmsSearchParams) {
        this.isFetching = true;
        const response = await this.rootStore.makeNetworkCall<PaginatedSmsList>(
            {
                method: HttpMethod.GET,
                url: 'sms-history',
                params,
            }
        );
        this.isFetching = false;

        if (response.data) {
            this.list = response.data;
        }
    }
}
