import React from 'react';
import style from './style.module.scss';
import { Select } from 'antd';

type DropDownStatusProps = {
    options: {
        label: string;
        value: string;
    }[];
    onChange?: (value: string) => void;
    placeholder?: string;
    selectedOption?: string;
};

export const DropDownStatus = ({
    options,
    onChange,
    placeholder,
    selectedOption,
}: DropDownStatusProps) => {
    return (
        <>
            <Select
                showArrow
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                className={style.dropdown_select}
                value={selectedOption}
            />
        </>
    );
};
