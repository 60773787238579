import { observable, computed, makeObservable } from 'mobx';
import { fromResource, IResource } from 'mobx-utils';
import { oktaAuth } from '../App';
import { AuthState, UserClaims } from '@okta/okta-auth-js';

const REACT_APP_CT_GROUP_PREFIX = process.env.REACT_APP_CT_GROUP_PREFIX;

export class AuthStore {
    @observable jwt = window.localStorage.getItem('jwt');
    @observable isRequestingTemporaryAccessToken = false;
    @observable isRequestingJwt = false;
    authState: IResource<AuthState | undefined | null> | null = null;

    constructor() {
        makeObservable(this);
        let authSubscription: (authState: AuthState) => void;
        this.authState = fromResource<AuthState | null>(
            (sink) => {
                sink(oktaAuth.authStateManager.getAuthState());
                authSubscription = (authState) => {
                    sink(authState);
                };
                oktaAuth.authStateManager.subscribe(authSubscription);
            },
            () => {
                oktaAuth.authStateManager.unsubscribe(authSubscription);
            }
        );
    }

    @computed
    get claims() {
        return this.authState?.current()?.idToken?.claims ?? null;
    }

    @computed
    get accessTokenClaims() {
        const authState = this.authState?.current();
        return (authState?.accessToken?.claims as unknown) as UserClaims<{
            groups: string[];
        }> | null;
    }

    @computed
    get roles() {
        const claims = this.accessTokenClaims;
        const roles = [];

        if (claims) {
            const userGroups = claims.groups.map((group) =>
                group.replace(`${REACT_APP_CT_GROUP_PREFIX}.`, '')
            );

            if (userGroups.includes('Manager')) {
                roles.push('Manager');
            }
            if (userGroups.includes('Admin')) {
                roles.push('Administrator');
            }
            if (userGroups.includes('Customer')) {
                roles.push('Customer Service Agent');
            }
            if (roles.length === 0) {
                roles.push('Cashier');
            }
        }

        return roles;
    }

    @computed
    get hasManagementAccess() {
        const claims = this.accessTokenClaims;
        const userGroups =
            claims?.groups.map((group) =>
                group.replace(`${REACT_APP_CT_GROUP_PREFIX}.`, '')
            ) ?? [];
        return userGroups.includes('Manager') || userGroups.includes('Admin');
    }

    @computed
    get isCustomerServiceAgent() {
        const claims = this.accessTokenClaims;
        const userGroups =
            claims?.groups.map((group) =>
                group.replace(`${REACT_APP_CT_GROUP_PREFIX}.`, '')
            ) ?? [];
        return userGroups.includes('Customer');
    }

    @computed
    get isManager() {
        const claims = this.accessTokenClaims;
        const userGroups =
            claims?.groups.map((group) =>
                group.replace(`${REACT_APP_CT_GROUP_PREFIX}.`, '')
            ) ?? [];
        return userGroups.includes('Manager');
    }

    @computed
    get isCashier() {
        const claims = this.accessTokenClaims;
        const userGroups =
            claims?.groups.map((group) =>
                group.replace(`${REACT_APP_CT_GROUP_PREFIX}.`, '')
            ) ?? [];
        return userGroups.includes('Cashier');
    }

    @computed
    get expireAt() {
        return this.authState?.current()?.refreshToken?.expiresAt ?? null;
    }
}
