import React from 'react';
import { AcvtPromotion } from '../../../../types/acvt';
import { AcvtRedSticker } from '../../red-sticker';
import style from './style.module.scss';

type SmallPromoCard = {
    promoObject: AcvtPromotion;
    opened: React.Dispatch<
        React.SetStateAction<{
            homePromoCard: boolean;
            homeGameCard: boolean;
            promoCard: boolean;
            gameCard: boolean;
        }>
    >;
    setSinglePromo: (promo: AcvtPromotion) => void;
};

export const AcvtSmallPromoCard = ({
    promoObject,
    opened,
    setSinglePromo,
}: SmallPromoCard) => {
    const setPromo = () => {
        setSinglePromo(promoObject);
    };
    return (
        <>
            <div className={style.container}>
                <div
                    className={style.card}
                    onClick={() => {
                        opened((prevState) => {
                            return { ...prevState, homePromoCard: true };
                        });
                        setPromo();
                    }}
                >
                    <div className={style.image}>
                        <img alt="" src={promoObject.thumbnail!} />
                    </div>
                </div>
                <div className={style.red_sticker}>
                    {promoObject.type === 'coupon' &&
                        promoObject?.promotionalOffer?.title !==
                            (undefined && 'undefined' && '') && (
                            <AcvtRedSticker
                                offer={promoObject?.promotionalOffer?.title}
                            />
                        )}
                </div>
            </div>
        </>
    );
};
