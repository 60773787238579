import React from 'react';
import style from './style.module.scss';
import { Store } from '../../stores/stores';
import { CoordinatesEditor } from '../coordinates-editor';
import { PhoneEditor } from '../phone-editor';

type StoreEditingLocationProps = {
    storeObject: Store;
    storePhone: string;
    setStorePhone: (value: string) => void;
    storeCoordinates: { lat: number; lng: number };
    setStoreCoordinates: (value: { lng: number; lat: number }) => void;
};

export const StoreEditingLocation = ({
    storeObject,
    storePhone,
    setStorePhone,
    storeCoordinates,
    setStoreCoordinates,
}: StoreEditingLocationProps) => {
    return (
        <>
            <div className={style.single_info}>
                <b>Title: </b>
                {storeObject?.addr.street}
            </div>
            <div className={style.single_info}>
                <b>Tel: </b>
                <PhoneEditor phone={storePhone} setPhone={setStorePhone} />
            </div>
            <div className={style.single_info}>
                <b>Street: </b>
                <span>{storeObject?.addr.street}</span>
            </div>
            <div className={style.single_info}>
                <b>City: </b>
                <span>{storeObject?.addr.city}</span>
            </div>
            <div className={style.single_info}>
                <b>Zip code: </b>
                <span>{storeObject?.addr.post}</span>
            </div>
            <div>
                <div className={style.single_info}>
                    <b>Coordinates: </b>
                    <CoordinatesEditor
                        coordinates={storeCoordinates}
                        setCoordinates={setStoreCoordinates}
                    />
                </div>
            </div>
        </>
    );
};
