import React, { useCallback } from 'react';
import { Select, Input, Tooltip, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormikHelpers } from 'formik';
import styles from './style.module.scss';
import { PromotionForm } from '../..';
import { ErrorAwareLabel } from '../../../../components';

interface ActivationTimeSectionProps {
    values: PromotionForm;
    setFieldValue: FormikHelpers<PromotionForm>['setFieldValue'];
    checkIfHasError: (path: string) => boolean;
    hours: number[];
}

const LabelWithTooltip = ({
    text,
    tooltip,
    hasError,
}: {
    text: string;
    tooltip: string;
    hasError: boolean;
}) => (
    <div className={styles.label_with_tooltip}>
        <ErrorAwareLabel
            text={text}
            hasError={hasError}
            className={styles.bold_label}
        />
        <Tooltip title={tooltip}>
            <InfoCircleOutlined />
        </Tooltip>
    </div>
);

const HourSelect = ({
    value,
    onChange,
    hours,
}: {
    value: number | undefined;
    onChange: (value: number) => void;
    hours: number[];
}) => (
    <Select<number> onChange={onChange} value={value} style={{ width: '100%' }}>
        {hours.map((hour) => (
            <Select.Option value={hour} key={hour}>
                {hour}
            </Select.Option>
        ))}
    </Select>
);

export const ActivationTimeSection = ({
    values,
    setFieldValue,
    checkIfHasError,
    hours,
}: ActivationTimeSectionProps) => {
    const handleStartPeriodChange = useCallback(
        (value: number) => {
            setFieldValue('startPeriod', value);
        },
        [setFieldValue]
    );

    const handleEndPeriodChange = useCallback(
        (value: number) => {
            setFieldValue('endPeriod', value);
        },
        [setFieldValue]
    );

    const handleOutsideActiveTimeChange = useCallback(
        (index: number, value: string) => {
            setFieldValue(`items[${index}].outsideActivatableHours`, value);
        },
        [setFieldValue]
    );

    return (
        <Card style={{ width: '100%' }}>
            <div className={styles.input_row}>
                <div className={styles.single_input}>
                    <LabelWithTooltip
                        text="Hour Start"
                        tooltip="The promotion is activable starting at this hour every day."
                        hasError={checkIfHasError('startPeriod')}
                    />
                    <HourSelect
                        value={values.startPeriod || undefined}
                        onChange={handleStartPeriodChange}
                        hours={hours}
                    />
                </div>

                <div className={styles.single_input}>
                    <LabelWithTooltip
                        text="Hour End"
                        tooltip="The promotion becomes non-activable after this hour every day."
                        hasError={checkIfHasError('endPeriod')}
                    />
                    <HourSelect
                        value={values.endPeriod || undefined}
                        onChange={handleEndPeriodChange}
                        hours={hours}
                    />
                </div>

                {values.items?.map((item, i) => (
                    <div key={item.language} className={styles.single_input}>
                        <LabelWithTooltip
                            text={`Outside Active Hours ${item.language.toUpperCase()}`}
                            tooltip="Specify the hours during which the promotion is not active."
                            hasError={checkIfHasError(
                                `items[${i}].outsideActivatableHours`
                            )}
                        />
                        <Input
                            value={
                                item.outsideActivatableHours ??
                                'Outside active time'
                            }
                            onChange={(e) =>
                                handleOutsideActiveTimeChange(i, e.target.value)
                            }
                            style={{ width: '100%' }}
                        />
                    </div>
                ))}
            </div>
        </Card>
    );
};
