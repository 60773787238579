import React from 'react';
import { Modal, Descriptions, Spin } from 'antd';

interface UnblockCarwashModalProps {
    isVisible: boolean;
    clientId: number | null | undefined;
    deviceFirebaseId: string | null | undefined;
    deviceLockedUntil: string | null | undefined;
    onUnblock: (id: number) => void;
    onClose: () => void;
    isUnblocking: boolean;
}

const UnblockCarwashModal: React.FC<UnblockCarwashModalProps> = ({
    isVisible,
    clientId,
    deviceFirebaseId,
    deviceLockedUntil,
    onUnblock,
    onClose,
    isUnblocking,
}) => {
    return (
        <Modal
            title="Unblock carwash device"
            visible={isVisible}
            onOk={() => {
                if (clientId) {
                    onUnblock(clientId);
                }
            }}
            onCancel={onClose}
        >
            {isUnblocking ? (
                <Spin />
            ) : (
                <>
                    <Descriptions>
                        <Descriptions.Item label="Device Firebase Id">
                            {deviceFirebaseId}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="Lock Until (UTC)">
                            {deviceLockedUntil}
                        </Descriptions.Item>
                    </Descriptions>
                    <p>
                        This action will let this account claim a new device to
                        use a carwash subscription.
                    </p>
                    <h4>Are you sure you want to unlock this account?</h4>
                </>
            )}
        </Modal>
    );
};

export default UnblockCarwashModal;
