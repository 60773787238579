import React from 'react';
import style from './style.module.scss';
import { Switch, TimePicker } from 'antd';
import { ReactComponent as MinusIcon } from '../../icons/Minus.svg';
import { Moment } from 'moment';

type SelectOpenHoursProps = {
    startTime: Moment | null;
    endTime: Moment | null;
    toggleState: boolean;
    onClickToggle?: (open: any) => void;
    onChangeStartTime?: (time: any) => void;
    onChangeEndTime?: (time: any) => void;
};

export const SelectOpenHours = ({
    startTime,
    endTime,
    toggleState,
    onClickToggle,
    onChangeStartTime,
    onChangeEndTime,
}: SelectOpenHoursProps) => {
    return (
        <>
            <div className={style.select_hours}>
                <div className={style.open_toggle}>
                    <Switch checked={toggleState} onChange={onClickToggle} />
                    <div>Open</div>
                </div>
                <div className={style.time_range}>
                    <TimePicker
                        value={startTime}
                        onChange={onChangeStartTime}
                        placeholder="00:00"
                        suffixIcon={null}
                        format={'HH:mm'}
                        className={style.time_picker}
                    />
                    <MinusIcon />
                    <TimePicker
                        value={endTime}
                        onChange={onChangeEndTime}
                        placeholder="00:00"
                        suffixIcon={null}
                        format={'HH:mm'}
                        className={style.time_picker}
                    />
                </div>
            </div>
        </>
    );
};
