import { Select, Button, Input } from 'antd';
import React from 'react';
import { ImageHolder } from '../image-holder';
import { DeleteOutlined } from '@ant-design/icons';

import style from './style.module.scss';
import { Promotion } from '../../types/promotion';
import { NhlGameReward } from '../../types/nhl-game';

export interface NhlGameRewardsProps {
    privatePromotions: Promotion[];
    loading: boolean;
    value: NhlGameReward[];
    setValue: (v: NhlGameReward[]) => void;
}

export const NhlGameRewardsForm = ({
    privatePromotions,
    loading,
    value,
    setValue,
}: NhlGameRewardsProps) => {
    const [rewardsList, setRewardsList] = React.useState<NhlGameReward[]>(
        value ? value : []
    );

    React.useEffect(() => {
        setValue(rewardsList);
        console.log('value', value);
    }, [rewardsList]);

    const addReward = () => {
        const newReward: NhlGameReward = {
            id: null,
            active: true,
            promotionId: privatePromotions[0].id,
            pucksValue: 0,
        };
        setRewardsList([...rewardsList, newReward]);
    };

    const removeReward = (index: number) => {
        const newList = [...rewardsList].filter((_, i) => i !== index);
        setRewardsList(newList);
    };

    const updateReward = (
        index: number,
        key: keyof NhlGameReward,
        value: NhlGameReward[keyof NhlGameReward]
    ) => {
        const newList = [...rewardsList];
        newList[index][key] = value as never;
        setRewardsList(newList);
    };

    return (
        <>
            {rewardsList.map((reward, index) => (
                <div key={index} className={style.container}>
                    <span>Reward {index + 1}</span>
                    <br />
                    <div className={style.input_row}>
                        <Select
                            className={style.section}
                            listHeight={512}
                            showSearch
                            optionLabelProp="label"
                            filterOption={false}
                            loading={loading}
                            value={reward.promotionId}
                            onChange={(value) =>
                                updateReward(index, 'promotionId', value)
                            }
                        >
                            {privatePromotions.map((promo) => (
                                <Select.Option
                                    key={promo.id}
                                    value={promo.id}
                                    label={promo.items[0].title}
                                >
                                    <div className={style.custom_option}>
                                        <div className={style.titles_holder}>
                                            <span>{promo.items[0].title}</span>
                                            <span>{promo.items[1].title}</span>
                                        </div>
                                        <ImageHolder
                                            src={promo.items[0].thumbnail}
                                            size={40}
                                            className={style.option_image}
                                        />
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                        <div className={style.right_section}>
                            <Input
                                type="number"
                                placeholder="Pucks Value"
                                className={style.pucks}
                                value={
                                    reward.pucksValue !== 0
                                        ? reward.pucksValue
                                        : ''
                                }
                                onChange={(e) =>
                                    updateReward(
                                        index,
                                        'pucksValue',
                                        parseInt(e.target.value)
                                    )
                                }
                            />
                            <Button
                                type="primary"
                                onClick={() => removeReward(index)}
                                className={style.remove}
                                danger
                                icon={<DeleteOutlined />}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
            <Button
                type="primary"
                onClick={addReward}
                style={{ marginLeft: '10px' }}
            >
                Add Reward
            </Button>
        </>
    );
};
