import React from 'react';
import style from './style.module.scss';

interface Props {
    text: string;
    hasError?: boolean;
    className?: string;
}

export const ErrorAwareLabel = ({ text, hasError, className }: Props) => {
    return (
        <span
            className={`${hasError ? style.has_error : ''} ${className ?? ''}`}
        >
            {text}
        </span>
    );
};
