import React from 'react';
import style from './style.module.scss';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';
import { Store } from '../../stores/stores';
import { ExpansionPanel } from '../expansion-panel';
import { Moment } from 'moment';
import { StoreEditingDays } from '../store-editing-days';
import { StoreEditingServices } from '../store-editing-services';
import { StoreEditingStatus } from '../store-editing-status';
import { WEEKDAYS } from '../../helpers/store-helper';

type StoreEditingBatchProps = {
    onCloseClick: () => void;
    storeObjects: Store[];
    saveBatchModifications: (modifications: Store[]) => void;
};

export const StoreEditingBatch = ({
    onCloseClick,
    storeObjects,
    saveBatchModifications,
}: StoreEditingBatchProps) => {
    const [selectedServices, setSelectedServices] = React.useState<string[]>(
        []
    );
    const [selectedWeekdays, setSelectedWeekdays] = React.useState<string[]>(
        []
    );
    const [storeStatus, setStoreStatus] = React.useState('');
    const [openHoursComponents, setOpenHoursComponents] = React.useState<
        {
            id: number;
            startTime: Moment | null;
            endTime: Moment | null;
            open: boolean;
            selectedWeekdays: string[];
        }[]
    >([]);

    function initalState() {
        setSelectedServices([]);
        setSelectedWeekdays([]);
        setStoreStatus('closed');
        setOpenHoursComponents([]);
    }

    const saveChanges = async () => {
        const hours = new Array(7).fill('');

        if (storeStatus === 'open-24') {
            hours.fill('00:00-00:00');
        } else if (storeStatus !== 'close') {
            openHoursComponents.forEach((component) => {
                if (
                    component.startTime &&
                    component.endTime &&
                    component.open
                ) {
                    const formattedHour = `${component.startTime.format(
                        'HH:mm'
                    )}-${component.endTime.format('HH:mm')}`;
                    component.selectedWeekdays.forEach((weekday) => {
                        const index = WEEKDAYS.indexOf(weekday);
                        if (index !== -1) {
                            hours[index] = formattedHour;
                        }
                    });
                }
            });
        }

        const modifications = storeObjects.map((storeObject) => ({
            id: storeObject.id,
            nref: storeObject.nref,
            title: storeObject.title,
            lat: storeObject.lat,
            lng: storeObject.lng,
            img: storeObject.img,
            tel: storeObject.tel,
            hours: hours,
            services: selectedServices,
            status: storeStatus,
            addr: {
                street: storeObject.addr.street,
                city: storeObject.addr.city,
                post: storeObject.addr.post,
            },
        }));
        saveBatchModifications(modifications);
    };

    const resetChanges = () => {
        initalState();
    };

    React.useEffect(() => {
        initalState();
    }, [storeObjects]);

    return (
        <>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.close} onClick={onCloseClick}>
                        <CloseIcon />
                    </div>
                    <div className={style.editing_type}>Batch Editing</div>
                    <div className={style.title}>
                        {storeObjects.length > 1
                            ? `Editing ${storeObjects.length} items`
                            : 'Editing 1 item'}
                    </div>
                    <div className={style.store_info}>
                        <ExpansionPanel title="Status" defaultExpanded>
                            <div className={style.editable_info}>
                                <StoreEditingStatus
                                    selectedStatus={storeStatus}
                                    setSelectedStatus={setStoreStatus}
                                />
                            </div>
                        </ExpansionPanel>
                        {storeStatus === 'open' && (
                            <ExpansionPanel title="Opening Hours">
                                <div className={style.editable_info}>
                                    <StoreEditingDays
                                        openHoursComponents={
                                            openHoursComponents
                                        }
                                        setOpenHoursComponents={
                                            setOpenHoursComponents
                                        }
                                        selectedWeekdays={selectedWeekdays}
                                        setSelectedWeekdays={
                                            setSelectedWeekdays
                                        }
                                    />
                                </div>
                            </ExpansionPanel>
                        )}
                        <ExpansionPanel title="Services">
                            <div className={style.editable_info}>
                                <StoreEditingServices
                                    selectedServices={selectedServices}
                                    setSelectedServices={setSelectedServices}
                                />
                            </div>
                        </ExpansionPanel>
                        <div className={style.save_buttons}>
                            <button
                                className={style.reset_button}
                                onClick={resetChanges}
                            >
                                Reset to inital
                            </button>
                            <button
                                className={style.save_button}
                                onClick={saveChanges}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
