import React from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import style from './style.module.scss';
import {
    NotificationManager,
    ModalManager,
    NavigationHeader,
    Images,
} from '../../components';
import { useAuthStore, useCareerStore } from '../../contexts/mobx';
import { useNavigation } from '../../utils/use-navigation';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CareerForm, UpsertCareerCard } from '../../stores/career';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Card, Switch } from 'antd';

const YupParams = yup.object({
    action: yup.string().oneOf(['create', 'edit']).required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(v === undefined && action !== 'create');
        }),
});

type Params = yup.InferType<typeof YupParams>;

const YupForm = yup.object({
    image: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
    active: yup.boolean().required(),
});

export const CareerPage = observer(() => {
    const params = useParams<Params>();
    const store = useCareerStore();

    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const formik = useFormik<CareerForm>({
        initialValues: {
            active: false,
            image: {
                fr: '',
                en: '',
            },
        },
        validateOnMount: true,
        validationSchema: YupForm,
        onSubmit: () => void 0,
    });

    if (!YupParams.isValidSync(params)) {
        navigation.goToHome();
    }

    React.useEffect(() => {
        if (params.action === 'edit') {
            store
                .fetchCareer(params.id!)
                .then((career) => {
                    if (career) {
                        formik.setValues(career);
                    } else {
                        throw new Error('Career not found');
                    }
                })
                .catch(() => navigation.goToHome());
        }
    }, []);

    const createCareer = async () => {
        const payload = YupForm.validateSync(formik.values, {
            stripUnknown: true,
        });
        await store.createCareer(payload as UpsertCareerCard);

        navigation.goToCareers();
    };

    const updateCareer = async () => {
        const payload = YupForm.validateSync(formik.values, {
            stripUnknown: true,
        });

        await store.updateCareer(params.id!, payload as UpsertCareerCard);

        navigation.goToCareers();
    };

    const onDeleteConfirm = async () => {
        if (params.id) store.deleteCareer(params.id);
        else NotificationManager.showError("Can't delete this career");
        navigation.goToCareers();
    };

    const transformId = params.id ? Number(params.id) : undefined;

    return (
        <div className={style.container}>
            <div className={style.page_title_container}>
                <NavigationHeader
                    id={transformId}
                    action={params.action}
                    onBackClick={() => window.history.back()}
                    onSaveClick={updateCareer}
                    onCreateClick={createCareer}
                    disabled={!formik.isValid}
                    isLoading={store.isUpdatedCareer}
                    onDeleteClick={ModalManager.showDeleteModal({
                        onConfirm: onDeleteConfirm,
                    })}
                    isDeleting={store.isDeletingCareer}
                    canBeDeleted={true}
                    type="Career"
                />
            </div>

            <Card className={style.card_container}>
                <div className={style.input_row}>
                    {' '}
                    <span className="left_label">Active</span>
                    <Switch
                        checked={formik.values.active}
                        onChange={(e) => formik.setFieldValue('active', e)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                </div>

                <div className={style.input_row}>
                    <Images
                        image={formik.values.image}
                        title="Image"
                        applyStyle={false}
                        onChange={(image) => {
                            formik.setValues({
                                ...formik.values,
                                image,
                            });
                        }}
                        onDelete={(image) => {
                            formik.setValues({
                                ...formik.values,
                                image,
                            });
                        }}
                    />
                </div>
            </Card>
        </div>
    );
});
