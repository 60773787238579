import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { enableMapSet } from 'immer';
import { BrowserRouter as Router } from 'react-router-dom';

Sentry.init({
    dsn:
        'https://e8169a5b2ff6eba2d48e690bdb224181@o4506061906378752.ingest.sentry.io/4506061985349632',
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_RELEASE,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [
                'localhost',
                process.env.REACT_APP_API_URL!,
            ],
        }),
        new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

enableMapSet();

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
