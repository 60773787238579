/* eslint-disable react/no-children-prop */
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import AcvtCarousel, {
    Acvt,
    AcvtGameCard,
    AcvtGamePage,
    AcvtGamesPage,
    AcvtPlaceholderPage,
    AcvtPromoCard,
    AcvtPromotionPage,
    AcvtPromotionsPage,
    AcvtBottomTabBar,
    AcvtCarouselItem,
    AcvtHomePromotions,
    AcvtSmallPromoCard,
    AcvtHomePage,
    AcvtHomeGames,
    AcvtFeaturedGameCard,
    AcvtSmallGameCard,
} from '../../components/advanced-ct-visualisation-tool';
import { AcvtCanvas } from '../../components/advanced-ct-visualisation-tool/acvt-canvas';
import { useAcvtStore } from '../../contexts/mobx';
import {
    AcvtGame,
    AcvtHomeGame,
    AcvtPromotion,
    AcvtSupportedLanguages,
    AcvtTabs,
} from '../../types/acvt';
import { ReactComponent as StoresIcon } from '../../icons/Icon_Stores_1X.svg';
import { ReactComponent as CarwashIcon } from '../../icons/CarwashIcon.svg';
import style from './style.module.scss';

export const AcvtPage = observer(() => {
    const acvtStore = useAcvtStore();

    const [lang, setLang] = useState<AcvtSupportedLanguages>('en');

    const [isCardOpened, setIsCardOpened] = useState({
        homePromoCard: false,
        homeGameCard: false,
        promoCard: false,
        gameCard: false,
    });

    const [selectedTab, setSelectedTab] = useState<AcvtTabs>('home');

    const [singlePromo, setSinglePromo] = useState<AcvtPromotion>();

    const [singleHomePromo, setSingleHomePromo] = useState<AcvtPromotion>();

    const [singleGame, setSingleGame] = useState<AcvtGame>();

    const [singleHomeGame, setSingleHomeGame] = useState<AcvtHomeGame>();

    const [offers, setOffers] = useState<string>();

    const [couponCategories, setCouponCategories] = useState<string>();

    const [inStoreCategories, setInStoreCategories] = useState<string>();

    useEffect(() => {
        acvtStore.fetchAcvtPromotions(lang);
        acvtStore.fetchAcvtGames(lang);
        acvtStore.fetchAcvtHome(lang);
    }, [acvtStore, lang]);

    useEffect(() => {
        setSinglePromo((prevSinglePromo) =>
            acvtStore.acvtPromotionList.find(
                (element) => element.id === prevSinglePromo?.id
            )
        );
    }, [acvtStore.acvtPromotionList]);

    const changeLanguage = (language: AcvtSupportedLanguages) => {
        setLang(language);
        setOffers(undefined);
        setCouponCategories(undefined);
        setInStoreCategories(undefined);
    };

    const getPromotionalOffers = () => {
        const promotionalOffersList = acvtStore.acvtPromotionList.map(
            (promo) => promo.promotionalOffer?.title
        );
        const uniqPromotionalOffersList = [...new Set(promotionalOffersList)];
        return uniqPromotionalOffersList;
    };

    const getCouponCategoriesList = () => {
        const categoriesList = acvtStore.acvtPromotionList.map((promo) =>
            promo.type === 'coupon' ? promo.category.title : null
        );

        const uniqCategoriesList = [...new Set(categoriesList)].filter(
            (n) => n
        );
        return uniqCategoriesList;
    };

    const getInStoreCategoriesList = () => {
        const categoriesList = acvtStore.acvtPromotionList.map((promo) =>
            promo.type === 'in_store' ? promo.category.title : null
        );

        const uniqCategoriesList = [...new Set(categoriesList)].filter(
            (n) => n
        );
        return uniqCategoriesList;
    };

    const setPromo = (promo: AcvtPromotion) => {
        setSinglePromo(promo);
    };

    const setHomePromo = (homePromo: AcvtPromotion) => {
        setSingleHomePromo(homePromo);
    };

    const changeOffers = (selection: string) => {
        setOffers(selection);
    };

    const changeCouponCategories = (selection: string) => {
        setCouponCategories(selection);
    };

    const changeInStoreCategories = (selection: string) => {
        setInStoreCategories(selection);
    };

    const setGame = (game: AcvtGame) => {
        setSingleGame(game);
    };

    const setHomeGame = (game: AcvtHomeGame) => {
        setSingleHomeGame(game);
    };

    return (
        <>
            <div className={style.content}>
                <AcvtCanvas
                    changeLang={changeLanguage}
                    key={lang}
                    children={
                        (selectedTab === 'home' &&
                            (isCardOpened.homePromoCard ? (
                                <Acvt
                                    backgroundColor="white"
                                    children={
                                        <>
                                            <AcvtPromotionPage
                                                language={lang}
                                                promoObject={singleHomePromo}
                                                opened={setIsCardOpened}
                                            />
                                            <AcvtBottomTabBar
                                                language={lang}
                                                tab={selectedTab}
                                                tabSelected={setSelectedTab}
                                            />
                                        </>
                                    }
                                />
                            ) : isCardOpened.homeGameCard ? (
                                <Acvt
                                    backgroundColor="#d8281c"
                                    children={
                                        <>
                                            <AcvtGamePage
                                                key={singleHomeGame?.id}
                                                language={lang}
                                                gameObject={singleHomeGame}
                                                opened={setIsCardOpened}
                                            />
                                            <AcvtBottomTabBar
                                                language={lang}
                                                tab={selectedTab}
                                                tabSelected={setSelectedTab}
                                            />
                                        </>
                                    }
                                />
                            ) : (
                                <Acvt
                                    backgroundColor="#d8281c"
                                    children={
                                        <>
                                            <AcvtHomePage
                                                language={lang}
                                                featuredCarousel={
                                                    <AcvtCarousel>
                                                        {acvtStore.acvtHomeObject.featured?.map(
                                                            (featured) => {
                                                                return (
                                                                    <AcvtCarouselItem
                                                                        key={
                                                                            featured.id
                                                                        }
                                                                        image={
                                                                            featured.backgroundImage
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </AcvtCarousel>
                                                }
                                                promos={
                                                    <AcvtHomePromotions
                                                        language={lang}
                                                        viewAll={setSelectedTab}
                                                        couponCards={acvtStore.acvtHomeObject.coupons?.map(
                                                            (promo) => (
                                                                <AcvtSmallPromoCard
                                                                    key={
                                                                        promo.id
                                                                    }
                                                                    promoObject={
                                                                        promo
                                                                    }
                                                                    opened={
                                                                        setIsCardOpened
                                                                    }
                                                                    setSinglePromo={
                                                                        setHomePromo
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                        inStoreCards={acvtStore.acvtHomeObject.in_stores?.map(
                                                            (promo) => (
                                                                <AcvtSmallPromoCard
                                                                    key={
                                                                        promo.id
                                                                    }
                                                                    promoObject={
                                                                        promo
                                                                    }
                                                                    opened={
                                                                        setIsCardOpened
                                                                    }
                                                                    setSinglePromo={
                                                                        setHomePromo
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    />
                                                }
                                                games={
                                                    <AcvtHomeGames
                                                        language={lang}
                                                        smallCards={acvtStore.acvtHomeObject.games?.map(
                                                            (game) =>
                                                                !game.isFeatured && (
                                                                    <AcvtSmallGameCard
                                                                        key={
                                                                            game.id
                                                                        }
                                                                        gameObject={
                                                                            game
                                                                        }
                                                                        language={
                                                                            lang
                                                                        }
                                                                        opened={
                                                                            setIsCardOpened
                                                                        }
                                                                        setSingleGame={
                                                                            setHomeGame
                                                                        }
                                                                    />
                                                                )
                                                        )}
                                                        featuredCards={acvtStore.acvtHomeObject.games?.map(
                                                            (game) =>
                                                                game.isFeatured && (
                                                                    <AcvtFeaturedGameCard
                                                                        key={
                                                                            game.id
                                                                        }
                                                                        gameObject={
                                                                            game
                                                                        }
                                                                        language={
                                                                            lang
                                                                        }
                                                                        opened={
                                                                            setIsCardOpened
                                                                        }
                                                                        setSingleGame={
                                                                            setHomeGame
                                                                        }
                                                                    />
                                                                )
                                                        )}
                                                    />
                                                }
                                            />
                                            <AcvtBottomTabBar
                                                language={lang}
                                                tab={selectedTab}
                                                tabSelected={setSelectedTab}
                                            />
                                        </>
                                    }
                                />
                            ))) ||
                        (selectedTab === 'stores' && (
                            <Acvt
                                backgroundColor="white"
                                children={
                                    <>
                                        <AcvtPlaceholderPage language={lang}>
                                            <StoresIcon />
                                        </AcvtPlaceholderPage>
                                        <AcvtBottomTabBar
                                            language={lang}
                                            tab={selectedTab}
                                            tabSelected={setSelectedTab}
                                        />
                                    </>
                                }
                            />
                        )) ||
                        (selectedTab === 'promos' && (
                            <Acvt
                                backgroundColor="white"
                                children={
                                    isCardOpened.promoCard ? (
                                        <>
                                            <AcvtPromotionPage
                                                language={lang}
                                                promoObject={singlePromo}
                                                opened={setIsCardOpened}
                                            />
                                            <AcvtBottomTabBar
                                                language={lang}
                                                tab={selectedTab}
                                                tabSelected={setSelectedTab}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <AcvtPromotionsPage
                                                language={lang}
                                                offersList={getPromotionalOffers()}
                                                couponCategoriesList={getCouponCategoriesList()}
                                                inStoreCategoriesList={getInStoreCategoriesList()}
                                                selectedOffer={offers}
                                                selectedCouponCategory={
                                                    couponCategories
                                                }
                                                selectedInStoreCategory={
                                                    inStoreCategories
                                                }
                                                changeOffers={changeOffers}
                                                changeCouponCategories={
                                                    changeCouponCategories
                                                }
                                                changeInStoreCategories={
                                                    changeInStoreCategories
                                                }
                                                couponCards={
                                                    <div>
                                                        {acvtStore.acvtPromotionList.map(
                                                            (promo) =>
                                                                promo.type ===
                                                                    'coupon' &&
                                                                (promo
                                                                    .promotionalOffer
                                                                    ?.title ===
                                                                    offers ||
                                                                    offers ===
                                                                        undefined) &&
                                                                (promo.category
                                                                    .title ===
                                                                    couponCategories ||
                                                                    couponCategories ===
                                                                        undefined) && (
                                                                    <AcvtPromoCard
                                                                        key={
                                                                            promo.id
                                                                        }
                                                                        promoObject={
                                                                            promo
                                                                        }
                                                                        language={
                                                                            lang
                                                                        }
                                                                        opened={
                                                                            setIsCardOpened
                                                                        }
                                                                        setSinglePromo={
                                                                            setPromo
                                                                        }
                                                                    />
                                                                )
                                                        )}
                                                    </div>
                                                }
                                                inStoreCards={
                                                    <div>
                                                        {acvtStore.acvtPromotionList.map(
                                                            (promo) =>
                                                                promo.type ===
                                                                    'in_store' &&
                                                                (promo.category
                                                                    .title ===
                                                                    inStoreCategories ||
                                                                    inStoreCategories ===
                                                                        undefined) && (
                                                                    <AcvtPromoCard
                                                                        key={
                                                                            promo.id
                                                                        }
                                                                        promoObject={
                                                                            promo
                                                                        }
                                                                        language={
                                                                            lang
                                                                        }
                                                                        opened={
                                                                            setIsCardOpened
                                                                        }
                                                                        setSinglePromo={
                                                                            setPromo
                                                                        }
                                                                    />
                                                                )
                                                        )}
                                                    </div>
                                                }
                                            />
                                            <AcvtBottomTabBar
                                                language={lang}
                                                tab={selectedTab}
                                                tabSelected={setSelectedTab}
                                            />
                                        </>
                                    )
                                }
                            />
                        )) ||
                        (selectedTab === 'carwash' && (
                            <Acvt
                                backgroundColor="white"
                                children={
                                    <>
                                        <AcvtPlaceholderPage language={lang}>
                                            <CarwashIcon />
                                        </AcvtPlaceholderPage>
                                        <AcvtBottomTabBar
                                            language={lang}
                                            tab={selectedTab}
                                            tabSelected={setSelectedTab}
                                        />
                                    </>
                                }
                            />
                        )) ||
                        (selectedTab === 'more' &&
                            (isCardOpened.gameCard ? (
                                <Acvt
                                    backgroundColor="#d8281c"
                                    children={
                                        <>
                                            <AcvtGamePage
                                                key={singleGame?.id}
                                                language={lang}
                                                gameObject={singleGame}
                                                opened={setIsCardOpened}
                                            />
                                            <AcvtBottomTabBar
                                                language={lang}
                                                tab={selectedTab}
                                                tabSelected={setSelectedTab}
                                            />
                                        </>
                                    }
                                />
                            ) : (
                                <Acvt
                                    backgroundColor="#0164a8"
                                    children={
                                        <>
                                            <AcvtGamesPage
                                                language={lang}
                                                cards={acvtStore.acvtGamesList.map(
                                                    (game) => (
                                                        <AcvtGameCard
                                                            key={game?.id}
                                                            gameObject={game}
                                                            language={lang}
                                                            opened={
                                                                setIsCardOpened
                                                            }
                                                            setSingleGame={
                                                                setGame
                                                            }
                                                        />
                                                    )
                                                )}
                                            />
                                            <AcvtBottomTabBar
                                                language={lang}
                                                tab={selectedTab}
                                                tabSelected={setSelectedTab}
                                            />
                                        </>
                                    }
                                />
                            )))
                    }
                />
            </div>
        </>
    );
});
