import { action, makeObservable, observable } from 'mobx';
import { AcvtPromotion, AcvtGame, AcvtHomeResult } from '../types/acvt';
import { RootStore } from './root';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { HttpMethod } from '../types';

const params = {
    includeGamesV2: 'true',
};
export class AcvtStore extends WithNetworkConcurrency {
    @observable acvtPromotionList: AcvtPromotion[] = [];
    @observable acvtGamesList: AcvtGame[] = [];
    @observable acvtHomeObject: AcvtHomeResult = {};

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchAcvtPromotions(this: AcvtStore, lang: string) {
        const response = await this.rootStore.makeMobileNetworkCall<
            AcvtPromotion[]
        >(
            {
                method: HttpMethod.GET,
                url: '/promotions',
            },
            lang
        );

        if (!response.err) {
            this.acvtPromotionList = response.data;
        }
    }

    @action
    async fetchAcvtGames(this: AcvtStore, lang: string) {
        const response = await this.rootStore.makeMobileNetworkCall<AcvtGame[]>(
            {
                method: HttpMethod.GET,
                url: '/games',
            },
            lang
        );

        if (!response.err) {
            this.acvtGamesList = response.data;
        }
    }

    @action
    async fetchAcvtHome(this: AcvtStore, lang: string) {
        const response = await this.rootStore.makeMobileNetworkCall<
            AcvtHomeResult
        >(
            {
                method: HttpMethod.GET,
                url: '/home',
                params,
            },
            lang
        );

        if (!response.err) {
            this.acvtHomeObject = response.data;
        }
    }
}
