import React from 'react';
import style from './style.module.scss';
import { ReactComponent as CalendarIcon } from '../../../icons/CalendarBlank.svg';
import { ReactComponent as GenderIcon } from '../../../icons/GenderIntersex.svg';
import { ReactComponent as MapPinIcon } from '../../../icons/MapPin.svg';
import { ReactComponent as StarIcon } from '../../../icons/Star.svg';
import { ReactComponent as UpdatedIcon } from '../../../icons/ArrowsCounterClockwise.svg';
import { ReactComponent as AppleIcon } from '../../../icons/AppleLogo.svg';
import { ReactComponent as FacebookIcon } from '../../../icons/FacebookLogo.svg';
import { ReactComponent as InvalidIcon } from '../../../icons/Prohibit.svg';
import { ReactComponent as LockIcon } from '../../../icons/LockSimple.svg';
import { ReactComponent as DropIcon } from '../../../icons/Drop.svg';
import { ReactComponent as CircleIcon } from '../../../icons/Circle.svg';
import { ReactComponent as CreditIcon } from '../../../icons/CreditCard.svg';
import { ReactComponent as StackIcon } from '../../../icons/Stack.svg';
import { ReactComponent as TermsIcon } from '../../../icons/TermsIcon.svg';
import { ReactComponent as PrivacyIcon } from '../../../icons/PrivacyIcon.svg';
import { Client, DeviceAppVersion, UserConsent } from '../../../stores/client';
import { UserSingleDetail } from '../user-single-detail';
import { Spin } from 'antd';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../utils';

type UserDetailsTabProps = {
    clientObject?: Client;
    resetPassword?: () => void;
    paymentMethod?: {
        loading: boolean;
        data: string | null;
    };
    deviceAppVersion?: {
        loading: boolean;
        data: DeviceAppVersion | null;
    };
    userConsents?: UserConsent[] | null;
    onUpdateConsentStatus?: (
        userConsentRecordId: number,
        status: string
    ) => void;
    isUpdatingConsentStatus?: boolean;
};

export const UserDetailsTab = (props: UserDetailsTabProps) => {
    const {
        userConsents,
        onUpdateConsentStatus,
        isUpdatingConsentStatus,
    } = props;

    const termsConsent = userConsents?.find(
        (consent) => consent.type === 'terms_and_conditions'
    );
    const privacyPolicyConsent = userConsents?.find(
        (consent) => consent.type === 'privacy_policy'
    );

    const handleConsentStatusChange = (consent: UserConsent) => {
        if (onUpdateConsentStatus) {
            const newStatus = 'revoked';
            onUpdateConsentStatus(consent.id, newStatus);
        }
    };

    return (
        <div className={style.details_tab}>
            <UserSingleDetail
                title={
                    <>
                        <CalendarIcon />
                        Date of birth
                    </>
                }
                info={props.clientObject?.birthday?.format('LL')}
                style={{ paddingTop: '0px' }}
            />
            <UserSingleDetail
                title={
                    <>
                        <GenderIcon />
                        Gender
                    </>
                }
                info={props.clientObject?.gender}
            />
            <UserSingleDetail
                title={
                    <>
                        <MapPinIcon />
                        Postal Code
                    </>
                }
                info={props.clientObject?.postalCode}
            />
            <UserSingleDetail
                title={
                    <>
                        <StarIcon />
                        Created
                    </>
                }
                info={props.clientObject?.dateCreated?.format('LL')}
            />
            <UserSingleDetail
                title={
                    <>
                        <UpdatedIcon />
                        Last updated
                    </>
                }
                info={props.clientObject?.dateUpdated?.format('LLL')}
            />
            <UserSingleDetail
                title={
                    <>
                        <AppleIcon />
                        Apple ID
                    </>
                }
                info={props.clientObject?.appleId}
            />
            <UserSingleDetail
                title={
                    <>
                        <FacebookIcon />
                        Facebook
                    </>
                }
                info={props.clientObject?.facebookId}
            />
            <UserSingleDetail
                title={
                    <>
                        <InvalidIcon />
                        Invalid logins
                    </>
                }
                info={props.clientObject?.invalidLoginCount}
            />
            <UserSingleDetail
                title={
                    <>
                        <LockIcon />
                        Locked at
                    </>
                }
                info={props.clientObject?.lockedAt?.format('LLL')}
                buttonText="Reset Password"
                buttonFunctionality={props.resetPassword}
            />
            <UserSingleDetail
                title={
                    <>
                        <DropIcon />
                        Liquid barcode ID
                    </>
                }
                info={props.clientObject?.liquidBarcodeId}
            />
            <UserSingleDetail
                title={
                    <>
                        <CircleIcon />
                        NGRP
                    </>
                }
                info={props.clientObject?.ngrpCustomerId}
            />
            <UserSingleDetail
                title={
                    <>
                        <CreditIcon />
                        Payment method
                    </>
                }
                info={
                    props.paymentMethod?.loading ? (
                        <Spin />
                    ) : (
                        props.paymentMethod?.data
                    )
                }
            />
            <UserSingleDetail
                title={
                    <>
                        <StackIcon />
                        App version
                    </>
                }
                info={
                    props.deviceAppVersion?.loading ? (
                        <Spin />
                    ) : (
                        `${props.deviceAppVersion?.data?.appVersion || '-'} ${
                            props.deviceAppVersion?.data?.os || '-'
                        }`
                    )
                }
            />

            {(termsConsent || privacyPolicyConsent) && (
                <div className={style.consents_section}>
                    {termsConsent ? (
                        <UserSingleDetail
                            title={
                                <>
                                    <TermsIcon className={style.iconStyle} />
                                    Terms and Conditions
                                </>
                            }
                            info={
                                termsConsent.status
                                    ? `${capitalizeFirstLetter(
                                          termsConsent.status
                                      )}${
                                          termsConsent.createdAt
                                              ? ` on: ${moment(
                                                    termsConsent.createdAt
                                                ).format('LLL')}`
                                              : ''
                                      }`
                                    : 'No Status'
                            }
                            buttonText={
                                termsConsent.status === 'accepted'
                                    ? 'Revoke Consent'
                                    : 'Revoked Consent'
                            }
                            buttonFunctionality={
                                termsConsent.status === 'accepted'
                                    ? () =>
                                          handleConsentStatusChange(
                                              termsConsent
                                          )
                                    : undefined
                            }
                            buttonDisabled={
                                termsConsent.status === 'accepted'
                                    ? isUpdatingConsentStatus
                                    : true
                            }
                        />
                    ) : (
                        <UserSingleDetail
                            title={
                                <>
                                    <TermsIcon className={style.iconStyle} />
                                    Terms and Conditions
                                </>
                            }
                            info="Not Accepted"
                        />
                    )}
                    {privacyPolicyConsent ? (
                        <UserSingleDetail
                            title={
                                <>
                                    <PrivacyIcon className={style.iconStyle} />
                                    Privacy Policy
                                </>
                            }
                            info={
                                privacyPolicyConsent.status
                                    ? `${capitalizeFirstLetter(
                                          privacyPolicyConsent.status
                                      )}${
                                          privacyPolicyConsent.createdAt
                                              ? ` on: ${moment(
                                                    privacyPolicyConsent.createdAt
                                                ).format('LLL')}`
                                              : ''
                                      }`
                                    : 'No Status'
                            }
                            buttonText={
                                privacyPolicyConsent.status === 'accepted'
                                    ? 'Revoke Consent'
                                    : 'Revoked Consent'
                            }
                            buttonFunctionality={
                                privacyPolicyConsent.status === 'accepted'
                                    ? () =>
                                          handleConsentStatusChange(
                                              privacyPolicyConsent
                                          )
                                    : undefined
                            }
                            buttonDisabled={
                                privacyPolicyConsent.status === 'accepted'
                                    ? isUpdatingConsentStatus
                                    : true
                            }
                        />
                    ) : (
                        <UserSingleDetail
                            title={
                                <>
                                    <PrivacyIcon className={style.iconStyle} />
                                    Privacy Policy
                                </>
                            }
                            info="Not Accepted"
                        />
                    )}
                </div>
            )}
        </div>
    );
};
