import React from 'react';
import style from './style.module.scss';
import { ImageInput } from '../..';
import { NullableTranslationMap, SupportedLanguages } from '../../../types';

interface ImageProps {
    image: NullableTranslationMap;
    title?: string;
    applyStyle?: boolean;
    onChange: (image: NullableTranslationMap) => void;
    onDelete: (image: NullableTranslationMap) => void;
}

export const Images = ({ applyStyle = true, ...props }: ImageProps) => {
    return (
        <div className={style.input_row}>
            {SupportedLanguages.map((lang) => (
                <div key={lang}>
                    <div className={applyStyle ? style.image_field : ''}>
                        {props.title && (
                            <span>
                                {props.title} {lang}
                            </span>
                        )}
                        <ImageInput
                            url={props.image[lang]}
                            onChange={(url) =>
                                props.onChange({
                                    ...props.image,
                                    [lang]: url,
                                })
                            }
                            onDelete={() =>
                                props.onChange({
                                    ...props.image,
                                    [lang]: null,
                                })
                            }
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
