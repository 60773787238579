import { notification } from 'antd';
import './style.scss';

export const NotificationManager = {
    showError: (description: string, duration?: number) => {
        //eslint-disable-next-line
        notification.open({
            className: 'notification-error',
            message: 'An error has occured',
            description,
            duration: duration ?? 4.5,
        });
    },
    showSuccess: (description: string) => {
        //eslint-disable-next-line
        notification.open({
            className: 'notification-success',
            message: 'Success',
            description,
        });
    },
};
