import { Avatar, Tag } from 'antd';
import React from 'react';
import style from './style.module.scss';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';
import moment from 'moment';
import { ReactComponent as CheckIcon } from '../../icons/CheckCircle.svg';
import { ReactComponent as EmptyCheckIcon } from '../../icons/CheckCircleEmpty.svg';
import { coucheTardRed } from '../../utils/const';
import { SingleVisit } from '../../stores/visit';

type VisitDetailsProps = {
    onCloseClick: () => void;
    visitObject?: SingleVisit | null;
};

export const VisitDetails = ({
    onCloseClick,
    visitObject,
}: VisitDetailsProps) => {
    const user = visitObject?.visit.user;
    const order = visitObject?.visit.order;
    const renderStatus = (status: string) => {
        let tagColor = 'red';
        switch (status) {
            case 'COMPLETE':
                tagColor = 'cyan';
                break;
            case 'EMPTY_CART':
                tagColor = 'default';
                break;
            case 'PENDING':
                tagColor = 'gold';
                break;
            case 'OPEN':
                tagColor = 'geekblue';
                break;
            case 'ASSOCIATED':
                tagColor = 'blue';
                break;
        }
        return (
            <Tag color={tagColor} className={style.order_status_tag}>
                {status}
            </Tag>
        );
    };

    return (
        <>
            <div className={style.container}>
                <div className={style.header}>
                    <div className={style.close} onClick={onCloseClick}>
                        <CloseIcon />
                    </div>
                    <div className={style.client_id}>CLIENT #{user?.id} </div>
                    <div className={style.user_info}>
                        <Avatar
                            shape="circle"
                            style={{
                                verticalAlign: 'middle',
                                backgroundColor: `${coucheTardRed}`,
                            }}
                            size={64}
                            src={user?.picture ?? ''}
                        >
                            {user?.firstName?.charAt(0).toLocaleUpperCase()}
                            {user?.lastName?.charAt(0).toLocaleUpperCase()}
                        </Avatar>
                        <div className={style.user_info_and_contact}>
                            <div className={style.user_title}>
                                {user?.birthday
                                    ? `${user?.firstName} ${user?.lastName} (${
                                          user
                                              ? moment().diff(
                                                    user?.birthday,
                                                    'years'
                                                )
                                              : ''
                                      })`
                                    : `${user?.firstName} ${user?.lastName}`}
                                {user?.language && (
                                    <Tag
                                        color={
                                            user?.language === 'fr'
                                                ? 'red'
                                                : 'orange'
                                        }
                                    >
                                        {user?.language}
                                    </Tag>
                                )}
                            </div>
                            <div className={style.user_contact}>
                                <div className={style.phone_number}>
                                    {user?.phoneNumber}
                                    {user?.phoneNumber && (
                                        <div className={style.confirmed}>
                                            {user?.isPhoneNumberConfirmed ? (
                                                <CheckIcon />
                                            ) : (
                                                <EmptyCheckIcon stroke="grey" />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className={style.email}>
                                    {user?.email}
                                    {user?.email && (
                                        <div className={style.confirmed}>
                                            {user?.isEmailConfirmed ? (
                                                <CheckIcon />
                                            ) : (
                                                <EmptyCheckIcon stroke="grey" />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.order}>
                    <div className={style.order_status}>
                        {order?.status ? renderStatus(order.status) : <></>}
                    </div>
                    <div className={style.order_date}>
                        <div className={style.order_day_date}>
                            {order?.receiptDate?.format('LL')}
                        </div>
                        <div className={style.order_location}>
                            {order?.receiptDate?.format('LT')} EST @{' '}
                            {order?.storeLocation}
                        </div>
                        <div className={style.order_store_id}>
                            Store ID:{order?.storeNum}
                        </div>
                        <div className={style.order_store_id}>
                            Order ID: {order?.id ? order.id : <></>}
                        </div>
                    </div>
                    <div className={style.order_items_container}>
                        <div className={style.order_items_header}>
                            <span>Description</span>
                            <span className={style.order_item_quantity}>
                                Qty.
                            </span>
                            <span>Price</span>
                        </div>
                        <div className={style.order_receipt}>
                            <div className={style.order_items}>
                                {order?.items?.map((item) => (
                                    <>
                                        <div className={style.item}>
                                            <span
                                                key={item.description as string}
                                                className={
                                                    style.order_item_description
                                                }
                                            >
                                                {item.description}
                                            </span>
                                            <span
                                                className={
                                                    style.order_item_quantity
                                                }
                                                key={item.quantity!}
                                            >
                                                {item.quantity}
                                            </span>
                                            <span key={item.amount!}>
                                                ${item.amount}
                                            </span>
                                        </div>
                                        {item?.discounts?.map((discount) => (
                                            <>
                                                <div
                                                    className={
                                                        style.item_discount
                                                    }
                                                >
                                                    <span key={discount.type!}>
                                                        ${discount.type}
                                                    </span>
                                                    <span
                                                        key={
                                                            discount.amount as number
                                                        }
                                                        className={
                                                            style.order_item_description_discount
                                                        }
                                                    >
                                                        {discount.amount}
                                                    </span>
                                                    <span
                                                        className={
                                                            style.order_item_quantity_discount
                                                        }
                                                        key={discount.quantity!}
                                                    >
                                                        {item.quantity}
                                                    </span>
                                                </div>
                                            </>
                                        ))}
                                    </>
                                ))}
                            </div>
                            <div className={style.order_subtotal_taxes}>
                                <div className={style.order_subtotal}>
                                    <span>Subtotal</span>
                                    <span>${order?.subTotal}</span>
                                </div>
                                {order?.taxes?.map((tax) => (
                                    <div
                                        key={tax.description as string}
                                        className={style.order_tax}
                                    >
                                        <span key={tax.description as string}>
                                            {tax.description}
                                        </span>
                                        <span key={tax.amount!}>
                                            ${tax.amount}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={style.order_total}>
                                <span>Total</span>
                                <span>${order?.total}</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.order_payment}>
                        <div className={style.order_payment_header}>
                            <span>Payment method</span>
                        </div>
                        <div className={style.order_receipt}>
                            <div className={style.order_payment_infos}>
                                <div className={style.item}>
                                    <span>Card #</span>
                                    <span>{order?.paymentMethod.cardMask}</span>
                                </div>
                                <div className={style.item}>
                                    <span>Card type</span>
                                    <span>{order?.paymentMethod.cardType}</span>
                                </div>
                            </div>
                            <div className={style.border}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
