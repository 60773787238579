import { useEffect, useState } from 'react';
import { Table, Modal, Form, Tag, Card, Switch, Input } from 'antd';
import React from 'react';
import { FeatureFlags } from '../../../types';
import moment from 'moment';
import style from './style.module.scss';

const { Meta } = Card;

interface Props {
    featureFlags: FeatureFlags[] | null;
    isLoading: boolean;
    onSubmit: (updatedFeatureFlag: FeatureFlags) => void;
}

export const FeatureFlagsCard = ({
    featureFlags,
    onSubmit,
    isLoading,
}: Props) => {
    const [editingFeatureFlag, setEditingFeatureFlag] = useState<
        FeatureFlags | undefined
    >(undefined);
    const [form] = Form.useForm();

    const handleEdit = (featureFlag: FeatureFlags) => {
        setEditingFeatureFlag(featureFlag);
        form.setFieldsValue(featureFlag);
    };

    const handleCancel = () => {
        setEditingFeatureFlag(undefined);
        form.resetFields();
    };

    const [featureFlagsData, setFeatureFlagsData] = useState<
        FeatureFlags[] | null
    >(featureFlags);

    useEffect(() => {
        setFeatureFlagsData(featureFlags);
    }, [featureFlags]);

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                const {
                    active,
                    iosMinimalVersion,
                    androidMinimalVersion,
                } = values;
                const updatedFeatureFlag = {
                    ...editingFeatureFlag!,
                    active,
                    iosMinimalVersion,
                    androidMinimalVersion,
                };
                onSubmit(updatedFeatureFlag);
                setEditingFeatureFlag(undefined);
                form.resetFields();
                const updatedFeatureFlags = featureFlags?.map((flag) =>
                    flag.id === updatedFeatureFlag.id
                        ? updatedFeatureFlag
                        : flag
                );
                setFeatureFlagsData(updatedFeatureFlags || null);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'iOS Min Version',
            dataIndex: 'iosMinimalVersion',
            key: 'iosMinimalVersion',
        },
        {
            title: 'Android Min Version',
            dataIndex: 'androidMinimalVersion',
            key: 'androidMinimalVersion',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (_: any, record: FeatureFlags) => (
                <Tag color={record.active ? 'green' : 'red'}>
                    {record.active ? 'Active' : 'Inactive'}
                </Tag>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_: any, record: FeatureFlags) => (
                <span>{moment(record.createdAt).format('LLL')}</span>
            ),
        },
    ];

    return (
        <Card bordered={false} loading={isLoading} className={style.card}>
            <Meta title="Feature Flags" />
            <Table
                dataSource={featureFlagsData ?? []}
                columns={columns}
                pagination={false}
                onRow={(row) => ({
                    onClick: () => handleEdit(row),
                })}
            />

            <Modal
                title={`Edit ${editingFeatureFlag?.name}`}
                visible={!!editingFeatureFlag}
                onCancel={handleCancel}
                onOk={handleSubmit}
                okText="Update"
            >
                <Form form={form}>
                    <Form.Item
                        label="Active"
                        name="active"
                        initialValue={editingFeatureFlag?.active}
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a value for active',
                            },
                        ]}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label="iOS Minimal Version"
                        name="iosMinimalVersion"
                        initialValue={editingFeatureFlag?.iosMinimalVersion}
                    >
                        <Input placeholder="Enter iOS minimal version" />
                    </Form.Item>
                    <Form.Item
                        label="Android Minimal Version"
                        name="androidMinimalVersion"
                        initialValue={editingFeatureFlag?.androidMinimalVersion}
                    >
                        <Input placeholder="Enter Android minimal version" />
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
};
