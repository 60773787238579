import * as yup from 'yup';
import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import { NotificationManager } from '../components';
import {
    FeaturedTypes,
    FeaturedTypesRegEx,
    ActiveQueryParam,
} from '../utils/const';
import { Lang, LangRegEx } from '../utils';
import { HttpMethod } from '../types';

export const yupFeaturedItems = yup
    .object({
        backgroundImage: yup.string().nullable(),
        imageTitle: yup.string().nullable(),
        textTitle: yup.string().nullable(),
        cta: yup.string().nullable(),
        externalUrl: yup.string().nullable(),
    })
    .required()
    .shape({
        language: yup.string<Lang>().required().matches(LangRegEx),
    });

export const yupFeatured = yup.object({
    id: yup.number().required(),
    type: yup.string<FeaturedTypes>().matches(FeaturedTypesRegEx).required(),
    canBeDeleted: yup.boolean(),
    position: yup.number(),
    geolocated: yup.boolean().nullable(),
    isActive: yup.boolean(),
    promotionId: yup.number().nullable(),
    items: yup.array(yupFeaturedItems).required(),
});

export const yupPaginatedList = yup.object({
    page: yup.number().required(),
    perPage: yup.number().required(),
    total: yup.number().required(),
    results: yup.array(yupFeatured).default([]),
});

export type Featured = yup.InferType<typeof yupFeatured>;
export type PaginatedFeatured = yup.InferType<typeof yupPaginatedList>;

export type SearchParams = {
    perPage?: number;
    page?: number;
    filter?: ActiveQueryParam;
};

export class FeaturedStore {
    @observable paginatedList: PaginatedFeatured | null = null;
    @observable isFetchingList = false;
    @observable visibleList: Featured[] = [];
    @observable isFetchingVisibleList = false;
    @observable isUpdatingVisibleList = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async getPaginatedList(params: SearchParams) {
        if (this.isFetchingList) {
            return;
        }

        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/featured',
                params,
            },
            yupPaginatedList
        );
        this.isFetchingList = false;

        if (!response.err) {
            this.paginatedList = response.data;
        }
    }

    @action
    async getVisibleList() {
        if (this.isFetchingVisibleList) {
            return;
        }

        this.isFetchingVisibleList = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/featured/visible',
            },
            yup.array(yupFeatured).required()
        );
        this.isFetchingVisibleList = false;

        if (!response.err) {
            this.setVisibleList(response.data);
        }
    }

    @action
    setVisibleList(list: Featured[]) {
        this.visibleList = list.filter((featured) => !featured.geolocated);
    }

    @action
    async updateVisibleList() {
        const featuredIds = this.visibleList.map((f) => f.id);
        this.isUpdatingVisibleList = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PATCH,
            url: '/featured/sort',
            data: { featuredIds },
        });
        this.isUpdatingVisibleList = false;

        if (!response.err) {
            NotificationManager.showSuccess('List updated');
        }
    }
}
