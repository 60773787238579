import React from 'react';
import style from './style.module.scss';
import { ReactComponent as StoresIcon } from '../../../icons/Icon_Stores_1X.svg';
import { ReactComponent as PromotionsIcon } from '../../../icons/Icon_Promotions_1X.svg';
import { ReactComponent as HomeIcon } from '../../../icons/Icon_Home_1X.svg';
import { ReactComponent as MoreIcon } from '../../../icons/Icon_Plus_1X.svg';
import { ReactComponent as CarwashIcon } from '../../../icons/CarwashIcon.svg';
import { AcvtSupportedLanguages, AcvtTabs } from '../../../types/acvt';
import { Text } from '../../../utils/text';

type AcvtBottomProps = {
    language?: AcvtSupportedLanguages;
    tab: AcvtTabs;
    tabSelected: React.Dispatch<React.SetStateAction<AcvtTabs>>;
    onEditMode?: boolean;
};

export const AcvtBottomTabBar = ({
    language,
    tab,
    tabSelected,
    onEditMode = false,
}: AcvtBottomProps) => {
    return (
        <>
            <div className={style.navbar}>
                <div
                    className={
                        tab === 'home'
                            ? style.icon_activated
                            : onEditMode
                            ? style.icon_deactivated
                            : style.icon
                    }
                    id={style.home}
                    onClick={() => (onEditMode ? {} : tabSelected('home'))}
                >
                    <HomeIcon />
                    {language === 'en'
                        ? Text.ACVT.bottomTabBar.home.en
                        : Text.ACVT.bottomTabBar.home.fr}
                    {tab === 'home' && <span className={style.inkbar}></span>}
                </div>
                <div
                    className={
                        tab === 'stores'
                            ? style.icon_activated
                            : onEditMode
                            ? style.icon_deactivated
                            : style.icon
                    }
                    id={style.stores}
                    onClick={() => (onEditMode ? {} : tabSelected('stores'))}
                >
                    <StoresIcon />
                    {language === 'en'
                        ? Text.ACVT.bottomTabBar.stores.en
                        : Text.ACVT.bottomTabBar.stores.fr}
                    {tab === 'stores' && <span className={style.inkbar}></span>}
                </div>
                <div
                    className={
                        tab === 'promos'
                            ? style.icon_activated
                            : onEditMode
                            ? style.icon_deactivated
                            : style.icon
                    }
                    id={style.promos}
                    onClick={() => (onEditMode ? {} : tabSelected('promos'))}
                >
                    <PromotionsIcon id={style.promos_icon} />
                    Promos
                    {tab === 'promos' && <span className={style.inkbar}></span>}
                </div>
                <div
                    className={
                        tab === 'carwash'
                            ? style.icon_activated
                            : onEditMode
                            ? style.icon_deactivated
                            : style.icon
                    }
                    id={style.carwash}
                    onClick={() => (onEditMode ? {} : tabSelected('carwash'))}
                >
                    <CarwashIcon />
                    {language === 'en'
                        ? Text.ACVT.bottomTabBar.carWash.en
                        : Text.ACVT.bottomTabBar.carWash.fr}
                    {tab === 'carwash' && (
                        <span className={style.inkbar}></span>
                    )}
                </div>
                <div
                    className={
                        tab === 'more'
                            ? style.icon_activated
                            : onEditMode
                            ? style.icon_deactivated
                            : style.icon
                    }
                    id={style.more}
                    onClick={() => (onEditMode ? {} : tabSelected('more'))}
                >
                    <MoreIcon />
                    {language === 'en'
                        ? Text.ACVT.bottomTabBar.more.en
                        : Text.ACVT.bottomTabBar.more.fr}
                    {tab === 'more' && <span className={style.inkbar}></span>}
                </div>
            </div>
        </>
    );
};
