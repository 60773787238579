import React from 'react';
import style from './style.module.scss';
import { DropDownStatus } from '../dropdown-status';
import { StoreTag } from '../store-tag';
import { batchStoreStatusOptions } from '../../helpers/store-helper';

type StoreEditingStatusProps = {
    selectedStatus: string;
    setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
};

export const StoreEditingStatus = ({
    selectedStatus,
    setSelectedStatus,
}: StoreEditingStatusProps) => {
    const statusOptions = Object.entries(batchStoreStatusOptions).map(
        ([key, value]) => ({
            label: value,
            value: key,
        })
    );
    return (
        <>
            <div className={style.single_info}>
                <div className={style.dropdown_wrapper}>
                    <DropDownStatus
                        options={statusOptions ?? []}
                        onChange={setSelectedStatus}
                        placeholder="Select status here"
                        selectedOption={selectedStatus}
                    />
                    <div className={style.tag_wrapper}>
                        <StoreTag status={selectedStatus} />
                    </div>
                </div>
            </div>
        </>
    );
};
