import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import style from './style.module.scss';
import { useOktaAuth } from '@okta/okta-react';

export const LoginPage = observer(() => {
    const { oktaAuth } = useOktaAuth();

    const signIn = () => {
        oktaAuth.signInWithRedirect({ originalUri: '/' });
    };

    return (
        <div className={style.container}>
            <img
                loading="lazy"
                src={process.env.PUBLIC_URL + '/ct-logo-large.png'}
                alt="logo"
            />

            <Button
                type="primary"
                onClick={signIn}
                className={style.submit_button}
            >
                Sign In With Okta
            </Button>
        </div>
    );
});
