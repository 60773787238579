import React from 'react';
import style from './style.module.scss';
import { Moment } from 'moment';
import { DropDownDays } from '../dropdown-days';
import { SelectOpenHours } from '../select-open-hours';
import { WEEKDAYS } from '../../helpers/store-helper';

type StoreEditingDaysProps = {
    openHoursComponents: {
        id: number;
        startTime: Moment | null;
        endTime: Moment | null;
        open: boolean;
        selectedWeekdays: string[];
    }[];
    setOpenHoursComponents: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                startTime: Moment | null;
                endTime: Moment | null;
                open: boolean;
                selectedWeekdays: string[];
            }[]
        >
    >;
    selectedWeekdays: string[];
    setSelectedWeekdays: React.Dispatch<React.SetStateAction<string[]>>;
};

export const StoreEditingDays = ({
    openHoursComponents,
    setOpenHoursComponents,
    selectedWeekdays,
    setSelectedWeekdays,
}: StoreEditingDaysProps) => {
    const weekdayOption = WEEKDAYS.map((weekday) => ({
        label: weekday,
        value: weekday,
    }));
    const handleWeekdaysChange = (id: number, selectedWeekdays: string[]) => {
        setOpenHoursComponents((prevComponents) =>
            prevComponents.map((component) =>
                component.id === id
                    ? { ...component, selectedWeekdays }
                    : component
            )
        );
        const allSelectedWeekdays = openHoursComponents.reduce(
            (acc, component) => {
                if (component.id === id) {
                    return acc.concat(selectedWeekdays);
                }
                return acc.concat(component.selectedWeekdays);
            },
            [] as string[]
        );

        setSelectedWeekdays(allSelectedWeekdays);
    };

    const addOpenHoursComponent = () => {
        setOpenHoursComponents((prevComponents) => [
            ...prevComponents,
            {
                id: prevComponents.length,
                startTime: null,
                endTime: null,
                open: true,
                selectedWeekdays: [],
            },
        ]);
    };

    const handleToggleOpen = (id: number) => {
        setOpenHoursComponents((prevComponents) =>
            prevComponents.map((component) =>
                component.id === id
                    ? { ...component, open: !component.open }
                    : component
            )
        );
    };

    const handleStartTimeChange = (id: number, time: Moment | null) => {
        setOpenHoursComponents((prevComponents) =>
            prevComponents.map((component) =>
                component.id === id
                    ? { ...component, startTime: time }
                    : component
            )
        );
    };

    const handleEndTimeChange = (id: number, time: Moment | null) => {
        setOpenHoursComponents((prevComponents) =>
            prevComponents.map((component) =>
                component.id === id
                    ? { ...component, endTime: time }
                    : component
            )
        );
    };
    return (
        <>
            <div>
                <div>
                    {openHoursComponents.map((component) => (
                        <div key={component.id} className={style.open_hours}>
                            <DropDownDays
                                options={weekdayOption.filter(
                                    (weekday) =>
                                        !selectedWeekdays.includes(
                                            weekday.value
                                        ) ||
                                        component.selectedWeekdays.includes(
                                            weekday.value
                                        )
                                )}
                                selectedOptions={component.selectedWeekdays}
                                onChange={(value) =>
                                    handleWeekdaysChange(component.id, value)
                                }
                                placeholder="Select days here"
                            />
                            <SelectOpenHours
                                key={component.id}
                                startTime={component.startTime}
                                endTime={component.endTime}
                                toggleState={component.open}
                                onClickToggle={() =>
                                    handleToggleOpen(component.id)
                                }
                                onChangeStartTime={(time) =>
                                    handleStartTimeChange(component.id, time)
                                }
                                onChangeEndTime={(time) =>
                                    handleEndTimeChange(component.id, time)
                                }
                            />
                        </div>
                    ))}
                    {weekdayOption.length > selectedWeekdays.length && (
                        <button
                            className={style.add_button}
                            onClick={addOpenHoursComponent}
                        >
                            + Add Days
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
