import { observable, action, makeObservable } from 'mobx';
import * as yup from 'yup';
import { RootStore } from './root';
import { MomentSchema } from '../utils/yup-schemas';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { HttpMethod } from '../types';

const feedbackSchema = yup.object({
    userId: yup.number().required(),
    score: yup.number().required(),
    description: yup.string().nullable(),
    metadata: yup.object().nullable().notRequired(),
    featureScored: yup.string().notRequired().nullable(),
    selectedStore: yup.number().nullable().notRequired(),
    updateAt: new MomentSchema().notRequired().nullable(),
    createdAt: new MomentSchema().notRequired().nullable(),
    category: yup.string().nullable().notRequired(),
    user: yup.object({
        firstName: yup.string().notRequired(),
        lastName: yup.string().notRequired(),
        email: yup.string().notRequired().nullable(),
        picture: yup.string().notRequired().nullable(),
        language: yup.string<'en' | 'fr'>().matches(/en|fr/).nullable(),
        birthday: new MomentSchema().nullable(),
        newsletter_receiver: yup.boolean().notRequired().nullable(),
        gender: yup
            .string<'male' | 'female'>()
            .matches(/male|female/)
            .notRequired()
            .nullable(),
        phoneNumber: yup.string().notRequired().nullable(),
        postalCode: yup.string().notRequired().nullable(),
    }),
});

const paginatedListSchema = yup.object({
    page: yup.number().required(),
    perPage: yup.number().required(),
    total: yup.number().required(),
    results: yup.array(feedbackSchema).default([]),
});

const csvListSchema = yup.array(feedbackSchema);

export type Feedback = yup.InferType<typeof feedbackSchema>;
export type PaginatedFeedbackList = yup.InferType<typeof paginatedListSchema>;
export type CSVFeedbackList = yup.InferType<typeof csvListSchema>;

export type SearchParams = {
    perPage?: number;
    page?: number;
    q?: string;
    startDate?: string;
    endDate?: string;
    compareStartDate?: string;
    compareEndDate?: string;
    featureScored?: string;
};

export type CsvSearchParams = {
    q?: string;
    startDate?: string;
    endDate?: string;
    featureScored?: string;
};
export class FeedbackStore extends WithNetworkConcurrency {
    @observable fetchingCount = 0;
    @observable list: PaginatedFeedbackList | null = null;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchList(this: FeedbackStore, params: SearchParams) {
        const tag = this.getTag();
        this.fetchingCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: 'feedback',
                params,
            },
            paginatedListSchema
        );
        this.fetchingCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.list = response.data;
        }
    }

    @action
    async fetchFeedbackForCSV(this: FeedbackStore, params: CsvSearchParams) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: 'feedback/csv',
                params,
            },
            csvListSchema
        );

        if (response.data) {
            return response.data;
        }
    }
}
