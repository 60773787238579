import React from 'react';
import { observer } from 'mobx-react';
import { useAuthStore } from '../../contexts/mobx';
import { Card, Descriptions } from 'antd';
import moment from 'moment';

export const ProfilePage = observer(() => {
    const authStore = useAuthStore();

    const { claims, roles, expireAt, hasManagementAccess } = authStore;

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '0px 3px 15px 0px rgba(208, 216, 243, 0.4)',
        marginRight: '20px',
        marginBottom: '10px',
    };

    const timestamp = expireAt ? expireAt : 0;
    const formatted = moment
        .unix(timestamp)
        .format('dddd, MMMM Do, YYYY h:mm:ss A');

    return (
        <>
            <Card title="Profile" style={cardStyle}>
                <Descriptions title={claims?.name}>
                    <Descriptions.Item label="Email">
                        {claims?.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Roles">
                        {roles.join(', ')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Session expire">
                        {formatted}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            {hasManagementAccess && (
                <Card title="Section Admin" style={cardStyle}>
                    <p>Unlimited access to all sections of the application.</p>
                </Card>
            )}
        </>
    );
});
