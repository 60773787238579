import React from 'react';
import style from './style.module.scss';
import { InputNumber } from '../input-number';

type CoordinatesEditorProps = {
    coordinates: { lat: number; lng: number };
    setCoordinates: (value: { lng: number; lat: number }) => void;
};

export const CoordinatesEditor = ({
    coordinates,
    setCoordinates,
}: CoordinatesEditorProps) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [latitude, setLatitude] = React.useState(coordinates.lat);
    const [longitude, setLongitude] = React.useState(coordinates.lng);

    React.useEffect(() => {
        setLatitude(coordinates.lat);
        setLongitude(coordinates.lng);
    }, [coordinates]);

    const onCancel = () => {
        setLatitude(coordinates.lat);
        setLongitude(coordinates.lng);
        setIsEditing(false);
    };

    const saveCoordinates = () => {
        setCoordinates({
            lat: latitude,
            lng: longitude,
        });
        setIsEditing(false);
    };

    return (
        <div>
            <div className={style.coordinate}>
                <b>Latitude:</b>
                {isEditing ? (
                    <InputNumber value={latitude} setValue={setLatitude} />
                ) : (
                    latitude
                )}
            </div>
            <div className={style.coordinate}>
                <b>Longitude:</b>
                {isEditing ? (
                    <InputNumber value={longitude} setValue={setLongitude} />
                ) : (
                    longitude
                )}
            </div>
            {isEditing ? (
                <div className={style.buttons_holder}>
                    <button className={style.cancel_button} onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className={style.confirm_button}
                        onClick={saveCoordinates}
                    >
                        Confirm
                    </button>
                </div>
            ) : (
                <div className={style.buttons_holder}>
                    <button
                        className={style.edit_button}
                        onClick={() => setIsEditing(true)}
                    >
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
};
