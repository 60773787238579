import { Store } from '../stores/stores';
import differenceBy from 'lodash/differenceBy';
import intersectionBy from 'lodash/intersectionBy';
import difference from 'lodash/difference';
import PhoneNumber from 'awesome-phonenumber';
import { toJS } from 'mobx';
import produce from 'immer';
import moment from 'moment';
export interface BaseStore {
    nref: string;
}

export interface ParsedStore {
    id: number;
    nref: string;
    addr: {
        city: string;
        post: string;
        street: string;
    };
    lat: number;
    lng: number;
    hours: [string, string, string, string, string, string, string];
    tel: string;
    services: string[];
    status: string;
}

export class Difference {
    constructor(public initial: Store) {}

    phoneNumber?: string;
    city?: string;
    post?: string;
    street?: string;
    addedServices?: string[];
    removedServices?: string[];
    status?: string;
    lat?: number;
    lng?: number;

    hours: [
        string | undefined,
        string | undefined,
        string | undefined,
        string | undefined,
        string | undefined,
        string | undefined,
        string | undefined
    ] = [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
    ];

    getModifiedStore() {
        return produce(this.initial, (draft) => {
            if (this.phoneNumber) {
                draft.tel = this.phoneNumber;
            }
            if (this.city) {
                draft.addr.city = this.city;
            }
            if (this.post) {
                draft.addr.post = this.post;
            }
            if (this.street) {
                draft.addr.street = this.street;
            }
            if (this.status) {
                draft.status = this.status;
            }
            if (this.addedServices) {
                draft.services = draft.services.concat(this.addedServices);
            }
            if (this.lat) {
                draft.lat = this.lat;
            }
            if (this.lng) {
                draft.lng = this.lng;
            }
            if (this.removedServices) {
                for (const removedService of this.removedServices) {
                    const index = draft.services.findIndex(
                        (s) => s === removedService
                    );
                    draft.services.splice(index, 1);
                }
            }
            for (let i = 0; i < this.hours.length; i++) {
                const hour = this.hours[i];
                if (hour !== undefined) {
                    draft.hours[i] = hour;
                }
            }
        });
    }
}

export class DifferenceMap extends Map<string, Difference> {
    getOrCreate(store: Store): Difference {
        if (this.has(store.nref)) {
            return this.get(store.nref)!;
        } else {
            const difference = new Difference(toJS(store));
            this.set(store.nref, difference);
            return difference;
        }
    }
}

class Field {
    index = -1;
    constructor(public readonly title: string) {}
}

class FileConfiguration {
    private innerMap = new Map<string | undefined, Field>();

    STORE_NUMBER = new Field('numéro magasin');
    ADDRESS = new Field('adresse');
    CITY = new Field('ville');
    ZIP_CODE = new Field('code postal');
    LATITUDE = new Field('latitude');
    LONGITUDE = new Field('longitude');
    PHONE = new Field('téléphone');
    STATUS = new Field('statut');
    SUNDAY = new Field('dimanche - ouverture');
    MONDAY = new Field('lundi - ouverture');
    TUESDAY = new Field('mardi - ouverture');
    WEDNESDAY = new Field('mercredi - ouverture');
    THURSDAY = new Field('jeudi - ouverture');
    FRIDAY = new Field('vendredi - ouverture');
    SATURDAY = new Field('samedi - ouverture');
    SERVICES = new Field('services');

    constructor() {
        Object.defineProperty(this, 'innerMap', {
            enumerable: false,
        });

        for (const field of Object.values(this)) {
            this.innerMap.set(normalize(field.title), field);
        }
    }

    setFieldIndexForFirstRow(row: string[]) {
        for (let i = 0; i < row.length; i++) {
            this.setFieldIndex(row[i], i);
        }
    }

    private setFieldIndex(title: string, index: number) {
        const field = this.innerMap.get(normalize(title));
        if (field && field.index === -1) {
            field.index = index;
        }
    }
}

export const getAddedStores = (
    existingList: Store[],
    parsedList: ParsedStore[]
) => {
    const addedStores = differenceBy<ParsedStore, Store>(
        parsedList,
        existingList,
        (s) => s.nref
    );

    return addedStores;
};

export const normalize = (title?: string) => {
    return title
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase()
        .replace(/-/g, '')
        .replace(/,/g, '')
        .replace(/\s/g, '');
};

const normalizeStreet = (street: string) => {
    const nStreet = street
        .toLocaleLowerCase()
        .replace(/\s(boulevard|boul\.|boul)\s/g, 'boulevard')
        .replace(/\s(avenue|av\.|av)\s/g, 'avenue')
        .replace(/\s(saint|st)\s/g, 'saint')
        .replace(/\s(saint|st)-/g, 'saint')
        .replace(/\srue\s/g, '')
        .replace(/\s(chemin|ch\.|ch)\s/g, 'chemin');

    return normalize(nStreet);
};

const normalizeCity = (city: string) => {
    const nCity = city
        .toLocaleLowerCase()
        .replace(/(saint|st)\s/g, 'saint')
        .replace(/(saint|st)-/g, 'saint');

    return normalize(nCity);
};

export const storeFilter = (filter?: string) => (store: Store) => {
    const normalizeFilter = normalize(filter) ?? '';
    return (
        !filter ||
        store.nref.includes(filter) ||
        normalize(store.addr.city ?? '')?.includes(normalizeFilter) ||
        normalize(store.addr.street ?? '')?.includes(normalizeFilter) ||
        normalize(store.addr.post ?? '')?.includes(normalizeFilter)
    );
};

export const diffStores = (
    existingList: Store[],
    parsedList: ParsedStore[]
) => {
    const mapOfExisting = new Map(existingList.map((e) => [e.nref, e]));
    const mapOfParsed = new Map(parsedList.map((p) => [p.nref, p]));

    const intersection = intersectionBy<BaseStore>(
        parsedList,
        existingList,
        (store) => store.nref
    );

    const diffMap = new DifferenceMap();
    for (const store of intersection) {
        const existing = mapOfExisting.get(store.nref);
        const parsed = mapOfParsed.get(store.nref);
        // Since we are in the intersection, we can assume that the store will exist
        diffStore(existing!, parsed!, diffMap);
    }

    return diffMap;
};

const isHoursFormat = (hours: string) => {
    return (
        /^[0-9,:,-]+$/.test(hours) &&
        hours.includes('-') &&
        hours.split('-')[0].length >= 4 &&
        hours.split('-')[1].length >= 4
    );
};

const isSameHours = (hoursA: string, hoursB: string) => {
    return (
        moment('1970-01-01 ' + hoursA).format('HH:mm') ===
        moment('1970-01-01 ' + hoursB).format('HH:mm')
    );
};

const diffStore = (
    existing: Store,
    parsed: ParsedStore,
    diffMap: DifferenceMap
) => {
    // Compare phone numbers
    const existingPhone = new PhoneNumber(existing.tel ?? '', 'CA');
    const parsedPhone = new PhoneNumber(parsed.tel, 'CA');
    if (existingPhone.getNumber() !== parsedPhone.getNumber()) {
        diffMap.getOrCreate(existing).phoneNumber = parsed.tel;
    }

    // Compare hours
    for (let i = 0; i < 7; i++) {
        const existingHour = existing.hours[i];
        const parsedHour = parsed.hours[i];

        if (existingHour != null && parsedHour != null) {
            if (isHoursFormat(existingHour) && isHoursFormat(parsedHour)) {
                const existingHourStart = existingHour.split('-')[0];
                const parsedHourStart = parsedHour.split('-')[0];
                const existingHourEnd = existingHour.split('-')[1];
                const parsedHourEnd = parsedHour.split('-')[1];

                if (
                    isSameHours(existingHourStart, parsedHourStart) &&
                    isSameHours(existingHourEnd, parsedHourEnd)
                ) {
                    continue;
                }
            }

            if (existingHour !== parsedHour) {
                diffMap.getOrCreate(existing).hours[i] = parsedHour;
            }
        }
    }

    // Compare city
    if (
        normalizeCity(existing.addr.city ?? '') !==
        normalizeCity(parsed.addr.city)
    ) {
        diffMap.getOrCreate(existing).city = parsed.addr.city;
    }

    // Compare zip
    if (normalize(existing.addr.post ?? '') !== normalize(parsed.addr.post)) {
        diffMap.getOrCreate(existing).post = parsed.addr.post;
    }

    // Compare street
    if (
        normalizeStreet(existing.addr.street ?? '') !==
        normalizeStreet(parsed.addr.street)
    ) {
        diffMap.getOrCreate(existing).street = parsed.addr.street;
    }

    // Compare status
    if (existing.status !== parsed.status) {
        diffMap.getOrCreate(existing).status = parsed.status;
    }

    // Compare lat
    if (existing.lat !== parsed.lat && parsed.lat !== 0) {
        diffMap.getOrCreate(existing).lat = parsed.lat;
    }

    // Compare lng
    if (existing.lng !== parsed.lng && parsed.lng !== 0) {
        diffMap.getOrCreate(existing).lng = parsed.lng;
    }

    // Compare services
    const removedServices = difference(existing.services, parsed.services);
    const addedServices = difference(parsed.services, existing.services);

    if (removedServices.length > 0) {
        diffMap.getOrCreate(existing).removedServices = removedServices;
    }

    if (addedServices.length > 0) {
        diffMap.getOrCreate(existing).addedServices = addedServices;
    }
};

// Helper method to extract the hours
function getOpeningHoursFactory(status: Field, row: string[]) {
    return function (field: Field) {
        const is24 =
            normalize(row[status.index]) === normalize(FrenchStatus['open-24']);
        const isClosed =
            normalize(row[status.index]) === normalize(FrenchStatus['closed']);
        if (is24) {
            return '0:00-0:00';
        }
        if (isClosed) {
            return '';
        }
        if (row[field.index] === '') {
            return '';
        }
        // The below if should be eventually deleted when the data is normalized (i.e.: data is either in a XX:XX format or empty string)
        if (normalize(row[field.index]) === normalize(FrenchStatus['closed'])) {
            return '';
        }
        return `${row[field.index]}-${row[field.index + 1]}`;
    };
}

// Helper method to extract the service using closure to access the row
function valueExtractorFactory(row: string[]) {
    return function (field: Field, map: { [key: string]: string }) {
        const normalizedMap = new Map<string | undefined, string>();
        for (const [key, value] of Object.entries(map)) {
            normalizedMap.set(normalize(key), value);
        }

        const values = row[field.index].split(',').map((s) => normalize(s));
        const extractedValues = values
            .map((value) => normalizedMap.get(value))
            .filter((v): v is string => !!v);
        return extractedValues;
    };
}

export const parseStoreFile = (data: string[][]): ParsedStore[] => {
    const configuration = new FileConfiguration();
    const stores: ParsedStore[] = [];
    for (let i = 0; i < data.length; i++) {
        const row = data[i];
        const id = i + 1;
        if (i < 1) {
            configuration.setFieldIndexForFirstRow(row);
        } else {
            const nref = row[configuration.STORE_NUMBER.index];

            if (!nref) {
                break;
            }

            // Create the store
            const getOpeningHours = getOpeningHoursFactory(
                configuration.STATUS,
                row
            );
            const valueExtractor = valueExtractorFactory(row);

            const is24 =
                normalize(row[configuration.STATUS.index]) ===
                normalize(FrenchStatus['open-24']);

            const store: ParsedStore = {
                id,
                nref,
                addr: {
                    city: row[configuration.CITY.index],
                    post: row[configuration.ZIP_CODE.index],
                    street: row[configuration.ADDRESS.index],
                },
                lat: Number(row[configuration.LATITUDE.index]),
                lng: Number(row[configuration.LONGITUDE.index]),
                tel: row[configuration.PHONE.index],
                status:
                    valueExtractor(configuration.STATUS, {
                        Ouvert: StoreStatuskeys.OPEN,
                        'Ouvert 24h': StoreStatuskeys.OPEN_24,
                        'Fermé temporairement':
                            StoreStatuskeys.TEMPORARILY_CLOSED,
                        Fermé: StoreStatuskeys.CLOSED,
                        'Ouverture prochainement': StoreStatuskeys.COMING_SOON,
                    }).toString() ?? 'open',
                hours: [
                    getOpeningHours(configuration.SUNDAY),
                    getOpeningHours(configuration.MONDAY),
                    getOpeningHours(configuration.TUESDAY),
                    getOpeningHours(configuration.WEDNESDAY),
                    getOpeningHours(configuration.THURSDAY),
                    getOpeningHours(configuration.FRIDAY),
                    getOpeningHours(configuration.SATURDAY),
                ],
                services: [
                    is24 ? 'horaire-detaillant_24' : undefined,
                    ...valueExtractor(configuration.SERVICES, invertedServices),
                ].filter((s): s is string => !!s),
            };
            stores.push(store);
        }
    }
    return stores;
};

export const services = {
    'service-coffee-club': 'Café club',
    'service-guichet': 'Guichet automatique',
    'service-poste_ts': 'Vente de timbres postaux',
    'service-guichet_rbc': 'Guichet automatique RBC',
    'service-guichet_ret': 'Guichet automatique retrait seulement',
    'serv-repaschaud': 'Grillades',
    'service-propane': 'Propane',
    'service-propane_exc': 'Propane échange',
    'horaire-detaillant_24': 'Ouvert 24 heures',
    'service-guichet_mnvie': 'Guichet automatique Manuvie',
    'service-toilette': 'Toilette accessible à la clientèle',
    'essence-any': 'Essence',
    'essence-esso': 'Esso',
    'serv-polarpop': 'Polar Pop',
    'service-propane_refill': 'Propane remplissage',
    'service-guichet_cibc': 'Guichet automatique CIBC',
    'essence-ultramar': 'Ultramar',
    'service-guichet_scotia': 'Guichet automatique Scotia',
    diesel: 'Diésel',
    'essence-couche_tard': 'Essence Couche-Tard',
    'service-guichet_bnc': 'Guichet automatique Banque Nationale',
    'service-guichet_multi': 'Guichet automatique multiservices',
    'service-lave_auto': 'Lave-auto',
    'service-lave_auto_auto': 'Lave-auto automatique',
    'service-lave_auto_program': 'Abonnement Lave-auto',
    'essence-petrocanada': 'Petro-Canada',
    'service-poste': 'Bureau de poste',
    'resto-any': 'Restaurant',
    'resto-buffet': 'Buffet',
    'resto-foire_alimentaire': 'Foire alimentaire',
    'resto-mm': 'M&M',
    'resto-tim_hortons': 'Tim Hortons',
    'service-lave_auto_manuel': 'Lave-auto à la main',
    'resto-coq_lala': 'Coq Lala',
    'resto-mcdonalds': 'McDonalds',
    'resto-sushi_shop': 'Sushi Shop',
    'service-messagerie_pc': 'Service de colis / messagerie',
    'essence-petro_t': 'Pétro-T',
    'essence-irving': 'Irving',
    'resto-big_stop': 'Big Stop',
    'resto-restaurant_sans_banniere': 'Restaurant sans bannière',
    'resto-epicerie_fine_des_chutes': 'Épicerie fine des Chutes',
    'resto-subway': 'Subway',
    'service-service_pompe': 'Service aux pompes',
    'service-guichet_multi_nd':
        'Guichet automatique multiservices (sauf dépôt)',
    'service-guichet_desjardins': 'Guichet automatique Desjardins',
    'essence-shell': 'Shell',
    'resto-regal_des_chutes': 'Régal des Chutes',
    'resto-la_belle_province': 'La belle province',
    'resto-mikes': 'Mikes',
    'resto-pfk': 'PFK',
    'resto-poulet_chester': 'Poulet Chester',
    'essence-sonic': 'Sonic',
    'resto-quiznos': 'Quiznos',
    'resto-presse_cafe': 'Presse Café',
    'resto-saint_hubert': 'Saint Hubert',
    'resto-aw': 'A&W',
    'resto-valentine': 'Valentine',
    'resto-ottario': 'Ottario',
} as const;

export const invertedServices = Object.fromEntries(
    Object.entries(services).map(([key, value]) => [value, key])
);

export const batchStoreStatusOptions = {
    'open-24': 'Open 24',
    open: 'open',
    closed: 'closed',
};

export function getWeekdayTagColor(label: string) {
    switch (label) {
        case 'Monday':
            return 'cyan';
        case 'Tuesday':
            return 'orange';
        case 'Wednesday':
            return 'geekblue';
        case 'Thursday':
            return 'red';
        case 'Friday':
            return 'purple';
        case 'Saturday':
        case 'Sunday':
            return 'green';
        default:
            return 'default';
    }
}

export const WEEKDAYS = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

const WEEKDAYS_FRENCH = [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
];

const StoreStatuskeys = {
    OPEN_24: 'open-24',
    OPEN: 'open',
    CLOSED: 'closed',
    TEMPORARILY_CLOSED: 'temporarily-closed',
    COMING_SOON: 'coming-soon',
} as const;

const FrenchStatus = {
    'open-24': 'Ouvert 24h',
    open: 'Ouvert',
    closed: 'Fermé',
    'temporarily-closed': 'Fermé temporairement',
    'coming-soon': 'Ouverture prochainement',
} as const;

function englishStatusToFrench(status: string) {
    if (status in FrenchStatus) {
        return FrenchStatus[status as keyof typeof FrenchStatus];
    }
    return status;
}

function serviceMapper(service: string) {
    if (service in services) {
        return services[service as keyof typeof services];
    }
    return service;
}

export const storesToJson = (stores: Store[]) => {
    const data = stores.map((store) => {
        const hoursData: { [key: string]: string } = {};

        WEEKDAYS_FRENCH.forEach((day, index) => {
            const hoursOfTheDay = store.hours[index];
            if (hoursOfTheDay) {
                const [open, close] = hoursOfTheDay.split('-');
                hoursData[`${day} - ouverture`] = open;
                hoursData[`${day} - fermeture`] = close;
            } else {
                hoursData[`${day} - ouverture`] = '';
                hoursData[`${day} - fermeture`] = '';
            }
        });

        return {
            'numéro magasin': store.nref,
            adresse: store.addr.street || '',
            ville: store.addr.city || '',
            'code postal': store.addr.post || '',
            latitude: store.lat || '',
            longitude: store.lng || '',
            téléphone: store.tel || '',
            statut: englishStatusToFrench(store.status),
            ...hoursData,
            services: store.services.map(serviceMapper).join(', '),
        };
    });

    return data;
};
