import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import { PER_PAGE } from '../../utils/const';

interface Props {
    className?: string;
    params: {
        page?: number;
        perPage?: number;
    };
    meta: {
        perPage: number;
        total: number;
    } | null;
    onChange: (pageNumber: number) => void;
    onPageSizeChange: (pageNumber: number) => void;
}

export const LegacyPagination = (props: Props) => {
    const { className, params, meta, onChange, onPageSizeChange } = props;
    return (
        <AntdPagination
            className={className}
            current={params.page ? params.page + 1 : 1}
            pageSize={params.perPage ?? meta?.perPage ?? PER_PAGE}
            total={meta?.total}
            onChange={(pageNumber) => onChange(pageNumber - 1)}
            defaultPageSize={PER_PAGE}
            onShowSizeChange={(_, size) => onPageSizeChange(size)}
        />
    );
};
