import React from 'react';
import { CompositeDecorator } from 'draft-js';
import { Popover } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import EventEmitter from 'events';
import style from './style.module.scss';

const RichContext = React.createContext<EventEmitter>(new EventEmitter());

export const RichProvider = (props: { children: JSX.Element }) => {
    const [event] = React.useState(new EventEmitter());

    return (
        <RichContext.Provider value={event}>
            {props.children}
        </RichContext.Provider>
    );
};

export const useDispatcher = () => {
    const event = React.useContext(RichContext);
    return (key: string) => {
        event.emit('remove', key);
    };
};

export const useListener = (fn: (key: string) => void) => {
    const event = React.useContext(RichContext);
    React.useEffect(() => {
        event.on('remove', fn);
        return () => void event.removeListener('remove', fn);
    }, [fn]);
};

const Link = (props: any) => {
    const entity = props.contentState.getEntity(props.entityKey);
    const dispatch = useDispatcher();
    const url = entity.data.href || entity.data.url;

    const content = (
        <div>
            <b>{url}</b>
            <DeleteOutlined
                className={style.remove_icon}
                onClick={() => dispatch(props.entityKey)}
            />
        </div>
    );

    return (
        <Popover content={content} title={null} trigger="hover">
            <span className="inline-link">{props.children}</span>
        </Popover>
    );
};

export const linkDecorator = new CompositeDecorator([
    {
        strategy: (contentBlock, callback, contentState) => {
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity();
                return (
                    entityKey !== null &&
                    contentState.getEntity(entityKey).getType() === 'LINK'
                );
            }, callback);
        },
        component: Link,
    },
]);
