import React from 'react';
import { InputProps } from 'antd/lib/input';
import { SearchInput } from '../search-input';

export const DebounceInput = ({
    debounce,
    onStableChange,
    onChange,
    ...props
}: InputProps & {
    debounce: number;
    onStableChange: (val: string) => void;
}) => {
    const timerRef = React.useRef<number | null>(null);
    const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        onChange?.(event);
        if (timerRef.current !== null) {
            window.clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            onStableChange(value);
        }, debounce);
    };
    React.useEffect(() => {
        return () => {
            if (timerRef.current !== null) {
                window.clearTimeout(timerRef.current);
            }
        };
    }, []);
    return <SearchInput onChange={onChangeWrapper} />;
};
