import React from 'react';
import { AcvtSupportedLanguages } from '../../../types/acvt';
import { AcvtGenHeader } from '../generic-header';
import { AcvtTopTabs } from '../top-tabs';
import { AcvtCouponsPage } from './coupons-page';
import { AcvtInStorePage } from './instore-page';
import style from './style.module.scss';

export const AcvtPromotionsPage = (props: {
    language?: AcvtSupportedLanguages;
    offersList?: (string | undefined)[];
    couponCategoriesList?: (string | undefined | null)[];
    inStoreCategoriesList?: (string | undefined | null)[];
    couponCards: JSX.Element;
    inStoreCards: JSX.Element;
    selectedOffer: string | undefined;
    selectedCouponCategory: string | undefined;
    selectedInStoreCategory: string | undefined;
    changeOffers: (selection: string) => void;
    changeCouponCategories: (selection: string) => void;
    changeInStoreCategories: (selection: string) => void;
}) => {
    return (
        <>
            <div className={style.promotions_content}>
                <AcvtGenHeader>Promotions</AcvtGenHeader>
                {props.language === 'en' && (
                    <AcvtTopTabs
                        tabsArray={[
                            {
                                name: 'Coupons',
                                key: 'coupons_en',
                                children: (
                                    <AcvtCouponsPage
                                        key={'coupon_en'}
                                        language={props.language}
                                        cards={props.couponCards}
                                        offersList={props.offersList}
                                        categoriesList={
                                            props.couponCategoriesList
                                        }
                                        selectedOffer={props.selectedOffer}
                                        selectedCategory={
                                            props.selectedCouponCategory
                                        }
                                        changeOffers={props.changeOffers}
                                        changeCategories={
                                            props.changeCouponCategories
                                        }
                                    />
                                ),
                            },
                            {
                                name: 'In Store',
                                key: 'instore_en',
                                children: (
                                    <AcvtInStorePage
                                        key={'instore_en'}
                                        language={props.language}
                                        cards={props.inStoreCards}
                                        categoriesList={
                                            props.inStoreCategoriesList
                                        }
                                        selectedCategory={
                                            props.selectedInStoreCategory
                                        }
                                        changeCategories={
                                            props.changeInStoreCategories
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                )}
                {props.language === 'fr' && (
                    <AcvtTopTabs
                        tabsArray={[
                            {
                                name: 'Coupons',
                                key: 'coupons_fr',
                                children: (
                                    <AcvtCouponsPage
                                        key={'coupon_fr'}
                                        cards={props.couponCards}
                                        offersList={props.offersList}
                                        categoriesList={
                                            props.couponCategoriesList
                                        }
                                        selectedOffer={props.selectedOffer}
                                        selectedCategory={
                                            props.selectedCouponCategory
                                        }
                                        changeOffers={props.changeOffers}
                                        changeCategories={
                                            props.changeCouponCategories
                                        }
                                    />
                                ),
                            },
                            {
                                name: 'En Magasin',
                                key: 'instore_fr',
                                children: (
                                    <AcvtInStorePage
                                        key={'instore_en'}
                                        cards={props.inStoreCards}
                                        categoriesList={
                                            props.inStoreCategoriesList
                                        }
                                        selectedCategory={
                                            props.selectedInStoreCategory
                                        }
                                        changeCategories={
                                            props.changeInStoreCategories
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                )}
            </div>
        </>
    );
};
