import React from 'react';
import { Modal, Spin } from 'antd';

interface ValidateClientEmailModalProps {
    isVisible: boolean;
    clientId: number | null | undefined;
    clientEmail: string | null | undefined;
    isSending: boolean;
    onValidate: (id: number) => void;
    onClose: () => void;
}

const ValidateClientEmailModal: React.FC<ValidateClientEmailModalProps> = ({
    isVisible,
    clientId,
    clientEmail,
    isSending,
    onValidate,
    onClose,
}) => {
    return (
        <Modal
            title="Validate Client Email"
            visible={isVisible}
            onOk={() => {
                if (clientId) {
                    onValidate(clientId);
                }
                onClose();
            }}
            onCancel={onClose}
        >
            {isSending ? (
                <Spin />
            ) : (
                <p>
                    This action will send an email validation email to{' '}
                    {clientEmail}, are you sure you want to continue?
                </p>
            )}
        </Modal>
    );
};

export default ValidateClientEmailModal;
