import React from 'react';
import style from './style.module.scss';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';
import { Store, StoresStore } from '../../stores/stores';
import { observer } from 'mobx-react';
import { Spin, Tag } from 'antd';
import { StoreTag } from '../store-tag';
import { getWeekdayTagColor, WEEKDAYS } from '../../helpers/store-helper';

type StoreBatchConfirmProps = {
    onCloseClick: () => void;
    modifications: Store[];
    store: StoresStore;
    goBack: () => void;
    onConfirm: (actionWorked: boolean) => void;
};

type Weekday = typeof WEEKDAYS[number];
type HoursMap = { [key: string]: Weekday[] };

function groupDaysByHours(hours: (string | null | undefined)[]): HoursMap {
    const hoursMap: HoursMap = {};

    hours.forEach((hour, index) => {
        if (hour) {
            const day = WEEKDAYS[index];
            if (!hoursMap[hour]) {
                hoursMap[hour] = [];
            }
            hoursMap[hour].push(day);
        }
    });
    return hoursMap;
}

export const StoreBatchConfirm = observer(
    ({
        onCloseClick,
        modifications,
        store,
        goBack,
        onConfirm,
    }: StoreBatchConfirmProps) => {
        const [isLoading, setIsLoading] = React.useState(false);

        const saveChanges = async () => {
            setIsLoading(true);

            const payload: { additions: Store[]; modifications: Store[] } = {
                additions: [],
                modifications: modifications,
            };
            const actionWorked = await store.uploadChanges(payload);
            setIsLoading(false);
            onConfirm(actionWorked);
        };

        const modifiedHours = modifications[0].hours;
        const modifiedServices = modifications[0].services;
        const modifiedStatus = modifications[0].status;

        return (
            <>
                <div className={style.container}>
                    <div className={style.content}>
                        <div className={style.close} onClick={onCloseClick}>
                            <CloseIcon />
                        </div>
                        <div className={style.title}>
                            {`You are about to change the following ${modifications.length} stores:`}
                        </div>
                        <div className={style.modified_stores}>
                            {modifications.map((store) => (
                                <div key={store.nref} className={style.store}>
                                    <span className={style.store_nref}>
                                        {store.nref}
                                    </span>
                                    <span className={style.store_address}>
                                        {store.addr.street}, {store.addr.city},{' '}
                                        {store.addr.post}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className={style.title}>
                            {`To new information:`}
                        </div>
                        <div className={style.store_info}>
                            <div className={style.single_info}>
                                <b>Status: </b>
                                <div>
                                    <StoreTag status={modifiedStatus} />
                                </div>
                            </div>
                            <div className={style.single_info}>
                                <b>Opening hours:</b>
                                <div>
                                    {Object.entries(
                                        groupDaysByHours(modifiedHours)
                                    ).map(([hour, days]) => (
                                        <div key={hour}>
                                            {days.map((day) => (
                                                <Tag
                                                    color={getWeekdayTagColor(
                                                        day
                                                    )}
                                                    key={day}
                                                >
                                                    {day}
                                                </Tag>
                                            ))}
                                            <div className={style.open_hours}>
                                                Open between {hour}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={style.single_info}>
                                <b>Services:</b>
                                <div>
                                    {modifiedServices.map((service) => (
                                        <Tag key={service}>{service}</Tag>
                                    ))}
                                </div>
                            </div>
                            <div className={style.save_buttons}>
                                <button
                                    className={style.reset_button}
                                    onClick={goBack}
                                >
                                    Go back
                                </button>
                                <button
                                    className={style.save_button}
                                    onClick={saveChanges}
                                >
                                    {isLoading ? <Spin /> : 'Confirm'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);
