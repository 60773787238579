import React from 'react';
import style from './style.module.scss';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Table, Tag, Select, Input, Button } from 'antd';
import { useAuthStore, useGamesStore } from '../../contexts/mobx';
import { ColumnProps } from 'antd/es/table';
import {
    getGamesTypeDisplay,
    getGamesLocaleItem,
    getGamesTypeName,
} from '../../utils/games';
import { Game, ExternalGame, SportGame, GameType } from '../../types/games';
import { useNavigation } from '../../utils/use-navigation';
import { useQueryParams } from '../../utils/use-query-params';
import { GamesSearchParams } from '../../stores/games';

const renderIsActive = (_: unknown, record: ExternalGame | SportGame) => {
    return (
        <div>
            {record.status === 'active' ? (
                <Tag color="#87d068">Active</Tag>
            ) : (
                <Tag color="#ee1313">Inactive</Tag>
            )}
        </div>
    );
};

const renderThumbnail = (_: unknown, record: ExternalGame | SportGame) => {
    const enImgSrc = getGamesLocaleItem(record.image, 'en');
    return <img key="en" src={enImgSrc ?? ''} alt="" className={'thumbnail'} />;
};

const renderTitle = (_: unknown, record: ExternalGame | SportGame) => {
    const enTitle = getGamesLocaleItem(record.title, 'en');
    return <div className={style.title}>{enTitle}</div>;
};

const renderType = (_: unknown, record: ExternalGame | SportGame) => {
    return getGamesTypeDisplay(record.type);
};

const renderDates = (_: unknown, record: ExternalGame | SportGame) => {
    return (
        <div className="dates_holder">
            <div className="text_center inline_block">
                <div className="light_label">
                    Start
                    <div className="vr"></div>
                </div>
                <div>{moment(record.startDate).format('ll')}</div>
                <div className="lil_label">
                    {moment(record.startDate).format('LT')}
                </div>
            </div>
            {record.endDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        End
                        <div className="vr"></div>
                    </div>
                    <div>{moment(record.endDate).format('ll')}</div>
                    <div className="lil_label">
                        {moment(record.endDate).format('LT')}
                    </div>
                </div>
            )}
        </div>
    );
};

const columns: ColumnProps<Game>[] = [
    {
        title: 'Status',
        key: 'is_active',
        render: renderIsActive,
        defaultSortOrder: 'ascend',
        sorter: {
            compare: (a, b) => {
                return a.status.localeCompare(b.status);
            },
            multiple: 2,
        },
    },
    {
        key: 'image',
        render: renderThumbnail,
    },
    {
        title: 'Title',
        key: 'title',
        render: renderTitle,
    },
    {
        title: 'Type',
        key: 'type',
        render: renderType,
    },
    {
        title: 'Dates',
        key: 'dates',
        render: renderDates,
        defaultSortOrder: 'descend',
        sorter: {
            compare: (a, b) =>
                moment(a.endDate).unix() - moment(b.endDate).unix(),
            multiple: 1,
        },
    },
];

export const GamesPage = observer(() => {
    const gamesStore = useGamesStore();
    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const [searchParams, updateSearchParams] = useQueryParams<
        GamesSearchParams
    >();

    React.useEffect(() => {
        gamesStore.fetchGames(searchParams);
        gamesStore.filterGames(searchParams);
    }, [gamesStore]);

    React.useEffect(() => {
        gamesStore.filterGames(searchParams);
    }, [searchParams]);

    const clearFilters = () => {
        updateSearchParams({
            status: undefined,
            title: undefined,
            type: undefined,
        });
    };

    return (
        <div>
            <h1>Games</h1>

            <div className={'buttons_holder'}>
                <Button onClick={() => navigation.goToGameCreate('external')}>
                    Create new external game
                </Button>

                <Button onClick={() => navigation.goToGameCreate('sport')}>
                    Create new sport game
                </Button>
            </div>
            <div className={`${style.selects_row} top_spacer`}>
                <Input
                    value={searchParams.title}
                    onChange={(e) =>
                        updateSearchParams({ title: e.target.value })
                    }
                    allowClear={true}
                    placeholder="Search"
                />
                <Select
                    placeholder="Type"
                    value={searchParams.type}
                    onChange={(v) => updateSearchParams({ type: v })}
                    allowClear
                >
                    <Select.Option value={GameType[0]}>
                        {getGamesTypeName(GameType[0])}
                    </Select.Option>
                    <Select.Option value={GameType[1]}>
                        {getGamesTypeName(GameType[1])}
                    </Select.Option>
                    <Select.Option value={GameType[2]}>
                        {getGamesTypeName(GameType[2])}
                    </Select.Option>
                </Select>
                <Select<string>
                    placeholder="Status"
                    value={searchParams.status}
                    onChange={(v) => updateSearchParams({ status: v })}
                    allowClear
                >
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                </Select>
                <Button type="primary" onClick={() => clearFilters()}>
                    Clear Filters
                </Button>
            </div>

            <Table<Game>
                className={`${style.table} clickable_table`}
                rowKey="id"
                dataSource={gamesStore.filteredGames ?? undefined}
                columns={columns}
                pagination={false}
                loading={gamesStore.isFetchingCount > 0}
                onRow={(r) => ({
                    onClick: () => navigation.goToGameEdit(r.id, r.type),
                })}
            />
        </div>
    );
});
