import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import { Avatar, Select, Spin, Table } from 'antd';
import { useAuthStore, useNhlGamesStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { useNavigation } from '../../utils/use-navigation';
import { LeaderboardSearchParams } from '../../stores/nhl-game';
import moment from 'moment';
import {
    ImageHolder,
    NotificationManager,
    PageHeader,
    Pagination,
} from '../../components';
import { ColumnProps } from 'antd/lib/table';
import { Leaderboard } from '../../types/nhl-game';
import { coucheTardRed } from '../../utils/const';
import { Text } from '../../utils/text';

export const NhlGamesLeaderboardPage = observer(() => {
    const store = useNhlGamesStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const columns = React.useMemo<ColumnProps<Leaderboard>[]>(
        () => [
            {
                title: Text.nhlGame.leaderBoardContainer.table.id,
                dataIndex: 'userId',
                key: 'id',
                width: '100px',
                ellipsis: true,
                render: (_: unknown, record: Leaderboard) => (
                    <span>{record.userId}</span>
                ),
            },
            {
                title: Text.nhlGame.leaderBoardContainer.table.name,
                key: 'name',
                ellipsis: true,
                render: (_: unknown, record: Leaderboard) => (
                    <>
                        <Avatar
                            style={{
                                verticalAlign: 'middle',
                                backgroundColor: coucheTardRed,
                            }}
                            size={30}
                        >
                            {record.firstName?.charAt(0).toLocaleUpperCase()}
                            {record.lastName?.charAt(0).toLocaleUpperCase()}
                        </Avatar>

                        <span className={style.name}>
                            {record.firstName} {record.lastName}
                        </span>
                    </>
                ),
            },
            {
                title: Text.nhlGame.leaderBoardContainer.table.email,
                dataIndex: 'email',
                key: 'email',
                ellipsis: true,
                render: (_: unknown, record: Leaderboard) => (
                    <span>{record.email}</span>
                ),
            },
            {
                title: Text.nhlGame.leaderBoardContainer.table.totalPoints,
                key: 'totalPoints',
                ellipsis: true,
                render: (_, record: Leaderboard) => (
                    <span>{record.totalPoints}</span>
                ),
            },
            {
                title: Text.nhlGame.leaderBoardContainer.table.registration,
                key: 'registration',
                ellipsis: true,
                render: (_, record: Leaderboard) => (
                    <span>{moment(record.createdAt).format('LLL')}</span>
                ),
            },
        ],
        []
    );

    const [queryParams, updateQueryParams] = useQueryParams<
        LeaderboardSearchParams
    >();

    React.useEffect(() => {
        store.getValidNhlGames();
        if (queryParams.nhlGameId) store.getNhlGameLeaderboard(queryParams);
    }, [store, queryParams]);

    const fetchLeaderboard = async (nhlGameId: number) => {
        try {
            queryParams.nhlGameId = nhlGameId;
            store.getNhlGameLeaderboard(queryParams);
        } catch (error) {
            NotificationManager.showError(
                Text.notificationManager.nhlGame.fetchLeaderboard
            );
        }
    };

    return (
        <div className={style.page_content}>
            <PageHeader title={Text.nhlGame.leaderBoardContainer.pageTitle} />
            <div className={style.table_header}>
                <div className={style.query_box}>
                    <Select
                        loading={store.isFetchingValidList}
                        onChange={(v) => {
                            updateQueryParams({ nhlGameId: Number(v) });
                            fetchLeaderboard(Number(v));
                        }}
                        className={style.config_field}
                        optionLabelProp="label"
                    >
                        {store.leaderboardList?.results.map((game) => (
                            <Select.Option
                                key={game.id}
                                value={game.id}
                                label={`#${game.id} ${game.title.en}`}
                            >
                                <div className={style.custom_option}>
                                    <div>#{game.id}</div>
                                    <div className={style.titles_holder}>
                                        <span>{game.title.en}</span>
                                        <span>{game.title.fr}</span>
                                    </div>
                                    <ImageHolder
                                        src={game.image.en}
                                        size={40}
                                    />
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={style.pagination_container}>
                    <Pagination
                        className={style.pagination}
                        params={queryParams}
                        meta={store.leaderboard}
                        onChange={(pageNumber) =>
                            updateQueryParams({ page: pageNumber })
                        }
                        onPageSizeChange={(size) =>
                            updateQueryParams({ perPage: size })
                        }
                    />
                </div>
            </div>

            <div className={style.layout_section}>
                <div className={style.input_row}>
                    {store.isFetchingLeaderboard ? (
                        <Spin />
                    ) : store.leaderboard?.result ? (
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={store.leaderboard?.result || []}
                            loading={store.isFetchingLeaderboard}
                            bordered
                            pagination={false}
                        ></Table>
                    ) : (
                        <div className={style.no_game_selected}>
                            <p>
                                {
                                    Text.nhlGame.leaderBoardContainer
                                        .noGameSelected
                                }
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
