import React from 'react';
import { Tag } from 'antd';
import { GameType } from '../types/games';
import { NullableTranslationMap, SupportedLanguage } from '../types';

export const getGamesTypeDisplay = (type: GameType) => {
    if (type === 'external') {
        return <Tag color="volcano">{getGamesTypeName(type)}</Tag>;
    }
    if (type === 'sport') {
        return <Tag color="gold">{getGamesTypeName(type)}</Tag>;
    }
};

export const getGamesTypeName = (type: GameType) => {
    if (type === 'external') {
        return 'External';
    }
    if (type === 'sport') {
        return 'Sport';
    }
    if (type === 'default') {
        return 'Default';
    }
};

export const getGamesLocaleItem = (
    items: NullableTranslationMap,
    lang: SupportedLanguage
) => {
    return items[lang];
};
