import { Select, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import style from './style.module.scss';
import { RangeValue } from 'rc-picker/lib/interface';
import { AnalyticsCompareOptions } from '../../utils/const';

const { RangePicker } = DatePicker;

interface Props {
    changeSearchPeriod: (values: AnalyticsCompareOptions) => void;
    searchPeriod: AnalyticsCompareOptions;
    compareStartDate: string | undefined;
    compareEndDate: string | undefined;
    endDate: string | undefined;
    format: string;
    updateParams: (dates: RangeValue<Moment>) => void;
}

export const AnalyticsCompareDates = (props: Props) => {
    return (
        <>
            <Select
                className={style.select_filter}
                defaultValue={props.searchPeriod}
                onChange={props.changeSearchPeriod}
            >
                <Select.Option value="preceding">
                    Preceding period
                </Select.Option>
                <Select.Option value="yearly">Same as last year</Select.Option>
                <Select.Option value="custom">Custom</Select.Option>
            </Select>
            {props.searchPeriod === 'custom' && (
                <RangePicker
                    className={style.filters}
                    allowEmpty={[true, true]}
                    placeholder={['Start at', 'End at']}
                    defaultValue={[
                        props.compareStartDate
                            ? moment(props.compareStartDate)
                            : null,
                        props.endDate ? moment(props.compareEndDate) : null,
                    ]}
                    value={[
                        props.compareStartDate
                            ? moment(props.compareStartDate)
                            : null,
                        props.endDate ? moment(props.compareEndDate) : null,
                    ]}
                    onCalendarChange={(dates) => {
                        props.updateParams(dates);
                    }}
                    format={props.format}
                />
            )}
        </>
    );
};
