import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './root';
import { NotificationManager } from '../components';
import { HttpMethod } from '../types';

interface StoreSummary {
    nref: string;
    createdAt: string;
    deletedAt: string | null;
}

interface CarWashStoreCms {
    contactlessOnly: StoreSummary[];
    acceptUltimateWash: StoreSummary[];
    softTouchOnly: StoreSummary[];
}

export class CarWashStore {
    @observable carwashData: CarWashStoreCms | null = null;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchCarWashData() {
        const response = await this.rootStore.makeNetworkCall<CarWashStoreCms>({
            method: HttpMethod.GET,
            url: '/stores/carwash/data',
        });

        if (response.data) {
            this.carwashData = response.data;
        }
    }

    @action
    async addSoftTouchCarWash(nref: string) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: `/stores/carwash/soft-touch-store/${nref}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Soft touch carwash data added.');
            this.fetchCarWashData();
        }
    }

    @action
    async addContactlessCarWash(nref: string) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: `/stores/carwash/contactless-store/${nref}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Contactless carwash data added.');
            this.fetchCarWashData();
        }
    }

    @action
    async addUltimateCarWash(nref: string) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: `/stores/carwash/ultimate-store/${nref}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Ultimate carwash data added.');
            this.fetchCarWashData();
        }
    }

    @action
    async deleteSoftTouchCarWash(nref: string) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/stores/carwash/soft-touch-store/${nref}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Soft touch carwash data deleted.');
            this.fetchCarWashData();
        }
    }

    @action
    async deleteContactlessCarWash(nref: string) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/stores/carwash/contactless-store/${nref}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess(
                'Contactless carwash data deleted.'
            );
            this.fetchCarWashData();
        }
    }

    @action
    async deleteUltimateCarWash(nref: string) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/stores/carwash/ultimate-store/${nref}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Ultimate carwash data deleted.');
            this.fetchCarWashData();
        }
    }
}
