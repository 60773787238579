import React from 'react';
import { observer } from 'mobx-react';
import { ReactSortable } from 'react-sortablejs';
import { Button, Spin, Tag } from 'antd';
import style from './style.module.scss';
import {
    useAuthStore,
    useFeaturedStore,
    usePromotionStore,
} from '../../contexts/mobx';
import { ImageHolder } from '../../components';
import { getItemProp } from '../../utils';
import { FeatureTypeColors } from '../../utils/const';
import { useNavigation } from '../../utils/use-navigation';

export const VisibleFeaturedPage = observer(() => {
    const featuredStore = useFeaturedStore();
    const promotionStore = usePromotionStore();

    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    React.useEffect(() => {
        featuredStore.getVisibleList();
    }, [featuredStore]);

    React.useEffect(() => {
        promotionStore.fetchVisiblePromotions();
    }, [promotionStore]);

    return (
        <div>
            <h1>Visible Featured</h1>

            {featuredStore.isFetchingVisibleList &&
                promotionStore.isFetchingVisiblePromotions && <Spin />}
            {!(
                featuredStore.isFetchingVisibleList &&
                promotionStore.isFetchingVisiblePromotions
            ) && (
                <>
                    <div className={style.container}>
                        <div
                            className={`${style.featured} ${style.columns_styling}`}
                        >
                            <ReactSortable
                                list={featuredStore.visibleList}
                                setList={(newState) =>
                                    featuredStore.setVisibleList(newState)
                                }
                                direction="horizontal"
                            >
                                {featuredStore.visibleList.map((featured) => (
                                    <div
                                        className={style.featured_item}
                                        key={featured.id}
                                    >
                                        {/* Text title */}
                                        <div className={style.title_holder}>
                                            <span>
                                                {featured.items[0].textTitle ||
                                                    '--'}
                                            </span>
                                            <span>
                                                {featured.items[1].textTitle ||
                                                    '--'}
                                            </span>
                                        </div>
                                        {/* Type */}
                                        <Tag
                                            color={
                                                FeatureTypeColors[featured.type]
                                            }
                                        >
                                            {featured.type}
                                        </Tag>
                                        {/* Image */}
                                        <ImageHolder
                                            size={100}
                                            src={getItemProp(
                                                featured.items,
                                                'en',
                                                'backgroundImage'
                                            )}
                                        />
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>

                    <div className={style.input_row}>
                        <Button
                            type="primary"
                            loading={featuredStore.isUpdatingVisibleList}
                            onClick={() => featuredStore.updateVisibleList()}
                        >
                            Save
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
});
