import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import { Tag, Table, Input } from 'antd';
import { LegacyPagination } from '../../components';
import { ColumnProps } from 'antd/es/table';
import { AugmentedCategory, CategorySearchParams } from '../../stores/category';
import { useAuthStore, useCategoryStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { useNavigation } from '../../utils/use-navigation';
import { getItemProp } from '../../utils';

interface OnPromoClick {
    (id: number): (event: React.MouseEvent<HTMLDivElement>) => void;
}

const renderTitle = (_: unknown, record: AugmentedCategory) => {
    const enTitle = getItemProp(record.items, 'en', 'title');
    const frTitle = getItemProp(record.items, 'fr', 'title');
    return (
        <div>
            {enTitle}
            <br />
            <span className="light_label">{frTitle}</span>
        </div>
    );
};

const renderActivePromoCount = (_: unknown, record: AugmentedCategory) => {
    return <Tag color="#87d068">{record.activePromotionsCount}</Tag>;
};

const renderAssociatedPromotions = (onPromoClick: OnPromoClick) => {
    const AssociatedPromotionsComponent = (
        _: unknown,
        record: AugmentedCategory
    ) => {
        return (
            <div className={style.associated_promotions}>
                <div className={style.container}>
                    {record.promotions.map((promo) => (
                        <div
                            key={promo.id}
                            className={style.promo_container}
                            onClick={onPromoClick(promo.id)}
                        >
                            <img
                                className={style.promo_img}
                                src={promo.items[0].thumbnail ?? ''}
                                alt=""
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    AssociatedPromotionsComponent.displayName = 'AssociatedPromotionsComponent';
    return AssociatedPromotionsComponent;
};

export const CategoriesPage = observer(() => {
    const categoryStore = useCategoryStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const [queryParams, updateQueryParams, resetQueryParams] = useQueryParams<
        CategorySearchParams
    >();

    React.useEffect(() => {
        categoryStore.fetchCategories(queryParams);
    }, [categoryStore, queryParams]);

    const onPromoClick: OnPromoClick = (promotionId) => (event) => {
        event.stopPropagation();
        navigation.goToPromotionEdit(promotionId);
    };

    const columns: ColumnProps<AugmentedCategory>[] = [
        {
            title: 'Title',
            key: 'title',
            width: '20%',
            render: renderTitle,
        },
        {
            title: 'Active promo count',
            key: 'active_promo_count',
            render: renderActivePromoCount,
            width: '20%',
        },
        {
            title: 'Associated promotions',
            key: 'associated_promotions',
            render: renderAssociatedPromotions(onPromoClick),
            width: '70%',
        },
    ];

    const goToEditPage = (c: AugmentedCategory) => () => {
        navigation.goToCategoryEdit(c.id);
    };

    return (
        <div>
            <h1>Categories</h1>
            <a
                href="/category/create"
                onClick={() => navigation.goToCategoryCreate()}
            >
                Create new
            </a>
            <div className={style.query_box}>
                <Input
                    value={queryParams.q}
                    onChange={(e) => resetQueryParams('q', e.target.value)}
                    allowClear={true}
                    placeholder="Search"
                />
            </div>
            <LegacyPagination
                className={style.pagination}
                params={queryParams}
                meta={categoryStore.categories}
                onChange={(pageNumber) =>
                    updateQueryParams({ page: pageNumber })
                }
                onPageSizeChange={(size) =>
                    updateQueryParams({ perPage: size })
                }
            />
            <Table<AugmentedCategory>
                className="clickable_table"
                rowKey="id"
                dataSource={categoryStore.categories?.results}
                columns={columns}
                loading={categoryStore.isFetchingCount > 0}
                pagination={false}
                onRow={(r) => ({
                    onClick: goToEditPage(r),
                })}
            />
        </div>
    );
});
