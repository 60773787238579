export const Text = {
    ACVT: {
        bottomTabBar: {
            home: {
                en: 'Home',
                fr: 'Accueil',
            },
            stores: {
                en: 'Stores',
                fr: 'Succursales',
            },
            carWash: {
                en: 'Car wash',
                fr: 'Lave-auto',
            },
            more: {
                en: 'More',
                fr: 'Plus',
            },
        },
        canvas: {
            tabLangFr: 'FR',
            tabLangEn: 'EN',
        },
        gamesPage: {
            header: {
                fr: 'Jeux & concours',
                en: 'Games & Contests',
            },
            playbutton: {
                fr: 'Jouer',
                en: 'Play',
            },
        },
        homePage: {
            greatings: {
                morning: {
                    fr: 'Bon matin,',
                    en: 'Good morning,',
                },
                generic: {
                    fr: 'Bonjour',
                    en: 'Good afternoon',
                },
            },
            header: {
                delivery: {
                    fr: 'COUCHE-TARD LIVRAISON',
                    en: 'COUCHE-TARD DELIVERY',
                },
                description: {
                    fr: `Couche-Tard offre maintenant
                    la livraison de certains produits
                    grâce à Couche-Tard livraison.
                    C'est l'idéal pour comber tes
                    fringales de dernière minute!`,
                    en: `Couche-Tard now offers to
                    deliver some of its products
                    thanks to Couche-Tard Delivery.
                    Perfect for filling your last
                    minute cravings!`,
                },
                link: {
                    fr: 'Commander maintenant',
                    en: 'Start Your Order',
                },
                title: {
                    fr: 'Couche-Tard Livraison',
                    en: 'Couche-Tard Delivery',
                },
            },
        },
    },
    feedbacksPage: {
        table: {
            userName: 'Name',
            score: 'Score',
            description: 'Description',
            featuredScore: 'Feature Scored',
            selectedStore: 'Selected Store',
            createdDate: 'Created Date',
        },
        modal: {
            user: 'User ID: ',
            username: 'Name: ',
            email: 'Email: ',
            score: 'Score: ',
            category: 'Category: ',
            featureScored: 'Feature Scored: ',
            selectedStore: 'Selected Store: ',
            description: 'Description: ',
            lang: 'Language: ',
            birthday: 'Birthday: ',
            gender: 'Gender: ',
            phoneNumber: 'Phone Number: ',
            postalCode: 'Postal Code: ',
            newsletter_receiver: 'Email Subscriber : ',
            updateAt: 'Updated At: ',
            createdAt: 'Created At: ',
        },
    },
    featuredPage: {
        columnTitle: {
            active: 'Active',
            type: 'Type',
            image: 'Image',
        },
        pageTitle: 'Featured',
        createButton: 'Create new',
        geolocatedSwitch: 'Is geolocated',
        tags: {
            geolocated: 'Geolocated',
        },
    },
    userCouponsTab: {
        columns: {
            status: {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
            },
            deal: {
                title: 'Deal',
                key: 'deal',
                dataIndex: 'title',
            },
            activation: {
                title: 'Activation',
                key: 'activation',
                dataIndex: 'createdAt',
            },
            reissue: {
                title: 'Re-issue',
                key: 'reissue',
                text: 'Reissue coupon',
            },
        },
        promoStatus: {
            active: 'active',
            upcoming: 'upcoming',
            expired: 'expired',
        },
        numberOfDays: {
            lastDay: 'Last day',
            last7: 'Last 7 days',
            last14: 'Last 14 days',
            last30: 'Last 30 days',
            last90: 'Last 90 days',
            all: 'All',
        },
        modal: {
            title: 'Reissue coupon to cient',
            text: 'This action will reissue the coupon',
            toUserText: 'to user id #',
            areYouSure: 'Are you sure you want to reissue this coupon?',
        },
    },
    bigModal: {
        molsonGame: {
            registerd: ' Registered since',
        },
        reissueCoupon: {
            headertext: 'Re-issuing coupon for client #',
        },
    },
    clientsPage: {
        description: {
            create: 'create',
            grantPins: 'Grant up to 10 pins',
            newPinsCreated: 'Client need to refresh the pins view',
            newPins: 'New Pin(s)',
            points: 'Points',
            predictionsTotal: 'Total',
            predictionsWithPins: 'With Pins',
            predictionsWithoutPins: 'Without Pins',
            predictions: 'Predictions',
            pins: 'Pins',
            rank: 'Rank',
            team: 'Team',
            totalPins: 'Total',
            totalPinsUsed: 'Used',
            totalPinsPending: 'Pending',
            totalPinsCreatedSac: 'Created by the SAC',
            totalPinsValidated: 'Validated',
        },
        quickAction: {
            carwash: 'Unlock carwash',
            email: 'Validate email',
            pins: 'Grant Molson Game Pins',
        },
        reissueButton: 'Reissue coupons',
    },
    nhlGame: {
        configContainer: {
            createButton: 'Create New',
            pageTitle: 'Nhl-Game Config',
            uploadPinsDescription: 'Last time pins were uploaded:',
            lastDateMissing: 'unknown',
        },
        leaderBoardContainer: {
            pageTitle: 'Nhl-Game Leaderboard',
            table: {
                id: 'ID',
                name: 'Name',
                email: 'Email',
                totalPoints: 'Total Points',
                registration: 'Registration',
            },
            noGameSelected: 'Select a NHL Game',
        },
        dashboardContainer: {
            pageTitle: 'Nhl-Game dashboard',
            csvTitle: 'molson game participants',
            downloadCsv: 'Download .csv',
            stats: {
                newUser: 'New Users',
                newPlayers: 'New Players',
                visits: 'Visits',
                distinctUsers: 'Distinct users:',
                couponsWon: 'Coupons Won',
                couponsRedeemed: 'Coupons Redeemed',
            },
            teamSelected: 'Selected:',
            sectionTitle: {
                users: 'Users',
                coupons: 'Coupons',
                teams: 'Teams',
                stores: 'Stores With PINs Validated',
            },
            rangerPicker: {
                placeholder: {
                    start: 'Start at',
                    end: 'End at',
                },
            },
            stores: {
                csvFile: 'Stores PINS',
                table: {
                    nref: 'Nref',
                    adress: 'Adress',
                    pinsValidated: 'Pins Validated',
                },
            },
        },
        sectionHeaders: {
            pins: 'Pins',
        },
        buttonText: {
            importCsv: 'Import .csv',
        },
        uploadPins: {
            tooltip:
                'By uploading now, every PIN entered by users will be validated from 48 hours before the last uploaded file until now.',
        },
        pinsStats: {
            ctPinsTotal: 'Couche-Tard Imported Pins',
            tbPinPendingValidation: 'Users Pins Waiting Validations',
            tbPinsNeverValidated: 'Users Pins Expired',
            tbPinsTotal: 'Users Pins Total',
            tbPinsUsed: 'Users Pins Used',
            tbPinsValidated: 'Users Pins Validated',
            tbPredsWithPin: 'Predictions With Pin',
            tbPredsNoPin: 'Predictions Without Pin',
            totalInscriptions: 'Total Inscriptions',
            tbPredsWithFreePin: 'Predictions With Free Pin',
            tbPinsTotalFree: 'Total Free Pins',
            tbPinsTotalInputted: 'Total Inputted PINs',
            rewardsWon: 'Rewards Won',
            rewardsActivated: 'Rewards Activated',
            mostPopularTeam: 'Most Popular Teams',
        },
        store: {
            addPinsSuccess: 'Nhl game pins added.',
            ctPinsEnteredSuccess: 'Pins successfully added:',
            pinsValidatedSuccess: 'Pins from users validated:',
            invalidPinsSuccess: 'Invalid pins:',
        },
        gameFields: {
            startDate: {
                title: 'Start Date',
                key: 'startDate',
            },
            endDate: {
                title: 'End Date',
                key: 'endDate',
            },
            endGameDate: {
                title: 'End Game Date',
                key: 'endGameDate',
            },
            grandPrizeEndDate: {
                title: 'Grand Prize End Date',
                key: 'grandPrizeEndDate',
            },
        },
    },
    dateTime: {
        longAMPM: 'YYYY-MM-DD hh:mm A',
        hourMinute: 'HH:mm',
    },
    notificationManager: {
        nhlGame: {
            fetchLeaderboard: 'Error fetching leaderboard',
            errorDateExportDashboard: 'Need dates for exporting to csv',
            errorDateFetchingDashboard:
                'An error occurred while fetching the kpi to export',
            errorDateFetchingLeaderboard:
                'An error occurred while fetching the leaderboard',
            errorGetValidNhlGames: 'An error occurred while fetching the list',
        },
        general: {
            errorExportFile: 'Error during exportation of file',
        },
    },
    homePage: {
        navBar: {
            nhlSection: {
                title: 'NHL',
                dashboard: 'Dashboard',
                leaderboard: 'Leaderboard',
                config: 'Config',
            },
            clients: 'Clients',
        },
    },
    cmsUserhistoryComponent: {
        columns: {
            email: 'Email',
            action: 'Action',
            date: 'Date',
        },
        title: 'CMS User History',
    },
    navigationHeader: {
        game: 'Game',
        promotion: 'Promotion',
    },
    promotionPage: {
        params: {
            action: {
                create: 'create',
                edit: 'edit',
                duplicate: 'duplicate',
            },
        },
    },
};
