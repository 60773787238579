import React from 'react';
import { observer } from 'mobx-react';
import { ReactSortable } from 'react-sortablejs';
import { Button, Spin } from 'antd';
import { useAuthStore, usePromotionStore } from '../../contexts/mobx';
import { Item } from './item';
import style from './style.module.scss';
import { useNavigation } from '../../utils/use-navigation';

export const VisiblePromotionsPage = observer(() => {
    const promotionStore = usePromotionStore();
    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    React.useEffect(() => {
        promotionStore.fetchVisiblePromotions();
    }, [promotionStore]);

    return (
        <div>
            <h1>Visible promotions</h1>
            {promotionStore.isFetchingVisiblePromotions && <Spin />}
            {!promotionStore.isFetchingVisiblePromotions && (
                <div className={style.dual_lists}>
                    <div className={style.left_content}>
                        <h3 className={style.coupon_list_header}>Coupons</h3>
                        <div className={style.save_button_container}>
                            <Button
                                type="primary"
                                loading={promotionStore.isUpdatingCouponsOrder}
                                onClick={() =>
                                    promotionStore.updateVisibleCouponsOrder()
                                }
                            >
                                Save
                            </Button>
                        </div>
                        <ReactSortable
                            className={style.list}
                            list={promotionStore.couponVisiblePromotions}
                            setList={(newState) =>
                                promotionStore.setCouponVisiblePromotions(
                                    newState
                                )
                            }
                        >
                            {promotionStore.couponVisiblePromotions.map(
                                (promotion) => (
                                    <Item
                                        promotion={promotion}
                                        key={promotion.id}
                                    />
                                )
                            )}
                        </ReactSortable>
                    </div>

                    <div className={style.left_content}>
                        <h3 className={style.coupon_list_header}>In stores</h3>
                        <div className={style.save_button_container}>
                            <Button
                                type="primary"
                                loading={promotionStore.isUpdatingInStoreOrder}
                                onClick={() =>
                                    promotionStore.updateVisibleInStoreOrder()
                                }
                            >
                                Save
                            </Button>
                        </div>

                        <ReactSortable
                            className={style.list}
                            list={promotionStore.inStoreVisiblePromotions}
                            setList={(newState) =>
                                promotionStore.setInStoreVisiblePromotions(
                                    newState
                                )
                            }
                        >
                            {promotionStore.inStoreVisiblePromotions.map(
                                (promotion) => (
                                    <Item
                                        promotion={promotion}
                                        key={promotion.id}
                                    />
                                )
                            )}
                        </ReactSortable>
                    </div>
                </div>
            )}
        </div>
    );
});
