import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import { useAuthStore, useRechargeStore } from '../../contexts/mobx';
import { useNavigation } from '../../utils/use-navigation';
import { Recharge } from '../../stores/recharge';
import Table, { ColumnProps } from 'antd/lib/table';
import { ImageHolder, PageHeader } from '../../components';
import { Spin, Tag } from 'antd';
import { FormOutlined } from '@ant-design/icons';

export const RechargeImagesPage = observer(() => {
    const store = useRechargeStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const renderImage = (_: unknown, record: Recharge) => {
        return (
            <ImageHolder
                src={record.image.en ?? undefined}
                className="thumbnail"
            />
        );
    };

    const renderIsActive = (_: unknown, record: Recharge) => {
        return (
            <div>
                {record.active ? (
                    <Tag color="#87d068">Active</Tag>
                ) : (
                    <Tag color="#ee1313">Inactive</Tag>
                )}
            </div>
        );
    };

    const columns = React.useMemo<ColumnProps<Recharge>[]>(
        () => [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                width: '5rem',
                ellipsis: true,
                render: (_: unknown, record: Recharge) => (
                    <span>{record.id}</span>
                ),
            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'active',
                ellipsis: true,
                width: '7rem',
                render: renderIsActive,
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                ellipsis: true,
            },
            {
                title: 'Place',
                dataIndex: 'place',
                key: 'place',
                ellipsis: true,
            },
            {
                title: 'Image',
                key: 'image',
                ellipsis: true,
                render: renderImage,
            },
        ],
        []
    );

    React.useEffect(() => {
        store.fetchRechargeImageList();
    }, [store]);

    const goToEditPage = (o: Recharge) => () => {
        navigation.goToRechargeEdit(o.id);
    };

    return (
        <div className={style.page_content}>
            <PageHeader title={'Recharge'} />

            <div className={style.table_header}>
                <div className={style.query_box}>
                    <span
                        className={style.create_button}
                        onClick={() => {
                            navigation.goToRechargeCreate();
                        }}
                    >
                        {'Create New'} <FormOutlined />
                    </span>
                </div>
            </div>

            {store.isFetchingList ? (
                <Spin />
            ) : (
                <Table<Recharge>
                    rowKey="id"
                    dataSource={store.rechargeList}
                    columns={columns}
                    loading={store.isFetchingList}
                    pagination={false}
                    bordered
                    onRow={(r) => ({
                        onClick: goToEditPage(r),
                    })}
                />
            )}
        </div>
    );
});
