import React from 'react';
import style from './style.module.scss';

type NumberInputProps = {
    value: number;
    setValue: (value: number) => void;
};

export const InputNumber = ({ value, setValue }: NumberInputProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.valueAsNumber;
        if (!isNaN(newValue)) {
            setValue(newValue);
        }
    };

    return (
        <>
            <input
                type="number"
                value={value}
                onChange={handleChange}
                className={style.number_input}
            />
        </>
    );
};
