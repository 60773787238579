import React from 'react';
import style from './style.module.scss';
import { ReactComponent as SearchIcon } from '../../icons/MagnifyingGlass.svg';
import { Input } from 'antd';

type SearchInputProps = {
    searchValue?: string | number | string[] | undefined;
    onClickIcon?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => false | void;
    propsStyle?: React.CSSProperties;
};

export const SearchInput = ({
    searchValue,
    onClickIcon,
    onChange,
    onKeyDown,
    propsStyle,
}: SearchInputProps) => {
    return (
        <div className={style.search}>
            <Input
                value={searchValue}
                prefix={
                    <SearchIcon
                        className={style.search_icon}
                        onClick={onClickIcon}
                    />
                }
                onChange={onChange}
                onKeyDown={onKeyDown}
                allowClear={true}
                placeholder="Search"
                style={propsStyle}
            />
        </div>
    );
};
