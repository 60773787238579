import React from 'react';

function getWindowHeight() {
    const { innerHeight: windowHeight } = window;
    return {
        windowHeight,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowHeight()
    );

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowHeight());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
