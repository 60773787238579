import React from 'react';
import style from './style.module.scss';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';

type BigModalProps = {
    onClose?:
        | ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
        | undefined;
    header?: React.ReactNode;
    children?: React.ReactNode;
};

export const BigModal = ({ onClose, header, children }: BigModalProps) => {
    return (
        <>
            <div className={style.coupons_modal} onClick={onClose}>
                <div
                    className={style.coupons_modal_content}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={style.header}>
                        <div className={style.close} onClick={onClose}>
                            <CloseIcon />
                        </div>
                        {header}
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};
