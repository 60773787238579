import * as yup from 'yup';
import React from 'react';
import style from './style.module.scss';
import moment from 'moment';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import {
    useAuthStore,
    useGamesStore,
    usePromotionStore,
} from '../../contexts/mobx';
import { useParams } from 'react-router-dom';
import { useNavigation } from '../../utils/use-navigation';
import {
    ErrorAwareLabel,
    ImageHolder,
    GameForm,
    NavigationHeader,
    Images,
    DatesPicker,
    IsActiveSwitch,
    NotificationManager,
    CheckboxForm,
    DeepLinkLabel,
    ModalManager,
} from '../../components';
import {
    SportGame,
    GameType,
    initialSportGame,
    SportGamePayload,
} from '../../types/games';
import { Input, DatePicker, Select, Tag, Switch, Card, Tooltip } from 'antd';
import {
    CalendarOutlined,
    CloseOutlined,
    CheckOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import {
    Acvt,
    AcvtBottomTabBar,
    AcvtCanvas,
    AcvtGamePage,
} from '../../components/advanced-ct-visualisation-tool';
import { AcvtGame, AcvtSupportedLanguages, AcvtTabs } from '../../types/acvt';
import { Text } from '../../utils/text';

const YupParams = yup.object({
    action: yup.string().oneOf(['create', 'edit']).required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(v === undefined && action !== 'create');
        }),
    type: yup.string<GameType>(),
});

type Params = yup.InferType<typeof YupParams>;

const transformGame = (game: SportGame): SportGamePayload => {
    return {
        id: game.id,
        startDate: game.startDate,
        endDate: game.endDate,
        status: game.status,
        type: game.type,
        isFeatured: game.isFeatured,
        image: game.image,
        detailImage: game.detailImage,
        description: game.description,
        subTitle: game.subTitle,
        title: game.title,
        buttonTitle: game.buttonTitle,
        gameTodayMessage: game.gameTodayMessage,
        noGameTodayMessage: game.noGameTodayMessage,
        dates: game.dates,
        sport: game.sport,
        promotionId1: game.promotion1.id,
        promotionId2: game.promotion2.id,
        promotionId3: game.promotion3.id,
        checkboxes: game.checkboxes,
        marketingDeepLink: game.marketingDeepLink,
        cmsUserEmail: game.cmsUserEmail,
        cmsUserHistory: game.cmsUserHistory,
    };
};

export const GameSportPage = observer(() => {
    const gamesStore = useGamesStore();
    const params = useParams<Params>();
    const navigation = useNavigation();
    const promotionStore = usePromotionStore();

    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }
    const [isFetching, setIsFetching] = React.useState(false);

    const [isFeatured, setIsFeatured] = React.useState<boolean>(false);

    const formik = useFormik<SportGamePayload>({
        initialValues: initialSportGame,
        validateOnMount: true,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    const onDeleteConfirm = async () => {
        await gamesStore.deleteGame(formik.values.type, formik.values.id);
        navigation.goToGames();
    };

    const updateGame = async () => {
        if (
            isFeatured &&
            (formik.values.detailImage['en'] == null ||
                formik.values.detailImage['fr'] == null)
        ) {
            NotificationManager.showError(
                'A detail image is mandatory when game is to be featured on homepage'
            );
        }
        formik.values.cmsUserEmail = authStore.claims?.email || null;
        await gamesStore.modifyGame(formik.values);
        navigation.replaceToGames();
    };

    const createGame = async () => {
        formik.values.cmsUserEmail = authStore.claims?.email || null;

        const unknownGame = formik.values;
        const success = await gamesStore.createGame(unknownGame);
        if (success) {
            navigation.replaceToGames();
        } else if (
            isFeatured &&
            (formik.values.detailImage['en'] == null ||
                formik.values.detailImage['fr'] == null)
        ) {
            NotificationManager.showError(
                'A detail image is mandatory when game is to be featured on homepage'
            );
        }
    };

    React.useEffect(() => {
        if (params.action === 'edit') {
            setIsFetching(true);
            gamesStore
                .fetchSingleSportGame(Number(params.id!))
                .then((game) => {
                    formik.setValues(transformGame(game));
                })
                .catch(() => navigation.goToGameCreate('sport'))
                .finally(() => setIsFetching(false));
        }
    }, [gamesStore]);

    React.useEffect(() => {
        promotionStore.fetchForSelect();
    }, [promotionStore]);

    // The type inference is not working well for nested error schema. So we are
    // going to cheat a little bit using lodash
    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return hasError && isTouched;
    };

    const promos = promotionStore.promotionsForSelect;
    const promoList = promos.filter(
        (promo) => promo.isActive === true && promo.isPrivate === true
    );

    const transformId = params.id ? Number(params.id) : undefined;

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '1px 4px 20px 1px rgba(208, 216, 243, 0.4)',
        marginRight: '20px',
    };

    const [lang, setLang] = React.useState<AcvtSupportedLanguages>('en');
    const [selectedTab, setSelectedTab] = React.useState<AcvtTabs>('promos');
    const [isCardOpened, setIsCardOpened] = React.useState({
        homePromoCard: false,
        homeGameCard: false,
        promoCard: false,
        gameCard: true,
    });

    const changeLanguage = (language: AcvtSupportedLanguages) => {
        setLang(language);
    };

    const checkIsGameToday = formik.values.dates.some((date) => {
        return date.includes(moment().format('YYYY-MM-DD'));
    });

    const sportGameObjectData: AcvtGame = {
        id: formik.values.id,
        type: formik.values.type,
        title: formik.values.title,
        subTitle: formik.values.subTitle,
        isGameDay: checkIsGameToday,
        image: formik.values.image,
        buttonTitle: formik.values.buttonTitle,
        checkboxes: formik.values.checkboxes,
        description: formik.values.description,
        gameTodayMessage: formik.values.gameTodayMessage,
        noGameTodayMessage: formik.values.noGameTodayMessage,
    };

    return (
        <div>
            {/* back,create,save */}
            <NavigationHeader
                id={transformId}
                type={Text.navigationHeader.game}
                action={params.action}
                onBackClick={() => window.history.back()}
                onSaveClick={updateGame}
                onCreateClick={createGame}
                disabled={!formik.isValid}
                isLoading={gamesStore.isUpsertingGame}
                cmsUserHistory={formik.values.cmsUserHistory}
                onDeleteClick={ModalManager.showDeleteModal({
                    onConfirm: onDeleteConfirm,
                })}
                isDeleting={gamesStore.isDeletingGame}
                canBeDeleted={true}
            />
            <div className={style.page}>
                <form style={{ display: isFetching ? 'none' : 'initial' }}>
                    <div className={style.left_column}>
                        <div className={style.input_row}>
                            <div
                                className={`${style.fluid_input_col} ${style.vertical_col}`}
                            >
                                <IsActiveSwitch
                                    values={formik.values}
                                    title="Active"
                                    onChange={(values) => {
                                        formik.setValues({
                                            ...formik.values,
                                            ...values,
                                        });
                                    }}
                                />
                                <div className={style.input_row}>
                                    <div className={style.light_label}>
                                        <Tooltip
                                            className={style.tooltip}
                                            title={
                                                "Featured games appear as full width cards on the mobile apps' home page"
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </div>

                                    <span className="left_label">
                                        Is Featured Game
                                    </span>

                                    <Switch
                                        checked={
                                            formik.values.isFeatured === true
                                        }
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'isFeatured',
                                                e
                                            );
                                            setIsFeatured(false);
                                        }}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </div>
                            </div>
                            <div className={style.dates_row}>
                                <div className={style.input_row}>
                                    <DatesPicker
                                        values={formik.values}
                                        errors={{
                                            startDate: checkIfHasError(
                                                'startDate'
                                            ),
                                            endDate: checkIfHasError('endDate'),
                                        }}
                                        tooltips={{
                                            start:
                                                'Game & contest becomes visible in app on start date',
                                            end:
                                                'Game & contest becomes visible in app on end date',
                                        }}
                                        onChange={(values) => {
                                            formik.setValues({
                                                ...formik.values,
                                                ...values,
                                            });
                                        }}
                                    />
                                </div>
                                <div className={style.input_row}>
                                    {formik.values.type === 'sport' && (
                                        <>
                                            <div
                                                className={`${style.fluid_input_col} ${style.vertical_col} left_spacer`}
                                            >
                                                <span
                                                    className={
                                                        style.header_dates
                                                    }
                                                >
                                                    Dates
                                                </span>
                                                <div
                                                    className={
                                                        style.fluid_input_col
                                                    }
                                                >
                                                    <DatePicker
                                                        allowClear={true}
                                                        format="YYYY-MM-DD"
                                                        onChange={(e) => {
                                                            if (
                                                                formik.values
                                                                    .type ===
                                                                    'sport' &&
                                                                e
                                                            ) {
                                                                formik.setFieldValue(
                                                                    'dates',

                                                                    uniq([
                                                                        ...formik
                                                                            .values
                                                                            .dates,
                                                                        e.format(),
                                                                    ])
                                                                );
                                                            }
                                                        }}
                                                        disabledDate={(d) =>
                                                            d.isSameOrBefore(
                                                                moment()
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className={style.tag}>
                                                    {formik.values.dates.map(
                                                        (date) => {
                                                            return (
                                                                <Tag
                                                                    closable
                                                                    key={date}
                                                                    onClose={() => {
                                                                        if (
                                                                            formik
                                                                                .values
                                                                                .type ===
                                                                            'sport'
                                                                        ) {
                                                                            const indexToRemove = formik.values.dates.indexOf(
                                                                                date
                                                                            );

                                                                            const newArray = [
                                                                                ...formik
                                                                                    .values
                                                                                    .dates,
                                                                            ];

                                                                            newArray.splice(
                                                                                indexToRemove,
                                                                                1
                                                                            );

                                                                            formik.setFieldValue(
                                                                                'dates',
                                                                                newArray
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <CalendarOutlined />{' '}
                                                                    {date.substr(
                                                                        0,
                                                                        10
                                                                    )}
                                                                </Tag>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>{' '}
                        </div>
                    </div>

                    {/* Promotions */}
                    {formik.values.type === 'sport' && (
                        <>
                            <div className={style.input_row}>
                                <div
                                    className={`${style.fluid_input_col} ${style.promotions_row_select}`}
                                >
                                    <span>Promotion 1</span>
                                    {
                                        <Select
                                            className={style.input_row}
                                            listHeight={512}
                                            showSearch
                                            optionLabelProp="label"
                                            filterOption={false}
                                            loading={
                                                promotionStore.isFetchingForSelect
                                            }
                                            value={
                                                formik.values.promotionId1 ??
                                                undefined
                                            }
                                            onChange={(v) =>
                                                formik.setFieldValue(
                                                    'promotionId1',
                                                    v
                                                )
                                            }
                                        >
                                            {promoList.map((promo) => (
                                                <Select.Option
                                                    key={promo.id}
                                                    value={promo.id}
                                                    label={promo.items[0].title}
                                                >
                                                    <div
                                                        className={
                                                            style.custom_option
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                style.titles_holder
                                                            }
                                                        >
                                                            <span>
                                                                {
                                                                    promo
                                                                        .items[0]
                                                                        .title
                                                                }
                                                            </span>
                                                            <span>
                                                                {
                                                                    promo
                                                                        .items[1]
                                                                        .title
                                                                }
                                                            </span>
                                                        </div>
                                                        <ImageHolder
                                                            src={
                                                                promo.items[0]
                                                                    .thumbnail
                                                            }
                                                            size={40}
                                                            className={
                                                                style.option_image
                                                            }
                                                        />
                                                    </div>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    }
                                </div>

                                <div
                                    className={`${style.fluid_input_col} ${style.promotions_row_select}`}
                                >
                                    <span>Promotion 2</span>

                                    <Select
                                        className={style.input_row}
                                        listHeight={512}
                                        showSearch
                                        optionLabelProp="label"
                                        filterOption={false}
                                        loading={
                                            promotionStore.isFetchingForSelect
                                        }
                                        value={
                                            formik.values.promotionId2 ??
                                            undefined
                                        }
                                        onChange={(v) =>
                                            formik.setFieldValue(
                                                'promotionId2',
                                                v
                                            )
                                        }
                                    >
                                        {promoList.map((promo) => (
                                            <Select.Option
                                                key={promo.id}
                                                value={promo.id}
                                                label={promo.items[0].title}
                                            >
                                                <div
                                                    className={
                                                        style.custom_option
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.titles_holder
                                                        }
                                                    >
                                                        <span>
                                                            {
                                                                promo.items[0]
                                                                    .title
                                                            }
                                                        </span>
                                                        <span>
                                                            {
                                                                promo.items[1]
                                                                    .title
                                                            }
                                                        </span>
                                                    </div>
                                                    <ImageHolder
                                                        src={
                                                            promo.items[0]
                                                                .thumbnail
                                                        }
                                                        size={40}
                                                        className={
                                                            style.option_image
                                                        }
                                                    />
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                                <div
                                    className={`${style.fluid_input_col} ${style.promotions_row_select}`}
                                >
                                    <span>Promotion 3</span>

                                    <Select
                                        className={style.input_row}
                                        listHeight={512}
                                        showSearch
                                        optionLabelProp="label"
                                        filterOption={false}
                                        loading={
                                            promotionStore.isFetchingForSelect
                                        }
                                        value={
                                            formik.values.promotionId3 ??
                                            undefined
                                        }
                                        onChange={(v) =>
                                            formik.setFieldValue(
                                                'promotionId3',
                                                v
                                            )
                                        }
                                    >
                                        {promoList.map((promo) => (
                                            <Select.Option
                                                key={promo.id}
                                                value={promo.id}
                                                label={promo.items[0].title}
                                            >
                                                <div
                                                    className={
                                                        style.custom_option
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.titles_holder
                                                        }
                                                    >
                                                        <span>
                                                            {
                                                                promo.items[0]
                                                                    .title
                                                            }
                                                        </span>
                                                        <span>
                                                            {
                                                                promo.items[1]
                                                                    .title
                                                            }
                                                        </span>
                                                    </div>
                                                    <ImageHolder
                                                        src={
                                                            promo.items[0]
                                                                .thumbnail
                                                        }
                                                        size={40}
                                                        className={
                                                            style.option_image
                                                        }
                                                    />
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </>
                    )}

                    <Card
                        className={style.card_container}
                        title="Images"
                        style={cardStyle}
                    >
                        <Images
                            image={formik.values.image}
                            title={
                                formik.values.isFeatured
                                    ? 'Featured Image'
                                    : 'Image'
                            }
                            onChange={(image) => {
                                formik.setValues({
                                    ...formik.values,
                                    image,
                                });
                            }}
                            onDelete={(image) => {
                                formik.setValues({
                                    ...formik.values,
                                    image,
                                });
                            }}
                        />
                        {formik.values.isFeatured && (
                            <Images
                                image={formik.values.detailImage}
                                title="Detail View Image"
                                onChange={(detailImage) => {
                                    formik.setValues({
                                        ...formik.values,
                                        detailImage,
                                    });
                                }}
                                onDelete={(detailImage) => {
                                    formik.setValues({
                                        ...formik.values,
                                        detailImage,
                                    });
                                }}
                            />
                        )}
                    </Card>

                    {/* Details */}
                    <div className={style.config_container}>
                        <div className={style.items_defs_box}>
                            <div className={style.items_content}>
                                {/* title,subtitle,description,button */}
                                <GameForm
                                    values={formik.values}
                                    onChange={(values) => {
                                        formik.setValues({
                                            ...formik.values,
                                            ...values,
                                        });
                                    }}
                                    errors={{
                                        fr: checkIfHasError('fr'),
                                        en: checkIfHasError('en'),
                                    }}
                                />

                                {/* Game Today Message */}
                                {formik.values.type === 'sport' && (
                                    <div className={style.input_row}>
                                        <div
                                            className={
                                                style.fixed_width_input_col
                                            }
                                        >
                                            <ErrorAwareLabel
                                                text={`Game Today Message en`}
                                                hasError={checkIfHasError(
                                                    `gameTodayMessage.en`
                                                )}
                                            />
                                            <Input
                                                name={`gameTodayMessage.en`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                    formik.values
                                                        .gameTodayMessage.en
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                style.fixed_width_input_col
                                            }
                                        >
                                            <ErrorAwareLabel
                                                text={`Game Today Message fr`}
                                                hasError={checkIfHasError(
                                                    `gameTodayMessage.fr`
                                                )}
                                            />
                                            <Input
                                                name={`gameTodayMessage.fr`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                    formik.values
                                                        .gameTodayMessage.fr
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* No Game Today Message */}
                                {formik.values.type === 'sport' && (
                                    <div className={style.input_row}>
                                        <div
                                            className={
                                                style.fixed_width_input_col
                                            }
                                        >
                                            <ErrorAwareLabel
                                                text={`No Game Today Message en`}
                                                hasError={checkIfHasError(
                                                    `noGameTodayMessage.en`
                                                )}
                                            />
                                            <Input
                                                name={`noGameTodayMessage.en`}
                                                onChange={formik.handleChange}
                                                // onBlur={formik.handleBlur}
                                                value={
                                                    formik.values
                                                        .noGameTodayMessage.en
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                style.fixed_width_input_col
                                            }
                                        >
                                            <ErrorAwareLabel
                                                text={`No Game Today Message fr`}
                                                hasError={checkIfHasError(
                                                    `noGameTodayMessage.fr`
                                                )}
                                            />
                                            <Input
                                                name={`noGameTodayMessage.fr`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                    formik.values
                                                        .noGameTodayMessage.fr
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {formik.values.marketingDeepLink && (
                                    <DeepLinkLabel
                                        text={formik.values.marketingDeepLink}
                                    />
                                )}
                                <CheckboxForm
                                    values={formik.values}
                                    onChange={(values) => {
                                        formik.setValues({
                                            ...formik.values,
                                            ...values,
                                        });
                                    }}
                                    errors={{
                                        fr: checkIfHasError('fr'),
                                        en: checkIfHasError('en'),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <Card className={style.card_container} title="App Preview">
                    <AcvtCanvas changeLang={changeLanguage}>
                        <Acvt backgroundColor="#d8281c">
                            <>
                                <AcvtGamePage
                                    key={formik.values.id}
                                    language={lang}
                                    gameObject={sportGameObjectData}
                                    opened={setIsCardOpened}
                                />
                                <AcvtBottomTabBar
                                    language={lang}
                                    tab={selectedTab}
                                    tabSelected={setSelectedTab}
                                    onEditMode={true}
                                />
                            </>
                        </Acvt>
                    </AcvtCanvas>
                </Card>
            </div>
        </div>
    );
});
