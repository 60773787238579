import React from 'react';
import style from './style.module.scss';
import moment from 'moment';
import { AcvtGame, AcvtSupportedLanguages } from '../../../../types/acvt';

type GameCard = {
    gameObject: AcvtGame;
    language: AcvtSupportedLanguages;
    opened: React.Dispatch<
        React.SetStateAction<{
            homePromoCard: boolean;
            homeGameCard: boolean;
            promoCard: boolean;
            gameCard: boolean;
        }>
    >;
    setSingleGame: (promo: AcvtGame) => void;
};

export const AcvtGameCard = ({
    gameObject,
    language,
    opened,
    setSingleGame,
}: GameCard) => {
    const setGame = () => {
        setSingleGame(gameObject);
    };

    const getStartDateFR = (date: string | undefined) => {
        const localLocale = moment(date);
        localLocale.locale('fr-ca');
        return localLocale.format('Do MMMM YYYY');
    };

    const getEndDateFR = (date: string | undefined) => {
        const localLocale = moment(date);
        localLocale.locale('fr-ca');
        return localLocale.format('Do MMMM YYYY');
    };

    return (
        <>
            <div className={style.container}>
                <div
                    className={style.card}
                    onClick={() => {
                        opened((prevState) => {
                            return { ...prevState, gameCard: true };
                        });
                        setGame();
                    }}
                >
                    <div className={style.image}>
                        <img
                            alt=""
                            src={
                                gameObject?.image[
                                    language as AcvtSupportedLanguages
                                ] ?? ''
                            }
                        />
                    </div>
                    <div className={style.text_grid}>
                        <div id={style.title}>
                            {
                                gameObject?.title[
                                    language as AcvtSupportedLanguages
                                ]
                            }
                        </div>
                        <div id={style.subtitle}>
                            {
                                gameObject?.subTitle[
                                    language as AcvtSupportedLanguages
                                ]
                            }
                        </div>
                        <div id={style.dates}>
                            {language === 'fr'
                                ? `Du ${getStartDateFR(
                                      gameObject?.startDate
                                  )} au ${getEndDateFR(gameObject?.endDate)}`
                                : `From ${moment(gameObject?.startDate).format(
                                      'MMMM Do YYYY'
                                  )} to ${moment(gameObject?.endDate).format(
                                      'MMMM Do YYYY'
                                  )} `}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
