import React from 'react';
import { DatePicker, Tooltip, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { FormikHelpers } from 'formik';
import styles from './style.module.scss';
import { PromotionForm } from '../..';
import { ErrorAwareLabel } from '../../../../components';

interface ScheduleSectionProps {
    values: PromotionForm;
    setFieldValue: FormikHelpers<PromotionForm>['setFieldValue'];
    checkIfHasError: (path: string) => boolean;
    fileSelected: boolean;
}

export const ScheduleSection = ({
    values,
    setFieldValue,
    checkIfHasError,
    fileSelected,
}: ScheduleSectionProps) => {
    const renderDatePicker = (
        label: string,
        field: keyof PromotionForm,
        tooltip: string,
        value: Moment | null,
        onChange: (date: Moment | null) => void,
        disabledDate: (date: Moment | null) => boolean,
        allowClear = true
    ) => (
        <div className={styles.date_picker_column}>
            <div className={styles.config_label}>
                <ErrorAwareLabel
                    text={label}
                    hasError={checkIfHasError(field)}
                />
                <Tooltip title={tooltip}>
                    <InfoCircleOutlined style={{ paddingLeft: '0.4rem' }} />
                </Tooltip>
            </div>
            <DatePicker
                allowClear={allowClear}
                format="YYYY-MM-DD hh:mm A"
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                value={value}
                onChange={onChange}
                disabledDate={disabledDate}
            />
        </div>
    );

    return (
        <Card className={styles.schedule_container}>
            <div className={styles.schedule_row}>
                {renderDatePicker(
                    'Start date',
                    'startDate',
                    'This is the date when the promotion becomes visible to users in the app.',
                    values.startDate,
                    (date) => setFieldValue('startDate', date),
                    (date) =>
                        fileSelected &&
                        (!date || date.isSameOrBefore(moment())),
                    false
                )}
                {renderDatePicker(
                    'End date',
                    'endDate',
                    'This is the date when the promotion will be automatically removed from the app.',
                    values.endDate,
                    (date) => setFieldValue('endDate', date),
                    (date) =>
                        !date ||
                        (values.inactivatableDate
                            ? date.isSameOrBefore(
                                  values.inactivatableDate as Moment
                              )
                            : values.activatableDate
                            ? date.isSameOrBefore(
                                  values.activatableDate as Moment
                              )
                            : date.isSameOrBefore(values.startDate as Moment))
                )}
                {renderDatePicker(
                    'Activatable',
                    'activatableDate',
                    "Once the activatable date is reached, users will be able to interact with the promotion. Before this date, the promotion will be marked as 'upcoming'.",
                    values.activatableDate,
                    (date) => setFieldValue('activatableDate', date),
                    (date) =>
                        !date ||
                        date.isSameOrBefore(values.startDate as Moment),
                    true
                )}
                {renderDatePicker(
                    'Inactivatable',
                    'inactivatableDate',
                    'The promotion will stop being active after this date, but it will remain visible until the end date.',
                    values.inactivatableDate,
                    (date) => setFieldValue('inactivatableDate', date),
                    (date) =>
                        !date ||
                        (values.activatableDate
                            ? date.isSameOrBefore(
                                  values.activatableDate as Moment
                              )
                            : date.isSameOrBefore(values.startDate as Moment)),
                    true
                )}
            </div>
        </Card>
    );
};
