import { observable, action, makeObservable } from 'mobx';
import * as yup from 'yup';
import { RootStore } from './root';
import { MomentSchema } from '../utils/yup-schemas';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { NotificationManager } from '../components';
import moment from 'moment';
import { HttpMethod } from '../types';

const optionalString = () => yup.string().notRequired().nullable();
const optionalNumber = () => yup.number().notRequired().nullable();
const nullableDate = new MomentSchema().nullable();

export interface ClientExternalDetails {
    id: number;
    ngrpCustomerId: string;
    liquidBarcodeId: string | null;
    email: string;
}

export interface SwapClientDetails {
    client: {
        email: string;
        ngrpCustomerId: string;
        liquidBarcodeId: string;
        id: number;
    };
    otherAccount: {
        email: string;
        ngrpCustomerId: string;
        liquidBarcodeId: string;
        id: number;
    };
}

const washCodeRecordSchema = yup.object({
    id: yup.number().required(),
    washCode: yup.string().nullable(),
    store: yup.string().nullable(),
    successCode: yup.string().nullable(),
    rejectMessage: yup.string().nullable(),
    createdAt: new MomentSchema().nullable(),
});

export type WashCodeRecord = yup.InferType<typeof washCodeRecordSchema>;

const paginatedWashCodeRecordSchema = yup.object({
    page: yup.number().required(),
    perPage: yup.number().required(),
    total: yup.number().required(),
    results: yup.array(washCodeRecordSchema).default([]),
});

export type PaginatedWashCodeRecordList = yup.InferType<
    typeof paginatedWashCodeRecordSchema
>;

const consentStatusSchema = yup.object({
    id: yup.number().required(),
    type: yup.string().required(),
    linkText: yup.string().required(),
    status: yup.string().nullable(),
    createdAt: yup.string().notRequired(),
});

type ConsentStatus = yup.InferType<typeof consentStatusSchema>;
export type UserConsent = ConsentStatus;

const clientSchema = yup.object({
    id: yup.number().required(),
    firstName: yup.string().notRequired(),
    lastName: yup.string().notRequired(),
    email: optionalString(),
    picture: optionalString(),
    language: optionalString().matches(/en|fr/),
    facebookId: optionalString(),
    liquidBarcodeId: optionalString(),
    ngrpCustomerId: optionalString(),
    appleId: optionalString(),
    birthday: nullableDate,
    gender: optionalString().matches(/male|female/),
    phoneNumber: optionalString(),
    postalCode: optionalString(),
    carwashDeviceLockedUntil: optionalString(),
    carwashSubscriptionDevice: optionalString(),
    dateCreated: nullableDate,
    dateUpdated: nullableDate,
    lockedAt: nullableDate,
    invalidLoginCount: optionalNumber(),
    isEmailConfirmed: yup.boolean().notRequired().nullable(),
    isPhoneNumberConfirmed: yup.boolean().notRequired().nullable(),
    deletedAt: new MomentSchema().nullable(),
});

const rewardsSchema = yup.object({
    id: yup.number().required(),
    promotionId: yup.number().required(),
    expireAt: yup.string().notRequired(),
    image: yup.string().notRequired(),
    title: yup.string().notRequired(),
});

const paginatedListSchema = yup.object({
    page: yup.number().required(),
    perPage: yup.number().required(),
    total: yup.number().required(),
    results: yup.array(clientSchema).default([]),
});

export type Client = yup.InferType<typeof clientSchema>;
export type Rewards = yup.InferType<typeof rewardsSchema>;
export type PaginatedClientList = yup.InferType<typeof paginatedListSchema>;

export type SearchParams = {
    perPage?: number;
    page?: number;
    q?: string;
};

export interface PaymentMethod {
    defaultPayment: string;
}

export interface DeviceAppVersion {
    appVersion: string | null;
    os: string | null;
}

interface RewardResponse {
    results: Rewards[];
}

export type PaymentMethodQuery = {
    clientId: number;
};

export type ActivatedCouponsQuery = {
    clientId?: number;
    numberOfDays?: number;
    q?: string;
};

const activatedCouponsSchema = yup.object({
    id: yup.number().required(),
    promotionId: yup.number(),
    userId: yup.number(),
    createdAt: new MomentSchema().notRequired().nullable(),
    deletedAt: new MomentSchema().nullable(),
    updatedAt: new MomentSchema().nullable(),
    status: yup.string().nullable(),
    thumbnail: yup.string().nullable(),
    title: yup.string().nullable(),
});

const nhlGamePinsSchema = yup.object({
    pins: yup.array(yup.string().required()),
});

const nhlGameProfilSchema = yup.object({
    participationId: yup.number().required(),
    nhlGameId: yup.number(),
    userId: yup.number(),
    createdAt: new MomentSchema().notRequired().nullable(),
    team: yup.string().nullable(),
    teamLogo: yup.string().nullable(),
    points: yup.number().nullable(),
    rank: yup.number().nullable(),
    pins: yup.object({
        totalPins: yup.number().nullable(),
        totalPinsPending: yup.number().nullable(),
        totalPinsValidated: yup.number().nullable(),
        totalPinsBurned: yup.number().nullable(),
        totalPinsSac: yup.number().nullable(),
    }),
    predictions: yup.object({
        totalPredictions: yup.number().nullable(),
        predictionWithPins: yup.number().nullable(),
        predictionWithoutPins: yup.number().nullable(),
    }),
});

const paginatedCouponsSchema = yup.object({
    total: yup.number().required(),
    results: yup.array(activatedCouponsSchema).default([]),
});

const unblockPaymentMethodSchema = yup.object().shape({
    status: yup.string().oneOf(['unblocked', 'already_unblocked']).required(),
    unblockUntil: yup.string().nullable().required(),
});

export type ActivatedCoupons = yup.InferType<typeof activatedCouponsSchema>;
export type ActivatedCouponsArray = yup.InferType<
    typeof paginatedCouponsSchema
>;

export type NhlGameProfil = yup.InferType<typeof nhlGameProfilSchema>;

export type NhlGamePins = yup.InferType<typeof nhlGamePinsSchema>;

export type UnblockPaymentSchema = yup.InferType<
    typeof unblockPaymentMethodSchema
>;

export class ClientStore extends WithNetworkConcurrency {
    @observable fetchingCount = 0;
    @observable list: PaginatedClientList | null = null;
    @observable isSendingResetEmail = false;
    @observable isUnblockingDevice = false;
    @observable paymentMethod: string | null = null;
    @observable isFetchingPaymentMethod = false;
    @observable activatedCoupons: ActivatedCouponsArray | null = null;
    @observable isSendingValidationEmail = false;
    @observable isFetchingActivatedCoupons = false;
    @observable isReissuingPromotion = false;
    @observable isFetchingNhlGameProfil = false;
    @observable isFetchingNhlGameProfilPins = false;
    @observable isUnblockingPaymentMethod = false;
    @observable nhlGameProfil: NhlGameProfil | null = null;
    @observable nhlGamePins: NhlGamePins | null = null;
    @observable isDeletingClient = false;
    @observable clientRewardsList: Rewards[] | null = null;
    @observable deviceAppVersion: DeviceAppVersion | null = null;
    @observable isFetchingDeviceAppVersion = false;
    @observable isLoading = false;
    @observable clientDetails: ClientExternalDetails | null = null;
    @observable userConsentsWithStatus: ConsentStatus[] | null = null;
    @observable isFetchingUserConsents = false;
    @observable isUpdatingConsentStatus = false;
    @observable washCodeRecords: PaginatedWashCodeRecordList | null = null;
    @observable isFetchingWashCodeRecords = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchList(this: ClientStore, params: SearchParams) {
        const tag = this.getTag();
        this.fetchingCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: 'clients',
                params,
            },
            paginatedListSchema
        );
        this.fetchingCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.list = response.data;
        }
    }

    @action
    async sendResetPasswordEmail(this: ClientStore, email: string) {
        this.isSendingResetEmail = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: 'clients/reset-password',
            data: {
                email,
            },
        });
        this.isSendingResetEmail = false;
        if (!response.err) {
            NotificationManager.showSuccess('Reset password email sent');
        }
    }

    @action
    async unblockCarwashDevice(this: ClientStore, clientId: number) {
        this.isUnblockingDevice = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: 'clients/unblock-carwash',
            data: {
                clientId,
            },
        });
        this.isUnblockingDevice = false;
        if (!response.err) {
            NotificationManager.showSuccess('User can now claim a new device');
        }
    }

    @action
    async getPaymentMethod(this: ClientStore, params: PaymentMethodQuery) {
        this.isFetchingPaymentMethod = true;
        const response = await this.rootStore.makeNetworkCall<PaymentMethod>({
            method: HttpMethod.GET,
            url: 'clients/payment-method',
            params,
        });
        this.isFetchingPaymentMethod = false;
        if (!response.err) {
            this.paymentMethod = response.data.defaultPayment;
        }
    }

    @action
    async unblockPaymentMethod(this: ClientStore, clientId: number) {
        this.isUnblockingPaymentMethod = true;
        const response = await this.rootStore.makeNetworkCall<
            UnblockPaymentSchema
        >({
            method: HttpMethod.POST,
            url: `clients/${clientId}/payment-method/unblock`,
        });
        this.isUnblockingPaymentMethod = false;
        if (!response.err) {
            const date = moment(new Date(response.data.unblockUntil)).format(
                'LLL'
            );

            const statusMessage =
                response.data.status === 'unblocked'
                    ? `User's payment method has been unblocked until ${date}.`
                    : `User's payment method was already unblocked until ${date}.`;

            NotificationManager.showSuccess(statusMessage);
        }
    }

    @action
    async validateEmail(this: ClientStore, clientId: number) {
        this.isSendingValidationEmail = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: 'clients/validate-email',
            data: {
                clientId,
            },
        });
        this.isSendingValidationEmail = false;
        if (!response.err) {
            NotificationManager.showSuccess('Email validation email sent');
        }
    }

    @action
    async getActivatedCoupons(
        this: ClientStore,
        params: ActivatedCouponsQuery
    ) {
        this.isFetchingActivatedCoupons = true;
        const response = await this.rootStore.makeNetworkCall<
            ActivatedCouponsArray
        >({
            method: HttpMethod.GET,
            url: 'clients/activated-coupons',
            params,
        });
        this.isFetchingActivatedCoupons = false;
        if (!response.err) {
            this.activatedCoupons = response.data;
        }
    }

    @action
    async reissuePromotion(
        this: ClientStore,
        clientId: number,
        activationPromotionId: number
    ) {
        this.isReissuingPromotion = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: 'clients/reissue-promotion',
            data: {
                clientId,
                activationPromotionId,
            },
        });
        this.isReissuingPromotion = false;
        if (!response.err) {
            NotificationManager.showSuccess('Coupon has been reissued');
        }
    }

    @action
    async getNhlGameProfil(
        this: ClientStore,
        params: { clientId: number }
    ): Promise<boolean> {
        this.isFetchingNhlGameProfil = true;
        const response = await this.rootStore.makeNetworkCall<NhlGameProfil>({
            method: HttpMethod.GET,
            url: 'clients/nhl-game-profil',
            params,
        });
        this.isFetchingNhlGameProfil = false;
        if (!response.err) {
            this.nhlGameProfil = response.data;

            return true;
        }

        return false;
    }

    @action
    async createSacPins(
        this: ClientStore,
        clientId: number,
        count: number
    ): Promise<NhlGamePins | null> {
        this.isFetchingNhlGameProfilPins = true;

        const response = await this.rootStore.makeNetworkCall<NhlGamePins>({
            method: HttpMethod.POST,
            url: 'clients/nhl-game-pins',
            data: {
                clientId,
                count,
            },
        });
        this.isFetchingNhlGameProfilPins = false;
        if (!response.err) {
            this.nhlGamePins = response.data;

            return this.nhlGamePins;
        }

        return null;
    }

    @action
    async deleteClient(clientId: number) {
        this.isDeletingClient = true;
        try {
            await this.rootStore.makeNetworkCall({
                method: HttpMethod.DELETE,
                url: `clients/${clientId}`,
            });
        } catch (error) {
            NotificationManager.showError(
                'An error occurred while deleting the client.'
            );
        }
        this.isDeletingClient = false;
    }

    @action
    async fetchClientRewards(clientId: number) {
        try {
            const response = await this.rootStore.makeNetworkCall<
                RewardResponse
            >({
                method: HttpMethod.GET,
                url: `clients/${clientId}/grants`,
            });

            if (response.data) {
                this.clientRewardsList = response.data.results;
            }
        } catch (error) {
            NotificationManager.showError(
                'An error occurred while fetching client rewards.'
            );
        }
    }

    @action
    async updateClientGrant(
        clientId: number,
        grantId: number,
        expireAt: string
    ): Promise<boolean> {
        try {
            await this.rootStore.makeNetworkCall({
                method: HttpMethod.PATCH,
                url: `clients/${clientId}/grants/${grantId}`,
                data: { expireAt },
            });

            NotificationManager.showSuccess(
                'The expiration date has been successfully updated.'
            );
            return true;
        } catch (error) {
            NotificationManager.showError(
                'An error occurred while updating rewards.'
            );
            return false;
        }
    }

    @action
    async fetchClientDeviceAppVersion(this: ClientStore, clientId: number) {
        this.isFetchingDeviceAppVersion = true;
        const response = await this.rootStore.makeNetworkCall<DeviceAppVersion>(
            {
                method: HttpMethod.GET,
                url: `clients/${clientId}/device-app-version`,
            }
        );
        this.isFetchingDeviceAppVersion = false;
        if (!response.err) {
            this.deviceAppVersion = {
                appVersion: response.data.appVersion,
                os: response.data.os,
            };
        }
    }

    @action
    async fetchClientDetails(clientId: number) {
        this.isLoading = true;
        const response = await this.rootStore.makeNetworkCall<
            ClientExternalDetails
        >({
            method: HttpMethod.GET,
            url: `clients/${clientId}/details`,
        });
        this.isLoading = false;

        if (!response.err) {
            this.clientDetails = response.data;
        } else {
            this.clientDetails = null;
            NotificationManager.showError('Failed to fetch client details');
        }
    }

    @action
    async updateExternalIdentifiers(
        clientId: number,
        data: { sourceAccountId: number }
    ) {
        const response = await this.rootStore.makeNetworkCall<
            SwapClientDetails
        >({
            method: HttpMethod.PATCH,
            url: `clients/${clientId}/external-identifiers`,
            data,
        });

        if (!response.err) {
            NotificationManager.showSuccess(
                'External identifiers updated successfully'
            );
            return response.data;
        } else {
            NotificationManager.showError(
                'Failed to update external identifiers'
            );
            return null;
        }
    }

    @action
    async fetchUserActiveConsentsWithStatus(clientId: number) {
        this.isFetchingUserConsents = true;
        const response = await this.rootStore.makeNetworkCall<ConsentStatus[]>({
            method: HttpMethod.GET,
            url: `clients/${clientId}/consents`,
        });

        this.isFetchingUserConsents = false;
        if (!response.err) {
            this.userConsentsWithStatus = response.data;
        } else {
            NotificationManager.showError(
                'Failed to fetch user consent statuses'
            );
            this.userConsentsWithStatus = null;
        }
    }

    @action
    async updateUserConsentStatus(
        clientId: number,
        userConsentRecordId: number,
        status: string
    ) {
        this.isUpdatingConsentStatus = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PATCH,
            url: `clients/${clientId}/consents/${userConsentRecordId}`,
            data: { status },
        });

        this.isUpdatingConsentStatus = false;
        if (!response.err) {
            NotificationManager.showSuccess(
                'Consent status updated successfully'
            );
            await this.fetchUserActiveConsentsWithStatus(clientId);
        } else {
            NotificationManager.showError('Failed to update consent status');
        }
    }

    @action
    async fetchWashCodeRecords(clientId: number, page = 0, perPage = 10) {
        this.isFetchingWashCodeRecords = true;

        const response = await this.rootStore.makeNetworkCall<
            PaginatedWashCodeRecordList
        >(
            {
                method: HttpMethod.GET,
                url: `clients/${clientId}/car-wash/records`,
                params: { page, perPage },
            },
            paginatedWashCodeRecordSchema
        );

        this.isFetchingWashCodeRecords = false;
        if (!response.err) {
            this.washCodeRecords = response.data;
        } else {
            this.washCodeRecords = null;
            NotificationManager.showError('Failed to fetch wash code records');
        }
    }
}
