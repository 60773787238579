import React from 'react';
import { Modal, Descriptions } from 'antd';
import { Client } from '../../../../../stores/client';

interface UnblockPaymentModalProps {
    isVisible: boolean;
    client: Client | null;
    onUnblock: (clientId: number) => void;
    onClose: () => void;
}

const UnblockPaymentModal: React.FC<UnblockPaymentModalProps> = ({
    isVisible,
    client,
    onUnblock,
    onClose,
}) => {
    return (
        <Modal
            title="Unblock Payment Method"
            visible={isVisible}
            onOk={() => {
                if (client?.id) {
                    onUnblock(client.id);
                }
                onClose();
            }}
            okText={'Unblock'}
            onCancel={onClose}
        >
            <Descriptions>
                <Descriptions.Item label="Client ID">
                    {client?.id}
                </Descriptions.Item>
            </Descriptions>
            <p>
                By performing this action, the selected client will be permitted
                to make unlimited payment method actions for the next{' '}
                <b>24 hours</b>.
            </p>
            <p>
                <b>Note:</b> Clients are always allowed to delete their payment
                methods, regardless of this status.
            </p>
            <h4>
                Are you sure you want to unlock this client's payment method
                actions?
            </h4>
        </Modal>
    );
};

export default UnblockPaymentModal;
