import { observable, action, makeObservable } from 'mobx';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { RootStore } from './root';
import * as yup from 'yup';
import { HttpMethod } from '../types';

const activeUsersSchema = yup.object({
    date: yup.string().required(),
    activeUsers: yup.string().required(),
    category: yup.string().required(),
    dateRange: yup.string().nullable(),
});

const activeUsersListSchema = yup.object({
    activeDayUsers: yup.array(activeUsersSchema),
});

export type ActiveUsersList = yup.InferType<typeof activeUsersListSchema>;

export type DateRange = {
    startDate: string;
    endDate: string;
};

export type ComparableDateRange = DateRange & {
    compareStartDate?: string;
    compareEndDate?: string;
};

const periodSchema = yup.object({
    currentPeriod: yup.number().nullable(),
    previousPeriod: yup.number().nullable(),
});

const featuresKpisResponseSchema = yup.object({
    washCodeGenerated: periodSchema,
    beaconGenerated: periodSchema,
    beaconRequested: periodSchema,
    visitCount: periodSchema,
    totalRevenueCtConnecte: periodSchema,
    averageRevenueCtConnectePerUser: periodSchema,
    totalAppUser: periodSchema,
    totalPromotionActivation: periodSchema,
    carwashPurchased: periodSchema,
    newPaymentsMethod: periodSchema,
});

export type FeaturesKpisResponseSchema = yup.InferType<
    typeof featuresKpisResponseSchema
>;

export class AnalyticsStore extends WithNetworkConcurrency {
    @observable fetchingCount = 0;
    @observable activeUsersList: ActiveUsersList | null = null;
    @observable featuresKpisList: FeaturesKpisResponseSchema | null = null;
    @observable userCount = 0;
    @observable userWithActionsCount = 0;
    @observable isFetchingAnalytics = false;
    @observable isFetchingKpis = false;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchData(this: AnalyticsStore, params: ComparableDateRange) {
        this.isFetchingAnalytics = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: 'analytics',
                params,
            },
            activeUsersListSchema
        );
        this.isFetchingAnalytics = false;

        if (response.data) {
            this.activeUsersList = response.data;
        }
    }
    @action
    async fetchAcquisition(this: AnalyticsStore, params: DateRange) {
        this.isFetchingAnalytics = true;
        const response = await this.rootStore.makeNetworkCall<{
            newUsers: number;
            usersWithAnyActions: number;
        }>({
            method: HttpMethod.GET,
            url: 'analytics/acquisition',
            params,
        });
        this.isFetchingAnalytics = false;

        if (response.data) {
            this.userCount = response.data.newUsers;
            this.userWithActionsCount = response.data.usersWithAnyActions;
        }
    }
    @action
    async fetchFeaturesKpis(this: AnalyticsStore, params: DateRange) {
        this.isFetchingKpis = true;
        const response = await this.rootStore.makeNetworkCall<
            FeaturesKpisResponseSchema
        >({
            method: HttpMethod.GET,
            url: 'analytics/features/kpi',
            params,
        });
        this.isFetchingKpis = false;

        if (response.data) {
            this.featuresKpisList = response.data;
        }
    }
}
