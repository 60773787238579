import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import style from './style.module.scss';

export const AcvtCarouselItem = (props: {
    image?: string;
    children?: JSX.Element;
}) => {
    return (
        <div
            className={style.carousel_item}
            style={{ backgroundImage: `url(${props.image})` }}
        >
            {props.children}
        </div>
    );
};

const AcvtCarousel = (props: { children: React.ReactNode }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const updateIndex = (newIndex: number) => {
        if (newIndex < 0) {
            newIndex = 0;
        } else if (newIndex >= React.Children.count(props.children)) {
            newIndex = React.Children.count(props.children) - 1;
        }

        setActiveIndex(newIndex);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1),
        trackMouse: true,
    });

    return (
        <div {...handlers} className={style.carousel}>
            <div
                className={style.inner}
                style={{ transform: `translateX(-${activeIndex * 100}%)` }}
            >
                {props.children}
            </div>
            <div className={style.indicators}>
                {React.Children.map(props.children, (child, index) => {
                    return (
                        <div
                            className={
                                index === activeIndex
                                    ? style.active
                                    : style.inactive
                            }
                            onClick={() => {
                                updateIndex(index);
                            }}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
};

export default AcvtCarousel;
