import React from 'react';
import style from './style.module.scss';
import { TranslationMap, SupportedLanguages } from '../../types';
import { ErrorAwareLabel } from '../error-aware-label';
import { RichText } from '../rich-text';
import { Button, Card, Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

interface CheckboxFormErrors {
    fr: boolean;
    en: boolean;
}

export interface Checkboxes {
    id: number | null;
    isMandatory: boolean | null;
    deletedAt?: string | null;
    translations: TranslationMap;
}

interface CheckboxFormValues {
    checkboxes: Checkboxes[];
}
interface CheckboxFormProps {
    values: CheckboxFormValues;
    errors: CheckboxFormErrors;
    onChange: (values: CheckboxFormValues) => void;
}

const cardStyle = {
    borderRadius: '10px',
    boxShadow: '0px 3px 15px 0px rgba(208, 216, 243, 0.4)',
    marginRight: '20px',
    marginBottom: '10px',
};

export const CheckboxForm = (props: CheckboxFormProps) => {
    const [checkboxesList, setcheckboxesList] = React.useState(
        props.values.checkboxes
    );

    React.useEffect(() => {
        setcheckboxesList(props.values.checkboxes);
    }, [props.values.checkboxes]);

    const removeCheckbox = async (checkbox: Checkboxes, index: number) => {
        checkbox.deletedAt = new Date().toString();

        setcheckboxesList([...checkboxesList]);
    };

    const setMandatory = async (checkbox: Checkboxes, check: boolean) => {
        checkbox.isMandatory = check;

        setcheckboxesList([...checkboxesList]);
    };

    const addCheckbox = async () => {
        setcheckboxesList([
            ...checkboxesList,
            {
                id: null,
                isMandatory: false,
                translations: {
                    fr: '',
                    en: '',
                },
            },
        ]);
        props.values.checkboxes = [
            ...checkboxesList,
            {
                id: null,
                isMandatory: false,
                translations: {
                    fr: '',
                    en: '',
                },
            },
        ];
    };
    return (
        <>
            {/* Checkboxes */}
            {checkboxesList
                .filter((checkboxes) => !checkboxes.deletedAt)
                .map((checkbox, index) => (
                    <Card
                        key={index}
                        className={style.card_container}
                        title={`Checkbox ${index + 1}`}
                        style={cardStyle}
                    >
                        <div className={style.input_row}>
                            {SupportedLanguages.map((lang) => (
                                <div
                                    key={lang}
                                    className={style.fixed_width_input_col}
                                >
                                    <ErrorAwareLabel
                                        text={
                                            lang === 'en'
                                                ? 'English Text'
                                                : 'French Text'
                                        }
                                        hasError={props.errors[lang]}
                                    />
                                    <RichText
                                        text={checkbox.translations[lang] ?? ''}
                                        onChange={(text) => {
                                            checkbox.translations[lang] = text;
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className={style.input_row}>
                            <span className="left_label">Is Mandatory</span>
                            <Switch
                                checked={checkbox.isMandatory === true}
                                onChange={(e) => setMandatory(checkbox, e)}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                            />
                        </div>
                        <div className={style.input_row}>
                            <Button
                                type="primary"
                                onClick={() => removeCheckbox(checkbox, index)}
                            >
                                Delete checkbox {index + 1}
                            </Button>
                        </div>
                    </Card>
                ))}
            <div className={style.input_row}>
                <Button type="primary" onClick={() => addCheckbox()}>
                    Add checkbox
                </Button>
            </div>
        </>
    );
};
