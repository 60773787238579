import React from 'react';
import style from './style.module.scss';

export const AcvtRedSticker = (props: { offer?: string }) => {
    return (
        <>
            <span
                className={
                    props.offer === undefined
                        ? style.no_sticker
                        : style.red_sticker
                }
            >
                <div
                    className={
                        props.offer && props.offer?.length < 12
                            ? style.offer_text
                            : style.offer_long_text
                    }
                >
                    {props.offer}
                </div>
            </span>
        </>
    );
};
