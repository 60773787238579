import React from 'react';
import { Tabs } from 'antd';
import { AcvtSupportedLanguages } from '../../../types/acvt';
import { TabType } from '../../top-tabs';

const { TabPane } = Tabs;
interface TabsAcvt {
    name: string;
    key: string;
    children?: React.ReactNode;
}

interface TabsProps {
    tabsArray: TabsAcvt[];
    tabType?: TabType;
    tabChangeLanguage?: (lang: AcvtSupportedLanguages) => void;
}

export const AcvtTopTabs = ({
    tabsArray,
    tabType,
    tabChangeLanguage,
}: TabsProps) => {
    const changeTab = () => {
        return tabChangeLanguage;
    };

    return (
        <>
            <Tabs type={tabType} onTabClick={changeTab()}>
                {tabsArray?.map((name) => (
                    <TabPane tab={name.name} key={name.key}>
                        {name.children}
                    </TabPane>
                ))}
            </Tabs>
        </>
    );
};
