import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Card, Button } from 'antd';
import { CarwashCustomerServiceStatus } from '../../../types';
import style from './style.module.scss';
import { IsActiveSwitch } from '../../../components';

const { Meta } = Card;

interface Props {
    isLoading: boolean;
    isUpdating: boolean;
    carwashCustomerServiceStatus: CarwashCustomerServiceStatus | null;
    onSubmit: (conf: CarwashCustomerServiceStatus) => void;
}

export const CarwashCustomerServiceStatusCard = (props: Props) => {
    const valuesSnapshot = React.useRef<null | CarwashCustomerServiceStatus>(
        null
    );
    const formik = useFormik<CarwashCustomerServiceStatus>({
        initialValues: {
            status: 'inactive',
        },
        onSubmit: props.onSubmit,
    });

    useEffect(() => {
        if (props.carwashCustomerServiceStatus) {
            valuesSnapshot.current = props.carwashCustomerServiceStatus;
            formik.setValues(props.carwashCustomerServiceStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.carwashCustomerServiceStatus]);

    const hasChanged = () => {
        return formik.values.status !== valuesSnapshot.current?.status;
    };

    return (
        <Card bordered={false} loading={props.isLoading} className={style.card}>
            <Meta title="CUSTOMER SERVICE CONFIGURATION" />
            <form className={style.card_content}>
                <div>
                    <IsActiveSwitch
                        values={formik.values}
                        title="Customer service phone button visibility"
                        onChange={(values) => {
                            formik.setValues({
                                ...formik.values,
                                ...values,
                            });
                        }}
                    />
                </div>
                <Button
                    type="primary"
                    disabled={!hasChanged()}
                    loading={props.isUpdating}
                    onClick={() => formik.submitForm()}
                >
                    Save
                </Button>
            </form>
        </Card>
    );
};
