import React from 'react';
import style from './style.module.scss';
import moment from 'moment';
import { Tooltip, DatePicker } from 'antd';
import { ErrorAwareLabel } from '..';
import { InfoCircleOutlined } from '@ant-design/icons';

interface DatesValues {
    startDate: string;
    endDate: string;
}
interface DatesErrors {
    startDate: boolean;
    endDate: boolean;
}
interface Tooltips {
    start: string;
    end: string;
}
interface DatesPicker {
    values: DatesValues;
    onChange: (values: DatesValues) => void;
    errors?: DatesErrors;
    tooltips?: Tooltips;
}

export const DatesPicker = (props: DatesPicker) => {
    return (
        <div
            className={`${style.fluid_input_col} ${style.vertical_col} left_spacer`}
        >
            <div className={'dates_holder'}>
                <div className="text_center inline_block">
                    <div className="light_label">
                        <ErrorAwareLabel
                            text="Start"
                            hasError={props.errors?.startDate}
                        />
                        &nbsp;
                        <Tooltip title={props.tooltips?.start}>
                            <InfoCircleOutlined />
                        </Tooltip>
                        <div className="vr"></div>
                    </div>
                    <DatePicker
                        allowClear={false}
                        format="YYYY-MM-DD hh:mm A"
                        showTime={{
                            format: 'HH:mm',
                            minuteStep: 15,
                        }}
                        value={moment(props.values.startDate)}
                        defaultValue={moment().endOf('day')}
                        onChange={(e) =>
                            props.onChange({
                                ...props.values,
                                startDate: e!.format(),
                            })
                        }
                    />
                </div>
                <div className="text_center inline_block">
                    <div className="light_label">
                        <ErrorAwareLabel
                            text="End"
                            hasError={props.errors?.endDate}
                        />
                        &nbsp;
                        <Tooltip title={props.tooltips?.end}>
                            <InfoCircleOutlined />
                        </Tooltip>
                        <div className="vr"></div>
                    </div>
                    <DatePicker
                        allowClear={false}
                        format="YYYY-MM-DD hh:mm A"
                        showTime={{
                            format: 'HH:mm',
                            minuteStep: 15,
                        }}
                        onChange={(e) =>
                            props.onChange({
                                ...props.values,
                                endDate: e!.format(),
                            })
                        }
                        value={moment(props.values.endDate)}
                        disabledDate={(d) =>
                            !d || d.isSameOrBefore(props.values.startDate)
                        }
                        disabled={!props.values.startDate}
                    />
                </div>
            </div>
        </div>
    );
};
