import { Promotion } from './promotion';
import {
    TranslationMap,
    NullableTranslationMap,
    Checkboxes,
    CmsUserHistory,
} from './index';
import moment from 'moment';

export interface ExternalGame {
    id: number;
    status: string;
    startDate: string;
    endDate: string;
    type: 'external';
    isFeatured: boolean;
    image: NullableTranslationMap;
    detailImage: NullableTranslationMap;
    title: TranslationMap;
    subTitle: TranslationMap;
    description: TranslationMap;
    externalUrl: TranslationMap;
    phoneNumberValidationRequired: boolean;
    six12RegistrationUrl: string;
    buttonTitle: TranslationMap;
    checkboxes: Checkboxes[];
    marketingDeepLink: string | null;
    cmsUserHistory: CmsUserHistory[] | null;
    cmsUserEmail: string | null;
}

export interface SportGame {
    id: number;
    status: string;
    startDate: string;
    endDate: string;
    type: 'sport';
    isFeatured: boolean;
    dates: string[];
    sport: Sport;
    promotion1: Promotion;
    promotion2: Promotion;
    promotion3: Promotion;
    image: NullableTranslationMap;
    detailImage: NullableTranslationMap;
    title: TranslationMap;
    subTitle: TranslationMap;
    description: TranslationMap;
    buttonTitle: TranslationMap;
    gameTodayMessage: TranslationMap;
    noGameTodayMessage: TranslationMap;
    checkboxes: Checkboxes[];
    marketingDeepLink: string | null;
    cmsUserHistory: CmsUserHistory[] | null;
    cmsUserEmail: string | null;
}

export type ExternalGamePayload = ExternalGame;

export type SportGamePayload = Omit<
    SportGame,
    'promotion1' | 'promotion2' | 'promotion3'
> & {
    promotionId1: number;
    promotionId2: number;
    promotionId3: number;
};

export type Game = ExternalGame | SportGame;
export type GamePayload = ExternalGamePayload | SportGamePayload;

export type GameType = 'external' | 'sport' | 'default';
export const GameType = ['external', 'sport', 'default'] as const;

export type Sport = 'hockey' | 'soccer';

export const initialExternalGame: ExternalGame = {
    id: 0,
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().startOf('day').toISOString(),
    status: 'inactive',
    type: 'external',
    isFeatured: false,
    image: { en: '', fr: '' },
    detailImage: { en: '', fr: '' },
    description: { en: '', fr: '' },
    subTitle: { en: '', fr: '' },
    title: { en: '', fr: '' },
    externalUrl: { en: '', fr: '' },
    phoneNumberValidationRequired: false,
    six12RegistrationUrl: '',
    buttonTitle: { en: '', fr: '' },
    checkboxes: [],
    cmsUserHistory: [],
    marketingDeepLink: null,
    cmsUserEmail: '',
};
export const initialSportGame: SportGamePayload = {
    id: 0,
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().startOf('day').toISOString(),
    status: 'inactive',
    type: 'sport',
    isFeatured: false,
    image: { en: '', fr: '' },
    detailImage: { en: '', fr: '' },
    description: { en: '', fr: '' },
    subTitle: { en: '', fr: '' },
    title: { en: '', fr: '' },
    buttonTitle: { en: '', fr: '' },
    gameTodayMessage: { en: '', fr: '' },
    noGameTodayMessage: { en: '', fr: '' },
    dates: [],
    sport: 'hockey',
    promotionId1: 0,
    promotionId2: 0,
    promotionId3: 0,
    checkboxes: [],
    marketingDeepLink: null,
    cmsUserHistory: [],
    cmsUserEmail: '',
};
