import React from 'react';
import style from './style.module.scss';

type DeliveryCard = {
    image?: JSX.Element;
    title?: string;
    description?: string;
    link?: string;
};

export const AcvtDeliveryCard = ({
    image,
    title,
    description,
    link,
}: DeliveryCard) => {
    return (
        <>
            <div className={style.container}>
                <div className={style.card}>
                    <div className={style.image}>{image}</div>
                    <div className={style.text_grid}>
                        <div id={style.title}>{title}</div>
                        <div id={style.description}>{description}</div>
                        <div id={style.link}>{link}</div>
                    </div>
                </div>
            </div>
        </>
    );
};
