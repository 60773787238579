import React from 'react';
import { observer } from 'mobx-react';
import { useNavigation } from '../../utils/use-navigation';
import { useAuthStore, useSmsHistoryStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { SmsSearchParams, SmsLog } from '../../stores/sms-history';
import { Table, Tag } from 'antd';
import { LegacyPagination } from '../../components';
import { ColumnProps } from 'antd/es/table';
import style from './style.module.scss';
import moment from 'moment';

export const SmsHistoryPage = observer(() => {
    const store = useSmsHistoryStore();
    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const [queryParams, setParams] = useQueryParams<SmsSearchParams>();

    React.useEffect(() => {
        store.fetchList(queryParams);
    }, [store, queryParams]);

    const columns = React.useMemo<ColumnProps<SmsLog>[]>(
        () => [
            {
                title: 'Message Id',
                dataIndex: 'messageId',
                key: 'messageId',
            },
            {
                title: 'Name',
                key: 'name',
                render: (_: unknown, record: SmsLog) => (
                    <span
                        className={style.clickable_span}
                        onClick={() =>
                            navigation.goToClients(String(record.userId))
                        }
                    >
                        {record.userFirstName} {record.userLastName}
                    </span>
                ),
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber',
            },
            {
                title: 'Status',
                key: 'status',
                render: (_: unknown, record: SmsLog) => {
                    if (record.status === null) {
                        return <Tag>pending</Tag>;
                    } else if (record.status === 'SUCCESS') {
                        return <Tag color="green">success</Tag>;
                    } else {
                        return <Tag color="red">{record.status}</Tag>;
                    }
                },
            },
            {
                title: 'Provider',
                key: 'provider',
                dataIndex: 'provider',
            },
            {
                title: 'Sent At',
                key: 'sent_at',
                render: (_: unknown, record: SmsLog) =>
                    moment(record.createdAt).format('YYYY-MM-DD hh:mm'),
            },
        ],
        []
    );

    return (
        <div className={style.container}>
            <LegacyPagination
                className={style.pagination}
                params={queryParams}
                meta={store.list}
                onChange={(pageNumber) => setParams({ page: pageNumber })}
                onPageSizeChange={(size) => setParams({ perPage: size })}
            />
            <Table<SmsLog>
                rowKey="id"
                columns={columns}
                scroll={{ x: 900 }}
                dataSource={store.list?.results}
                loading={store.isFetching}
                pagination={false}
            />
        </div>
    );
});
