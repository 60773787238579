import { Select } from 'antd';
import React from 'react';
import { AcvtSupportedLanguages } from '../../../../types/acvt';
import style from './style.module.scss';

const { Option } = Select;

export const AcvtInStorePage = (props: {
    language?: AcvtSupportedLanguages;
    cards: React.ReactNode;
    categoriesList?: (string | undefined | null)[];
    selectedCategory: string | undefined;
    changeCategories: (selection: string) => void;
}) => {
    const changeCategories = () => {
        return props.changeCategories;
    };

    return (
        <>
            <div className={style.page_container}>
                <div className={style.selector_container}>
                    <Select
                        placeholder={
                            props.language === 'en'
                                ? 'Categories'
                                : 'Catégories'
                        }
                        allowClear
                        style={{ width: 320 }}
                        onChange={changeCategories()}
                        defaultValue={props.selectedCategory}
                    >
                        {props.categoriesList?.map((category) => (
                            <Option key={`${category}`} value={`${category}`}>
                                {category}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={style.instore_content}>
                    <h3 className={style.header}>
                        {props.language === 'en' ? 'IN STORE' : 'EN MAGASIN'}
                    </h3>
                    {props.cards}
                </div>
            </div>
        </>
    );
};
