import { observable, action } from 'mobx';
import { RootStore } from './root';
import { HttpMethod, User } from '../types';
import * as yup from 'yup';

const FetchUserPayload = yup.object({
    id: yup.number().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    updatedAt: yup.string().required(),
    createdAt: yup.string().required(),
});

export class UserStore {
    @observable user: User | null = null;
    @observable isFetchingUser = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    setUser(this: UserStore, user: User) {
        this.user = user;
    }

    @action
    async fetchUser(this: UserStore) {
        if (this.isFetchingUser) {
            return;
        }

        this.isFetchingUser = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/users/me',
            },
            FetchUserPayload
        );
        this.isFetchingUser = false;

        if (!response.err) {
            this.setUser(response.data);
        }
    }
}
