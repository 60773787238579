import React from 'react';
import { Tabs } from 'antd';
import style from './style.module.scss';

const { TabPane } = Tabs;
export type TabType = 'line' | 'card' | 'editable-card';
interface SingleTab {
    name: string;
    key: string;
    children?: React.ReactNode;
}

interface TabsProps {
    tabsArray: SingleTab[];
    tabType?: TabType;
}

export const TopTabs = ({ tabsArray, tabType }: TabsProps) => {
    return (
        <>
            <div className={style.toptabs_container}>
                <Tabs type={tabType}>
                    {tabsArray?.map((name) => (
                        <TabPane tab={name.name} key={name.key}>
                            {name.children}
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </>
    );
};
