import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import { Tag, Table, Input } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Offer, OfferSearchParams } from '../../stores/offer';
import { useAuthStore, useOfferStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { useNavigation } from '../../utils/use-navigation';
import { getItemProp } from '../../utils';
import { LegacyPagination } from '../../components';

const renderTitle = (_: unknown, record: Offer) => {
    const enTitle = getItemProp(record.items, 'en', 'title');
    const frTitle = getItemProp(record.items, 'fr', 'title');
    return (
        <div>
            {enTitle}
            <br />
            <span className="light_label">{frTitle}</span>
        </div>
    );
};

const renderIsActive = (_: unknown, record: Offer) => {
    // eslint-disable-next-line no-constant-condition
    return true ? (
        <Tag color="#87d068">Active</Tag>
    ) : (
        <Tag color="#f50">Not active</Tag>
    );
};

export const OffersPage = observer(() => {
    const offerStore = useOfferStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }
    const [queryParams, updateQueryParams, resetQueryParams] = useQueryParams<
        OfferSearchParams
    >();

    React.useEffect(() => {
        offerStore.fetchPaginatedOffers(queryParams);
    }, [offerStore, queryParams]);

    const columns: ColumnProps<Offer>[] = [
        {
            title: 'Title',
            key: 'title',
            width: '20%',
            render: renderTitle,
        },
        {
            title: 'Is active',
            key: 'is_active',
            render: renderIsActive,
            width: '10%',
        },
    ];

    const goToEditPage = (o: Offer) => () => {
        navigation.goToOfferEdit(o.id);
    };

    return (
        <div>
            <h1>Promotional Offers</h1>
            <a
                href="/offer/create"
                onClick={() => navigation.goToOfferCreate()}
            >
                Create new
            </a>
            <div className={style.query_box}>
                <Input
                    value={queryParams.q}
                    onChange={(e) => resetQueryParams('q', e.target.value)}
                    allowClear={true}
                    placeholder="Search"
                />
            </div>
            <LegacyPagination
                params={queryParams}
                meta={offerStore.paginatedOffers}
                onChange={(pageNumber) =>
                    updateQueryParams({ page: pageNumber })
                }
                onPageSizeChange={(size) =>
                    updateQueryParams({ perPage: size })
                }
            />
            <Table<Offer>
                className="clickable_table"
                rowKey="id"
                dataSource={offerStore.paginatedOffers?.results}
                columns={columns}
                loading={offerStore.isFetchingPaginatedCount > 0}
                pagination={false}
                onRow={(r) => ({
                    onClick: goToEditPage(r),
                })}
            />
        </div>
    );
});
