import moment from 'moment';
import { RANGE_PROPERTIES } from '../utils/const';

export interface GoogleAnalyticsData {
    value: number;
    date: string;
    category: string;
}

export interface LastDaysData {
    value_1: number | undefined;
    value_2: number | undefined;
    value_3: number | undefined;
}

export interface NPS {
    value: number;
    date: string | any;
}

export const CHART_CONFIG = (data: GoogleAnalyticsData[]) => {
    return {
        data: data,
        height: 300,
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        color: ['#1366a6', '#ed3426', '#f6931e'],
    };
};

const Normalizer = (min: number, max: number) => ({
    normalize: (x: number) => min + x * (max - min),
    denormalize: (x: number) => (x + max) / (max - min),
});

const gaugeNormalizer = Normalizer(-100, 100);

export const GAUGE_CONFIG = (npsPeriodScore: number) => {
    return {
        percent: gaugeNormalizer.denormalize(npsPeriodScore),
        height: 180,
        width: 180,
        range: {
            ticks: [0, 1 / 2, 2 / 3, 1],
            color: ['#F4664A', '#FAAD14', '#30BF78'],
        },
        meta: {
            percent: {
                tickInterval: 0.1,
                formatter: (text: string) => {
                    const num = gaugeNormalizer.normalize(+text);
                    return `${num > 0 ? `${num}` : num}`;
                },
            },
        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                },
            },
        },
        statistic: {
            title: {
                content: 'NPS',
            },
            content: {
                content: npsPeriodScore.toFixed(),
                style: {
                    fontSize: '18px',
                    lineHeight: '16px',
                    color:
                        npsPeriodScore > 35
                            ? '#30BF78'
                            : npsPeriodScore >= 0
                            ? '#FAAD14'
                            : '#F4664A',
                },
            },
        },
    };
};

export const LINE_CONFIG = (data: NPS[]) => {
    return {
        data,
        height: 300,
        xField: 'date',
        yField: 'value',
        point: {
            size: 7,
            shape: 'circle',
        },
        label: {
            style: {
                fill: '#aaa',
            },
        },
    };
};

export type ActiveUsers = {
    date: string;
    activeUsers: string;
    category: string;
    dateRange?: string | null;
};

export const mapAnalytics = (
    date: string | undefined,
    activeUsers: ActiveUsers[],
    properties: RANGE_PROPERTIES
): GoogleAnalyticsData[] => {
    return activeUsers
        .filter((user) =>
            properties === 'current'
                ? filterCurrentUsers(user, date)
                : filterCompareUsers(user, date)
        )
        .map((r) => ({
            value: Number(r.activeUsers),
            date: r.date,
            category: r.category,
        }));
};

export const getLastDayData = (
    data: GoogleAnalyticsData[],
    date: string | undefined
): LastDaysData => {
    return {
        value_1: data.find(
            (activeUsers) =>
                moment(activeUsers.date, 'MMMM Do YYYY').utc().format() ===
                    moment(date, 'YYYY-MM-DD').utc().format() &&
                activeUsers.category === 'active1DayUsers'
        )?.value,
        value_2: data.find(
            (activeUsers) =>
                moment(activeUsers.date, 'MMMM Do YYYY').utc().format() ===
                    moment(date, 'YYYY-MM-DD').utc().format() &&
                activeUsers.category === 'active7DayUsers'
        )?.value,
        value_3: data.find(
            (activeUsers) =>
                moment(activeUsers.date, 'MMMM Do YYYY').utc().format() ===
                    moment(date, 'YYYY-MM-DD').utc().format() &&
                activeUsers.category === 'active28DayUsers'
        )?.value,
    };
};

const filterCurrentUsers = (
    user: ActiveUsers,
    date: string | undefined
): boolean => {
    return (
        user.dateRange === undefined ||
        (user.dateRange === 'current' &&
            moment(user.date, 'MMMM Do YYYY') >= moment(date, 'YYYY-MM-DD'))
    );
};

const filterCompareUsers = (
    user: ActiveUsers,
    date: string | undefined
): boolean => {
    return user.dateRange &&
        user.dateRange === 'compare' &&
        moment(user.date, 'MMMM Do YYYY') <= moment(date, 'YYYY-MM-DD')
        ? true
        : false;
};
