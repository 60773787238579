import React from 'react';
import style from './style.module.scss';
import { Select } from 'antd';

type DropDownServicesProps = {
    options: {
        label: string;
        value: string;
    }[];
    selectedOptions: string[];
    onChange?: (value: string[]) => void;
    placeholder?: string;
};

export const DropDownServices = ({
    options,
    selectedOptions,
    onChange,
    placeholder,
}: DropDownServicesProps) => {
    return (
        <>
            <Select
                mode="tags"
                showArrow
                allowClear
                placeholder={placeholder}
                options={options}
                value={selectedOptions}
                onChange={onChange}
                className={style.services_select}
            />
        </>
    );
};
