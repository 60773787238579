import React from 'react';
import styles from './style.module.scss';
import { ReactComponent as DownArrow } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../icons/ArrowUp.svg';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface StatsBoxProps {
    title: string;
    currentValue: number;
    difference?: number;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    isCurrency?: boolean;
    tooltipText?: string;
}

const DifferenceDisplay: React.FC<{ difference?: number }> = ({
    difference,
}) => {
    const isDifferenceValid =
        difference !== undefined &&
        !isNaN(difference) &&
        difference !== Infinity;
    const isDifferencePositive = difference !== undefined && difference >= 0;

    const sign = isDifferencePositive ? '+' : '-';
    const differenceClass = isDifferencePositive
        ? styles.differenceUp
        : styles.differenceDown;
    const arrowDirection = getArrow(isDifferencePositive);

    return isDifferenceValid ? (
        <div className={`${styles.difference} ${differenceClass}`}>
            <span className={styles.arrowCircle}>{arrowDirection}</span>
            {sign}
            {Math.abs(difference!).toFixed(2)}%
        </div>
    ) : (
        <div className={styles.difference}>Nothing trending</div>
    );
};

const getArrow = (isDifferencePositive: boolean | undefined) => {
    return isDifferencePositive ? (
        <ArrowUp width={23} height={22} />
    ) : (
        <DownArrow width={23} height={22} />
    );
};

const formatNumber = (num: number, isCurrency?: boolean) => {
    const value = num.toLocaleString();
    return isCurrency ? `$${value}` : value;
};

const StatsBox: React.FC<StatsBoxProps> = ({
    title,
    currentValue,
    difference,
    icon: Icon,
    isCurrency,
    tooltipText,
}) => {
    const formattedValue = formatNumber(currentValue, isCurrency);

    return (
        <div className={styles.statsBox}>
            <div className={styles.title}>
                {Icon && (
                    <span className={styles.icon_wrapper}>
                        <Icon />
                    </span>
                )}
                {title}
                {tooltipText && (
                    <Tooltip title={tooltipText}>
                        <InfoCircleOutlined
                            style={{
                                marginLeft: '0.8em',
                                marginBottom: '0.2em',
                            }}
                        />
                    </Tooltip>
                )}
            </div>
            <div className={styles.value}>{formattedValue}</div>
            <DifferenceDisplay difference={difference} />
        </div>
    );
};

export default StatsBox;
