import { observable, action, makeObservable } from 'mobx';
import * as yup from 'yup';
import { MomentSchema } from '../utils/yup-schemas';
import { RootStore } from './root';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { HttpMethod } from '../types';

export const VisitSuccess = ['OPEN', 'COMPLETE', 'ASSOCIATED', 'PENDING'];

export const visitSchema = yup
    .object({
        id: yup.number().required(),
        userId: yup.number().required(),
        orderId: yup.string().required(),
        shoppingSessionId: yup.string().notRequired().nullable(),
        enterAt: yup.date().notRequired().nullable(),
        exitedAt: yup.date().notRequired().nullable(),
        paymentProcessAt: yup.date().notRequired().nullable(),
        status: yup.string().notRequired().nullable(),
        itemCount: yup.string().notRequired().nullable(),
        user: yup.object({
            firstName: yup.string(),
            lastName: yup.string(),
        }),
        total: yup.number().notRequired().nullable(),
    })
    .required();

export const singleVisitSchema = yup.object({
    visit: yup.object({
        user: yup.object({
            id: yup.number().required(),
            firstName: yup.string().notRequired(),
            lastName: yup.string().notRequired(),
            birthday: new MomentSchema().notRequired().nullable(),
            email: yup.string().notRequired().nullable(),
            isEmailConfirmed: yup.boolean().notRequired().nullable(),
            phoneNumber: yup.string().notRequired().nullable(),
            isPhoneNumberConfirmed: yup.boolean().notRequired().nullable(),
            picture: yup.string().notRequired().nullable(),
            language: yup.string().notRequired().nullable(),
        }),
        order: yup.object({
            id: yup.string().required(),
            status: yup.string().notRequired().nullable(),
            itemCount: yup.string().notRequired().nullable(),
            receiptDate: new MomentSchema().notRequired().nullable(),
            total: yup.number().notRequired().nullable(),
            subTotal: yup.number().notRequired().nullable(),
            items: yup.array(
                yup.object({
                    code: yup.string().notRequired(),
                    amount: yup.number().notRequired().nullable(),
                    description: yup.string().notRequired(),
                    quantity: yup.number().notRequired().nullable(),
                    voided: yup.boolean().notRequired().nullable(),
                    discounts: yup.array(
                        yup.object({
                            amount: yup.number().notRequired(),
                            quantity: yup.number().notRequired(),
                            type: yup.string().notRequired(),
                        })
                    ),
                })
            ),
            taxes: yup.array(
                yup.object({
                    levelId: yup.number().notRequired().nullable(),
                    description: yup.string().notRequired(),
                    amount: yup.number().notRequired().nullable(),
                })
            ),
            storeNum: yup.number().notRequired().nullable(),
            receiptNum: yup.string().notRequired().nullable(),
            storeLocation: yup.string().notRequired().nullable(),
            paymentMethod: yup.object({
                cardType: yup.string().notRequired().nullable(),
                cardMask: yup.string().notRequired().nullable(),
            }),
            visitDuration: yup.number().notRequired().nullable(),
            discount: yup.number().notRequired().nullable(),
        }),
    }),
});

const paginatedListSchema = yup.object({
    page: yup.number().required(),
    perPage: yup.number().required(),
    total: yup.number().required(),
    minutesToReceipt: yup.number(),
    results: yup.array(visitSchema).default([]),
});

export type Visit = yup.InferType<typeof visitSchema>;
export type PaginatedVisitList = yup.InferType<typeof paginatedListSchema>;
export type SingleVisit = yup.InferType<typeof singleVisitSchema>;

export type SearchParams = {
    perPage?: number;
    page?: number;
    q?: string;
    enterAt?: string;
    exitedAt?: string;
};

export class VisitStore extends WithNetworkConcurrency {
    @observable fetchingCount = 0;
    @observable list: PaginatedVisitList | null = null;
    @observable singleVisit: SingleVisit | null = null;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchList(this: VisitStore, params: SearchParams): Promise<Visit[]> {
        const tag = this.getTag();
        this.fetchingCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: 'visits',
                params,
            },
            paginatedListSchema
        );
        this.fetchingCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.list = response.data;

            return response.data.results;
        }

        return [];
    }

    @action
    async fetchSingleVisit(this: VisitStore, orderId: string) {
        this.fetchingCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: `visits/${orderId}`,
            },
            singleVisitSchema
        );
        this.fetchingCount--;

        if (response.data) {
            this.singleVisit = response.data;
        }
    }
}
