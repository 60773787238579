import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import { Table, Tag, DatePicker, Button } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { DebounceInput, PageHeader, Pagination } from '../../components';
import { ColumnProps } from 'antd/es/table';
import { useVisitStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { SearchParams, SingleVisit, Visit } from '../../stores/visit';
import moment from 'moment';
import useWindowDimensions from '../../utils/display';
import { oktaAuth } from '../../App';
import { ReactComponent as FileCsv } from '../../icons/FileCsv.svg';
import { VisitDetails } from '../../components/visit-details';

const { RangePicker } = DatePicker;

const renderIds = (_: unknown, record: Visit) => {
    return (
        <div className={style.user_information}>
            <div className={style.client_name}>
                {record.user.firstName}&nbsp;{record.user.lastName}
            </div>
            <div>Order ID: {record.orderId}</div>
            <div className="light_label">SSID: {record.shoppingSessionId}</div>
        </div>
    );
};

const renderTimeToReceipt = (_: unknown, record: Visit) => {
    if (record.paymentProcessAt && record.enterAt) {
        const duration = moment.duration(
            moment(record.paymentProcessAt).diff(record.enterAt)
        );
        const minutes = Math.round(duration.as('minute'));
        return (
            <div className={style.time_container}>
                {minutes >= 8 ? (
                    <div
                        className={style.time_indicator}
                        id={style.long_time}
                    ></div>
                ) : (
                    <div
                        className={style.time_indicator}
                        id={style.short_time}
                    ></div>
                )}
                {minutes} min{minutes > 1 ? 's' : ''}
            </div>
        );
    } else {
        return (
            <div className={style.time_container}>
                <div className={style.time_indicator} id={style.no_time}></div>
                <div className={style.no_time}>N/A</div>
            </div>
        );
    }
};

const renderStatus = (_: unknown, record: Visit) => {
    let tagColor = 'red';
    switch (record.status) {
        case 'COMPLETE':
            tagColor = 'cyan';
            break;
        case 'EMPTY_CART':
            tagColor = 'default';
            break;
        case 'PENDING':
            tagColor = 'gold';
            break;
        case 'OPEN':
            tagColor = 'geekblue';
            break;
        case 'ASSOCIATED':
            tagColor = 'blue';
            break;
    }
    return (
        <Tag color={tagColor} style={{ border: 'none' }}>
            {record.status}
        </Tag>
    );
};

const renderItems = (_: unknown, record: Visit) => {
    return (
        record.status !== 'authorize' &&
        record.status !== 'CANCELED' && (
            <div>
                <div className="mid_label">{record.itemCount}</div>
            </div>
        )
    );
};

const renderNfcTap = (_: unknown, record: Visit) => {
    return (
        <>
            <div>{moment(record.enterAt).format('ll')}</div>
            <div>{moment(record.enterAt).format('LT')}</div>

            {record.enterAt == null && (
                <div className={style.stop_outlined}>
                    <StopOutlined className="big_label" />
                </div>
            )}
        </>
    );
};

const renderPayment = (_: unknown, record: Visit) => {
    return (
        <>
            {record.paymentProcessAt ? (
                <>
                    <div>{moment(record.paymentProcessAt).format('ll')}</div>
                    <div>{moment(record.paymentProcessAt).format('LT')}</div>
                </>
            ) : null}

            {record.paymentProcessAt == null && (
                <div className={style.stop_outlined}>
                    <StopOutlined className="big_label" />
                </div>
            )}
        </>
    );
};

export const VisitsPage = observer(() => {
    const visitStore = useVisitStore();
    const [
        params,
        setParams,
        setParamsAndGoToFirstPage,
        setManyParamsAndGoToFirstPage,
    ] = useQueryParams<SearchParams>();

    const [searchValue, setSearchValue] = React.useState(params.q);

    const [visitList, setVisitList] = React.useState<Visit[]>([]);

    const [visitDetails, setVisitDetails] = React.useState(false);

    const [visitData, setVisitData] = React.useState<SingleVisit | null>();

    React.useEffect(() => {
        visitStore.fetchList(params).then((element) => {
            setVisitList(element);
        });
    }, [visitStore, params]);

    const downloadXlsx = async () => {
        const token = oktaAuth.getAccessToken() as string;
        const url = new URL(
            `${process.env.REACT_APP_API_URL}/api-cms/visits/download`
        );
        const searchCriterias: Record<string, string> = {};
        if (params.q) searchCriterias.q = params.q;
        if (params.enterAt) searchCriterias.enterAt = params.enterAt;
        if (params.exitedAt) searchCriterias.exitedAt = params.exitedAt;
        url.search = new URLSearchParams(searchCriterias).toString();
        const response = await fetch(url.toString(), {
            headers: { jwt: token },
        });
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.href = blobUrl;
        anchor.download = 'visits.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(anchor);
    };

    const columns: ColumnProps<Visit>[] = [
        {
            title: 'ID',
            key: 'orderId',
            render: renderIds,
            width: '25%',
        },
        {
            title: 'NFC Tap',
            key: 'enterAt',
            render: renderNfcTap,
            width: '10%',
        },
        {
            title: 'Payment',
            key: 'exitedAt',
            render: renderPayment,
            width: '10%',
        },
        {
            title: 'Status',
            key: 'status',
            render: renderStatus,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (_: unknown, record: Visit) => {
                return record.total != null && <div>{`$${record.total}`}</div>;
            },
            width: '10%',
        },
        {
            title: 'Items',
            key: 'items',
            render: renderItems,
        },
        {
            title: 'Time to receipt',
            key: 'timeToReceipt',
            render: renderTimeToReceipt,
        },
    ];

    const { windowHeight } = useWindowDimensions();

    const showSingleVisit = (visit: Visit) => {
        visitStore.fetchSingleVisit(visit.orderId).then(() => {
            setVisitData(visitStore.singleVisit);
        });

        !visitDetails && setVisitDetails((current) => !current);
    };

    const closeVisitDetails = () => {
        setVisitDetails((current) => !current);
    };

    return (
        <div className={style.visits_content}>
            <PageHeader title="Visits">
                <Button
                    className={style.xlsx_download_btn}
                    type="ghost"
                    onClick={downloadXlsx}
                >
                    <div>Export to XLSX</div>
                    <FileCsv className={style.svg_icon} />
                </Button>
            </PageHeader>

            <div className={style.table_header}>
                <div className={style.query_box}>
                    <div className={style.input_wrapper}>
                        <DebounceInput
                            debounce={300}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onStableChange={(val) =>
                                setParamsAndGoToFirstPage('q', val)
                            }
                            allowClear={true}
                            placeholder="Search"
                        />
                    </div>
                    <RangePicker
                        className={style.range_picker}
                        allowEmpty={[true, true]}
                        placeholder={['Enter at', 'Exited at']}
                        defaultValue={[
                            params.enterAt ? moment(params.enterAt) : null,
                            params.exitedAt ? moment(params.exitedAt) : null,
                        ]}
                        onCalendarChange={(dates) => {
                            setManyParamsAndGoToFirstPage({
                                enterAt: dates?.[0]?.toISOString(),
                                exitedAt: dates?.[1]?.toISOString(),
                            });
                        }}
                        format="YYYY-MM-DD hh:mm A"
                        showTime={{
                            format: 'HH:mm',
                            minuteStep: 15,
                        }}
                    />
                </div>

                <div className={style.pagination_container}>
                    <Pagination
                        className={style.pagination}
                        params={params}
                        meta={visitStore.list}
                        onChange={(pageNumber) =>
                            setParams({ page: pageNumber })
                        }
                        onPageSizeChange={(size) =>
                            setParams({ perPage: size })
                        }
                    />
                </div>
            </div>
            <Table<Visit>
                rowKey="id"
                columns={columns}
                dataSource={visitList}
                loading={visitStore.fetchingCount > 0}
                bordered
                pagination={false}
                scroll={{ y: windowHeight - 300, x: 900 }}
                onRow={(r) => ({
                    onClick: () => showSingleVisit(r),
                })}
            ></Table>
            <div
                className={
                    visitDetails
                        ? `${style.visit_details} ${style.active}`
                        : `${style.visit_details}`
                }
            >
                {visitStore.fetchingCount > 0 ? (
                    <></>
                ) : (
                    <VisitDetails
                        onCloseClick={closeVisitDetails}
                        visitObject={visitData}
                    />
                )}
            </div>
        </div>
    );
});
