import * as yup from 'yup';
import React from 'react';
import style from './style.module.scss';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import { useAuthStore, useGamesStore } from '../../contexts/mobx';
import { useParams } from 'react-router-dom';
import { useNavigation } from '../../utils/use-navigation';
import {
    ErrorAwareLabel,
    IsActiveSwitch,
    DatesPicker,
    GameForm,
    NavigationHeader,
    Images,
    NotificationManager,
    CheckboxForm,
    DeepLinkLabel,
    ModalManager,
} from '../../components';
import {
    CloseOutlined,
    CheckOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { ExternalGame, GameType, initialExternalGame } from '../../types/games';
import { Input, Spin, Switch, Card, Tooltip } from 'antd';
import {
    AcvtBottomTabBar,
    AcvtCanvas,
    AcvtGamePage,
    Acvt,
} from '../../components/advanced-ct-visualisation-tool';
import { AcvtSupportedLanguages, AcvtTabs } from '../../types/acvt';
import { SupportedLanguages } from '../../types';
import { Text } from '../../utils/text';

const YupParams = yup.object({
    action: yup.string().oneOf(['create', 'edit']).required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(v === undefined && action !== 'create');
        }),
    type: yup.string<GameType>(),
});

type Params = yup.InferType<typeof YupParams>;

const transformGame = (game: ExternalGame): ExternalGame => {
    return {
        id: game.id,
        startDate: game.startDate,
        endDate: game.endDate,
        status: game.status,
        type: game.type,
        isFeatured: game.isFeatured,
        image: game.image,
        detailImage: game.detailImage,
        description: game.description,
        subTitle: game.subTitle,
        title: game.title,
        externalUrl: game.externalUrl,
        phoneNumberValidationRequired: game.phoneNumberValidationRequired,
        six12RegistrationUrl: game.six12RegistrationUrl,
        buttonTitle: game.buttonTitle,
        checkboxes: game.checkboxes,
        marketingDeepLink: game.marketingDeepLink,
        cmsUserEmail: game.cmsUserEmail,
        cmsUserHistory: game.cmsUserHistory,
    };
};

export const GameExternalPage = observer(() => {
    const gamesStore = useGamesStore();
    const params = useParams<Params>();
    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const [isActivateSix12, setIsActivateSix12] = React.useState<boolean>(
        false
    );

    const [isFeatured, setIsFeatured] = React.useState<boolean>(false);

    const YupGameTranslation = yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    });

    const YupGameUrlTranslation = yup.object({
        fr: yup.string().url().required(),

        en: yup.string().url().required(),
    });

    const YupGameExternalValidationSchema = yup.object({
        title: YupGameTranslation,
        externalUrl: YupGameUrlTranslation,
        six12RegistrationUrl: yup.string().url().notRequired().nullable(),
    });

    const formik = useFormik<ExternalGame>({
        initialValues: initialExternalGame,
        validateOnMount: true,
        validationSchema: YupGameExternalValidationSchema,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {},
    });

    const updateGame = async () => {
        formik.values.cmsUserEmail = authStore.claims?.email || null;

        const unknownGame = formik.values;
        if (isActivateSix12 && formik.values.six12RegistrationUrl == null) {
            NotificationManager.showError(
                'Six12 Registration URL must not be empty'
            );
        } else if (
            isFeatured &&
            (formik.values.detailImage['en'] == null ||
                formik.values.detailImage['fr'] == null)
        ) {
            NotificationManager.showError(
                'A detail image is mandatory when game is to be featured on homepage'
            );
        } else {
            await gamesStore.modifyGame(unknownGame);
            navigation.replaceToGames();
        }
    };

    const createGame = async () => {
        formik.values.cmsUserEmail = authStore.claims?.email || null;

        const unknownGame = formik.values;
        if (isActivateSix12 && formik.values.six12RegistrationUrl == null) {
            NotificationManager.showError(
                'Six12 Registration URL must not be empty'
            );
        } else if (
            isFeatured &&
            (formik.values.detailImage['en'] == null ||
                formik.values.detailImage['fr'] == null)
        ) {
            NotificationManager.showError(
                'A detail image is mandatory when game is to be featured on homepage'
            );
        } else {
            const success = await gamesStore.createGame(unknownGame);
            if (success) {
                navigation.replaceToGames();
            }
        }
    };

    const onDeleteConfirm = async () => {
        await gamesStore.deleteGame(formik.values.type, formik.values.id);
        navigation.goToGames();
    };

    React.useEffect(() => {
        if (params.action === 'edit') {
            setIsFetching(true);
            gamesStore
                .fetchSingleExternalGame(Number(params.id!))
                .then((game) => {
                    formik.setValues(transformGame(game));
                    if (game.six12RegistrationUrl) setIsActivateSix12(true);
                })
                .catch(() => navigation.goToGameCreate('external'))
                .finally(() => setIsFetching(false));
        }
    }, [gamesStore]);

    // The type inference is not working well for nested error schema. So we are
    // going to cheat a little bit using lodash
    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return hasError && isTouched;
    };

    const transformId = params.id ? Number(params.id) : undefined;

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '1px 4px 20px 1px rgba(208, 216, 243, 0.4)',
        marginRight: '20px',
    };

    const [lang, setLang] = React.useState<AcvtSupportedLanguages>('en');
    const [selectedTab, setSelectedTab] = React.useState<AcvtTabs>('promos');
    const [isCardOpened, setIsCardOpened] = React.useState({
        homePromoCard: false,
        homeGameCard: false,
        promoCard: false,
        gameCard: true,
    });

    const changeLanguage = (language: AcvtSupportedLanguages) => {
        setLang(language);
    };

    return (
        <div>
            <NavigationHeader
                id={transformId}
                type={Text.navigationHeader.game}
                action={params.action}
                onBackClick={() => window.history.back()}
                onSaveClick={updateGame}
                onCreateClick={createGame}
                disabled={!formik.isValid}
                isLoading={gamesStore.isUpsertingGame}
                cmsUserHistory={formik.values.cmsUserHistory}
                onDeleteClick={ModalManager.showDeleteModal({
                    onConfirm: onDeleteConfirm,
                })}
                isDeleting={gamesStore.isDeletingGame}
                canBeDeleted={true}
            />
            {isFetching && <Spin />}
            {formik.values.type === 'external' && (
                <div className={style.page}>
                    <form style={{ display: isFetching ? 'none' : 'initial' }}>
                        <div className={style.section_one}>
                            <div className={style.left_column}>
                                <div className={style.input_row}>
                                    <div className="side_section">
                                        <IsActiveSwitch
                                            values={formik.values}
                                            title="Active"
                                            onChange={(values) => {
                                                formik.setValues({
                                                    ...formik.values,
                                                    ...values,
                                                });
                                            }}
                                        />
                                        <div
                                            className={`${style.fluid_input_col} ${style.vertical_col}`}
                                        >
                                            <div className={style.input_row}>
                                                <span className="left_label">
                                                    Phone Validation Required
                                                </span>
                                                <Switch
                                                    checked={
                                                        formik.values
                                                            .phoneNumberValidationRequired ===
                                                        true
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'phoneNumberValidationRequired',
                                                            e
                                                        );
                                                        formik.setFieldValue(
                                                            'six12RegistrationUrl',
                                                            null
                                                        );
                                                        setIsActivateSix12(
                                                            false
                                                        );
                                                    }}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    defaultChecked
                                                />
                                            </div>

                                            <div className={style.input_row}>
                                                <div
                                                    className={
                                                        style.light_label
                                                    }
                                                >
                                                    <Tooltip
                                                        className={
                                                            style.tooltip
                                                        }
                                                        title={
                                                            "Featured games appear as full width cards on the mobile apps' home page"
                                                        }
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </div>

                                                <span className="left_label">
                                                    Is Featured Game
                                                </span>
                                                <Switch
                                                    checked={
                                                        formik.values
                                                            .isFeatured === true
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            'isFeatured',
                                                            e
                                                        );
                                                        setIsFeatured(false);
                                                    }}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    defaultChecked
                                                />
                                            </div>

                                            {formik.values
                                                .phoneNumberValidationRequired && (
                                                <div>
                                                    <div
                                                        className={
                                                            style.input_row
                                                        }
                                                    >
                                                        <span className="left_label">
                                                            Activate Six12
                                                            Registration
                                                        </span>
                                                        <Switch
                                                            checked={
                                                                isActivateSix12
                                                            }
                                                            onChange={(e) => {
                                                                formik.setFieldValue(
                                                                    'six12RegistrationUrl',
                                                                    null
                                                                );
                                                                setIsActivateSix12(
                                                                    e
                                                                );
                                                            }}
                                                            checkedChildren={
                                                                <CheckOutlined />
                                                            }
                                                            unCheckedChildren={
                                                                <CloseOutlined />
                                                            }
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            style.input_row
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                style.fixed_width_input_col
                                                            }
                                                        >
                                                            <ErrorAwareLabel
                                                                text={`Registration URL`}
                                                                hasError={checkIfHasError(
                                                                    'six12RegistrationUrl'
                                                                )}
                                                            />

                                                            <Input
                                                                name={`six12RegistrationUrl`}
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                onBlur={
                                                                    formik.handleBlur
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .six12RegistrationUrl
                                                                }
                                                                disabled={
                                                                    !isActivateSix12
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <DatesPicker
                                        values={formik.values}
                                        errors={{
                                            startDate: checkIfHasError(
                                                'startDate'
                                            ),
                                            endDate: checkIfHasError('endDate'),
                                        }}
                                        tooltips={{
                                            start:
                                                'Game & contest becomes visible in app on start date',
                                            end:
                                                'Game & contest becomes visible in app on end date',
                                        }}
                                        onChange={(values) => {
                                            formik.setValues({
                                                ...formik.values,
                                                ...values,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <Card
                            className={style.card_container}
                            title="Images"
                            style={cardStyle}
                        >
                            <Images
                                image={formik.values.image}
                                title={
                                    formik.values.isFeatured
                                        ? 'Featured Image'
                                        : 'Image'
                                }
                                onChange={(image) => {
                                    formik.setValues({
                                        ...formik.values,
                                        image,
                                    });
                                }}
                                onDelete={(image) => {
                                    formik.setValues({
                                        ...formik.values,
                                        image,
                                    });
                                }}
                            />
                            {formik.values.isFeatured && (
                                <Images
                                    image={formik.values.detailImage}
                                    title="Detail View Image"
                                    onChange={(detailImage) => {
                                        formik.setValues({
                                            ...formik.values,
                                            detailImage,
                                        });
                                    }}
                                    onDelete={(detailImage) => {
                                        formik.setValues({
                                            ...formik.values,
                                            detailImage,
                                        });
                                    }}
                                />
                            )}
                        </Card>

                        {/* Details */}
                        <div className={style.config_container}>
                            <div className={style.items_defs_box}>
                                <div className={style.items_content}>
                                    <GameForm
                                        values={formik.values}
                                        onChange={(values) => {
                                            formik.setValues({
                                                ...formik.values,
                                                ...values,
                                            });
                                        }}
                                        errors={{
                                            fr: checkIfHasError('fr'),
                                            en: checkIfHasError('en'),
                                        }}
                                    />
                                    <div className={style.input_row}>
                                        {SupportedLanguages.map((lang) => (
                                            <div
                                                key={lang}
                                                className={
                                                    style.fixed_width_input_col
                                                }
                                            >
                                                <ErrorAwareLabel
                                                    text={`External URL ${lang}`}
                                                    hasError={checkIfHasError(
                                                        `externalUrl.${lang}`
                                                    )}
                                                />
                                                <Input
                                                    name={`externalUrl.${lang}`}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values
                                                            .externalUrl[lang]
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {formik.values.marketingDeepLink && (
                                        <DeepLinkLabel
                                            text={
                                                formik.values.marketingDeepLink
                                            }
                                        />
                                    )}
                                    <CheckboxForm
                                        values={formik.values}
                                        onChange={(values) => {
                                            formik.setValues({
                                                ...formik.values,
                                                ...values,
                                            });
                                        }}
                                        errors={{
                                            fr: checkIfHasError('fr'),
                                            en: checkIfHasError('en'),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <Card className={style.card_container} title="App Preview">
                        <AcvtCanvas changeLang={changeLanguage}>
                            <Acvt backgroundColor="#d8281c">
                                <>
                                    <AcvtGamePage
                                        key={formik.values.id}
                                        language={lang}
                                        gameObject={formik.values}
                                        opened={setIsCardOpened}
                                    />
                                    <AcvtBottomTabBar
                                        language={lang}
                                        tab={selectedTab}
                                        tabSelected={setSelectedTab}
                                        onEditMode={true}
                                    />
                                </>
                            </Acvt>
                        </AcvtCanvas>
                    </Card>
                </div>
            )}
        </div>
    );
});
