import { CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip, Card } from 'antd';
import React from 'react';
import { NotificationManager } from '../notification-manager';

interface DeepLinkText {
    text: string | null;
}

const clipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    NotificationManager.showSuccess('URL copied to clipboard');
};

const cardStyle = {
    marginTop: '20px',
    marginBottom: '20px',
    marginRight: '20px',
    borderRadius: '10px',
    boxShadow: '1px 4px 20px 1px rgba(208, 216, 243, 0.4)',
};

export const DeepLinkLabel = (props: DeepLinkText) => {
    return (
        <Card style={cardStyle} title="Marketing: Game deeplink">
            <span>
                {props.text}
                <Tooltip title="Copy deep link url">
                    <Button
                        type="link"
                        icon={<CopyOutlined />}
                        onClick={() => clipboard(props.text || '')}
                    />
                </Tooltip>
            </span>
        </Card>
    );
};
