import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { Game, GamePayload, SportGame, ExternalGame } from '../types/games';
import { NotificationManager } from '../components';
import * as yup from 'yup';
import { HttpMethod, TranslationMap } from '../types';

const YupGames = yup.object({
    id: yup.number().required(),
    type: yup.string().required(),
    image: yup
        .object({
            fr: yup.string().required(),
            en: yup.string().required(),
        })
        .required(),
    title: yup
        .object({
            fr: yup.string().required(),
            en: yup.string().required(),
        })
        .required(),
});

const YupVisibleGamesList = yup.array(YupGames).required();

export type GamesSearchParams = {
    [key in keyof Game]?: string;
};

interface GameForSelect {
    id: number;
    type: string;
    image: TranslationMap;
    title: TranslationMap;
}

export class GamesStore extends WithNetworkConcurrency {
    @observable games: Game[] | null = null;
    @observable filteredGames: Game[] | undefined = undefined;
    @observable isFetchingCount = 0;
    @observable isUpsertingGame = false;
    @observable isDeletingGame = false;
    @observable isFetchingSingleGame = false;
    @observable private cachedGame: Game | null = null;
    @observable isFetchingForSelect = false;
    @observable gamesForSelect: GameForSelect[] = [];

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    private setGames(
        this: GamesStore,
        games: Game[],
        searchParams: GamesSearchParams
    ) {
        this.games = games;
        this.filteredGames = games;

        if (searchParams) {
            this.filterGames(searchParams);
        }
    }

    @action
    async fetchGames(this: GamesStore, searchParams: GamesSearchParams) {
        const tag = this.getTag();

        this.isFetchingCount++;

        const response = await this.rootStore.makeNetworkCall<Game[]>({
            method: HttpMethod.GET,
            url: '/games',
        });

        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.setGames(response.data, searchParams);
        }
    }

    @action
    async fetchSingleSportGame(
        this: GamesStore,
        gameId: number
    ): Promise<SportGame> {
        this.isFetchingSingleGame = true;

        const response = await this.rootStore.makeNetworkCall<SportGame>({
            method: HttpMethod.GET,
            url: `/games/sport/${gameId}`,
        });

        this.isFetchingSingleGame = false;

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async fetchSingleExternalGame(
        this: GamesStore,
        gameId: number
    ): Promise<ExternalGame> {
        this.isFetchingSingleGame = true;

        const response = await this.rootStore.makeNetworkCall<ExternalGame>({
            method: HttpMethod.GET,
            url: `/games/external/${gameId}`,
        });

        this.isFetchingSingleGame = false;

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async createGame(this: GamesStore, game: GamePayload) {
        this.isUpsertingGame = true;

        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            data: game,
            url: `/games`,
        });

        this.isUpsertingGame = false;

        if (!response.err) {
            NotificationManager.showSuccess('Game created');
        }

        return !response.err;
    }

    @action
    async modifyGame(this: GamesStore, gamePayload: GamePayload) {
        this.isUpsertingGame = true;

        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            data: gamePayload,
            url: `/games/${gamePayload.id}`,
        });

        this.isUpsertingGame = false;

        if (!response.err) {
            NotificationManager.showSuccess('Game modified');
        }
    }

    @action
    async deleteGame(this: GamesStore, gameType: string, gameId: number) {
        this.isDeletingGame = true;

        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/games/${gameType}/${gameId}`,
        });

        this.isDeletingGame = false;

        if (!response.err) {
            NotificationManager.showSuccess(`Game id ${gameId} deleted.`);
        }
    }

    @action
    async fetchForSelect(this: GamesStore) {
        this.isFetchingForSelect = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/games/visible',
            },
            YupVisibleGamesList
        );
        this.isFetchingForSelect = false;

        if (!response.err) {
            this.gamesForSelect = response.data;
        } else {
            throw response.err;
        }
    }

    @action
    filterGames(gamesFilters: GamesSearchParams) {
        this.filteredGames = this.games?.filter((game) => {
            const keys = Object.keys(gamesFilters) as (keyof Game)[];

            for (const key of keys) {
                if (
                    (game[key] === undefined ||
                        game[key] !== gamesFilters[key]) &&
                    key !== 'title'
                ) {
                    return false;
                } else if (
                    key === 'title' &&
                    !game.title.en.includes(gamesFilters[key] as string)
                ) {
                    return false;
                }
            }
            return true;
        });
    }
}
