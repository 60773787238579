import React, { useCallback, useRef } from 'react';
import { Tabs, Table, Button, Switch, Tag, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Grant, AnonymousGrant } from '../../../../stores/promotion-grant';
import style from './style.module.scss';
import star from '../../../../icons/Icon_Coupons_Gagnants_1_1X.svg';

type WinnableSectionProps = {
    isPrivate: boolean | null | undefined;
    setFieldValue: (
        field: string,
        value: unknown,
        shouldValidate?: boolean
    ) => void;
    onFileSelected: (file: File) => void;
    isUploading: boolean;
    disableUpload: () => boolean;
    downloadWinners: () => void;
    grants: Grant[] | null;
    anonymousGrants: AnonymousGrant[] | null;
    isFetching: boolean;
    isFetchingAnon: boolean;
};

const columnsNormal = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Email',
        dataIndex: ['client', 'email'],
        key: 'email',
    },
    {
        title: 'Name',
        key: 'name',
        render: (_: unknown, record: Grant) =>
            `${record.client.firstName} ${record.client.lastName}`,
    },
    {
        title: 'Language',
        key: 'language',
        render: (_: unknown, record: Grant) => (
            <Tag color={record.client.language === 'fr' ? 'blue' : 'magenta'}>
                {record.client.language}
            </Tag>
        ),
    },
    {
        title: 'Number of Wins',
        key: 'numberOfWins',
        render: (_: unknown, record: Grant) => (
            <div className={style.star_container}>
                <img className={style.star_img} src={star} alt="star icon" />
                <span className={style.normal}>{record.cnt}</span>
            </div>
        ),
    },
];

const columnsAnonymous = [
    {
        title: 'Email',
        key: 'email',
        render: (_: unknown, record: AnonymousGrant) => (
            <Tag>{record.email}</Tag>
        ),
    },
    {
        title: 'Claimed by',
        key: 'claimedBy',
        render: (_: unknown, record: AnonymousGrant) => (
            <div>
                <span>{record.claimByGrant?.client.fullName}</span>
            </div>
        ),
    },
];

export const WinnableSection = ({
    isPrivate,
    setFieldValue,
    onFileSelected,
    isUploading,
    disableUpload,
    downloadWinners,
    grants,
    anonymousGrants,
    isFetching,
    isFetchingAnon,
}: WinnableSectionProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleSwitchChange = useCallback(
        (checked: boolean) => setFieldValue('isPrivate', checked),
        [setFieldValue]
    );

    const handleFileSelected = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (file) {
                onFileSelected(file);
            }
        },
        [onFileSelected]
    );

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className={style.winnable_container}>
            <div className={style.input_row}>
                <div className={style.fluid_input_col}>
                    <span className={style.left_label}>Is Winnable</span>
                    <Switch
                        checked={isPrivate ?? false}
                        onChange={handleSwitchChange}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </div>
            </div>

            {isPrivate && (
                <>
                    <div className={style.winnable_buttons_row}>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileSelected}
                        />
                        <Button
                            className={style.red_button}
                            onClick={handleButtonClick}
                            type="primary"
                            loading={isUploading}
                            disabled={disableUpload()}
                        >
                            Upload List
                        </Button>
                        <Button
                            className={style.red_button}
                            onClick={downloadWinners}
                            type="primary"
                        >
                            Download List
                        </Button>
                    </div>
                    <div className={style.winners_box}>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane
                                tab={`Registered Winners (${
                                    grants?.length ?? 0
                                })`}
                                key="1"
                            >
                                {isFetching ? (
                                    <Spin />
                                ) : (
                                    <Table<Grant>
                                        rowKey="id"
                                        columns={columnsNormal}
                                        dataSource={grants ?? undefined}
                                        pagination={false}
                                    />
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={`Anonymous Winners (${
                                    anonymousGrants?.length ?? 0
                                })`}
                                key="2"
                            >
                                {isFetchingAnon ? (
                                    <Spin />
                                ) : (
                                    <Table<AnonymousGrant>
                                        rowKey="id"
                                        columns={columnsAnonymous}
                                        dataSource={
                                            anonymousGrants ?? undefined
                                        }
                                        pagination={false}
                                    />
                                )}
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </>
            )}
        </div>
    );
};
