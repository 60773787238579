import React, { ChangeEvent } from 'react';
import { Button } from 'antd';

interface Props {
    onFileSelected(file: File): void;
    accept?: string;
    isLoading?: boolean;
    disabled?: boolean;
    text?: string;
}

export const ButtonFileInput = (props: Props) => {
    const hiddenInputRef = React.useRef<HTMLInputElement | null>(null);

    const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            // User cancelled the operation
            return;
        }
        props.onFileSelected(file);
    };

    const onButtonClick = () => {
        if (hiddenInputRef.current) {
            // the following line is for clearing the latest value. Without this, we cannot select the same
            // file twice
            hiddenInputRef.current.value = '';
            hiddenInputRef.current.click();
        }
    };

    // Without form autoComplete="off", Chrome was triggering the onChange event on back navigation, causing
    // the file to be automatically upload without any user interaction
    return (
        <form autoComplete="off">
            <Button
                loading={props.isLoading}
                type="primary"
                onClick={onButtonClick}
                disabled={props.disabled}
            >
                {props.text ?? 'Choose file'}
            </Button>
            <input
                type="file"
                name="hidden-input"
                accept={props.accept ?? '*'}
                ref={hiddenInputRef}
                onChange={onFileSelected}
                style={{
                    visibility: 'hidden',
                    height: 0,
                    width: 0,
                    zIndex: -1000,
                    userSelect: 'none',
                }}
            />
        </form>
    );
};

ButtonFileInput.displayName = 'ButtonFileInput';
