import * as yup from 'yup';
import { RootStore } from './root';
import { HttpMethod } from '../types';

const YupGrant = yup.object({
    id: yup.number().required(),
    clientId: yup.number().required(),
    cnt: yup.number().required(),
    client: yup
        .object({
            id: yup.number().required(),
            firstName: yup.string().notRequired(),
            lastName: yup.string().notRequired(),
            fullName: yup.string().notRequired(),
            gender: yup.string().nullable(),
            language: yup.string().nullable(),
            email: yup.string().nullable(),
        })
        .required(),
});

const YupAnonymousGrant = yup.object({
    id: yup.number().required(),
    email: yup.string().required(),
    claimByGrant: yup
        .object({
            id: yup.number().required(),
            clientId: yup.number().required(),
            client: yup
                .object({
                    id: yup.number().required(),
                    firstName: yup.string().required(),
                    lastName: yup.string().required(),
                    fullName: yup.string().required(),
                    gender: yup.string().nullable(),
                    language: yup.string().nullable(),
                    email: yup.string().nullable(),
                })
                .required(),
        })
        .nullable(),
});

const YupGrantList = yup.array(YupGrant);
const YupAnonymousGrantList = yup.array(YupAnonymousGrant);

export type Grant = yup.InferType<typeof YupGrant>;
export type AnonymousGrant = yup.InferType<typeof YupAnonymousGrant>;

export class PromotionGrantStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    /**
     * Wrapper to fetch the grants associated with a promotion
     *
     * @param this
     * @param promotionId
     */
    async fetchGrants(this: PromotionGrantStore, promotionId: number) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: `/promotion-grants?promotionId=${promotionId}`,
            },
            YupGrantList
        );

        if (response.data) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    /**
     * Wrapper to fetch the anonymous grants associated with a promotion
     *
     * @param this
     * @param promotionId
     */
    async fetchAnonymousGrants(this: PromotionGrantStore, promotionId: number) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: `/promotion-grants/anonymous?promotionId=${promotionId}`,
            },
            YupAnonymousGrantList
        );

        if (response.data) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    /**
     * Upload new grants
     *
     * @param this
     * @param promotionId
     * @param emails
     */
    async uploadGrants(
        this: PromotionGrantStore,
        promotionId: number,
        emails: unknown[]
    ) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: `/promotion-grants`,
            data: {
                promotionId,
                emails,
            },
        });

        if (response.err) {
            throw response.err;
        }
    }
}
