import React from 'react';
import { Button } from 'antd';
import {
    ArrowLeftOutlined,
    SaveOutlined,
    FileAddOutlined,
    DeleteOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import { CmsUserHistory } from '../../types';
import { CmsUserHistoryHeader } from '..';

interface NavigationHeaderProps {
    id?: number;
    type?: string;
    action: string;
    onBackClick: () => void;
    onSaveClick: () => void;
    onCreateClick: () => void;
    onDeleteClick?: () => void;
    onDuplicateClick?: () => void;
    disabled: boolean;
    isLoading: boolean;
    isDeleting?: boolean;
    canBeDeleted?: boolean;
    cmsUserHistory?: CmsUserHistory[] | null;
}

export const NavigationHeader = (props: NavigationHeaderProps) => {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.2rem',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <ArrowLeftOutlined onClick={props.onBackClick} />
                        {props.type && (
                            <h1 style={{ margin: '0' }}>
                                {props.type}
                                {props.type && props.id && (
                                    <span>&nbsp;#{props.id}</span>
                                )}
                            </h1>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >
                        {props.onDuplicateClick && (
                            <Button
                                type="default"
                                icon={<CopyOutlined />}
                                disabled={props.disabled}
                                onClick={props.onDuplicateClick}
                                loading={props.isLoading}
                            >
                                Duplicate
                            </Button>
                        )}
                        {props.action === 'create' && (
                            <Button
                                id="create-button"
                                type="primary"
                                icon={<FileAddOutlined />}
                                disabled={props.disabled}
                                onClick={props.onCreateClick}
                                loading={props.isLoading}
                            >
                                Create
                            </Button>
                        )}

                        {props.action === 'edit' && (
                            <Button
                                loading={props.isLoading}
                                id="save-button"
                                type="primary"
                                icon={<SaveOutlined />}
                                disabled={props.disabled}
                                onClick={props.onSaveClick}
                            >
                                Save
                            </Button>
                        )}
                        {props.action === 'duplicate' && (
                            <Button
                                loading={props.isLoading}
                                id="save-button"
                                type="primary"
                                icon={<SaveOutlined />}
                                disabled={props.disabled}
                                onClick={props.onSaveClick}
                            >
                                Save duplicate
                            </Button>
                        )}
                        {props.canBeDeleted && props.action !== 'duplicate' && (
                            <Button
                                loading={props.isDeleting}
                                id="delete-button"
                                type="primary"
                                danger={true}
                                icon={<DeleteOutlined />}
                                onClick={props.onDeleteClick}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
                {props.cmsUserHistory && (
                    <CmsUserHistoryHeader
                        cmsUserHistory={props.cmsUserHistory}
                    />
                )}
            </div>
        </div>
    );
};
