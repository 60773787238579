import React from 'react';
import { getItemProp } from '../../utils';
import style from './style.module.scss';
import { Promotion } from '../../stores/promotion';
import { ImageHolder } from '../../components';
import stars from '../../icons/Icon_Coupons_Gagnants_2_1X.svg';
import { getPromotionStatus } from '../../utils/promotion-status';

interface Props {
    promotion: Promotion;
}

export const Item = (props: Props) => {
    const { promotion } = props;
    const status = getPromotionStatus(promotion);
    const winner = promotion.isPrivate ? true : false; // allows to toggle item display between winning coupon or not
    let active = promotion.isActive ? true : false;
    const expired = status === 'expired';
    const upcoming = status === 'upcoming';

    if (active && winner) {
        active = false;
    } else if (active && expired) {
        active = false;
    } else if (active && upcoming) {
        active = false;
    }

    return (
        <div
            className={`${style.promotion_item} ${active ? style.active : ''} ${
                winner ? style.winner : ''
            } ${expired ? style.expired : ''} ${
                upcoming ? style.upcoming : ''
            } ${promotion.status === 'none' ? style.none : ''}`}
        >
            {/* Left column */}
            <div className={style.first_row}>
                <div className={style.left_column}>
                    <div className={style.image}>
                        <ImageHolder
                            src={getItemProp(
                                promotion.items,
                                'en',
                                'thumbnail'
                            )}
                            showOverlay={true}
                            grayscale={expired}
                        >
                            {promotion.promotionalOffer != null && (
                                <div
                                    className={`${style.promo} ${
                                        expired ? style.expired : ''
                                    }`}
                                >
                                    {getItemProp(
                                        promotion.promotionalOffer.items,
                                        'en',
                                        'title'
                                    )}
                                </div>
                            )}
                        </ImageHolder>
                    </div>
                </div>

                {/* Right column */}
                <div className={style.right_column}>
                    {winner && <img src={stars} alt="stars icon" />}

                    <div
                        className={`${style.category} ${
                            expired ? style.expired : ''
                        }`}
                    >
                        <span
                            className={`${style.category_text} ${
                                expired ? style.expired : ''
                            }`}
                        >
                            {getItemProp(
                                promotion.category.items,
                                'en',
                                'title'
                            )}
                        </span>
                    </div>

                    <div
                        className={`${style.titles} ${
                            expired ? style.expired : ''
                        }`}
                    >
                        <span>
                            {getItemProp(promotion.items, 'en', 'title')}
                        </span>
                    </div>

                    <div
                        className={`${style.description} ${
                            expired ? style.expired : ''
                        }`}
                    >
                        <span>
                            {getItemProp(promotion.items, 'en', 'subTitle')}
                        </span>
                    </div>

                    <div
                        className={`${style.description} ${
                            expired ? style.expired : ''
                        }`}
                    >
                        <span>
                            Valid between {promotion.startDate.format('LL')} and
                            {promotion.endDate.format('LL')}
                        </span>
                        {status != null && (
                            <div
                                className={`${style.date_status} ${
                                    expired ? style.expired : ''
                                }`}
                            >
                                {status}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
