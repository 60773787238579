import React from 'react';
import Papa from 'papaparse';
import { useParams } from 'react-router-dom';
import { Tabs, Spin, Tag, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import style from './style.module.scss';
import { Grant, AnonymousGrant } from '../../stores/promotion-grant';
import { useAuthStore, usePromotionGrantStore } from '../../contexts/mobx';
import { ButtonFileInput, NotificationManager } from '../../components';
import star from '../../icons/Icon_Coupons_Gagnants_1_1X.svg';
import { useNavigation } from '../../utils/use-navigation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const columnsNormal: ColumnProps<Grant>[] = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Name',
        key: 'name',
        render: (_: unknown, record: Grant) =>
            `${record.client.firstName} ${record.client.lastName}`,
    },
    {
        title: 'Language',
        key: 'language',
        render: (_: unknown, record: Grant) => (
            <Tag color={record.client.language === 'fr' ? 'blue' : 'magenta'}>
                {record.client.language}
            </Tag>
        ),
    },
    {
        title: 'Newsletter',
        key: 'newsletter',
        render: (_, record) => <Tag>No info yet</Tag>,
    },
    {
        title: 'Postal code',
        key: 'postalCode',
        dataIndex: 'postalCode',
    },
    {
        title: 'Phone number',
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
    },
    {
        title: 'Number of wins',
        key: 'numberOfWins',
        render: (_: unknown, record: Grant) => (
            <div>
                <img src={star} alt="star icon" />
                <span className={style.normal}>{record.cnt}</span>
            </div>
        ),
    },
];

const columnsAnonymus: ColumnProps<AnonymousGrant>[] = [
    {
        title: 'Email',
        key: 'email',
        render: (_: unknown, record: AnonymousGrant) => (
            <Tag>{record.email}</Tag>
        ),
    },
    {
        title: 'Claimed by',
        key: 'claimedBy',
        render: (_: unknown, record: AnonymousGrant) => (
            <div>
                <span>{record.claimByGrant?.client.fullName}</span>
            </div>
        ),
    },
];

export const PromotionGrantsPage = (props: Props) => {
    const store = usePromotionGrantStore();
    const params = useParams<{ id: string }>();
    const [grants, setGrants] = React.useState<Grant[] | null>(null);
    const [anonymousGrants, setAnonymousGrants] = React.useState<
        AnonymousGrant[] | null
    >(null);
    const [isFetching, setIsFetching] = React.useState(false);
    const [isFetchingAnon, setIsFetchingAnon] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const dataSourceNormal = grants?.map((x) => ({
        id: x.id,
        clientId: x.clientId,
        cnt: x.cnt,
        client: x.client,
    }));

    const dataSourceAnonymous = anonymousGrants?.map((x) => ({
        id: x.id,
        email: x.email,
        claimByGrant: x.claimByGrant,
    }));

    const fetchGrants = React.useCallback(() => {
        setIsFetching(true);
        setIsFetchingAnon(true);
        store
            .fetchGrants(Number(params.id))
            .then(setGrants)
            .catch(console.log)
            .finally(() => setIsFetching(false));

        store
            .fetchAnonymousGrants(Number(params.id))
            .then(setAnonymousGrants)
            .catch(console.log)
            .finally(() => setIsFetchingAnon(false));
    }, [params.id]);

    React.useEffect(() => {
        fetchGrants();
    }, []);

    const onFileSelected = (file: File) => {
        Papa.parse<unknown>(file, {
            complete: async (results) => {
                try {
                    const emails = results.data.flat().filter((e) => !!e);
                    setIsUploading(true);
                    await store.uploadGrants(Number(params.id), emails);
                    fetchGrants();
                } catch (e) {
                    console.error(e);
                } finally {
                    setIsUploading(false);
                }
            },
            error: () => {
                NotificationManager.showError('Error parsing the file');
            },
        });
    };

    return (
        <div>
            <div>
                <ButtonFileInput
                    onFileSelected={onFileSelected}
                    accept=".csv"
                    isLoading={isUploading}
                />
            </div>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Registered Winners" key="1">
                    {isFetching ? (
                        <Spin />
                    ) : (
                        <Table<Grant>
                            rowKey="id"
                            columns={columnsNormal}
                            dataSource={dataSourceNormal}
                            pagination={false}
                        />
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Anonymous Winners" key="2">
                    {isFetchingAnon ? (
                        <Spin />
                    ) : (
                        <Table<AnonymousGrant>
                            rowKey="id"
                            columns={columnsAnonymus}
                            dataSource={dataSourceAnonymous}
                            pagination={false}
                        />
                    )}
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

PromotionGrantsPage.displayName = 'PromotionGrantsPage';
