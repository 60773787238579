import React from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import { useNavigation } from '../../utils/use-navigation';
import { useGenericParams } from '../../utils/use-generic-params';
import {
    Input,
    Select,
    Button,
    Modal,
    Spin,
    Switch,
    Divider,
    Card,
    Tooltip,
    DatePicker,
} from 'antd';
import * as yup from 'yup';
import {
    ImageInput,
    ImageHolder,
    ErrorAwareLabel,
    ModalManager,
} from '../../components';
import {
    CloseOutlined,
    CheckOutlined,
    RollbackOutlined,
} from '@ant-design/icons';
import get from 'lodash/get';
import style from './style.module.scss';
import promoStyle from '../promotion-page/style.module.scss';
import { NotificationManager } from '../../components/notification-manager';
import {
    useAuthStore,
    usePromotionStore,
    useStore,
    useGamesStore,
} from '../../contexts/mobx';
import { FeaturedTypes, FeaturedTypesRegEx } from '../../utils/const';
import { ChromePicker } from 'react-color';
import AcvtCarousel, {
    Acvt,
    AcvtBottomTabBar,
    AcvtCanvas,
    AcvtCarouselItem,
    AcvtHomePage,
} from '../../components/advanced-ct-visualisation-tool';
import { AcvtSupportedLanguages, AcvtTabs } from '../../types/acvt';
import { Text } from '../../utils/text';
import moment from 'moment';
import { HttpMethod } from '../../types';

const validationSchema = yup.object({
    id: yup.number(),
    type: yup.string<FeaturedTypes>().matches(FeaturedTypesRegEx).required(),
    isActive: yup.boolean().required(),
    geolocated: yup.boolean().nullable(),
    isImageTitle: yup.boolean(),
    canBeDeleted: yup.boolean().required(),
    gameTypeId: yup.string().nullable().notRequired(),
    gameId: yup.number().nullable().notRequired(),
    gameType: yup.string().nullable().notRequired(),
    startDate: yup.string(),
    endDate: yup.string(),
    promotionId: yup
        .number()
        .notRequired()
        .nullable()
        .test('shouldBeDefined', 'It should be defined', function (v) {
            const type = this.resolve(yup.ref('type'));
            return !(type === 'promotion' && !v);
        }),
    items: yup
        .array(
            yup.object({
                backgroundImage: yup.string().required(),
                imageTitle: yup.string().notRequired().nullable(),
                textTitle: yup.string().notRequired().nullable(),
                textColor: yup.string().nullable(),
                cta: yup.string().notRequired().nullable(),
                externalUrl: yup.string().url().notRequired().nullable(),

                language: yup.string<'en' | 'fr'>().matches(/en|fr/).required(),
            })
        )
        .min(2)
        .max(2)
        .required(),
});

type Form = yup.InferType<typeof validationSchema>;

/**
 * As far has I know, it is not possible to access parent field a descendant child array.
 * So I am going to do this part of the validation manually
 *
 * @param values
 */
const externalUrlValidation = (values: Form) => {
    if (values.type !== 'external_url') {
        return true;
    }
    return values.items[0].externalUrl && values.items[1].externalUrl;
};

export const FeaturedSinglePage = observer(() => {
    const promotionStore = usePromotionStore();
    const gamesStore = useGamesStore();
    const rootStore = useStore();
    const navigation = useNavigation();
    const [query, setQuery] = React.useState<string>('');
    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);
    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const params = useGenericParams(() =>
        navigation.replaceToFeaturedCreation()
    );

    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    React.useEffect(() => {
        if (params.action === 'edit') {
            setIsFetching(true);
            rootStore
                .makeNetworkCall(
                    {
                        method: HttpMethod.GET,
                        url: `/featured/${params.id}`,
                    },
                    validationSchema
                )
                .then((response) => {
                    if (response.data) {
                        formik.setValues(response.data);
                        formik.setFieldValue(
                            'isImageTitle',
                            response.data.items[0].imageTitle !== ''
                        );
                        formik.setFieldValue(
                            'gameTypeId',
                            `${response.data.gameType}_${response.data.gameId}`
                        );
                    } else {
                        navigation.replaceToFeaturedCreation();
                    }
                })
                .finally(() => setIsFetching(false));
        }
    }, [rootStore]);

    const formik = useFormik<Form>({
        initialValues: {
            id: 0,
            type: 'promotion',
            isActive: true,
            canBeDeleted: false,
            geolocated: null,
            isImageTitle: false,
            gameTypeId: null,
            gameId: null,
            gameType: null,
            startDate: moment().startOf('day').toISOString(),
            endDate: moment().endOf('day').toISOString(),
            items: [
                {
                    backgroundImage: '',
                    imageTitle: '',
                    textTitle: '',
                    textColor: null,
                    cta: '',
                    externalUrl: '',
                    language: 'en',
                },
                {
                    backgroundImage: '',
                    imageTitle: '',
                    textTitle: '',
                    textColor: null,
                    cta: '',
                    externalUrl: '',
                    language: 'fr',
                },
            ],
        },
        validateOnMount: true,
        validationSchema,
        onSubmit: () => console.log('submit'),
    });

    React.useEffect(() => {
        promotionStore.fetchForSelect();
    }, [promotionStore]);

    React.useEffect(() => {
        gamesStore.fetchForSelect();
    }, [gamesStore]);

    const [modalUrl, setModalUrl] = React.useState<string | undefined>();
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);

    const getTouched = (path: string) => {
        return Boolean(get(formik.touched, path, false));
    };

    const createFeatured = async () => {
        setIsUpserting(true);
        const response = await rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: '/featured',
            data: formik.values,
        });
        setIsUpserting(false);
        if (!response.err) {
            NotificationManager.showSuccess('Featured created');
            navigation.replaceToFeaturedList();
        }
    };

    const updateFeatured = async () => {
        setIsUpserting(true);
        const response = await rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            url: `/featured/${params.id}`,
            data: formik.values,
        });
        setIsUpserting(false);
        if (!response.err) {
            NotificationManager.showSuccess('Featured updated');
        }
    };

    const onDeleteConfirm = async () => {
        setIsDeleting(true);
        const response = await rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/featured/${params.id}`,
        });
        setIsDeleting(false);
        if (!response.err) {
            NotificationManager.showSuccess('Featured deleted');
            navigation.replaceToFeaturedList();
        }
    };

    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return hasError && (isTouched || params.action !== 'create');
    };

    const promos = promotionStore.promotionsForSelect;
    const promoList = promos.filter((promo) => promo.isActive === true);

    const gameList = gamesStore.gamesForSelect;

    const [lang, setLang] = React.useState<AcvtSupportedLanguages>('en');
    const [acvtItemsIndex, setAcvtItemsIndex] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState<AcvtTabs>('home');

    React.useEffect(() => {
        lang === 'fr' ? setAcvtItemsIndex(1) : setAcvtItemsIndex(0);
    }, [lang, formik.values.items]);

    const changeLanguage = (language: AcvtSupportedLanguages) => {
        setLang(language);
    };

    return (
        <div>
            <Modal
                visible={isModalVisible}
                title="Image"
                footer={null}
                onCancel={() => setModalVisible(false)}
            >
                <img
                    alt="thumbnail"
                    style={{ width: '100%' }}
                    src={modalUrl ?? ''}
                />
            </Modal>

            <div className={'buttons_holder back_button'}>
                <Button
                    type="default"
                    icon={<RollbackOutlined />}
                    shape="round"
                    onClick={navigation.goToFeaturedList}
                >
                    Back
                </Button>
                {params.action === 'create' && (
                    <Button
                        onClick={createFeatured}
                        loading={isUpserting}
                        type="primary"
                        disabled={
                            !(
                                formik.isValid &&
                                externalUrlValidation(formik.values)
                            )
                        }
                    >
                        Create
                    </Button>
                )}

                {params.action === 'edit' && (
                    <Button
                        onClick={updateFeatured}
                        type="primary"
                        loading={isUpserting}
                        disabled={
                            !(
                                formik.isValid &&
                                externalUrlValidation(formik.values)
                            )
                        }
                    >
                        Save
                    </Button>
                )}
                {params.action === 'edit' && formik.values.canBeDeleted && (
                    <Button
                        type="primary"
                        danger={true}
                        onClick={ModalManager.showDeleteModal({
                            onConfirm: onDeleteConfirm,
                        })}
                        loading={isDeleting}
                        disabled={!formik.isValid}
                    >
                        Delete
                    </Button>
                )}
            </div>

            <br />

            <h1>
                Featured
                {formik.values.id !== 0 && (
                    <span>&nbsp;#{formik.values.id}</span>
                )}
            </h1>

            {isFetching && <Spin />}
            <div className={style.viewer_split}>
                <form style={{ display: isFetching ? 'none' : 'block' }}>
                    <Card className={style.card_container}>
                        <div
                            className={`${promoStyle.input_row} ${promoStyle.vertical_col}`}
                        >
                            {/* Is active */}
                            <div className={promoStyle.input_row}>
                                <span className="left_label">Is active</span>
                                <Switch
                                    checked={formik.values.isActive}
                                    onChange={(e) =>
                                        formik.setFieldValue('isActive', e)
                                    }
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />

                                <span
                                    className="left_label"
                                    style={{ marginLeft: 50 }}
                                >
                                    {Text.featuredPage.geolocatedSwitch}
                                </span>
                                <Switch
                                    checked={formik.values.geolocated || false}
                                    onChange={(e) => {
                                        formik.setFieldValue('geolocated', e);
                                    }}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </div>

                            <Divider
                                orientation="left"
                                className={style.divider}
                            ></Divider>

                            {/* Type */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                <div
                                    className={`${promoStyle.fluid_input_col} ${promoStyle.vertical_col}`}
                                >
                                    <div className="light_label">
                                        <ErrorAwareLabel
                                            text="Start"
                                            hasError={checkIfHasError(
                                                `startDate`
                                            )}
                                        />
                                        <div className="vr"></div>
                                    </div>
                                    <DatePicker
                                        allowClear={false}
                                        format="YYYY-MM-DD hh:mm A"
                                        showTime={{
                                            format: 'HH:mm',
                                            minuteStep: 15,
                                        }}
                                        defaultValue={moment().endOf('day')} // should set the default to midnight but doesnt do it
                                        value={moment(formik.values.startDate)}
                                        onChange={(e) =>
                                            formik.setFieldValue('startDate', e)
                                        }
                                    />
                                </div>

                                <div
                                    className={`${promoStyle.fluid_input_col} ${promoStyle.vertical_col}`}
                                >
                                    <div className="light_label">
                                        <ErrorAwareLabel
                                            text="End"
                                            hasError={checkIfHasError(
                                                `endDate`
                                            )}
                                        />
                                        <div className="vr"></div>
                                    </div>
                                    <DatePicker
                                        allowClear={false}
                                        format="YYYY-MM-DD hh:mm A"
                                        showTime={{
                                            format: 'HH:mm',
                                            minuteStep: 15,
                                        }}
                                        value={moment(formik.values.endDate)}
                                        onChange={(e) =>
                                            formik.setFieldValue('endDate', e)
                                        }
                                    />
                                </div>
                            </div>
                            <Divider
                                orientation="left"
                                className={style.divider}
                            ></Divider>
                            <div
                                className={`${promoStyle.fluid_input_col} ${promoStyle.vertical_col}`}
                            >
                                <span>Redirect to...</span>
                                <Select
                                    style={{ width: 200 }}
                                    onChange={(v) =>
                                        formik.setFieldValue('type', v)
                                    }
                                    value={formik.values.type}
                                >
                                    <Select.Option value="promotion">
                                        Promotion
                                    </Select.Option>
                                    <Select.Option value="external_url">
                                        External url
                                    </Select.Option>
                                    <Select.Option value="promotions/in_store">
                                        Promotions / In store
                                    </Select.Option>
                                    <Select.Option value="promotions/coupons">
                                        Promotions / Coupons
                                    </Select.Option>
                                    <Select.Option value="stores">
                                        Stores
                                    </Select.Option>
                                    <Select.Option value="ct-connect">
                                        CT connecté
                                    </Select.Option>
                                    <Select.Option value="car-wash">
                                        Car wash
                                    </Select.Option>
                                    <Select.Option value="games">
                                        Games
                                    </Select.Option>
                                </Select>
                            </div>
                            {/* Visible promotions */}
                            {formik.values.type === 'promotion' && (
                                <div className={style.item_padding}>
                                    <span>Promotion</span>
                                    <Select
                                        listHeight={512}
                                        showSearch
                                        optionLabelProp="label"
                                        filterOption={false}
                                        loading={
                                            promotionStore.isFetchingForSelect
                                        }
                                        value={
                                            formik.values.promotionId ??
                                            undefined
                                        }
                                        onSearch={(v) => setQuery(v)}
                                        onChange={(v) =>
                                            formik.setFieldValue(
                                                'promotionId',
                                                v
                                            )
                                        }
                                    >
                                        {promoList.map((promo) => (
                                            <Select.Option
                                                key={promo.id}
                                                value={promo.id}
                                                label={promo.items[0].title}
                                            >
                                                <div
                                                    className={
                                                        style.custom_option
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.titles_holder
                                                        }
                                                    >
                                                        <span>
                                                            {
                                                                promo.items[0]
                                                                    .title
                                                            }
                                                        </span>
                                                        <span>
                                                            {
                                                                promo.items[1]
                                                                    .title
                                                            }
                                                        </span>
                                                    </div>
                                                    <ImageHolder
                                                        src={
                                                            promo.items[0]
                                                                .thumbnail
                                                        }
                                                        size={40}
                                                        className={
                                                            style.option_image
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setModalVisible(
                                                                true
                                                            );
                                                            setModalUrl(
                                                                promo.items[0]
                                                                    .thumbnail ??
                                                                    undefined
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            )}
                            {/* Visible games */}
                            {formik.values.type === 'games' && (
                                <div className={style.item_padding}>
                                    <span>Games</span>
                                    <Select
                                        listHeight={512}
                                        showSearch
                                        optionLabelProp="label"
                                        filterOption={false}
                                        loading={gamesStore.isFetchingForSelect}
                                        value={formik.values.gameTypeId || ''}
                                        onSearch={(v) => setQuery(v)}
                                        onChange={(v) => {
                                            formik.setFieldValue(
                                                'gameType',
                                                v.substring(0, v.indexOf('_'))
                                            );
                                            formik.setFieldValue(
                                                'gameId',
                                                Number(
                                                    v.substring(
                                                        v.indexOf('_') + 1
                                                    )
                                                )
                                            );
                                            formik.setFieldValue(
                                                'gameTypeId',
                                                v
                                            );
                                        }}
                                    >
                                        {gameList.map((game) => (
                                            <Select.Option
                                                key={`${game.type}_${game.id}`}
                                                value={`${game.type}_${game.id}`}
                                                label={game.title.en}
                                            >
                                                <div
                                                    className={
                                                        style.custom_option
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.titles_holder
                                                        }
                                                    >
                                                        <span>
                                                            {game.title.en}
                                                        </span>
                                                        <span>
                                                            {game.title.fr}
                                                        </span>
                                                    </div>
                                                    <ImageHolder
                                                        src={game.image.en}
                                                        size={40}
                                                    />
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            )}
                            {/* External url */}
                            {formik.values.type === 'external_url' && (
                                <div className={style.item_padding}>
                                    {formik.values.items.map((item, i) => (
                                        <React.Fragment key={i}>
                                            <ErrorAwareLabel
                                                text={`External url ${item.language}`}
                                                hasError={checkIfHasError(
                                                    `items[${i}].externalUrl`
                                                )}
                                            />
                                            <Input
                                                name={`items[${i}].externalUrl`}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={item.externalUrl ?? ''}
                                            />
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </div>

                        <Divider
                            orientation="left"
                            className={style.divider}
                        ></Divider>

                        {/* Background image */}
                        <div className={promoStyle.input_row}>
                            {formik.values.items.map((item, i) => (
                                <div key={i} className={`${style.image_field}`}>
                                    <ErrorAwareLabel
                                        text={`Background image ${item.language}`}
                                        hasError={checkIfHasError(
                                            `items[${i}].backgroundImage`
                                        )}
                                    />
                                    <ImageInput
                                        key={i}
                                        url={item.backgroundImage}
                                        onChange={(url) =>
                                            formik.setFieldValue(
                                                `items[${i}].backgroundImage`,
                                                url
                                            )
                                        }
                                        onDelete={() =>
                                            formik.setFieldValue(
                                                `items[${i}].backgroundImage`,
                                                null
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>

                        <Divider
                            orientation="left"
                            className={style.divider}
                        ></Divider>

                        <div className={promoStyle.input_row}>
                            {formik.values.items.map((item, i) => (
                                <div key={i} className={style.image_field}>
                                    <ErrorAwareLabel
                                        text={`Text color ${item.language}`}
                                        hasError={false}
                                    />
                                    <ChromePicker
                                        disableAlpha={true}
                                        color={item.textColor ?? '000000'}
                                        onChange={(c) =>
                                            formik.setFieldValue(
                                                `items[${i}].textColor`,
                                                c.hex
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </Card>

                    <Divider
                        orientation="left"
                        className={style.divider}
                    ></Divider>

                    <div className={` ${style.item_padding}`}>
                        <span>Title Format</span>
                        <Select
                            style={{ width: 200 }}
                            onChange={(v) => {
                                if (v === '0')
                                    formik.setFieldValue('isImageTitle', false);
                                else formik.setFieldValue('isImageTitle', true);
                            }}
                            value={formik.values.isImageTitle ? '1' : '0'}
                        >
                            <Select.Option value="0">Text</Select.Option>
                            <Select.Option value="1">Image</Select.Option>
                        </Select>
                    </div>

                    {/* Image title */}
                    {formik.values.isImageTitle && (
                        <div className={` ${promoStyle.input_row}`}>
                            {formik.values.items?.map((item, i) => (
                                <div
                                    key={item.language}
                                    className={promoStyle.image_field}
                                >
                                    <ErrorAwareLabel
                                        text={`Image Title ${item.language}`}
                                        hasError={checkIfHasError(
                                            `items[${i}].title`
                                        )}
                                    />
                                    <ImageInput
                                        key={i}
                                        url={item.imageTitle}
                                        className={style.image_holder_bg}
                                        onChange={(url) =>
                                            formik.setFieldValue(
                                                `items[${i}].imageTitle`,
                                                url
                                            )
                                        }
                                        onDelete={() =>
                                            formik.setFieldValue(
                                                `items[${i}].imageTitle`,
                                                null
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Text title */}
                    {!formik.values.isImageTitle && (
                        <div className={style.item_padding}>
                            {formik.values.items.map((item, i) => (
                                <div key={i}>
                                    <span>Text title {item.language}</span>
                                    <Input
                                        key={i}
                                        name={`items[${i}].textTitle`}
                                        onChange={formik.handleChange}
                                        value={item.textTitle ?? ''}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    <Divider
                        orientation="left"
                        className={style.divider}
                    ></Divider>

                    {/* CTA */}
                    <div className={style.item_padding}>
                        {formik.values.items.map((item, i) => (
                            <div key={i}>
                                <span>CTA {item.language}</span>
                                <Input
                                    key={i}
                                    name={`items[${i}].cta`}
                                    onChange={formik.handleChange}
                                    value={item.cta ?? ''}
                                />
                            </div>
                        ))}
                    </div>
                </form>
                <Card className={style.card_container} title="App Preview">
                    <AcvtCanvas changeLang={changeLanguage}>
                        <Acvt backgroundColor="#d8281c">
                            <>
                                <AcvtHomePage
                                    key={formik.values.id}
                                    language={lang}
                                    onEditMode={true}
                                    featuredCarousel={
                                        <AcvtCarousel>
                                            <AcvtCarouselItem
                                                image={
                                                    formik.values.items[
                                                        acvtItemsIndex as number
                                                    ].backgroundImage
                                                }
                                            />
                                        </AcvtCarousel>
                                    }
                                />
                                <AcvtBottomTabBar
                                    language={lang}
                                    tab={selectedTab}
                                    tabSelected={setSelectedTab}
                                    onEditMode={true}
                                />
                            </>
                        </Acvt>
                    </AcvtCanvas>
                </Card>
            </div>
        </div>
    );
});
