import React from 'react';
import style from './style.module.scss';
import { Modal } from 'antd';
import { ReactComponent as DangerIcon } from '../../icons/DangerIcon.svg';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';

type PopupConfirmDeleteProps = {
    message: string;
    onClose: () => void;
    onOk: () => void;
};

export const PopupConfirmDelete = ({
    message,
    onClose,
    onOk,
}: PopupConfirmDeleteProps) => {
    return (
        <Modal
            visible={true}
            cancelText={null}
            footer={null}
            closeIcon={<CloseIcon onClick={onClose} />}
            className={style.modal}
        >
            <div className={style.modal_content}>
                <DangerIcon className={style.icon} />
                <div className={style.text}>{message}</div>
            </div>
            <div className={style.buttons_container}>
                <button className={style.gray_button} onClick={onClose}>
                    Cancel
                </button>
                <button className={style.red_button} onClick={onOk}>
                    Delete
                </button>
            </div>
        </Modal>
    );
};
