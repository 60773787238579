import React, { ChangeEvent } from 'react';
import { Input, Button } from 'antd';
import styles from './style.module.scss';

interface SearchComponentProps {
    otherClientId: string;
    setOtherClientId: (value: string) => void;
    handleSearch: () => void;
}

const SearchComponent = ({
    otherClientId,
    setOtherClientId,
    handleSearch,
}: SearchComponentProps) => {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setOtherClientId(e.target.value);
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.title} ${styles.rightPadding}`}>
                Other Client ID to Swap With
            </div>
            <div className={styles.searchContainer}>
                <Input
                    value={otherClientId}
                    onChange={onInputChange}
                    className={styles.input}
                    aria-label="Other Client ID"
                />
                <Button onClick={handleSearch} className={styles.button}>
                    Search
                </Button>
            </div>
        </div>
    );
};

export default SearchComponent;
