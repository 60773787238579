import React from 'react';
import { DropDownServices } from '../dropdown-services';
import { services } from '../../helpers/store-helper';

type StoreEditingServicesProps = {
    selectedServices: string[];
    setSelectedServices: React.Dispatch<React.SetStateAction<string[]>>;
};

export const StoreEditingServices = ({
    selectedServices,
    setSelectedServices,
}: StoreEditingServicesProps) => {
    const serviceOptions = Object.entries(services).map(([key, value]) => ({
        label: value,
        value: key,
    }));
    return (
        <>
            <div>
                <DropDownServices
                    options={serviceOptions ?? []}
                    selectedOptions={selectedServices}
                    onChange={(value) => setSelectedServices(value)}
                    placeholder="Select services here"
                />
            </div>
        </>
    );
};
