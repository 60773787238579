import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Table, Spin } from 'antd';
import moment from 'moment-timezone';
import { useClientStore } from '../../../contexts/mobx';
import { Client } from '../../../stores/client';
import { useQueryParams } from '../../../utils/use-query-params';

type WashCodeRecordsTabProps = {
    clientObject?: Client;
};

type WashCodeSearchParams = {
    washCodePage?: number;
    washCodePerPage?: number;
};

const columns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt: string) =>
            createdAt
                ? moment(createdAt)
                      .tz('America/New_York')
                      .format('MMMM Do, YYYY, h:mm A')
                : 'N/A',
    },
    {
        title: 'Code',
        dataIndex: 'washCode',
        key: 'washCode',
    },
    {
        title: 'Store',
        dataIndex: 'store',
        key: 'store',
    },
    {
        title: 'Success',
        dataIndex: 'successCode',
        key: 'successCode',
    },
    {
        title: 'Reject',
        dataIndex: 'rejectMessage',
        key: 'rejectMessage',
    },
];

export const WashCodeRecordsTab = observer(
    ({ clientObject }: WashCodeRecordsTabProps) => {
        const clientStore = useClientStore();
        const [params, setParams] = useQueryParams<WashCodeSearchParams>();

        const fetchWashCodeRecords = useCallback(
            async (clientId: number) => {
                const page = Number(params.washCodePage) || 0;
                const perPage = Number(params.washCodePerPage) || 10;

                try {
                    await clientStore.fetchWashCodeRecords(
                        clientId,
                        page,
                        perPage
                    );
                } catch (error) {
                    console.error('Failed to fetch wash code records:', error);
                }
            },
            [clientStore, params.washCodePage, params.washCodePerPage]
        );

        useEffect(() => {
            if (clientObject?.id) {
                fetchWashCodeRecords(clientObject.id);
            }
        }, [clientObject?.id, fetchWashCodeRecords]);

        const handleTableChange = (pagination: {
            current?: number;
            pageSize?: number;
        }) => {
            const currentPage = pagination.current ? pagination.current - 1 : 0;
            const pageSize = pagination.pageSize || 10;

            setParams({
                washCodePage: currentPage,
                washCodePerPage: pageSize,
            });
        };

        const paginationConfig = clientStore.washCodeRecords
            ? {
                  current: (clientStore.washCodeRecords.page ?? 0) + 1,
                  pageSize: clientStore.washCodeRecords.perPage,
                  total: clientStore.washCodeRecords.total,
                  showSizeChanger: true,
              }
            : false;

        return (
            <div>
                {clientStore.isFetchingWashCodeRecords ? (
                    <Spin />
                ) : (
                    <Table
                        columns={columns}
                        dataSource={clientStore.washCodeRecords?.results ?? []}
                        rowKey="createdAt"
                        pagination={paginationConfig}
                        onChange={handleTableChange}
                    />
                )}
            </div>
        );
    }
);

export default WashCodeRecordsTab;
