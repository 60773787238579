import React from 'react';
import { Input, Tooltip, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './style.module.scss';

interface ABTestingSectionProps {
    originalPrice: number;
    rebateAmount: number;
    setFieldValue: (field: string, value: number) => void;
    checkIfHasError: (path: string) => boolean;
}

const LabelWithTooltip = ({
    text,
    tooltip,
    hasError,
}: {
    text: string;
    tooltip: string;
    hasError: boolean;
}) => (
    <div className={styles.label_with_tooltip}>
        <span className={hasError ? styles.error_label : styles.label}>
            {text}
        </span>
        <Tooltip title={tooltip}>
            <InfoCircleOutlined />
        </Tooltip>
    </div>
);

export const ABTestingSection = ({
    originalPrice,
    rebateAmount,
    setFieldValue,
    checkIfHasError,
}: ABTestingSectionProps) => {
    const handleInputChange = (
        field: 'originalPrice' | 'rebateAmount',
        value: string
    ) => {
        const numericValue = parseFloat(value);
        setFieldValue(field, isNaN(numericValue) ? 0 : numericValue);
    };

    return (
        <>
            <Card className={styles.container}>
                <div className={styles.input_row}>
                    <div className={styles.top_label_input}>
                        <LabelWithTooltip
                            text="Original Price"
                            tooltip="Original Price is the item's pre-promotion cost."
                            hasError={checkIfHasError('originalPrice')}
                        />
                        <Input
                            type="number"
                            prefix="$"
                            value={originalPrice || ''}
                            onChange={(e) =>
                                handleInputChange(
                                    'originalPrice',
                                    e.target.value
                                )
                            }
                        />
                    </div>
                    <div className={styles.top_label_input}>
                        <LabelWithTooltip
                            text="Rebate Amount"
                            tooltip="Price the customer will pay after discount."
                            hasError={checkIfHasError('rebateAmount')}
                        />
                        <Input
                            type="number"
                            prefix="$"
                            value={rebateAmount || ''}
                            onChange={(e) =>
                                handleInputChange(
                                    'rebateAmount',
                                    e.target.value
                                )
                            }
                        />
                    </div>
                </div>
            </Card>
        </>
    );
};
