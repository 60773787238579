import React from 'react';
import style from './style.module.scss';
import { Modal } from 'antd';
import { ReactComponent as CheckCircle } from '../../icons/CheckCircleBlack.svg';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';

type PopupSuccessProps = {
    message: string;
    onClose: () => void;
};

export const PopupSuccess = ({ message, onClose }: PopupSuccessProps) => {
    return (
        <Modal
            visible={true}
            cancelText={null}
            footer={null}
            closeIcon={<CloseIcon onClick={onClose} />}
            className={style.modal}
        >
            <div className={style.modal_content}>
                <CheckCircle className={style.icon} />
                <div className={style.title}>Success!</div>
                <div className={style.text}>{message}</div>
            </div>
            <div>
                <button className={style.button} onClick={onClose}>
                    OK
                </button>
            </div>
        </Modal>
    );
};
