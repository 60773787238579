import React from 'react';
import { Card, Slider } from 'antd';
import styles from './style.module.scss';

interface WeatherActivationSectionProps {
    temperatureActivationFloor: number | null | undefined;
    temperatureActivationCeiling: number | null | undefined;
    setFieldValue: (field: string, value: number | null) => void;
}

const marks = {
    '-50': {
        style: {
            color: '#1890ff',
        },
        label: <strong>-50°C</strong>,
    },
    0: {
        style: {
            color: '#000',
        },
        label: <strong>0°C</strong>,
    },
    50: {
        style: {
            color: '#ff4d4f',
        },
        label: <strong>50°C</strong>,
    },
};

const handleSliderChange = (
    value: [number, number] | number,
    setFieldValue: (field: string, value: number | null) => void
) => {
    if (Array.isArray(value)) {
        setFieldValue('temperatureActivationFloor', value[0]);
        setFieldValue('temperatureActivationCeiling', value[1]);
    }
};

export const WeatherActivationSection = ({
    temperatureActivationFloor,
    temperatureActivationCeiling,
    setFieldValue,
}: WeatherActivationSectionProps) => {
    return (
        <Card className={styles.weather_card}>
            <Slider
                range
                min={-50}
                max={50}
                value={[
                    temperatureActivationFloor ?? -20,
                    temperatureActivationCeiling ?? 30,
                ]}
                onChange={(value) => handleSliderChange(value, setFieldValue)}
                marks={marks}
                tooltipVisible
                tipFormatter={(value) => `${value}°C`}
            />
        </Card>
    );
};
