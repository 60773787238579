import { PageHeader } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import style from './style.module.scss';
import { useAuthStore } from '../../contexts/mobx';

export const WelcomePage = observer(() => {
    const authStore = useAuthStore();
    const { claims } = authStore;
    return (
        <div className={style.welcome_content}>
            <PageHeader title={`Welcome ${claims?.name}`}></PageHeader>
        </div>
    );
});
