import { action, makeObservable, observable } from 'mobx';
import * as yup from 'yup';
import { RootStore } from './root';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { NotificationManager } from '../components';
import { HttpMethod } from '../types';

export const yupCareer = yup.object({
    id: yup.number().required(),
    active: yup.boolean().required(),
    image: yup.object({
        fr: yup.string().required(),
        en: yup.string().required(),
    }),
});

export type CareerListResponse = {
    careerCards: Career[];
};

export interface UpsertCareerCard {
    active: boolean;
    image: {
        fr: string;
        en: string;
    };
}

export interface CareerForm {
    image: {
        fr: string | null;
        en: string | null;
    };
    active: boolean;
}

export type Career = yup.InferType<typeof yupCareer>;

export class CareerStore extends WithNetworkConcurrency {
    @observable careerList: Career[] = [];

    @observable isFetchingList = false;
    @observable isFetchingCareer = false;
    @observable isUpdatedCareer = false;
    @observable isCreatingCareer = false;
    @observable isDeletingCareer = false;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchList(this: CareerStore) {
        this.isFetchingList = true;
        const response = await this.rootStore.makeNetworkCall<
            CareerListResponse
        >({
            method: HttpMethod.GET,
            url: 'career',
        });

        if (response.data) {
            this.careerList = response.data.careerCards;
        }

        this.isFetchingList = false;
    }

    @action
    async fetchCareer(this: CareerStore, id: string) {
        this.isFetchingCareer = true;
        const response = await this.rootStore.makeNetworkCall<Career>({
            method: HttpMethod.GET,
            url: `/career/${id}`,
        });

        if (!response.err) {
            return response.data;
        } else {
            NotificationManager.showError(
                'No career found. Look for something else.'
            );
        }

        this.isFetchingCareer = false;
    }

    @action
    async updateCareer(this: CareerStore, id: string, data: UpsertCareerCard) {
        this.isUpdatedCareer = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            data,
            url: `/career/${id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Career modified.');
        }

        this.isUpdatedCareer = false;
    }

    @action
    async createCareer(this: CareerStore, data: UpsertCareerCard) {
        this.isCreatingCareer = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            data,
            url: '/career',
        });

        if (!response.err) {
            NotificationManager.showSuccess('Career created');
        }
        this.isCreatingCareer = false;
    }

    @action
    async deleteCareer(this: CareerStore, id: string) {
        this.isDeletingCareer = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/career/${id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Career deleted.');
        }

        this.isDeletingCareer = false;

        return response;
    }
}
