import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import { Tag, Table, Spin } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useAuthStore, useNhlGamesStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { useNavigation } from '../../utils/use-navigation';
import { SearchParams } from '../../stores/nhl-game';
import { NhlGame } from '../../types/nhl-game';
import { PageHeader, Pagination } from '../../components';
import { Text } from '../../utils/text';
import { FormOutlined } from '@ant-design/icons';

const renderTitle = (_: unknown, record: NhlGame) => {
    const enTitle = record.title.en;
    const frTitle = record.title.fr;
    return (
        <div>
            {enTitle}
            <br />
            <span className="light_label">{frTitle}</span>
        </div>
    );
};

const renderIsActive = (_: unknown, record: NhlGame) => {
    return record.status === 'active' ? (
        <Tag color="#87d068">Active</Tag>
    ) : (
        <Tag color="#f50">Not active</Tag>
    );
};

const renderStage = (_: unknown, record: NhlGame) => {
    return record.stage === 'regular_season' ? (
        <Tag color="#3F51B5">Regular Season</Tag>
    ) : (
        <Tag color="#D81B60">Playoff</Tag>
    );
};

export const NhlGamesPage = observer(() => {
    const store = useNhlGamesStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }
    const [queryParams, updateQueryParams] = useQueryParams<SearchParams>();

    React.useEffect(() => {
        store.getAllGames(queryParams);
    }, [store, queryParams]);

    const columns: ColumnProps<NhlGame>[] = [
        {
            title: 'Title',
            key: 'title',
            width: '20%',
            render: renderTitle,
        },
        {
            title: 'Is active',
            key: 'is_active',
            render: renderIsActive,
            defaultSortOrder: 'ascend',
            sorter: {
                compare: (a, b) => {
                    return a.status.localeCompare(b.status);
                },
                multiple: 2,
            },
        },
        {
            title: 'Stage',
            key: 'stage',
            render: renderStage,
        },
        {
            title: 'Year',
            key: 'year',
            dataIndex: 'year',
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => {
                    return a.year - b.year;
                },
                multiple: 1,
            },
        },
    ];

    const goToEditPage = (o: NhlGame) => () => {
        navigation.goToNhlGameEdit(o.id);
    };

    return (
        <div className={style.page_content}>
            <PageHeader title={Text.nhlGame.configContainer.pageTitle} />
            <div className={style.table_header}>
                <div className={style.query_box}>
                    <span
                        className={style.create_button}
                        onClick={() => {
                            navigation.goToNhlGameCreate();
                        }}
                    >
                        {Text.nhlGame.configContainer.createButton}{' '}
                        <FormOutlined />
                    </span>
                </div>
                <div className={style.pagination_container}>
                    <Pagination
                        className={style.pagination}
                        params={queryParams}
                        meta={store.list}
                        onChange={(pageNumber) =>
                            updateQueryParams({ page: pageNumber })
                        }
                        onPageSizeChange={(size) =>
                            updateQueryParams({ perPage: size })
                        }
                    />
                </div>
            </div>

            {store.isFetchingList ? (
                <Spin />
            ) : (
                <Table<NhlGame>
                    rowKey="id"
                    dataSource={store.list?.results}
                    columns={columns}
                    loading={store.isFetchingList}
                    pagination={false}
                    bordered
                    onRow={(r) => ({
                        onClick: goToEditPage(r),
                    })}
                />
            )}
        </div>
    );
});
