import React, { useState, useEffect } from 'react';
import { Modal, Spin, Button, message } from 'antd';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import { useClientStore } from '../../../../../contexts/mobx';
import { OperationStatus } from '../../../../../types';
import ClientDetails from './components/ClientDetailsComponent';
import SearchComponent from './components/SearchComponent';
import ConfirmationDialogComponent from './components/ConfirmationDialogComponent';
import { Client, ClientExternalDetails } from '../../../../../stores/client';

interface UpdateExternalIdentifiersModalProps {
    isVisible: boolean;
    client: Client | null;
    onClose: () => void;
}

interface UpdateResult {
    status: OperationStatus;
    title: string;
}

const UpdateExternalIdentifiersModal = ({
    isVisible,
    client,
    onClose,
}: UpdateExternalIdentifiersModalProps) => {
    const clientStore = useClientStore();
    const [otherClientId, setOtherClientId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updateResult, setUpdateResult] = useState<UpdateResult | null>(null);
    const [
        newClientInfo,
        setNewClientInfo,
    ] = useState<ClientExternalDetails | null>(null);
    const [
        otherClientDetails,
        setOtherClientDetails,
    ] = useState<ClientExternalDetails | null>(null);
    const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
        false
    );

    useEffect(() => {
        if (isVisible) {
            resetState();
        }
    }, [isVisible]);

    const resetState = () => {
        setOtherClientId('');
        setUpdateResult(null);
        setNewClientInfo(null);
        setOtherClientDetails(null);
    };

    const handleSearch = async () => {
        if (otherClientId) {
            setIsLoading(true);
            try {
                await clientStore.fetchClientDetails(
                    parseInt(otherClientId, 10)
                );
                setOtherClientDetails(clientStore.clientDetails);
            } catch (error) {
                message.error('Failed to fetch client details.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleUpdate = async () => {
        if (client?.id && otherClientId) {
            setIsLoading(true);
            try {
                const response = await clientStore.updateExternalIdentifiers(
                    client.id,
                    { sourceAccountId: parseInt(otherClientId, 10) }
                );
                if (response) {
                    setNewClientInfo(response.client);
                    setUpdateResult({
                        status: OperationStatus.SUCCESS,
                        title: 'External identifiers successfully updated.',
                    });
                    setIsConfirmationVisible(false);
                } else {
                    setUpdateResult({
                        status: OperationStatus.ERROR,
                        title: 'Failed to update external identifiers.',
                    });
                }
            } catch (error) {
                setUpdateResult({
                    status: OperationStatus.ERROR,
                    title: 'Failed to update external identifiers.',
                });
                message.error('Update failed.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const showSwapConfirmation = () => {
        setIsConfirmationVisible(true);
    };

    const hideSwapConfirmation = () => {
        setIsConfirmationVisible(false);
    };

    return (
        <Modal
            title="Update External Identifiers"
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            aria-label="Update External Identifiers Modal"
        >
            {client && <ClientDetails client={client} title="Client Details" />}
            <SearchComponent
                otherClientId={otherClientId}
                setOtherClientId={setOtherClientId}
                handleSearch={handleSearch}
            />
            {otherClientDetails && (
                <ClientDetails
                    client={otherClientDetails}
                    title="Other Client Details"
                />
            )}
            {isLoading ? (
                <div className={styles.spinnerContainer}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {updateResult &&
                        updateResult.status === OperationStatus.SUCCESS &&
                        newClientInfo && (
                            <ClientDetails
                                client={newClientInfo}
                                title="Updated Client Details"
                            />
                        )}
                    <div className={styles.actionContainer}>
                        {updateResult &&
                        updateResult.status === OperationStatus.SUCCESS ? (
                            <Button
                                onClick={onClose}
                                className={`${styles.button} ${styles.closeButton}`}
                            >
                                Close
                            </Button>
                        ) : (
                            otherClientDetails && (
                                <Button
                                    onClick={showSwapConfirmation}
                                    className={`${styles.button} ${styles.swapButton}`}
                                >
                                    Swap
                                </Button>
                            )
                        )}
                    </div>
                </>
            )}
            <ConfirmationDialogComponent
                client1={client}
                client2={otherClientDetails}
                visible={isConfirmationVisible}
                onConfirm={handleUpdate}
                onCancel={hideSwapConfirmation}
            />
        </Modal>
    );
};

export default observer(UpdateExternalIdentifiersModal);
