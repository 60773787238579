import React from 'react';
import style from './style.module.scss';

type TextInputProps = {
    value: string;
    setValue: (value: string) => void;
};

export const InputText = ({ value, setValue }: TextInputProps) => {
    return (
        <>
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className={style.text_input}
            />
        </>
    );
};
