import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import * as yup from 'yup';
import { NotificationManager } from '../components';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { Lang, LangRegEx } from '../utils';
import { HttpMethod } from '../types';

const categoryItems = yup
    .object({
        title: yup.string().required(),
    })
    .required()
    .shape({
        language: yup.string<Lang>().required().matches(LangRegEx),
    });

const shortPromotionItems = yup.object({
    title: yup.string().required(),
    thumbnail: yup.string().nullable().notRequired(),
});

export const YupCategory = yup.object({
    id: yup.number().required(),
    items: yup.array(categoryItems).required(),
});

export const YupCategoryUpsertPayload = yup.object({
    items: yup.array(categoryItems).required(),
    canBeDeleted: yup.boolean().required(),
});

export const YupAugmentedCategory = YupCategory.shape({
    promotions: yup
        .array(
            yup.object({
                id: yup.number().required(),
                items: yup.array(shortPromotionItems).required(),
            })
        )
        .required(),
    activePromotionsCount: yup.number().required(),
    totalPromotionsCount: yup.number().required(),
    canBeDeleted: yup.boolean().required(),
});

export const YupCategoryList = yup.object({
    total: yup.number().required(),
    results: yup.array(YupAugmentedCategory).default([]),
});

export const YupPaginatedCategoryList = YupCategoryList.shape({
    page: yup.number().required(),
    perPage: yup.number().required(),
});

export type CategoryList = yup.InferType<typeof YupCategoryList>;
export type Category = yup.InferType<typeof YupCategory>;
export type AugmentedCategory = yup.InferType<typeof YupAugmentedCategory>;
export type CategoryUpsertPayload = yup.InferType<
    typeof YupCategoryUpsertPayload
>;
export type PaginatedCategoryList = yup.InferType<
    typeof YupPaginatedCategoryList
>;

export type CategorySearchParams = {
    perPage?: number;
    page?: number;
    q?: string;
};

export class CategoryStore extends WithNetworkConcurrency {
    @observable activeCategories: CategoryList | null = null;
    @observable isFetchingActiveCategories = false;
    @observable categories: PaginatedCategoryList | null = null;
    @observable isFetchingCount = 0;

    activeCategoriesMap: Map<number, Category> = new Map();
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    getActiveCategoryById(this: CategoryStore, id: number) {
        return this.activeCategoriesMap.get(id);
    }

    @action
    setActiveCategories(this: CategoryStore, categories: CategoryList) {
        this.activeCategories = categories;

        for (const category of categories.results) {
            this.activeCategoriesMap.set(category.id, category);
        }
    }

    @action
    setCategories(this: CategoryStore, categories: PaginatedCategoryList) {
        this.categories = categories;
    }

    @action
    async fetchActiveCategories(this: CategoryStore) {
        if (this.isFetchingActiveCategories || this.activeCategories) {
            return;
        }

        this.isFetchingActiveCategories = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotion-categories?all=true&status=active',
            },
            YupCategoryList
        );
        this.isFetchingActiveCategories = false;

        if (!response.err) {
            this.setActiveCategories(response.data);
        }
    }

    @action
    async fetchCategories(
        this: CategoryStore,
        searchParams: CategorySearchParams
    ) {
        const tag = this.getTag();
        this.isFetchingCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotion-categories',
                params: searchParams,
            },
            YupPaginatedCategoryList
        );
        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.setCategories(response.data);
        }
    }

    @action
    async fetchCategory(this: CategoryStore, categoryId: number) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: `/promotion-categories/${categoryId}`,
            },
            YupCategoryUpsertPayload,
            { stripUnknown: true }
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async updateCategory(
        this: CategoryStore,
        category: CategoryUpsertPayload,
        categoryId: number
    ) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            url: `/promotion-categories/${categoryId}`,
            data: category,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Category updated');
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async createCategory(this: CategoryStore, category: CategoryUpsertPayload) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: `/promotion-categories`,
            data: category,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Category created');
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async deleteCategory(this: CategoryStore, categoryId: number) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/promotion-categories/${categoryId}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Category deleted');
        }
        return response;
    }
}
