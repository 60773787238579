import React from 'react';
import { Modal, Divider, Typography, Button } from 'antd';
import styles from './style.module.scss';
import {
    Client,
    ClientExternalDetails,
} from '../../../../../../../stores/client';

const { Text } = Typography;

interface ConfirmationDialogProps {
    client1: Client | null;
    client2: ClientExternalDetails | null;
    visible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationDialogComponent = ({
    client1,
    client2,
    visible,
    onConfirm,
    onCancel,
}: ConfirmationDialogProps) => {
    return (
        <Modal
            title="Confirm Swap"
            visible={visible}
            onOk={onConfirm}
            onCancel={onCancel}
            okText="Confirm"
            className={styles.modal}
            footer={[
                <Button
                    key="confirm"
                    type="primary"
                    onClick={onConfirm}
                    className={styles.confirmButton}
                >
                    Confirm
                </Button>,
            ]}
        >
            <div className={styles.container}>
                <div className={styles.box}>
                    <Text className={styles.clientId}>
                        Client {client1?.id}
                    </Text>
                    <p>
                        <Text className={styles.label}>Customer ID:</Text>{' '}
                        {client1?.ngrpCustomerId}
                    </p>
                    <p>
                        <Text className={styles.label}>Liquid Barcode ID:</Text>{' '}
                        {client1?.liquidBarcodeId}
                    </p>
                </div>
                <Divider>Swap with</Divider>
                <div className={styles.box}>
                    <Text className={styles.clientId}>
                        Client {client2?.id}
                    </Text>
                    <p>
                        <Text className={styles.label}>Customer ID:</Text>{' '}
                        {client2?.ngrpCustomerId}
                    </p>
                    <p>
                        <Text className={styles.label}>Liquid Barcode ID:</Text>{' '}
                        {client2?.liquidBarcodeId}
                    </p>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationDialogComponent;
