import { convertToRaw } from 'draft-js';
import { produce } from 'immer';
import { PromotionForm } from '../containers/promotion-page';
import { PromotionStore, YupPromotion } from '../stores/promotion';
import draftToHtml from 'draftjs-to-html';
import { Navigation } from '@sentry/react/types/types';
import { AuthStore } from '../stores/auth';
import { CategoryStore } from '../stores/category';

const inverseConverter = (promo: PromotionForm): unknown => {
    const hashConfig = {
        trigger: '#',
        separator: ' ',
    };

    return {
        ...promo,
        items: promo.items.map((x) => {
            const rawContentState = convertToRaw(
                x.description.getCurrentContent()
            );
            const markup = draftToHtml(rawContentState, hashConfig);
            return {
                ...x,
                description: markup,
            };
        }),
    };
};

export const createPromotion = async (
    formik: { values: PromotionForm },
    promotionStore: PromotionStore,
    navigation: Navigation,
    authStore: AuthStore
) => {
    formik.values.cmsUserEmail = authStore.claims?.email || null;

    const unknownPromo = inverseConverter(formik.values);
    if (YupPromotion.isValidSync(unknownPromo)) {
        const success = await promotionStore.createPromotion(unknownPromo);
        if (success) {
            navigation.replaceToPromotions();
        }
    }
};

export const updatePromotion = async (
    formik: { values: PromotionForm },
    promotionStore: PromotionStore,
    authStore: AuthStore
) => {
    formik.values.cmsUserEmail = authStore.claims?.email || null;

    const unknownPromo = inverseConverter(formik.values);
    if (YupPromotion.isValidSync(unknownPromo)) {
        promotionStore.modifyPromotion(unknownPromo);
    }
};

export const setCategory = (
    categoryId: number,
    formik: {
        values: PromotionForm;
        setFieldTouched: (field: string, isTouched: boolean) => void;
        setValues: (values: PromotionForm) => void;
    },
    categoryStore: CategoryStore
) => {
    const values = produce(formik.values, (draft) => {
        draft.categoryId = categoryId;
        draft.category = categoryStore.getActiveCategoryById(categoryId);
    });
    formik.setFieldTouched('categoryId', true);
    formik.setValues(values);
};

export const onDeleteConfirm = async (
    params: { id: string },
    setIsDeleting: (isDeleting: boolean) => void,
    promotionStore: PromotionStore,
    navigation: Navigation
) => {
    setIsDeleting(true);
    const response = await promotionStore.delete(Number(params.id!));
    setIsDeleting(false);

    if (!response.err) {
        navigation.replaceToPromotions();
    }
};
