import React from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { Input, Spin, Button } from 'antd';
import { useFormik } from 'formik';
import { useAuthStore, useOfferStore } from '../../contexts/mobx';
import { OfferUpsertPayload, YupOfferUpsertPayload } from '../../stores/offer';
import { useNavigation } from '../../utils/use-navigation';
import { ModalManager } from '../../components';
import { RollbackOutlined } from '@ant-design/icons';

// Validation for the expected params
const YupParams = yup.object({
    action: yup.string().oneOf(['create', 'edit']).required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(v === undefined && action !== 'create');
        }),
});

type Params = yup.InferType<typeof YupParams>;

export const OfferPage = observer(() => {
    const params = useParams<Params>();
    const offerStore = useOfferStore();
    const navigation = useNavigation();
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);

    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const formik = useFormik<OfferUpsertPayload>({
        initialValues: {
            items: [
                {
                    title: '',
                    language: 'en',
                },
                {
                    title: '',
                    language: 'fr',
                },
            ],
            canBeDeleted: false,
        },
        validateOnMount: true,
        validationSchema: YupOfferUpsertPayload,
        onSubmit: () => console.log('Submit!'),
    });

    if (!YupParams.isValidSync(params)) {
        navigation.replaceToOfferCreate();
    }

    React.useEffect(() => {
        if (params.action === 'edit') {
            setIsFetching(true);
            offerStore
                .fetchOffer(Number(params.id!))
                .then((offer) => formik.setValues(offer))
                .catch(() => navigation.replaceToOfferCreate())
                .finally(() => setIsFetching(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createOffer = async () => {
        setIsUpserting(true);
        try {
            await offerStore.createOffer(formik.values);
            navigation.replaceToOffers();
        } catch {
            //empty
        } finally {
            setIsUpserting(false);
        }
    };

    const updateOffer = async () => {
        setIsUpserting(true);
        try {
            await offerStore.updateOffer(formik.values, Number(params.id!));
        } catch {
            //empty
        } finally {
            setIsUpserting(false);
        }
    };

    const onDeleteConfirm = async () => {
        setIsDeleting(true);
        const response = await offerStore.deleteOffer(Number(params.id!));
        setIsDeleting(false);

        if (!response.err) {
            navigation.replaceToOffers();
        }
    };

    return (
        <div>
            <div className={'buttons_holder'}>
                <Button
                    type="default"
                    icon={<RollbackOutlined />}
                    shape="round"
                    onClick={navigation.goToOffers}
                >
                    Back
                </Button>
                {params.action === 'create' && (
                    <Button
                        type="primary"
                        loading={isUpserting}
                        onClick={createOffer}
                        disabled={!formik.isValid}
                    >
                        Create
                    </Button>
                )}
                {params.action === 'edit' && (
                    <Button
                        type="primary"
                        onClick={updateOffer}
                        loading={isUpserting}
                        disabled={!formik.isValid}
                    >
                        Save
                    </Button>
                )}
                {params.action === 'edit' && formik.values.canBeDeleted && (
                    <Button
                        type="primary"
                        danger={true}
                        onClick={ModalManager.showDeleteModal({
                            onConfirm: onDeleteConfirm,
                        })}
                        loading={isDeleting}
                        disabled={!formik.isValid}
                    >
                        Delete
                    </Button>
                )}
            </div>
            {isFetching ? (
                <Spin />
            ) : (
                <form className="small_form">
                    {formik.values.items.map((item, i) => (
                        <div key={item.language}>
                            <span>Title {item.language}</span>
                            <Input
                                name={`items[${i}].title`}
                                value={item.title}
                                onChange={formik.handleChange}
                            />
                        </div>
                    ))}
                </form>
            )}
        </div>
    );
});
