import React from 'react';
import { AcvtSupportedLanguages, AcvtTabs } from '../../../types/acvt';
import { AcvtTopTabs } from '../top-tabs';
import style from './style.module.scss';

export const AcvtHomePromotions = (props: {
    language: AcvtSupportedLanguages;
    couponCards: React.ReactNode;
    inStoreCards: React.ReactNode;
    viewAll: React.Dispatch<React.SetStateAction<AcvtTabs>>;
}) => {
    const couponCount = React.Children.toArray(props.couponCards).length;
    const inStoreCount = React.Children.toArray(props.inStoreCards).length;

    return (
        <>
            <div className={style.promotions_content}>
                {props.language === 'en' && (
                    <AcvtTopTabs
                        tabsArray={[
                            {
                                name: 'Coupons',
                                key: 'home_coupons_en',
                                children: (
                                    <div className={style.side_scroll}>
                                        {props.couponCards}
                                    </div>
                                ),
                            },
                            {
                                name: 'In Store',
                                key: 'home_instore_en',
                                children: (
                                    <div className={style.side_scroll}>
                                        {props.inStoreCards}
                                    </div>
                                ),
                            },
                        ]}
                    />
                )}
                {props.language === 'fr' && (
                    <AcvtTopTabs
                        tabsArray={[
                            {
                                name: 'Coupons',
                                key: 'home_coupons_fr',
                                children: (
                                    <div className={style.side_scroll}>
                                        {props.couponCards}
                                    </div>
                                ),
                            },
                            {
                                name: 'En Magasin',
                                key: 'home_instore_fr',
                                children: (
                                    <div className={style.side_scroll}>
                                        {props.inStoreCards}
                                    </div>
                                ),
                            },
                        ]}
                    />
                )}
                <div className={style.view_all}>
                    {props.language === 'fr' ? (
                        <span onClick={() => props.viewAll('promos')}>
                            Voir tout
                        </span>
                    ) : (
                        <span onClick={() => props.viewAll('promos')}>
                            View all
                        </span>
                    )}
                </div>
                <div className={style.counter_coupon}>{couponCount}</div>
                <div className={style.counter_instore}>{inStoreCount}</div>
            </div>
        </>
    );
};
