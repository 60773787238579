import React from 'react';
import style from './style.module.scss';
import { InputText } from '../input-text';

type PhoneEditorProps = {
    phone: string;
    setPhone: (value: string) => void;
};

export const PhoneEditor = ({ phone, setPhone }: PhoneEditorProps) => {
    const [phoneNumber, setPhoneNumber] = React.useState(phone);
    const [isEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
        setPhoneNumber(phone);
    }, [phone]);

    const onCancel = () => {
        setPhoneNumber(phone);
        setIsEditing(false);
    };

    const savePhoneNumber = () => {
        setPhone(phoneNumber);
        setIsEditing(false);
    };

    const validatePhoneFormat = () => {
        const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
        return !phoneRegex.test(phoneNumber);
    };

    return (
        <div>
            <div className={style.input_holder}>
                {isEditing ? (
                    <div>
                        <span>*Must be in the format XXX-XXX-XXXX</span>
                        <InputText
                            value={phoneNumber}
                            setValue={setPhoneNumber}
                        />
                    </div>
                ) : (
                    phoneNumber
                )}
            </div>
            {isEditing ? (
                <div className={style.buttons_holder}>
                    <button className={style.cancel_button} onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className={style.confirm_button}
                        onClick={savePhoneNumber}
                        disabled={validatePhoneFormat()}
                    >
                        Confirm
                    </button>
                </div>
            ) : (
                <div className={style.buttons_holder}>
                    <button
                        className={style.edit_button}
                        onClick={() => setIsEditing(true)}
                    >
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
};
