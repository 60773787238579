import React from 'react';
import style from './style.module.scss';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';
import { Store, StoresStore } from '../../stores/stores';
import { Moment } from 'moment';
import moment from 'moment';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import { StoreEditingLocation } from '../store-editing-location';
import { StoreEditingDays } from '../store-editing-days';
import { StoreEditingServices } from '../store-editing-services';
import { StoreTag } from '../store-tag';
import { WEEKDAYS } from '../../helpers/store-helper';

type StoreEditingProps = {
    onCloseClick: () => void;
    storeObject: Store;
    store: StoresStore;
    onSave: () => void;
};

type Weekday = typeof WEEKDAYS[number];
type HoursMap = { [key: string]: Weekday[] };

function groupDaysByHours(hours: (string | null | undefined)[]): HoursMap {
    const hoursMap: HoursMap = {};

    hours.forEach((hour, index) => {
        if (hour) {
            const day = WEEKDAYS[index];
            if (!hoursMap[hour]) {
                hoursMap[hour] = [];
            }
            hoursMap[hour].push(day);
        }
    });
    return hoursMap;
}

export const StoreEditing = observer(
    ({ onCloseClick, storeObject, store, onSave }: StoreEditingProps) => {
        const [selectedServices, setSelectedServices] = React.useState<
            string[]
        >([]);
        const [selectedWeekdays, setSelectedWeekdays] = React.useState<
            string[]
        >([]);
        const [storeStatus, setStoreStatus] = React.useState('');
        const [storePhone, setStorePhone] = React.useState('');
        const [storeCoordinates, setStoreCoordinates] = React.useState({
            lat: storeObject?.lat ?? 0,
            lng: storeObject?.lng ?? 0,
        });

        const [openHoursComponents, setOpenHoursComponents] = React.useState<
            {
                id: number;
                startTime: Moment | null;
                endTime: Moment | null;
                open: boolean;
                selectedWeekdays: string[];
            }[]
        >([]);
        const [isLoading, setIsLoading] = React.useState(false);

        function initalState() {
            if (storeObject) {
                setSelectedServices(storeObject.services);
                setOpenHoursComponents([]);
                setSelectedWeekdays([]);
                setStorePhone(storeObject.tel ?? '');
                setStoreCoordinates({
                    lat: storeObject.lat ?? 0,
                    lng: storeObject.lng ?? 0,
                });
                let allSelectedWeekdays: string[] = [];
                const groupedDays = groupDaysByHours(storeObject.hours);
                for (const [hour, days] of Object.entries(groupedDays)) {
                    allSelectedWeekdays = [...allSelectedWeekdays, ...days];
                    setOpenHoursComponents((prevComponents) => [
                        ...prevComponents,
                        {
                            id: prevComponents.length,
                            startTime: moment(hour.substring(0, 5), 'HH:mm'),
                            endTime: moment(
                                hour.substring(hour.length - 5),
                                'HH:mm'
                            ),
                            open: true,
                            selectedWeekdays: days,
                        },
                    ]);
                    setSelectedWeekdays(allSelectedWeekdays);
                }
                determineStoreStatus(allSelectedWeekdays, openHoursComponents);
            }
        }

        React.useEffect(() => {
            initalState();
        }, [storeObject]);

        React.useEffect(() => {
            determineStoreStatus(selectedWeekdays, openHoursComponents);
        }, [openHoursComponents, selectedWeekdays]);

        const determineStoreStatus = (
            selectedWeekdays: string[],
            openHoursComponents: {
                id: number;
                startTime: Moment | null;
                endTime: Moment | null;
                open: boolean;
                selectedWeekdays: string[];
            }[]
        ) => {
            const allDaysSelected = WEEKDAYS.every((day) =>
                selectedWeekdays.includes(day)
            );
            const validOpenHoursComponents = openHoursComponents.filter(
                (component) => component.selectedWeekdays.length > 0
            );
            const allDays24Hours = validOpenHoursComponents.every(
                (component) =>
                    component.startTime &&
                    component.endTime &&
                    component.startTime.format('HH:mm') === '00:00' &&
                    component.endTime.format('HH:mm') === '00:00' &&
                    component.open
            );

            if (allDaysSelected && allDays24Hours) {
                setStoreStatus('open-24');
            } else if (
                selectedWeekdays.length > 0 &&
                openHoursComponents.some(
                    (component) =>
                        component.startTime &&
                        component.endTime &&
                        component.open
                )
            ) {
                setStoreStatus('open');
            } else {
                setStoreStatus('closed');
            }
        };

        const saveChanges = async () => {
            setIsLoading(true);

            const hours = new Array(7).fill('');
            openHoursComponents.forEach((component) => {
                if (
                    component.startTime &&
                    component.endTime &&
                    component.open
                ) {
                    const formattedHour = `${component.startTime.format(
                        'HH:mm'
                    )}-${component.endTime.format('HH:mm')}`;
                    component.selectedWeekdays.forEach((weekday) => {
                        const index = WEEKDAYS.indexOf(weekday);
                        if (index !== -1) {
                            hours[index] = formattedHour;
                        }
                    });
                }
            });

            const payload: { additions: Store[]; modifications: Store[] } = {
                additions: [],
                modifications: [
                    {
                        id: storeObject.id,
                        nref: storeObject.nref,
                        title: storeObject.title,
                        lat: storeCoordinates.lat,
                        lng: storeCoordinates.lng,
                        img: storeObject.img,
                        tel: storePhone,
                        hours: hours,
                        services: selectedServices,
                        status: storeStatus,
                        addr: {
                            street: storeObject.addr.street,
                            city: storeObject.addr.city,
                            post: storeObject.addr.post,
                        },
                    },
                ],
            };
            const actionWorked = await store.uploadChanges(payload);
            if (actionWorked) {
                onSave();
            }
            setIsLoading(false);
        };

        const resetChanges = () => {
            initalState();
        };

        return (
            <>
                <div className={style.container}>
                    <div className={style.content}>
                        <div className={style.close} onClick={onCloseClick}>
                            <CloseIcon />
                        </div>
                        <div className={style.editing_type}>
                            #{storeObject.nref}
                        </div>
                        <div className={style.title}>Store editing</div>
                        <div className={style.store_info}>
                            <StoreEditingLocation
                                storeObject={storeObject}
                                storePhone={storePhone}
                                setStorePhone={setStorePhone}
                                storeCoordinates={storeCoordinates}
                                setStoreCoordinates={setStoreCoordinates}
                            />
                            <div className={style.single_info}>
                                <b>Status: </b>
                                <div>
                                    <StoreTag status={storeStatus} />
                                </div>
                            </div>
                            <div className={style.single_info}>
                                <b>Opening hours:</b>
                                <StoreEditingDays
                                    openHoursComponents={openHoursComponents}
                                    setOpenHoursComponents={
                                        setOpenHoursComponents
                                    }
                                    selectedWeekdays={selectedWeekdays}
                                    setSelectedWeekdays={setSelectedWeekdays}
                                />
                            </div>
                            <div className={style.single_info}>
                                <b>Services:</b>
                                <StoreEditingServices
                                    selectedServices={selectedServices}
                                    setSelectedServices={setSelectedServices}
                                />
                            </div>
                            <div className={style.save_buttons}>
                                <button
                                    className={style.reset_button}
                                    onClick={resetChanges}
                                >
                                    Reset to inital
                                </button>
                                <button
                                    className={style.save_button}
                                    onClick={saveChanges}
                                >
                                    {isLoading ? <Spin /> : 'Save changes'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);
