import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import style from './style.module.scss';
import { Text } from '../../utils/text';
import moment from 'moment';

export interface DatePickerOptions {
    className?: string;
    allowClear?: boolean;
    format?: string;
    showTime?: boolean;
    disabledDate?: (current: any) => boolean;
    value: string | null;
    onChange: (e: any) => void;
}

export const DatePicker = ({
    className,
    allowClear,
    format,
    showTime,
    disabledDate,
    value,
    onChange,
}: DatePickerOptions) => {
    return (
        <AntDatePicker
            className={className ?? style.config_field}
            allowClear={allowClear ?? true}
            format={format ?? Text.dateTime.longAMPM}
            showTime={
                showTime ?? {
                    format: Text.dateTime.hourMinute,
                }
            }
            disabledDate={disabledDate}
            value={value ? moment(value) : undefined}
            onChange={(e) => onChange(e)}
        />
    );
};
