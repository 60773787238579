import React from 'react';
import { ReactComponent as IphoneIcon } from '../../../icons/IPhone13.svg';
import style from './style.module.scss';

interface PageProps {
    backgroundColor?: string;
    children?: React.ReactNode;
}

export const Acvt = ({ backgroundColor, children }: PageProps) => {
    return (
        <>
            <div
                className={style.iphone}
                style={{ backgroundColor: backgroundColor }}
            >
                <div className={style.content}>
                    {children}
                    <div className={style.iphone_home_bar}>
                        <span className={style.swipe_bar}></span>
                    </div>
                </div>
                <IphoneIcon className={style.iphone13} />
            </div>
        </>
    );
};
