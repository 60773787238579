import React from 'react';
import { observer } from 'mobx-react';
import { useAuthStore, useConfigStore } from '../../contexts/mobx';
import {
    MinimalAppCard,
    CarwashCustomerServiceStatusCard,
    FeatureFlagsCard,
} from './cards';
import { useNavigation } from '../../utils/use-navigation';
import style from './style.module.scss';
import { PageHeader } from '../../components/page-header';

export const GeneralConfigsPage = observer(() => {
    const configStore = useConfigStore();
    const authStore = useAuthStore();
    const navigation = useNavigation();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    React.useEffect(() => {
        configStore.fetchCarwashCustomerServiceStatus();
        configStore.fetchMinimalAppConfig();
        configStore.fetchFeatureFlags();
    }, [configStore]);

    return (
        <div className={style.container}>
            <PageHeader title={'Configuration'} />

            <FeatureFlagsCard
                isLoading={configStore.isFetchingFeatureFlags}
                featureFlags={configStore.featureFlags}
                onSubmit={(data) => configStore.updateFetureFlags(data)}
            />
            <CarwashCustomerServiceStatusCard
                isLoading={configStore.isfetchingCarwashCustomerServiceStatus}
                isUpdating={configStore.isUpdatingCarwashCustomerServiceStatus}
                carwashCustomerServiceStatus={
                    configStore.carwashCustomerServiceStatus
                }
                onSubmit={(data) =>
                    configStore.updateCarwashCustomerServiceStatus(data)
                }
            />
            <MinimalAppCard
                isLoading={configStore.isFetchingMinimalAppConfig}
                isUpdating={configStore.isUpdatingMinimalAppConfig}
                minimalAppConfig={configStore.minimalAppConfig}
                onSubmit={(data) => configStore.updateMinimalAppConfig(data)}
            />
        </div>
    );
});
