import React from 'react';
import './App.css';
import { StoreProvider } from './contexts/mobx';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Switch, Route, useHistory } from 'react-router-dom';

import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import 'antd/dist/antd.css';

import { LoginPage, HomePage } from './containers';

const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
const { REACT_APP_OKTA_CLIENT_ID, REACT_APP_OKTA_ISSUER } = process.env;

export const oktaAuth = new OktaAuth({
    clientId: REACT_APP_OKTA_CLIENT_ID,
    issuer: REACT_APP_OKTA_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    postLogoutRedirectUri: `${window.location.origin}${BASENAME}/login`,
});

function App() {
    const history = useHistory();

    return (
        <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={(_oktaAuth, originalUri) => {
                history.replace(
                    toRelativeUrl(originalUri || '/', window.location.origin)
                );
            }}
        >
            <StoreProvider>
                <Switch>
                    <Route path="/login/callback" component={LoginCallback} />
                    <Route path="/login">
                        <LoginPage />
                    </Route>
                    <SecureRoute path="/">
                        <HomePage />
                    </SecureRoute>
                </Switch>
            </StoreProvider>
        </Security>
    );
}

export default App;
