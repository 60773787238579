import { Switch, DatePicker } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment, { Moment } from 'moment';
import React from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import style from './style.module.scss';
import { RangeValue } from 'rc-picker/lib/interface';

const { RangePicker } = DatePicker;

interface Props {
    onChange: (values: RadioChangeEvent) => void;
    compare: (values: boolean) => void;
    updateParams: (dates: RangeValue<Moment>) => void;
    startDate: string | undefined;
    endDate: string | undefined;
}

export const AnalyticsDatesPicker = (props: Props) => {
    return (
        <>
            <div className={style.input_row}>
                <RangePicker
                    className={style.filters}
                    allowEmpty={[true, true]}
                    placeholder={['Start at', 'End at']}
                    defaultValue={[
                        props.startDate ? moment(props.startDate) : null,
                        props.endDate ? moment(props.endDate) : null,
                    ]}
                    value={[
                        props.startDate ? moment(props.startDate) : null,
                        props.endDate ? moment(props.endDate) : null,
                    ]}
                    onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                        props.updateParams(dates);
                    }}
                    format="YYYY-MM-DD"
                />
                <div className={style.compare}>
                    <span className={style.left_label}>Compare</span>
                    <Switch
                        onChange={(e) => props.compare(e)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        className={style.switch_button}
                    />
                </div>
            </div>
        </>
    );
};
