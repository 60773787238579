import React from 'react';
import styles from './style.module.scss';
import {
    Client,
    ClientExternalDetails,
} from '../../../../../../../stores/client';

interface ClientDetailsProps {
    client: Client | ClientExternalDetails | null;
    title: string;
}

const ClientDetails = ({ client, title }: ClientDetailsProps) => {
    if (!client) {
        return null;
    }

    return (
        <div className={styles.section}>
            {title && <div className={styles.sectionTitle}>{title}</div>}
            <table className={styles.table} aria-label="Client Details">
                <tbody>
                    <tr>
                        <th>Client ID</th>
                        <td>{client.id}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{client.email}</td>
                    </tr>
                    <tr>
                        <th>NGRP Customer ID</th>
                        <td>{client.ngrpCustomerId ?? 'None'}</td>
                    </tr>
                    <tr>
                        <th>Liquid Barcode ID</th>
                        <td>{client.liquidBarcodeId ?? 'None'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ClientDetails;
