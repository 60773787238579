import { observable, action, makeObservable } from 'mobx';
import * as yup from 'yup';
import { RootStore } from './root';
import { MomentSchema } from '../utils/yup-schemas';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { HttpMethod } from '../types';

const npsDaySchema = yup.object({
    date: new MomentSchema().nullable(),
    npsOfTheDay: yup.string().required(),
});

const npsListSchema = yup.object({
    currentPeriod: yup
        .object({
            npsPeriod: yup.object({
                npsOfThePeriod: yup.string().nullable(),
            }),
            npsDay: yup.array(npsDaySchema).default([]),
        })
        .nullable(),
    comparePeriod: yup
        .object({
            npsPeriod: yup
                .object({
                    npsOfThePeriod: yup.string().nullable(),
                })
                .nullable(),
            npsDay: yup.array(npsDaySchema).default([]).nullable(),
        })
        .nullable(),
});

export type NpsDay = yup.InferType<typeof npsDaySchema>;
export type NPSList = yup.InferType<typeof npsListSchema>;

export type NPSSearchParams = {
    startDate?: string;
    endDate?: string;
    featureScored?: string;
};

export class NPSStore extends WithNetworkConcurrency {
    @observable fetchingCount = 0;
    @observable npsList: NPSList | null = null;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    async fetchNPS(this: NPSStore, params: NPSSearchParams) {
        const tag = this.getTag();
        this.fetchingCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: 'feedback/nps',
                params,
            },
            npsListSchema
        );
        this.fetchingCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.npsList = response.data;
        }
    }
}
