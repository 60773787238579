import React from 'react';
import style from './style.module.scss';
import { Select, Tag } from 'antd';
import { getWeekdayTagColor } from '../../helpers/store-helper';

type DropDownDaysProps = {
    options: {
        label: string;
        value: string;
    }[];
    selectedOptions: string[];
    onChange?: (value: string[]) => void;
    placeholder?: string;
};

const tagRender = (props: { label: any; closable: any; onClose: any }) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={getWeekdayTagColor(label as string)}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginInlineEnd: 4 }}
        >
            {label}
        </Tag>
    );
};

const dayOrder = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

const sortOptions = (options: string[]) => {
    return options.sort((a, b) => {
        return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });
};

export const DropDownDays = ({
    options,
    selectedOptions,
    onChange,
    placeholder,
}: DropDownDaysProps) => {
    const handleChange = (value: string[]) => {
        const sortedDays = sortOptions(value);
        onChange && onChange(sortedDays);
    };
    return (
        <>
            <Select
                mode="multiple"
                tagRender={tagRender}
                showArrow
                allowClear
                placeholder={placeholder}
                options={options}
                value={selectedOptions}
                onChange={handleChange}
                className={style.weekday_select}
            />
        </>
    );
};
