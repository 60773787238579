import React from 'react';
import { Modal, Spin } from 'antd';

interface ResetPasswordModalProps {
    isVisible: boolean;
    clientEmail: string | null | undefined;
    onSend: (email: string) => void;
    onClose: () => void;
    isSending: boolean;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
    isVisible,
    clientEmail,
    onSend,
    onClose,
    isSending,
}) => {
    return (
        <Modal
            title="Send reset password"
            visible={isVisible}
            onOk={() => {
                if (clientEmail) {
                    onSend(clientEmail);
                }
            }}
            onCancel={onClose}
        >
            {isSending ? (
                <Spin />
            ) : (
                <p>
                    This action will send a reset password email to{' '}
                    {clientEmail}, are you sure you want to continue?
                </p>
            )}
        </Modal>
    );
};

export default ResetPasswordModal;
