import { Modal, Select, Spin, Table, Tooltip } from 'antd';
import React from 'react';
import {
    ActivatedCoupons,
    ActivatedCouponsQuery,
    Client,
} from '../../../stores/client';
import style from './style.module.scss';
import { ReactComponent as CircleIcon } from '../../../icons/CheckCircleEmpty.svg';
import { ReactComponent as TicketIcon } from '../../../icons/Ticket.svg';
import moment from 'moment';
import { useClientStore } from '../../../contexts/mobx';
import { observer } from 'mobx-react';
import {
    activeGreen,
    defaultNumberOfDays,
    inactiveGrey,
    pendingOrange,
} from '../../../utils/const';
import { noUserSelectedError } from '../../../utils/error';
import { SearchInput } from '../../search-input';
import { Text } from '../../../utils/text';

type CouponsProps = {
    clientObject?: Client;
    hasSearchBar?: boolean;
};

export const UserCouponsTab = observer(
    ({ clientObject, hasSearchBar = false }: CouponsProps) => {
        const clientStore = useClientStore();
        const [activatedCoupons, setActivatedCoupons] = React.useState({
            loading: clientStore.isFetchingActivatedCoupons,
            data: clientStore.activatedCoupons,
        });
        const [paramsCoupons, setParamsCoupons] = React.useState<
            ActivatedCouponsQuery
        >({ clientId: clientObject?.id, numberOfDays: defaultNumberOfDays });
        const [selectedCoupon, setSelectedCoupon] = React.useState<
            ActivatedCoupons
        >();
        const [reissueCouponModal, setReissueCouponModal] = React.useState(
            false
        );

        const [searchValue, setSearchValue] = React.useState<string>();

        const handleSearchClick = () =>
            setParamsCoupons({
                clientId: clientObject?.id,
                numberOfDays: paramsCoupons.numberOfDays,
                q: searchValue,
            });

        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
            e.target.value !== ''
                ? setSearchValue(e.target.value)
                : (setParamsCoupons({
                      clientId: clientObject?.id,
                      numberOfDays: paramsCoupons.numberOfDays,
                      q: e.target.value,
                  }),
                  setSearchValue(e.target.value));

        const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
            e.key === 'Enter' &&
            setParamsCoupons({
                clientId: clientObject?.id,
                numberOfDays: paramsCoupons.numberOfDays,
                q: searchValue,
            });

        React.useEffect(() => {
            setParamsCoupons({
                clientId: clientObject?.id,
                numberOfDays: defaultNumberOfDays,
            });
        }, [clientObject]);

        React.useEffect(() => {
            clientStore.getActivatedCoupons(paramsCoupons);
            setSearchValue(paramsCoupons.q);
        }, [paramsCoupons, clientStore]);

        React.useEffect(() => {
            setActivatedCoupons({
                loading: clientStore.isFetchingActivatedCoupons,
                data: clientStore.activatedCoupons,
            });
        }, [
            clientStore.isFetchingActivatedCoupons,
            clientStore.activatedCoupons,
        ]);

        const handleReissueCoupon = React.useCallback(
            (coupon: ActivatedCoupons) => {
                if (!clientObject) {
                    Modal.error({ title: noUserSelectedError });
                } else {
                    setSelectedCoupon(coupon);
                    setReissueCouponModal(true);
                }
            },
            []
        );

        const columns = [
            {
                title: Text.userCouponsTab.columns.status.title,
                dataIndex: Text.userCouponsTab.columns.status.dataIndex,
                key: Text.userCouponsTab.columns.status.title,
                render: (_: unknown, record: ActivatedCoupons) => (
                    <div
                        className={
                            record.status ===
                            Text.userCouponsTab.promoStatus.active
                                ? `${style.status} ${style.active_green}`
                                : record.status ===
                                  Text.userCouponsTab.promoStatus.upcoming
                                ? `${style.status} ${style.pending_orange}`
                                : `${style.status} ${style.inactive_grey}`
                        }
                    >
                        <CircleIcon
                            stroke={
                                record.status ===
                                Text.userCouponsTab.promoStatus.active
                                    ? activeGreen
                                    : record.status ===
                                      Text.userCouponsTab.promoStatus.upcoming
                                    ? pendingOrange
                                    : inactiveGrey
                            }
                            strokeWidth="2px"
                            className={style.active_circle}
                        />
                        {record.status}
                    </div>
                ),
            },
            {
                title: Text.userCouponsTab.columns.deal.title,
                dataIndex: Text.userCouponsTab.columns.deal.dataIndex,
                key: Text.userCouponsTab.columns.deal.key,
                render: (_: unknown, record: ActivatedCoupons) => (
                    <div className={style.deal}>
                        <img alt="" src={record.thumbnail!} />
                        <div className={style.deal_info}>
                            <span
                                className={
                                    record.status ===
                                    Text.userCouponsTab.promoStatus.active
                                        ? `${style.promo_title} ${style.active_promo_title}`
                                        : style.promo_title
                                }
                            >
                                {record.title}
                            </span>
                            <span>#{record.promotionId}</span>
                        </div>
                    </div>
                ),
            },
            {
                title: Text.userCouponsTab.columns.activation.title,
                dataIndex: Text.userCouponsTab.columns.activation.dataIndex,
                key: Text.userCouponsTab.columns.activation.key,
                render: (_: unknown, record: ActivatedCoupons) => (
                    <>
                        {record.createdAt != null && (
                            <div className={style.activation}>
                                <span className={style.created_at}>
                                    {moment(record.createdAt).format('LL')}
                                </span>
                                <span className={style.reissued_at}>
                                    {record.deletedAt != null &&
                                        `${
                                            Text.userCouponsTab.columns.reissue
                                                .title
                                        }: ${moment(record.deletedAt).format(
                                            'LL'
                                        )}`}
                                </span>
                            </div>
                        )}
                    </>
                ),
            },
            {
                title: Text.userCouponsTab.columns.reissue.title,
                key: Text.userCouponsTab.columns.reissue.key,
                render: (_: unknown, record: ActivatedCoupons) =>
                    record.status === Text.userCouponsTab.promoStatus.active &&
                    record.deletedAt === null && (
                        <Tooltip
                            placement="top"
                            title={Text.userCouponsTab.columns.reissue.text}
                        >
                            <div className={style.reissue}>
                                <button
                                    className={style.reissue_button}
                                    onClick={() => handleReissueCoupon(record)}
                                >
                                    <TicketIcon />
                                </button>
                            </div>
                        </Tooltip>
                    ),
            },
        ];
        return (
            <>
                <div className={style.coupons_tab}>
                    {hasSearchBar ? (
                        <div className={style.filters_container}>
                            <div className={style.searchbar_container}>
                                <SearchInput
                                    propsStyle={{
                                        width: '375px',
                                    }}
                                    searchValue={searchValue}
                                    onClickIcon={handleSearchClick}
                                    onChange={handleOnChange}
                                    onKeyDown={handleOnKeyDown}
                                />
                            </div>
                            <div
                                className={
                                    style.selector_container_beside_search
                                }
                            >
                                <Select
                                    value={paramsCoupons.numberOfDays}
                                    defaultValue={defaultNumberOfDays} //backend returns default value of 7
                                    placeholder={
                                        Text.userCouponsTab.numberOfDays.all
                                    }
                                    onChange={(e) => {
                                        setParamsCoupons({
                                            clientId: clientObject?.id,
                                            numberOfDays: e,
                                        });
                                    }}
                                    allowClear
                                >
                                    <Select.Option value={1}>
                                        {
                                            Text.userCouponsTab.numberOfDays
                                                .lastDay
                                        }
                                    </Select.Option>
                                    <Select.Option value={defaultNumberOfDays}>
                                        {Text.userCouponsTab.numberOfDays.last7}
                                    </Select.Option>
                                    <Select.Option value={14}>
                                        {
                                            Text.userCouponsTab.numberOfDays
                                                .last14
                                        }
                                    </Select.Option>
                                    <Select.Option value={30}>
                                        {
                                            Text.userCouponsTab.numberOfDays
                                                .last30
                                        }
                                    </Select.Option>
                                    <Select.Option value={90}>
                                        {
                                            Text.userCouponsTab.numberOfDays
                                                .last90
                                        }
                                    </Select.Option>
                                </Select>
                            </div>
                        </div>
                    ) : (
                        <div className={style.selector_container}>
                            <Select
                                value={paramsCoupons.numberOfDays}
                                defaultValue={defaultNumberOfDays} //backend returns default value of 7
                                placeholder={
                                    Text.userCouponsTab.numberOfDays.all
                                }
                                onChange={(e) => {
                                    setParamsCoupons({
                                        clientId: clientObject?.id,
                                        numberOfDays: e,
                                    });
                                }}
                                allowClear
                            >
                                <Select.Option value={1}>
                                    {Text.userCouponsTab.numberOfDays.lastDay}
                                </Select.Option>
                                <Select.Option value={defaultNumberOfDays}>
                                    {Text.userCouponsTab.numberOfDays.last7}
                                </Select.Option>
                                <Select.Option value={14}>
                                    {Text.userCouponsTab.numberOfDays.last14}
                                </Select.Option>
                                <Select.Option value={30}>
                                    {Text.userCouponsTab.numberOfDays.last30}
                                </Select.Option>
                                <Select.Option value={90}>
                                    {Text.userCouponsTab.numberOfDays.last90}
                                </Select.Option>
                            </Select>
                        </div>
                    )}
                    {activatedCoupons.loading ? (
                        <div className={style.spinner_container}>
                            <Spin />
                        </div>
                    ) : activatedCoupons.data?.results.length !== 0 ? (
                        <Table
                            columns={columns}
                            dataSource={
                                activatedCoupons.data?.results != null
                                    ? activatedCoupons.data.results
                                    : []
                            }
                            rowClassName={(record) =>
                                record.status !==
                                Text.userCouponsTab.promoStatus.active
                                    ? record.status ===
                                      Text.userCouponsTab.promoStatus.upcoming
                                        ? style.upcoming_row
                                        : style.expired_row
                                    : style.active_row
                            }
                            pagination={false}
                        />
                    ) : searchValue ? (
                        <div className={style.no_coupons}>
                            No coupons activated for this search query...
                        </div>
                    ) : (
                        <div className={style.no_coupons}>
                            No coupons activated during this time frame...
                        </div>
                    )}
                </div>
                <Modal
                    title={Text.userCouponsTab.modal.title}
                    visible={reissueCouponModal}
                    onOk={async () => {
                        clientObject &&
                            selectedCoupon &&
                            (await clientStore.reissuePromotion(
                                clientObject?.id,
                                selectedCoupon?.id
                            ));
                        setReissueCouponModal(false);
                        clientStore.getActivatedCoupons(paramsCoupons);
                    }}
                    onCancel={() => setReissueCouponModal(false)}
                >
                    {clientStore.isReissuingPromotion ? (
                        <Spin />
                    ) : (
                        <>
                            <p>
                                {Text.userCouponsTab.modal.text} "
                                {selectedCoupon?.title}"{' '}
                                {Text.userCouponsTab.modal.toUserText}
                                {clientObject?.id}.
                            </p>
                            <h4>{Text.userCouponsTab.modal.areYouSure}</h4>
                        </>
                    )}
                </Modal>
            </>
        );
    }
);
