import React from 'react';
import { Tooltip, Button, Descriptions } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Text } from '../../utils/text';
import style from './style.module.scss';
import { NhlGamePinsKpi, NhlGameKpiPeriod } from '../../types/nhl-game';
import { DatePicker } from '../date-picker';

export interface NhlGameKpiOptions {
    pinsKpi: NhlGamePinsKpi | null;
    kpiPeriod: NhlGameKpiPeriod;
    lastUploadDate: string;
    onPinsKpiFetch: () => void;
    setNhlGamePinsKpiPeriod: (kpiPeriod: NhlGameKpiPeriod) => void;
}

export const NhlGameKpi = ({
    pinsKpi,
    kpiPeriod,
    lastUploadDate,
    onPinsKpiFetch,
    setNhlGamePinsKpiPeriod,
}: NhlGameKpiOptions) => {
    const { startDate, endDate } = kpiPeriod;

    return (
        <>
            <div className={style.input_row}>
                <Tooltip
                    className={style.tooltip}
                    title={Text.nhlGame.uploadPins.tooltip}
                >
                    <InfoCircleOutlined />
                </Tooltip>
                <span>
                    {Text.nhlGame.configContainer.uploadPinsDescription}{' '}
                    {lastUploadDate}
                </span>
            </div>
            <div className={style.input_row_responsive}>
                <div>
                    <span>Start Date</span>
                    <br />
                    <DatePicker
                        value={startDate}
                        onChange={(e) =>
                            setNhlGamePinsKpiPeriod({
                                ...kpiPeriod,
                                startDate: e?.toISOString() ?? '',
                            })
                        }
                    />
                </div>
                <div>
                    <span>End Date</span>
                    <br />
                    <DatePicker
                        value={endDate}
                        onChange={(e) =>
                            setNhlGamePinsKpiPeriod({
                                ...kpiPeriod,
                                endDate: e?.toISOString() ?? '',
                            })
                        }
                    />
                </div>
                <div>
                    <Button
                        type="primary"
                        title="Get Pins Kpi"
                        onClick={onPinsKpiFetch}
                        style={{ marginTop: '1.375rem' }}
                    >
                        View Kpi Data
                    </Button>
                </div>
            </div>

            <Descriptions title="Kpi" bordered layout="vertical">
                <Descriptions.Item label={Text.nhlGame.pinsStats.ctPinsTotal}>
                    {pinsKpi?.ctPinsTotal}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.tbPinPendingValidation}
                >
                    {pinsKpi?.tbPinPendingValidation}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.tbPinsNeverValidated}
                >
                    {pinsKpi?.tbPinsNeverValidated}
                </Descriptions.Item>
                <Descriptions.Item label={Text.nhlGame.pinsStats.tbPinsTotal}>
                    {pinsKpi?.tbPinsTotal}
                </Descriptions.Item>
                <Descriptions.Item label={Text.nhlGame.pinsStats.tbPinsUsed}>
                    {pinsKpi?.tbPinsUsed}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.tbPinsValidated}
                >
                    {pinsKpi?.tbPinsValidated}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.tbPredsWithPin}
                >
                    {pinsKpi?.tbPredsWithPin}
                </Descriptions.Item>
                <Descriptions.Item label={Text.nhlGame.pinsStats.tbPredsNoPin}>
                    {pinsKpi?.tbPredsNoPin}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.totalInscriptions}
                >
                    {pinsKpi?.totalInscriptions}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.tbPredsWithFreePin}
                >
                    {pinsKpi?.tbPredsWithFreePin}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.tbPinsTotalFree}
                >
                    {pinsKpi?.tbPinsTotalFree}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.tbPinsTotalInputted}
                >
                    {pinsKpi?.tbPinsTotalInputted}
                </Descriptions.Item>
                <Descriptions.Item label={Text.nhlGame.pinsStats.rewardsWon}>
                    {pinsKpi?.rewardsWon}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.rewardsActivated}
                >
                    {pinsKpi?.rewardsActivated}
                </Descriptions.Item>
                <Descriptions.Item
                    label={Text.nhlGame.pinsStats.mostPopularTeam}
                >
                    {pinsKpi?.mostPopularTeam?.name ?? 'N/A'}
                </Descriptions.Item>
            </Descriptions>
        </>
    );
};
