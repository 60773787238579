import React, { useEffect, useState } from 'react';
import { Modal, Descriptions, Spin, Result, Button } from 'antd';
import { Client } from '../../../../../stores/client';
import { OperationStatus } from '../../../../../types';

interface DeleteClientModalProps {
    isVisible: boolean;
    client: Client | null;
    onDelete: (clientId: number) => Promise<void>;
    onClose: () => void;
}

const DeleteClientModal: React.FC<DeleteClientModalProps> = ({
    isVisible,
    client,
    onDelete,
    onClose,
}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteResult, setDeleteResult] = useState<{
        status: OperationStatus;
        title: string;
    } | null>(null);

    useEffect(() => {
        if (isVisible) {
            setIsDeleting(false);
            setDeleteResult(null);
        }
    }, [isVisible]);

    const handleDelete = async () => {
        if (client?.id) {
            setIsDeleting(true);
            try {
                await onDelete(client.id);
                setDeleteResult({
                    status: OperationStatus.SUCCESS,
                    title: 'Client successfully deleted from the database.',
                });
            } catch (error) {
                setDeleteResult({
                    status: OperationStatus.ERROR,
                    title: 'Failed to delete the client.',
                });
            } finally {
                setIsDeleting(false);
            }
        }
    };

    return (
        <Modal
            title="Delete Client"
            visible={isVisible}
            onCancel={onClose}
            footer={null}
        >
            <Descriptions column={1}>
                <Descriptions.Item label="Client ID">
                    {client?.id}
                </Descriptions.Item>
                <Descriptions.Item label="Name">
                    {client?.firstName} {client?.lastName}
                </Descriptions.Item>
            </Descriptions>
            {isDeleting ? (
                <div style={{ textAlign: 'center', padding: '24px' }}>
                    <Spin size="large" />
                </div>
            ) : deleteResult ? (
                <Result
                    status={deleteResult.status}
                    title={deleteResult.title}
                    extra={[
                        <Button key="close" onClick={onClose}>
                            Close
                        </Button>,
                    ]}
                />
            ) : (
                <>
                    <p>
                        You are about to delete the client's record from the
                        system. This action is irreversible.
                    </p>
                    <p>
                        <b>Note:</b> Ensure that you have verified the client's
                        identity and intent before proceeding.
                    </p>
                    <p>
                        If the client has an active carwash subscription, please
                        ensure to manually cancel it via the{' '}
                        <b>Liquid Barcode dashboard</b> before proceeding with
                        deletion.
                    </p>
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                        <Button onClick={handleDelete} type="primary" danger>
                            Confirm Delete
                        </Button>
                        <Button
                            onClick={onClose}
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default DeleteClientModal;
