import React from 'react';
import style from './style.module.scss';

export const PageHeader = (props: {
    children?: React.ReactNode;
    title: string;
}) => {
    return (
        <div className={style.page_title_container}>
            <h1 className={style.page_title}>{props.title}</h1>
            {props.children}
        </div>
    );
};
