import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import {
    CarwashCustomerServiceStatus,
    FeatureFlags,
    HttpMethod,
    MinimalAppConfig,
} from '../types';
import * as yup from 'yup';
import { NotificationManager } from '../components';

const MinimalAppNetworkResponse = yup.object({
    miniOSVersion: yup.string().required(),
    minAndroidBuildVersion: yup.number().required(),
});

const FeatureFlagSchema = yup.object({
    id: yup.number().required(),
    name: yup.string().required(),
    description: yup.string().required().nullable(),
    iosMinimalVersion: yup.string().required().nullable(),
    androidMinimalVersion: yup.number().required().nullable(),
    active: yup.boolean().required(),
    createdAt: yup.string().required(),
});

const FeatureFlagsResponse = yup.array(FeatureFlagSchema).required();

const CustomerServiceAppNetworkResponse = yup.object({
    status: yup.boolean().required(),
});

export class ConfigStore {
    @observable minimalAppConfig: MinimalAppConfig | null = null;
    @observable isFetchingMinimalAppConfig = false;
    @observable isUpdatingMinimalAppConfig = false;

    @observable featureFlags: FeatureFlags[] | null = null;
    @observable isFetchingFeatureFlags = false;

    @observable
    carwashCustomerServiceStatus: CarwashCustomerServiceStatus | null = null;
    @observable isfetchingCarwashCustomerServiceStatus = false;
    @observable isUpdatingCarwashCustomerServiceStatus = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    setMinimalAppConfig(this: ConfigStore, config: MinimalAppConfig) {
        this.minimalAppConfig = config;
    }

    @action
    setFeatureFlags(this: ConfigStore, featureFlags: FeatureFlags[]) {
        this.featureFlags = featureFlags;
    }

    @action
    setCarwashCustomerServiceStatus(
        this: ConfigStore,
        config: CarwashCustomerServiceStatus
    ) {
        this.carwashCustomerServiceStatus = config;
    }

    @action
    async fetchMinimalAppConfig(this: ConfigStore) {
        if (this.isFetchingMinimalAppConfig || this.minimalAppConfig) {
            return;
        }

        this.isFetchingMinimalAppConfig = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/config/app',
            },
            MinimalAppNetworkResponse
        );
        this.isFetchingMinimalAppConfig = false;

        if (!response.err) {
            this.setMinimalAppConfig(response.data);
        }
    }

    @action
    async fetchCarwashCustomerServiceStatus(this: ConfigStore) {
        if (
            this.isfetchingCarwashCustomerServiceStatus ||
            this.carwashCustomerServiceStatus
        ) {
            return;
        }

        this.isfetchingCarwashCustomerServiceStatus = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/config/carwash-customer-service',
            },
            CustomerServiceAppNetworkResponse
        );

        this.isfetchingCarwashCustomerServiceStatus = false;

        if (!response.err) {
            const config = {
                status: response.data.status ? 'active' : 'inactive',
            };
            this.setCarwashCustomerServiceStatus(config);
        }
    }

    @action
    async updateMinimalAppConfig(this: ConfigStore, config: MinimalAppConfig) {
        if (this.isUpdatingMinimalAppConfig) {
            return;
        }
        this.isUpdatingMinimalAppConfig = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PATCH,
            url: '/config/app',
            data: config,
        });
        this.isUpdatingMinimalAppConfig = false;

        if (!response.err) {
            NotificationManager.showSuccess('Config updated');
            this.setMinimalAppConfig(config);
        }
    }

    @action
    async updateCarwashCustomerServiceStatus(
        this: ConfigStore,
        config: CarwashCustomerServiceStatus
    ) {
        if (this.isUpdatingCarwashCustomerServiceStatus) {
            return;
        }
        this.isUpdatingCarwashCustomerServiceStatus = true;

        const data = {
            status: config.status === 'active',
        };

        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PATCH,
            url: '/config/carwash-customer-service',
            data: data,
        });
        this.isUpdatingCarwashCustomerServiceStatus = false;

        if (!response.err) {
            NotificationManager.showSuccess(
                'Carwash phone customer service updated'
            );
            this.setCarwashCustomerServiceStatus(config);
        }
    }

    @action
    async fetchFeatureFlags(this: ConfigStore) {
        this.isFetchingFeatureFlags = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/config/feature-flags',
            },
            FeatureFlagsResponse
        );
        this.isFetchingFeatureFlags = false;

        if (!response.err) {
            this.setFeatureFlags(response.data);
        }
    }

    @action
    async updateFetureFlags(this: ConfigStore, feature: FeatureFlags) {
        const data = {
            active: feature.active,
            iosMinimalVersion: feature.iosMinimalVersion,
            androidMinimalVersion: feature.androidMinimalVersion
                ? Number(feature.androidMinimalVersion)
                : null,
        };

        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PATCH,
            url: `/config/feature-flags/${feature.id}`,
            data,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Feature flag updated');
        }
    }
}
